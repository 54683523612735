<div class="uucc-header">
  <div class="uucc-header__filter">
    <bdr-input-filter [filters]="installationsHeader" (onSearch)="search = $event"></bdr-input-filter>
  </div>

  <div class="uucc-header__actions">
    <div class="uucc-header__toggle">
      <bdr-icon style="margin-left: 1em;" icon="ico-delete link" size="30px"
        title="Borrar los repartos de todas las tipología" (click)="fullSetToZeroAllAutomaticDistributions()">
      </bdr-icon>
    </div>
    <div class="uucc-header__toggle">
      <bdr-toggle-button id="uucc-detail" label="Detalle" (changed)="handleShowedColumns($event)"></bdr-toggle-button>
    </div>
    <div class="uucc-header__toggle">
      <bdr-toggle-button id="uucc-amounts" label="Importes" (changed)="handleQuantityAmount($event)">
      </bdr-toggle-button>
    </div>

    <div class="uucc-header__total">
      <span>{{ this.ucsTotal }}</span>
      <p>UCs</p>
    </div>
    <div class="uucc-header__total">
      <span>{{ uuccMtbtAmount }}</span>
      <p>€</p>
      <p>MT/BT</p>
    </div>
    <div class="uucc-header__total">
      <span>{{ uuccDispatchersAmount }}</span>
      <p>€</p>
      <p>Despachos</p>
    </div>
  </div>
</div>

<div class="uucc-table">
  <table mat-table [dataSource]="dataSource | filter: search" >
    <!-- Dynamic Columns -->
    <ng-container
      *ngFor="let column of installationsHeader; let i = index; trackBy: trackByFn"
      [matColumnDef]="column.name"
      [sticky]="isSticky(column.name)"
      >
      <th mat-header-cell *matHeaderCellDef>
        <b>{{ column.label }}</b>
        <ng-container *ngIf='column.label.includes("Total ") && !column.name.includes("Despacho-Digitalización")'>
          <bdr-icon style="margin-left: 1em;" icon="ico-down link" size="10"
            title="Repartir todas las UUCC a esta tipología" (click)="fullAutomaticDistribution(column.name)">
          </bdr-icon>
          <bdr-icon style="margin-left: 1em;" icon="ico-delete link" size="10"
            title="Borrar los repartos de esta tipología" (click)="fullRemoveAutomaticDistribution(column.name)">
          </bdr-icon>
        </ng-container>
      </th>

      <td mat-cell *matCellDef="let row" nowrap>
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'input'">
            <ng-container *ngIf="canEditCell(row.tipo_reparto, row.uucc_id, column.name, row); else noEditable">
              <div class="form-input" [ngClass]="{ editing: editableField?.focused }">

                <ng-container *ngIf="column.name === 'peso'; else distribution">
                  <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
                      (ngModelChange)="editUUCC($event, column.name, row)"
                      [value]="row[column.name]"
                  />
                </ng-container>
                <ng-template #distribution>
                  <ng-container *ngIf="isTotal(column.name); else isInstallations">
                    <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
                      (ngModelChange)="automaticDistribution($event, column.name, row)" [value]="row[column.name]" />
                  </ng-container>
                  <ng-template #isInstallations>
                    <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
                      (ngModelChange)="editUUCCDistributions($event, column.name, row, true)"
                      [value]="row[column.name]" />
                  </ng-template>
                </ng-template>
                <bdr-icon icon="ico-edit" size="10"></bdr-icon>
              </div>
            </ng-container>
            <ng-template #noEditable>0</ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'select'">
            <mat-select [(ngModel)]="row[column.name]" (ngModelChange)="editUUCC($event, column.name, row)"
              [compareWith]="compareFn" [disabled]="!canEditCell(row.tipo_reparto, row.uucc_id, column.name, row)">
              <mat-option *ngFor="let option of column.options" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </ng-container>

          <ng-container *ngSwitchDefault>{{ row[column.name] }}</ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Header row first group -->
    <ng-container *ngFor="let column of tipologyHeader" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="column.colspan">{{ column.label }}</th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedTipologyHeader; sticky: true " ></tr>
    <tr mat-header-row *matHeaderRowDef="displayedInstallationsHeader; sticky: true " class="second-row"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedInstallationsHeader" 
      [ngClass]="rowColor(row)" 
      [title]="getRowTitle(row.uucc_id)"
    ></tr>
  </table>
</div>

<bdr-splash *ngIf="isLoading" title="Guardando cambios realizados" [descriptions]="['Espere, por favor']"></bdr-splash>
