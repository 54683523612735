<div class="wc-breadcrumbs">
  <a [routerLink]="['/investments-sheet']">
    Sábana de inversiones
  </a>
  <a [routerLink]="['/investments-sheet/' + workClosureOriginView.level1Url]">
    > {{ workClosureOriginView.level1Description }}
  </a>
  <a [routerLink]="['/investments-sheet/' + workClosureOriginView.level1Url + '/' + workClosureOriginView.level2Url]">
    > {{ workClosureOriginView.level2Description }}
  </a>
  > Cierre de obra
</div>

<div class="wc-description bdr-card">
  <div class="wc-description__header">
    <div class="wc-description__info">
      <button *ngIf="previousWork" class="wc-description__button--back link" [title]="previousWork"
        (click)="navigateWorkClosure(previousWork)">
        <bdr-icon icon="ico-left" size="27px"></bdr-icon>
      </button>
      <div class="wc-description__content">
        <div class="wc-description__description">
          <div class="wc-description__title">
            Obra
            <span class="wc-description__total">{{ workClosure?.workcode }}</span>
            Importe
            <span class="wc-description__total">
              {{ (workClosure?.amount ? workClosure?.amount : 0) | localeCurrency }}
            </span>
            (MTBT
            <span class="wc-description__total2">
              {{ (workClosure?.mtbt_amount ? workClosure?.mtbt_amount : 0) | localeCurrency }}
            </span>
            , Despachos
            <span class="wc-description__total2">
              {{ (workClosure?.dispatchers_amount ? workClosure?.dispatchers_amount : 0) | localeCurrency }}
            </span>)
          </div>
        </div>

        <p class="wc-description__subtitle">
          <span>Descripción <span class="wc-description__total" [title]="workClosure?.full_address">{{
              workClosure?.address }} </span></span>
          <span>Responsable <span class="wc-description__total">{{ workClosure?.responsable }} </span></span>
        </p>

        <p class="wc-description__subtitle">
          <span>Nombre APM <span class="wc-description__total">{{ workClosure?.apm }} </span></span>
          <span>Salidas de baja tensión <span class="wc-description__total">{{ workClosure?.low_voltage_outputs }}
            </span></span>
        </p>

        <p class="wc-description__subtitle">
          Cesiones
          <span class="wc-description__total">
            {{ (workClosure?.mtbt_transferred_amount ? workClosure?.mtbt_transferred_amount : 0) | localeCurrency }}
          </span>
          <span>Validaciones UFD</span>
          <span class="wc-description__total">
            <ng-container *ngFor="let validation of workClosure?.ufd_validations">
              <span class="validations fake-icon" [title]="validation.description">{{
                validation.id
                }}</span>
            </ng-container>
            <ng-container *ngFor="let validation of workClosure?.dispatchers_validations">
              <span class="validations fake-icon" [title]="validation.description">{{
                validation.id
                }}</span>
            </ng-container>
          </span>

          <span>Validaciones imp. automáticos</span>
          <span class="wc-description__total">
            <ng-container *ngFor="let validation of workClosure?.panic_button_validations">
              <span class="validations fake-icon" [title]="validation.description">{{
                validation.id
                }}</span>
            </ng-container>
          </span>
        </p>

      </div>
    </div>
    <div class="wc-description__actions">
      <mat-form-field style="padding-top: 2em;">
        <mat-label>Importes automáticos</mat-label>
        <mat-select
          [disabled]="!canSwitchAutomaticAmount"
          [(ngModel)]="automaticAmounts"
          (ngModelChange)="handleAutomaticAmounts($event)">
          <mat-option
            *ngFor="let option of automaticAmountsOptions"
            [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>



      <div class="wc-description__pending-changes-work">
        <bdr-icon *ngIf="pendingChanges" class="wc-description__pending-changes--icon" style="padding-left: 2em;"
          size="27px" icon="ico-alert"
          title="Esta obra tiene cambios pendientes no consolidados. No se recomienda realizar modificaciones">
        </bdr-icon>
      </div>

      <div class="wc-description__revised-work">
        <bdr-icon class="wc-description__revised-work--icon" (click)="onRevisedWork()" style="padding-left: 2em;"
          size="27px" icon="{{ revisedWork ? 'ico-ok' : 'ico-ko' }}"
          title="{{ revisedWork ? 'Obra revisada' : 'Obra no revisada' }}"></bdr-icon>
      </div>

      <div class="wc-description__check">
        <bdr-icon *ngIf="!canCheckOut() && canCheckIn()" style="padding-left: 2em;" (click)="checkIn()"
          icon="ico-unlock" size="30px"></bdr-icon>

        <bdr-icon *ngIf="!canCheckOut() && !canCheckIn()" style="padding-left: 2em;" class="disabled" icon="ico-lock"
          size="30px" title="La obra está bloqueada por otro usuario"></bdr-icon>

        <bdr-icon *ngIf="canCheckOut()" style="padding-left: 2em;" (click)="checkOut()" class="orange" icon="ico-lock"
          size="30px" [title]="nonEditableMessage"></bdr-icon>
      </div>

      <!-- <div class="wc-description__undo-button-workclosure">
        <bdr-icon *ngIf="hasChanges(); else noChanges" class="wc-description__undo-button-workclosure--icon"
          style="padding-left: 2em;" (click)="undo()" size="30px" icon="ico-undo" title="Deshacer todos los cambios">
        </bdr-icon>

        <ng-template #noChanges>
          <bdr-icon class="wc-description__undo-button-workclosure--icon disabled" style="padding-left: 2em;"
            size="30px" icon="ico-undo" title="Deshacer todos los cambios"></bdr-icon>
        </ng-template>
      </div> -->
      


      <div class="wc-description__save-button-work">
        <bdr-icon *ngIf="canSave(); else cantSave" style="padding-left: 2em;"
          (click)="pendingChanges? confirmSaveWorkClosure() : saveWorkClosure()"
          class="wc-description__save-button-work--icon" icon="ico-save" title="Guardar" size="30px"></bdr-icon>

        <ng-template #cantSave>
          <bdr-icon style="padding-left: 2em;" class="wc-description__save-button-work--icon disabled" icon="ico-save"
            size="30px" [title]="nonEditableMessage"></bdr-icon>
        </ng-template>
      </div>

      <div class="wc-description_save-button-work">
        <bdr-icon class="wc-description__save-button-work--icon" style="padding-left: 2em; padding-right: 2em;"
          (click)="deleteManualChanges()" size="30px" icon="ico-undo" title="Borrar todos los cambios manuales de la obra">
        </bdr-icon>
      </div>
      <div class="wc-description_save-button-work">
        <bdr-icon class="wc-description__save-button-work--icon" style="padding-left: 2em; padding-right: 2em;"
          (click)="undo()" size="30px" icon="ico-update" title="Recargar página"></bdr-icon>
      </div>

      <div class="wc-description__simulate-works">
        <bdr-icon (click)="reloadWorkClosure()" style="padding-left: 2em;" icon="ico-process" size="30px"
          title="Simular Cierre de Obra"></bdr-icon>
      </div>

      <div class="wc-description__next-button">
        <button *ngIf="nextWork" class="wc-description__button--next link" [title]="nextWork"
          (click)="navigateWorkClosure(nextWork)">
          <bdr-icon icon="ico-right" size="30px" style="padding-left: 4em;"></bdr-icon>
        </button>
      </div>

    </div>

  </div>
</div>

<div class="wc-menu bdr-card">
  <div class="wc-menu__tabs">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab"
      (selectedIndexChange)="handleSelectedTab($event)">
      <mat-tab label="Instalaciones">
        <ng-template matTabContent>
          <closure-installations 
            [items]="installations" 
            [workCode]="workClosure?.workcode" 
            [user]="payload.user_id"
            [automaticAmounts]="automaticAmounts"
            (onAssociateInstallation)="onAssociateInstallation($event)"
            (onEditInstallation)="onEditInstallation($event)" 
            (onEditedStartupAct)="onEditedStartupAct($event)">
          </closure-installations>
        </ng-template>
      </mat-tab>

      <mat-tab label="UUCC">
        <closure-uucc [workcode]="workcode" 
          [retributiveYear]="retributiveYear" 
          [items]="uucc"
          [automaticAmounts]="automaticAmounts" 
          (onEditUUCC)="onEditUUCC($event)"
          (onEditUUCCDistribution)="onEditUUCCDistribution($event)"
        >
        </closure-uucc>
      </mat-tab>

      <mat-tab label="SAP">
        <ng-template matTabContent>
          <closure-sap [workcode]="workcode" [items]="workClosure?.sap">
          </closure-sap>
        </ng-template>
      </mat-tab>

      <mat-tab label="Reparto">
        <closure-distribution [workcode]="workcode" [distributions]="workClosure?.distribution"
          (onEditDistribution)="onEditDistribution($event)">
        </closure-distribution>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<bdr-splash *ngIf="isLoading" [title]="splashMessage" [descriptions]="['Espere, por favor']"></bdr-splash>


<bdr-splash *ngIf="saving" title="Guardando cambios realizados" [descriptions]="['Espere, por favor']"></bdr-splash>

<bdr-splash *ngIf="!transactionalWorkClosure && show19Splash" [icon]="true"
  title="La obra no está registrada en el transaccional (validación 19)"
  [descriptions]="['Pulse en este diálogo si desea continuar']" (onClick)='close19Splash()'></bdr-splash>

<bdr-splash *ngIf="!transactionalWorkClosureAnyYear && show20Splash" [icon]="true"
  title="La obra no está registrada en el transaccional de ningún año (validación 20)"
  [descriptions]="['Pulse en este diálogo si desea continuar']" (onClick)='close20Splash()'></bdr-splash>

<bdr-splash *ngIf="pendingChanges && showSplash" [icon]="true"
  [descriptions]="['Puede seguir trabajando con la obra pero es posible que encuentre o genere inconsistencias.', 'Se recomienda ejecutar la Sábana de Inversiones o la simulación del Cierre de Obra.', 'Pulse en este diálogo si desea continuar.']"
  iconImage="ico-alert" title="Esta obra tiene cambios pendientes sin consolidar" (onClick)="closeSplash()">
</bdr-splash>

<bdr-dialog-list></bdr-dialog-list>