import { Component, OnInit, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataApiService } from '../../services/data-api/data-api.service';
import { AuthService } from '../../services/auth/auth.service';
import { retryWhen, concatMap, delay, map } from 'rxjs/operators';
import { iif, throwError, of } from 'rxjs';
import { WorkClosureService } from './service/work-closure.service';
import { MastersService } from '../../services/masters/masters.service';
import { UserPermissionsService } from '../../services/user-permissions/user-permissions.service';

import { MASTER_AMENDMENT_TYPES } from '../../shared/interfaces/master-data.interface';
import {
  NotificationsService,
  Notification,
} from '../../services/notifications/notifications.service';
import {
  COHERENCE_PROCESSES_MTBT,
  COHERENCE_PROCESSES_DISPATCHERS,
  PANIC_BUTTON_VALIDATIONS_NICENAME,
} from '../../services/bdr-validations/bdr-validations.service';
import { TasksService } from '../../services/tasks/tasks.service';
import { cloneDeep } from 'lodash';
import { EditedInstallation } from '../../shared/interfaces/edited-installations.interface';
import { ManualInstallation } from '../../../app/models/common';
import { DialogConfirmComponent } from '../dialog/dialog-confirm/dialog-confirm.component';
import { DialogService } from '../dialog/dialog.service';

const INVESTMENTS_SHEET_MTBT_AGGREGATE_MANUAL = 'sabana_inversiones_mtbt_agregada_manual';

const NON_BLOCKED_WORK = 'Debe bloquear la obra para poder realizar cambios';
const AUTOMATIC_AMOUNT_VALIDATIONS =
  'Esta obra no tiene importes automáticos (revise las validaciones)';
const BLOCKED_BY_OTHERS_WORK = 'La obra está bloqueada por otro usuario';
const CLOSED_AUDITORY = 'La Auditoría está cerrada';
const WORK_CLOSURE_BLOCKED = 'Las ediciones del Cierre de Obra están bloqueadas';

@Component({
  selector: 'work-closure',
  templateUrl: './work-closure.component.html',
  styleUrls: ['./work-closure.component.scss'],
})
export class WorkClosureComponent implements OnInit {
  selectedTab: number = 0;
  workClosure;
  isWorkBlocked: boolean;
  profile: any;
  payload: any;
  workcode: string;
  manualCheckin: boolean = false;
  isLoading: boolean = false;
  splashMessage: string;
  retributiveYear: string;
  revisedWork: boolean;
  panicButtonWork: boolean = false;
  workCodesAggregates: string[] = [];
  previousWork: string;
  nextWork: string;
  validationsUFD = COHERENCE_PROCESSES_MTBT;
  validationsDispatchers = COHERENCE_PROCESSES_DISPATCHERS;
  validationsPanicButton = PANIC_BUTTON_VALIDATIONS_NICENAME;
  isBlockedWorkClosureEdition: boolean = false;
  isClosedAuditory: boolean = false;

  uucc: any;
  installations: any;

  mtbtAmount: number;
  dispatchersAmount: number;
  transferredAmount: number;

  workClosureOriginView: any = {
    level1Url: '',
    level2Url: '',
    level1Description: '',
    level2Description: '',
  };

  isEditable: boolean = true;
  nonEditableMessage: string = '';
  transactionalWorkClosure = true;
  transactionalWorkClosureAnyYear = true;
  pendingChanges = false;
  showSplash = true;

  automaticAmounts: string = 'NO';
  automaticAmountsOptions: any[] = ['SI', 'NO'];
  saving = false;

  AMOUNTS_FIELDS = [
    'peso_presupuesto_ufd',
    'peso_presupuesto_cedido',
    'importe_uucc_ufd',
    'importe_uucc_cedido',
    'importe_instalacion_ufd',
    'importe_instalacion_cedido',
    'importe_total',
    'comparativa_vur',
  ];

  canSwitchAutomaticAmount: boolean = false;
  automaticAmountDisableReason: string = '';

  manualInstallations: ManualInstallation[] = [];
  editedInstallations: EditedInstallation[] = [];
  editedUUCCs = [];
  editedUUCCDistributions = [];
  editedDistribution;
  editedStartupAct;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataApiService,
    private tasksService: TasksService,
    private workClosureService: WorkClosureService,
    private mastersService: MastersService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private userPermissionsService: UserPermissionsService,
  ) {}

  setNonEditableMessage() {
    if (this.isBlockedWorkClosureEdition) {
      this.nonEditableMessage = WORK_CLOSURE_BLOCKED;
    } else if (this.isWorkBlocked && !this.canCheckOut()) {
      this.nonEditableMessage = BLOCKED_BY_OTHERS_WORK;
      this.automaticAmountDisableReason = this.nonEditableMessage;
    } else if (!this.isWorkBlocked) {
      this.nonEditableMessage = NON_BLOCKED_WORK;
      this.automaticAmountDisableReason = this.nonEditableMessage;
    } else if (this.workClosure && this.workClosure.panic_button_validations.length > 0) {
      this.automaticAmountDisableReason = AUTOMATIC_AMOUNT_VALIDATIONS;
    } else {
      this.nonEditableMessage = '';
    }
  }

  ngOnInit() {
    this.setRetributiveYear();
    this.getParams();
    this.getWorkCodes();

    this.notificationsService.getNotificationObservable().subscribe((notifications) => {
      this.isClosedAuditory = notifications.some((n) => n.class === 'closed_auditory');
      this.isBlockedWorkClosureEdition = notifications.some((n) => n.class === 'block_work_clouse');

      if (this.isClosedAuditory) {
        this.nonEditableMessage = CLOSED_AUDITORY;
      } else if (this.isBlockedWorkClosureEdition) {
        this.nonEditableMessage = WORK_CLOSURE_BLOCKED;
      } else {
        this.setNonEditableMessage();
      }

      this.isEditable = !!(
        this.isWorkBlocked &&
        !this.canCheckIn() &&
        this.canCheckOut() &&
        !this.isClosedAuditory &&
        !this.isBlockedWorkClosureEdition
      );

      this.updateCanSwitchAutomaticAmount();

      this.detectChanges();
    });
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  private updateCanSwitchAutomaticAmount() {
    let isWorkClosureAutomaticAmountsValid =
      this.isClosedAuditory === false &&
      this.isBlockedWorkClosureEdition === false &&
      this.isWorkBlocked === true &&
      this.workClosure != undefined &&
      this.workClosure.panic_button_validations.length === 0;

    this.canSwitchAutomaticAmount = isWorkClosureAutomaticAmountsValid;
  }

  private checkTipologiesError(installations) {
    let installationTipologies = {};
    installations
      .filter((i) => i.origen_instalacion && i.tipologias_actuacion)
      .forEach((installation) => {
        let installationCode = installation.codigo_bdr_instalaciones;
        let tipologies = installationTipologies[installationCode] || [];
        tipologies.push(installation.tipologias_actuacion);
        installationTipologies[installationCode] = tipologies;
      });
    return Object.keys(installationTipologies).filter(
      (t) => installationTipologies[t].length !== new Set(installationTipologies[t]).size,
    );
  }

  addNotification(type: 'confirmation' | 'error' | 'confirmation', message: string) {
    const saveNotification: Notification = {
      fixed: false,
      popup: true,
      type,
      message,
    };
    setTimeout(() => {
      this.notificationsService.add(saveNotification);
    }, 0);
  }

  private setRetributiveYear() {
    this.mastersService.getRetributiveYear().subscribe((year) => (this.retributiveYear = year));
  }

  private getParams() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.params.pipe(map((params) => params.id)).subscribe((id) => {
      if (id) {
        this.workcode = id;
        this.getProfile();
        this.getInstallations(id);
      }
    });

    this.route.url.pipe(map((url) => url)).subscribe((url) => {
      if (url) {
        if (url.toString().includes('mtbt')) {
          this.setWorkClosureOriginView('mtbt');
        } else if (url.toString().includes('dispatchers')) {
          this.setWorkClosureOriginView('dispatchers');
        } else if (url.toString().includes('reclassification')) {
          this.setWorkClosureOriginView('reclassification');
        } else if (url.toString().includes('distribution')) {
          this.setWorkClosureOriginView('distribution');
        }
      }
    });
  }

  private setWorkClosureOriginView(origin) {
    let level2Url;
    let level1Description;
    let level2Description;

    let level1Url = origin;
    switch (origin) {
      case 'mtbt':
        level2Url = 'sabana_inversiones_mtbt_agregada_vista';
        level1Description = 'MT/BT';
        level2Description = 'Agregado por obra';
        break;

      case 'dispatchers':
        level2Url = 'sabana_inversiones_despachos_agregado_obra_vista';
        level1Description = 'Despachos';
        level2Description = 'Agregado por obra';
        break;

      case 'reclassification':
        level2Url = 'sap_incurridos_manual_registro_vista';
        level1Description = 'Reclasificaciones';
        level2Description = 'Histórico Reclasificaciones';
        break;
    }

    this.workClosureOriginView = {
      level1Url: level1Url,
      level2Url: level2Url,
      level1Description: level1Description,
      level2Description: level2Description,
    };
  }

  reloadWorkClosure() {
    this.tasksService.reloadWorkClosure();
  }

  private getWorkCodes() {
    this.workCodesAggregates = this.workClosureService.getWorkCodes();
    this.setPaginationWorkCodes();
  }

  private setPaginationWorkCodes() {
    const index = this.getIndexWorkCodeAggregate(this.workcode);
    this.previousWork = this.workCodesAggregates[index - 1];
    this.nextWork = this.workCodesAggregates[index + 1];
  }

  navigateWorkClosure(workCode: string) {
    this.router.navigate([`/investments-sheet/mtbt/work-closure/${workCode}`]);
  }

  back() {
    this.router.navigate([
      `/investments-sheet/${this.workClosureOriginView.level1Url}/${this.workClosureOriginView.level2Url}`,
    ]);
  }

  private getIndexWorkCodeAggregate(workCode: string) {
    return this.workCodesAggregates.findIndex((work) => work === this.workcode);
  }

  private getProfile() {
    this.authService.getProfile().subscribe((profile) => {
      this.profile = profile;
      this.payload = {
        user_id: this.profile.email,
        workcode: this.workcode,
      };
    });
  }

  getInstallations(workId: string) {
    this.isLoading = true;
    this.splashMessage = 'Cargando registros';
    const mapValidations = (work) => {
      var validations = {};

      if (work.ufd_validations.length > 0) {
        validations = {
          ufd_validations: work.ufd_validations.map((val) => ({
            id: val,
            description:
              this.validationsUFD[val] !== undefined
                ? this.validationsUFD[val].name
                : this.validationsDispatchers[val].name,
          })),
        };
      }

      return validations;
    };

    const mapDispatchersValidations = (work) => {
      const splitValidation = work.dispatchers_validations
        ? work.dispatchers_validations.split('|')
        : [];
      const validations = {
        dispatchers_validations: splitValidation.map((val) => ({
          id: val,
          description: this.validationsDispatchers[val].name,
        })),
      };

      return validations;
    };

    const mapPanicButtonValidations = (work) => {
      const splitValidation = work.panic_button_validations
        ? work.panic_button_validations.split('|')
        : [];
      const validations = {
        panic_button_validations: splitValidation.map((val) => ({
          id: val,
          description: this.validationsPanicButton[val].name,
        })),
      };

      return validations;
    };

    this.dataService
      .getWorkClosure(workId, this.payload.user_id)
      .pipe(
        map((work) => ({
          ...work,
          ...(work.ufd_validations && mapValidations(work)),
          ...(work.panic_button_validations && mapPanicButtonValidations(work)),
        })),
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => iif(() => i > 10, throwError(e), of(e).pipe(delay(500)))),
          ),
        ),
      )
      .subscribe((work) => {
        this.isLoading = false;

        this.workClosure = work;

        this.mtbtAmount = this.workClosure.mtbt_amount;
        this.dispatchersAmount = this.workClosure.dispatchers_amount;
        this.transferredAmount = this.workClosure.mtbt_transferred_amount;

        this.uucc = this.workClosure.uucc;
        this.installations = this.workClosure.installations;

        this.automaticAmounts = this.workClosure.work_closure.boton_panico ? 'SI' : 'NO';
        this.setAutomaticAmounts(this.automaticAmounts);

        this.installations.installations_detail.data.forEach((installation) => {
          installation.codigo_obra_original_aux = installation.codigo_obra;
          installation.codigo_obra = this.workClosure.workcode;
        });

        this.transactionalWorkClosure = work.is_loaded_in_transactional;
        this.transactionalWorkClosureAnyYear = work.is_loaded_in_transactional_any_year;

        if (this.transactionalWorkClosureAnyYear) {
          this.show20Splash = false;
        } else if (this.transactionalWorkClosure) {
          this.show19Splash = false;
        }

        let totalRepartos = [];
        let insts_id = [];
        this.uucc.installations_header.forEach(function (header, index, array) {
          let alias_total = 'Total ' + (index + 1);
          let filtered = header.installations
            .filter((i) => i.alias != alias_total)
            .map((ins) => {
              return ins.installation_id;
            });
          insts_id.push(...filtered);
        });

        this.uucc.uucc_matrix.forEach((uucc) => {
          insts_id.forEach((ins_id) => {
            totalRepartos.push({
              uucc_id: uucc.uucc_id,
              reparto: uucc[ins_id] ? uucc[ins_id] : 0,
              instalacion: ins_id,
            });
          });
        });
        this.uucc.uucc_reparto = totalRepartos;

        var virtual_index = this.uucc.uucc_matrix.findIndex((el) => el.uucc_id === '@VIRTUAL_UFD');
        var virtual_appointment = this.uucc.uucc_matrix.splice(virtual_index, 1);
        this.uucc.uucc_matrix.push(virtual_appointment[0]);

        virtual_index = this.uucc.uucc_matrix.findIndex((el) => el.uucc_id === '@VIRTUAL_CESION');
        virtual_appointment = this.uucc.uucc_matrix.splice(virtual_index, 1);
        this.uucc.uucc_matrix.push(virtual_appointment[0]);

        virtual_index = this.uucc.uucc_matrix.findIndex(
          (el) => el.uucc_id === '@VIRTUAL_DESPACHOS',
        );
        virtual_appointment = this.uucc.uucc_matrix.splice(virtual_index, 1);
        this.uucc.uucc_matrix.push(virtual_appointment[0]);

        this.revisedWork = this.workClosure.work_closure.obra_revisada;
        this.panicButtonWork = this.workClosure.work_closure.boton_panico;
        this.isWorkBlocked = this.workClosure.work_closure && this.workClosure.work_closure.blocked;
        this.pendingChanges = this.workClosure.work_closure.pending_changes;

        let distribution = this.workClosure.distribution;
        distribution.headers = distribution.headers.map((header) => ({
          name: header.field,
          label: header.title,
          options: header.options,
          type: 'select',
        }));

        distribution.rows = distribution.rows ? cloneDeep([distribution.rows]) : [];

        this.setNonEditableMessage();
        this.updateCanSwitchAutomaticAmount();
      });
  }

  handleSelectedTab(index) {
    this.selectedTab = index;
  }

  checkIn() {
    this.isLoading = true;
    this.splashMessage = 'Bloqueando obra';

    this.dataService
      .workClosureCheckIn(this.payload)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => iif(() => i > 10, throwError(e), of(e).pipe(delay(500)))),
          ),
        ),
      )
      .subscribe((check) => {
        this.isLoading = false;
        this.isWorkBlocked = true;
        this.manualCheckin = true;
        this.setNonEditableMessage();
        this.updateCanSwitchAutomaticAmount();
      });
  }

  checkOut() {
    this.isLoading = true;
    this.splashMessage = 'Desbloqueando obra';
    this.dataService
      .workClosureCheckOut(this.payload)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => iif(() => i > 10, throwError(e), of(e).pipe(delay(500)))),
          ),
        ),
      )
      .subscribe((check) => {
        this.isLoading = false;
        this.isWorkBlocked = false;
        this.setNonEditableMessage();
        this.updateCanSwitchAutomaticAmount();
      });
  }

  canCheckOut() {
    return (
      this.isWorkBlocked &&
      (this.userPermissionsService.canCheckoutAllWorks ||
        (this.workClosure.work_closure &&
          this.profile.email === this.workClosure.work_closure.user_id) ||
        this.manualCheckin)
    );
  }

  canCheckIn() {
    return !this.isWorkBlocked;
  }

  canEdit() {
    return this.isEditable;
  }

  onRevisedWork() {
    this.revisedWork = !this.revisedWork;
    const isRevised = this.revisedWork ? 'SI' : 'NO';

    let amendment = {
      table: INVESTMENTS_SHEET_MTBT_AGGREGATE_MANUAL,
      amendment_type: MASTER_AMENDMENT_TYPES.UPSERT,
      primary_keys: {
        anio_contable: this.retributiveYear,
        codigo_obra: this.workClosure.workcode,
      },
      new_values: { obra_revisada: isRevised },
    };

    let payload = {
      amendments: [amendment],
    };

    this.isLoading = true;
    this.splashMessage = 'Modificando estado de la obra';
    this.dataService.putAmendments(payload).subscribe(
      (res) => {
        this.isLoading = false;
        const success = {
          type: 'confirmation',
          message: 'Se ha modificado correctamente el estado de la obra',
          fixed: false,
          popup: true,
        };
        this.notificationsService.add(success);
      },
      (err) => {
        this.revisedWork = !this.revisedWork;
        const error = {
          type: 'error',
          message: 'Ha ocurrido un error modificando el estado de la obra',
          fixed: false,
          popup: true,
        };
        this.notificationsService.add(error);
      },
    );
  }

  /*
   * MANUAL
   */

  hasChanges() {
    return (
      this.manualInstallations.length > 0 ||
      this.editedInstallations.length > 0 ||
      this.editedUUCCs.length > 0 ||
      this.editedUUCCDistributions.length > 0 ||
      this.editedDistribution ||
      this.editedStartupAct
    );
  }

  canSave() {
    return this.canCheckOut() && this.hasChanges() && !this.isBlockedWorkClosureEdition;
  }

  confirmSaveWorkClosure(evt): void {
    const dialogOptions: any = {
      component: DialogConfirmComponent,
      size: { width: 50, height: 50 },
      fixed: true,
      bindings: {
        inputs: {
          buttonText: 'Confirmar',
          content:
            '<p>Esta obra tiene cambios no consolidados. Cualquier modificación que guarde puede generar inconsistencias. </p>' +
            '<p>¿Está seguro de que desea continuar?</p>',
        },
        outputs: {
          confirm: this.generateActionConfirm.bind(this, evt),
        },
      },
      notHeader: true,
    };
    this.dialogService.createDialog(dialogOptions);
  }

  generateActionConfirm(id) {
    this.dialogService.closeDialog(id);
    this.saveWorkClosure();
  }

  saveWorkClosure() {
    this.saving = true;
    this.dataService
      .saveWorkClosure(this.workcode, {
        manual_installations: this.manualInstallations,
        edited_installations: this.editedInstallations,
        edited_uucc: this.editedUUCCs,
        edited_uucc_distributions: this.editedUUCCDistributions,
        edited_distribution: this.editedDistribution,
        edited_startup_act: this.editedStartupAct,
      })
      .subscribe(
        () => {
          this.saving = false;
          this.addNotification('confirmation', 'Modificaciones guardadas correctamente');
        },
        () => {
          this.addNotification('error', 'Se ha producido un error');
        },
      );
  }

  undo() {
    this.getParams();
  }

  deleteManualChanges() {
    this.dataService.deleteAllWorkClosureManualChanges(this.workcode).subscribe(
      () => {
        this.saving = false;
        this.addNotification(
          'confirmation',
          'Se han borrado correctamente las manualidades de la obra',
        );
        this.undo();
      },
      () => {
        this.addNotification('error', 'Se ha producido un error');
      },
    );
  }

  onAssociateInstallation(associatedInstallations) {
    this.installations.installations_detail.data = associatedInstallations.installations;
    this.manualInstallations = this.manualInstallations.concat(
      associatedInstallations.installationsToSend,
    );
    this.installations = cloneDeep(this.installations);
  }

  onEditUUCCDistribution(event) {
    let editedUUCCDistribution = event.editedUUCCDistribution;
    const uuccDistribution = this.editedUUCCDistributions.find((m) => {
      return (
        m.tipologias_actuacion === editedUUCCDistribution.tipologias_actuacion &&
        m.uucc === editedUUCCDistribution.uucc &&
        m.cod_imputacion_uucc === editedUUCCDistribution.cod_imputacion_uucc &&
        m.trabajo === editedUUCCDistribution.trabajo &&
        m.mei === editedUUCCDistribution.mei &&
        m.instalacion === editedUUCCDistribution.instalacion
      );
    });

    if (uuccDistribution) {
      uuccDistribution.cantidad = editedUUCCDistribution.cantidad;
    } else {
      this.editedUUCCDistributions.push(editedUUCCDistribution);
    }

    this.updateInstallationsAmounts(event.uucc);
  }

  onEditUUCC(event) {
    let editedUUCC = event.editedUUCC;

    const existingEditedUUCC = this.editedUUCCs.find(
      (manual) =>
        manual.uucc === editedUUCC.uucc &&
        manual.cod_imputacion_uucc === editedUUCC.cod_imputacion_uucc &&
        manual.trabajo === editedUUCC.trabajo &&
        manual.mei === editedUUCC.mei,
    );

    if (existingEditedUUCC) {
      existingEditedUUCC[event.field] = editedUUCC[event.field];
    } else {
      this.editedUUCCs.push(editedUUCC);
    }
    this.updateInstallationsAmounts(event.uucc);
  }

  private updateInstallationsAmounts(fullUuccMatrix) {
    let uuccMatrix = fullUuccMatrix;

    let uuccMtbtAmountSum = uuccMatrix
      .filter(
        (uucc) =>
          uucc.tipo_reparto === 'UFD' &&
          uucc.tipo_importe === 'MT/BT' &&
          Object.keys(uucc)
            .filter((u) => u.includes('Total_') && u !== 'Total_Despacho-Digitalización')
            .some((k) => uucc[k] > 0),
      )
      .map((uucc) => uucc.importe_mtbt * uucc.peso)
      .reduce((a, b) => a + b, 0);

    let uuccDispatchersAmountSum = uuccMatrix
      .filter(
        (uucc) =>
          uucc.tipo_reparto === 'UFD' &&
          uucc.tipo_importe === 'Despachos' &&
          Object.keys(uucc)
            .filter((u) => u.includes('Total_') && u !== 'Total_Despacho-Digitalización')
            .some((k) => uucc[k] > 0),
      )
      .map((uucc) => uucc.importe_mtbt * uucc.peso)
      .reduce((a, b) => a + b, 0);

    let uuccTransferredMtbtAmountSum = uuccMatrix
      .filter(
        (uucc) =>
          uucc.tipo_reparto === 'Cesión' &&
          Object.keys(uucc)
            .filter((u) => u.includes('Total_') && u !== 'Total_Despacho-Digitalización')
            .some((k) => uucc[k] > 0),
      )
      .map((uucc) => uucc.importe_mtbt * uucc.peso)
      .reduce((a, b) => a + b, 0);

    let totalAmount = this.mtbtAmount + this.dispatchersAmount;

    let mtbtAmountWeight = this.mtbtAmount / totalAmount;
    let dispatchersAmountWeight = this.dispatchersAmount / totalAmount;
    this.installations.installations_detail.data.forEach((i) => {
      if (totalAmount && totalAmount > 0) {
        if (totalAmount > 0) {
          let installationUuccMtbtAmount = uuccMatrix
            .filter((uucc) => {
              return (
                uucc.tipo_reparto === 'UFD' &&
                uucc.tipo_importe === 'MT/BT' &&
                uucc.cantidad_instalada > 0 &&
                uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`]
              );
            })
            .map((uucc) => {
              return (
                (uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`] *
                  uucc.importe_mtbt *
                  uucc.peso) /
                uucc.cantidad_instalada
              );
            })
            .reduce((a, b) => a + b, 0);

          let installationUuccDispatchersAmount = uuccMatrix
            .filter((uucc) => {
              return (
                uucc.tipo_reparto === 'UFD' &&
                uucc.tipo_importe === 'Despachos' &&
                uucc.cantidad_instalada > 0 &&
                uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`]
              );
            })
            .map((uucc) => {
              return (
                (uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`] *
                  uucc.importe_mtbt *
                  uucc.peso) /
                uucc.cantidad_instalada
              );
            })
            .reduce((a, b) => a + b, 0);

          i.importe_uucc_ufd = (
            installationUuccMtbtAmount + installationUuccDispatchersAmount
          ).toFixed(2);
          let numeric_peso_presupuesto_ufd = 0;

          if (uuccMtbtAmountSum > 0) {
            numeric_peso_presupuesto_ufd +=
              mtbtAmountWeight * ((installationUuccMtbtAmount * 100) / uuccMtbtAmountSum);
          }

          if (uuccDispatchersAmountSum > 0) {
            numeric_peso_presupuesto_ufd +=
              dispatchersAmountWeight *
              ((installationUuccDispatchersAmount * 100) / uuccDispatchersAmountSum);
          }

          i.importe_instalacion_ufd = ((totalAmount * numeric_peso_presupuesto_ufd) / 100).toFixed(
            2,
          );
          i.peso_presupuesto_ufd = numeric_peso_presupuesto_ufd.toFixed(2);
        } else {
          i.importe_uucc_ufd = 0;
          i.peso_presupuesto_ufd = 0;
          i.importe_instalacion_ufd = 0;
        }
      }

      if (this.transferredAmount && this.transferredAmount > 0) {
        if (uuccTransferredMtbtAmountSum > 0) {
          let installationTransferredUuccMtbtAmount = uuccMatrix
            .filter((uucc) => {
              return (
                uucc.tipo_reparto === 'Cesión' &&
                uucc.cantidad_instalada > 0 &&
                uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`]
              );
            })
            .map((uucc) => {
              return (
                (uucc[`${i.tipologias_actuacion}||${i.codigo_bdr_instalaciones}`] *
                  uucc.importe_mtbt *
                  uucc.peso) /
                uucc.cantidad_instalada
              );
            })
            .reduce((a, b) => a + b, 0);

          i.importe_uucc_cedido = installationTransferredUuccMtbtAmount.toFixed(2);
          i.peso_presupuesto_cedido = (
            (installationTransferredUuccMtbtAmount * 100) /
            uuccTransferredMtbtAmountSum
          ).toFixed(2);
          i.importe_instalacion_cedido = (
            (this.transferredAmount * i.peso_presupuesto_cedido) /
            100
          ).toFixed(2);
        } else {
          i.importe_uucc_cedido = 0;
          i.peso_presupuesto_cedido = 0;
          i.importe_instalacion_cedido = 0;
        }
      }

      i.importe_total = (
        this.toNumber(i.importe_instalacion_ufd) +
        this.toNumber(i.importe_instalacion_cedido) +
        this.toNumber(i.importe_general) +
        this.toNumber(i.importe_ingenieria) +
        this.toNumber(i.importe_tramitaciones) +
        this.toNumber(i.importe_supervision) +
        this.toNumber(i.importe_seguridad) +
        this.toNumber(i.importe_moi) +
        this.toNumber(i.importe_centro) +
        this.toNumber(i.importe_galicia)
      ).toFixed(2);

      i.comparativa_vur = ((i.importe_total * 100) / i.vtr).toFixed(2);
    });
  }

  private toNumber(x: string) {
    return Number(x || '0');
  }

  onEditDistribution(editedDistribution) {
    this.editedDistribution = editedDistribution;
  }

  onEditInstallation(evt) {
    const workInstallation: EditedInstallation =
      evt.field === 'tipologias_actuacion'
        ? {
            anio_contable: this.retributiveYear,
            codigo_obra: evt.codigo_obra,
            codigo_bdr_instalaciones: evt.installation,
            secuencial: evt.secuencial,
            [evt.field]: evt.value,
            comparativa_inversiones: evt.comparativa_inversiones,
          }
        : {
            anio_contable: this.retributiveYear,
            codigo_obra: evt.codigo_obra,
            codigo_bdr_instalaciones: evt.installation,
            secuencial: evt.secuencial,
            [evt.field]: evt.value,
          };

    let newUuccDistribution = [];
    let newInstallationsHeaders = {};

    let installations = this.installations.installations_detail.data;
    let duplicatedTipologies = this.checkTipologiesError(installations);

    if (duplicatedTipologies.length > 0) {
      this.addNotification(
        'error',
        `No puede asignar la misma tipología dos veces a la misma instalación (${duplicatedTipologies.join(', ')})`,
      );
      installations.find(
        (i) => i.secuencial === evt.secuencial && i.codigo_bdr_instalaciones === evt.installation,
      ).tipologias_actuacion = null;
      this.installations = cloneDeep(this.installations);
    }

    if (evt.field === 'comparativa_inversiones' && evt.value === 'Sin inversión') {
      installations.find(
        (i) => i.secuencial === evt.secuencial && i.codigo_bdr_instalaciones === evt.installation,
      ).tipologias_actuacion = null;
      workInstallation.tipologias_actuacion = null;
    }

    const index = this.editedInstallations.findIndex(
      (installation) =>
        installation.codigo_bdr_instalaciones === workInstallation.codigo_bdr_instalaciones &&
        installation.secuencial === workInstallation.secuencial,
    );

    if (index !== -1) {
      this.editedInstallations[index] = { ...this.editedInstallations[index], ...workInstallation };
    } else {
      this.editedInstallations.push(workInstallation);
    }

    let tipologiesCounter = 1;

    let nonDispatchersInstallations = installations.filter((i) => i.origen_instalacion);

    // nonDispatchersInstallations.sort((a, b)=>
    //   (a.tipologias_actuacion || '').localeCompare(b.tipologias_actuacion || '') ||
    //     (a.codigo_bdr_instalaciones || '').localeCompare(b.codigo_bdr_instalaciones || '')
    // );

    // [lpm] [ID_145] TODO: i'm sure we will be asked to recover this code in the near future
    // Clean uucc keys
    // this.uucc.uucc_matrix.forEach(uucc => {
    //   nonDispatchersInstallations.forEach(i => {
    //     let installationId = i.codigo_bdr_instalaciones;
    //     Object.keys(uucc)
    //       .filter(uuccKey => {
    //         return uuccKey.includes(installationId)
    //       })
    //       .forEach(uuccKey => {
    //         delete uucc[uuccKey]
    //         delete uucc['Total_' + uuccKey.replace(`||${installationId}`, '')]
    //       });
    //   });
    // });

    nonDispatchersInstallations.forEach((installation) => {
      let installationCode = installation.codigo_bdr_instalaciones.trim();
      let installationTypology = installation.tipologias_actuacion;

      if (installationTypology) {
        let uuccIdentifier = installationTypology + '||' + installationCode;

        // UUCC
        // [lpm] [ID_145] TODO: i'm sure we will be asked to recover this code in the near future
        // let newUUCC = this.uucc.uucc_reparto
        //   .map(uucc => {
        //     return {
        //       uucc_id: uucc.uucc_id,
        //       reparto: 0,
        //       instalacion: uuccIdentifier
        //     }
        //   });

        let previousUUCCInstallationDistribution =
          this.uucc.uucc_reparto.find((uucc) => uucc.instalacion === uuccIdentifier) || {};

        let newUUCC = this.uucc.uucc_matrix.map((uucc) => {
          return {
            uucc_id: uucc.uucc_id,
            reparto: previousUUCCInstallationDistribution.reparto || 0,
            instalacion: uuccIdentifier,
          };
        });

        newUuccDistribution = newUuccDistribution.concat(newUUCC);
        let totalIdentifier = `Total_${installationTypology}`;

        // UUCC Matrix
        // [lpm] [ID_145] TODO: i'm sure we will be asked to recover this code in the near future
        // this.uucc.uucc_matrix.forEach(uucc => {
        //   uucc[uuccIdentifier] = null;
        //   uucc[totalIdentifier] = 0;
        // });

        this.uucc.uucc_matrix.forEach((uucc) => {
          uucc[uuccIdentifier] = uucc[uuccIdentifier];
          uucc[totalIdentifier] = uucc[totalIdentifier] || 0;
        });

        // Tipology headers
        if (!newInstallationsHeaders[installationTypology]) {
          newInstallationsHeaders[installationTypology] = {
            installations: [
              {
                installation_id: totalIdentifier,
                alias: `Total ${tipologiesCounter}`,
                coefficient: 0,
              },
            ],
            tipology_description: installationTypology,
          };
          tipologiesCounter++;
        }

        newInstallationsHeaders[installationTypology]['installations'].unshift({
          alias: installationCode,
          coefficient: 0,
          installation_id: uuccIdentifier,
          vtr: installation.vtr,
        });
      }
    });

    // coefficients
    let tipologies: any[] = Object.values(newInstallationsHeaders);
    tipologies.forEach((tipology) => {
      let installations = tipology.installations.filter(
        (i) => !i.installation_id.includes('Total_'),
      );
      let sum = installations.map((i) => i.vtr).reduce((a, b) => a + b);
      installations.forEach((i) => {
        i.coefficient = i.vtr / sum;
      });
    });

    this.uucc.uucc_reparto = newUuccDistribution.concat(
      this.uucc.uucc_reparto.filter((uucc) => uucc.instalacion.includes('Despacho-Digitalización')),
    );
    this.uucc.installations_header = tipologies.concat(
      this.uucc.installations_header.filter((header) =>
        header.tipology_description.includes('Despacho-Digitalización'),
      ),
    );
    this.uucc = cloneDeep(this.uucc);
  }

  onEditedStartupAct(editedStartupAct) {
    this.editedStartupAct = editedStartupAct;
  }

  handleAutomaticAmounts(evt) {
    this.setAutomaticAmounts(evt);
    this.isLoading = true;
    this.splashMessage = 'Modificando importes de la obra';

    this.dataService
      .panicButton(this.workcode, evt)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => iif(() => i > 10, throwError(e), of(e).pipe(delay(500)))),
          ),
        ),
      )
      .subscribe((check) => {
        this.isLoading = false;
        this.addNotification('confirmation', 'Modificaciones guardadas correctamente');
      });
  }

  private setAutomaticAmounts(automaticAmountsValue) {
    this.automaticAmounts = automaticAmountsValue;

    this.installations.installations_detail.headers.forEach((h) => {
      if (this.AMOUNTS_FIELDS.includes(h.field)) {
        h.visible = automaticAmountsValue === 'NO';
      } else if (this.AMOUNTS_FIELDS.includes(h.field.replace('_automatico', ''))) {
        h.visible = automaticAmountsValue === 'SI';
      }
    });
    this.installations = cloneDeep(this.installations);
  }

  closeSplash() {
    this.showSplash = false;
  }

  show19Splash: boolean = true;
  close19Splash() {
    this.show19Splash = false;
  }

  show20Splash: boolean = true;
  close20Splash() {
    this.show20Splash = false;
  }
}
