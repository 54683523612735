<span class="grid-title">{{ gridProperties.title }}

  <span class="grid-actions" *ngIf="gridProperties && gridProperties.gridActions">
    <span class="grid-action" *ngFor="let actionItem of gridProperties.gridActions" (click)="actionItem.action($event)">
      <bdr-icon *ngIf="actionItem.icon" [icon]="actionItem.icon"></bdr-icon>
      <span>{{ actionItem.title }} </span>
    </span>
  </span>
</span>
<div class="simple-grid-container" #container *ngIf="
    gridProperties &&
    (gridProperties.origin || (gridProperties.externalData && gridProperties.externalData.length))
  ">

  <div class="grid-subheader" *ngIf="subheaderSelector">
    <bdr-button-selectbox-selector [config]="subheaderSelector" [origin]="gridProperties.origin"
      [changedColumns]="changedColumns" [appliedFilters]="appliedFilters" [saving]="saving"
      [editMode]="editChangedValues" [resultsCount]="resultsCount" [headerActions]="gridProperties.headerActions"
      [selectedRows]="selectedRows" [scope]="scope" [saveDisabled]='saveDisabled'
      [saveDisabledReason]='saveDisabledReason' (onSaveChanges)="saveChanges()" (onCancelSave)="cancelActions()"
      (onOpenColumnSelector)="openColumnSelector()" (onResetColumns)="resetSchemaColumns()"
      (resetFilterRequest)="resetFilter($event)"></bdr-button-selectbox-selector>
  </div>


</div>
<div class="no-data" *ngIf="
    !gridProperties.origin && !(gridProperties.externalData && gridProperties.externalData.length)
  ">
  <bdr-icon icon="ico-eyenot"></bdr-icon>
  No se han encontrado datos
</div>