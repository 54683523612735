<ng-container *ngIf="!componentSelected">
  <div class="title">Otras acciones disponibles</div>
  <div class="menu-item" *ngFor="let action of content.actions" (click)="selectComponent(action)">
    <div class="menu-separator" *ngIf="action.separator"></div>
    <span class="menu-item-caption">{{ action.caption }}</span>
  </div>
</ng-container>
<ng-container *ngIf="componentSelected">
  <div class="select-component" (click)="componentSelected = null">&lt; Volver</div>
  <bdr-reflect-component [component]="componentSelected" [bindings]="componentBindings">
  </bdr-reflect-component>
</ng-container>