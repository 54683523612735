<div class="validations">
    <div class="validations__list">
        <bdr-validations-list
            [validations]="validations"
            (changeValidations)="handleCheckedValidations($event)"
        >
        </bdr-validations-list>
    </div>

    <div *ngIf="config.hasExtraFilters">
        <div class="validations__state">
            <mat-form-field>
                <mat-label>Estado de la obra</mat-label>
                <mat-select [(ngModel)]="workState">
                    <mat-option value="">Cualquiera</mat-option>
                    <mat-option value="SI">Revisada</mat-option>
                    <mat-option value="NO">No revisada</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="validations__amount-range">
            <div class="validations__amount-range--min">
                <mat-form-field>
                    <mat-label>Importe mínimo</mat-label>
                    <input [(ngModel)]="amountMin" matInput placeholder="0,00 €" />
                </mat-form-field>
            </div>

            <div class="validations__amount-range--max">
                <mat-form-field>
                    <mat-label>Importe máximo</mat-label>
                    <input [(ngModel)]="amountMax" matInput placeholder="10.000,00 €" />
                </mat-form-field>
            </div>
        </div>

        <div class="validations__zones">
            <bdr-validations-zones
                [zones]="zones"
                (selectedZones)="handleSelectedZones($event)"
            ></bdr-validations-zones>
        </div>
    </div>

    <div class="validations__apply-filters">
        <button
            class="bdr-btn bdr-btn-blue"
            [ngClass]="{ 'bdr-btn-disabled': !hasFiltersApplied }"
            (click)="hasFiltersApplied ? applyFilters() : null"
        >
            Aplicar filtros
        </button>
    </div>
</div>
