<div class="wrapper {{size}}" [ngClass]="{'active' : active, 'disactive' : !active}" (mouseenter)="hover = true"
  (mouseleave)="hover = false">
  <span class="text {{size}}">
    <ng-content></ng-content>
    <!-- <bdr-icon *ngIf="hover && !active && size !== 'small'" class="icon" icon="close" size="14px">
    </bdr-icon>
    <bdr-icon *ngIf="size === 'small'" class="icon" size="14px" icon="close" [ngClass]="{'active' : active, 'disactive' : !active, 'gray': gray}">
    </bdr-icon>
    <div *ngIf="hover && active && size !== 'small'" class="icon-false"></div> -->
  </span>
</div>