<label>
  <p class="input-error-caption" *ngIf="!bottomError && errorMsg && errorMsg.length > 0">{{ errorMsg }}</p>
</label>
<div class="input-search-container {{ size }} {{ positionDropdown }}"
  [ngClass]="{'active':active, 'focus':focus, 'select-appearance':selectMode, 'error': errorMsg && errorMsg.length > 0}"
  (click)="dropOptionList($event)">
  <div *ngIf="displayOptions.length > 0 && positionDropdown === 'top'" class="input-search-options-container {{size}}"
    [ngClass]="{'active':active}">
    <div class="input-search-option-dotted" *ngFor="let option of displayOptions" (mousedown)="selectOption(option)">
      <span title="{{ option.caption }}" [ngClass]="{'bold':bold}">{{option.caption}}</span>
    </div>
  </div>
  <div class="border-top {{ size }}" *ngIf="displayOptions.length > 0 && positionDropdown === 'top'"></div>
  <input [ngClass]="{'error' : errorMsg && errorMsg.length > 0, 'bold':bold}" class="input-search {{ size }}"
    (focus)="setFocusInput($event)" (blur)="setBlurInput($event)" (click)="dropOptionList($event)" type="text"
    [disabled]="disabled" [ngModel]="caption" (ngModelChange)="filterOptions($event)" title="{{ caption }}"
    [placeholder]="placeholder" #searchInput>

  <div class="border-bottom {{ size }}" *ngIf="displayOptions.length > 0 && positionDropdown === 'bottom'"></div>
  <div *ngIf="displayOptions.length > 0 && positionDropdown === 'bottom'"
    class="input-search-options-container {{size}}" [ngClass]="{'active':active}">
    <div class="input-search-option-dotted" *ngFor="let option of displayOptions" (mousedown)="selectOption(option)">
      <span title="{{ option.caption }}" [ngClass]="{'bold':bold}">{{option.caption}}</span>
    </div>
  </div>
</div>
<p class="input-error-caption" *ngIf="bottomError && errorMsg && errorMsg.length > 0">{{ errorMsg }}</p>