<div class="container-dialog" style="font-family:mULL Arial, Helvetica, sans-serif">

  <div class="container-dialog__header">
    <h1>Edición masiva de instalaciones</h1>
  </div>

  <div class="container-dialog__body">
    <h2>1. Bloquear Cierre de Obra </h2>
  
    Se bloqueará la edición del Cierre de Obra para todos los usuarios de la aplicación, que podrán seguir trabajando pero no guardar sus cambios hasta que finalice 
    esta operación. Puede desbloquear la edición si no va a hacer ningún cambio simplemente cerrando esta ventana.

    <div class="ci-header-installations centered">
      <button 
        class="container-dialog-bdr-btn bdr-btn-orange"
        (click)="isBlockedWorkClosure ? null : blockWorkClosure()"
        [disabled]="isBlockedWorkClosure">
        {{ blockButtonText }}
      </button>
    </div>
    
    <h2>2. Descargue los ficheros a editar</h2>

    <div class="ci-header-installations" (click)="downloadEditions()" title="Descargar ediciones">
      <bdr-icon icon="ico-download"></bdr-icon> Descargar ediciones de instalaciones   
    </div>

    <div class="ci-header-installations" (click)="downloadInstallations()" title="Descargar asociar/desasociar">
      <bdr-icon icon="ico-download"></bdr-icon> Descargar asociación/desasociación de instalaciones
    </div>
    
    <h2>3. Haga los cambios oportunos en el fichero descargado </h2>
    Debe respetar el formato de subida:
    <ul>
      <li>El formato debe ser .csv.gz</li>
      <li>La primera línea se considera la cabecera de la tabla y será ignorada</li>
      <li>Los campos deben estar separados por barras verticales, es decir, por '|'</li>
      <li>No incluya el campo de año en el ficheros subido</li>
    </ul>

    <h2>4. Suba los ficheros editados</h2>      
      
      <div class="file-upload-field">
        <label class="fake-input-file">
          <input #fileinput type="file" class="input-file" name="file-upload" (change)="editionsFileUpdate($event)" />
        </label>

        <button class="container-dialog-bdr-btn bdr-btn-orange" 
          [disabled]="error || !editionsFile"
          (click)="error || !editionsFile ? null : uploadEditions()">
            Subir ediciones
        </button>
      </div>
      <div *ngIf="error" style="color:red; font-weight: bold;">{{errorMsg}}</div>

      <div class="file-upload-field">
        <label class="fake-input-file">
          <input #fileinput type="file" class="input-file" name="file-upload" (change)="installationsFileUpdate($event)" />
        </label>

        <button class="container-dialog-bdr-btn bdr-btn-orange" 
          [disabled]="error || !installationsFile"
          (click)="error || !installationsFile ? null : uploadInstallations()">
            Subir asociar/desasociar
        </button>
      </div>

      <div *ngIf="error" style="color:red; font-weight: bold;">{{errorMsg}}</div>
    
    <h2>5. Aplique los cambios</h2>
    Se sustituirán todas las ediciones manuales por las indicadas en el fichero y se lanzará el cálculo de la Pantalla de Cierre de Obra. Esta operación es <b>irreversible</b>.

  </div>

  <div class="container-dialog__footer centered">
    <button class="container-dialog-bdr-btn bdr-btn-white" (click)="close()">Volver</button>
    <button class="container-dialog-bdr-btn bdr-btn-blue" [disabled]="!!nonUploaded" (click)="!!nonUploaded? null : save()">Aplicar cambios</button>
  </div>

</div>