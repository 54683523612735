<div class="grid-field-container">
  <bdr-input [size]="field.field.size || 'slimmest'"
    *ngIf="field.field.fieldType === 'number' || field.field.fieldType === 'text'" [type]="field.field.fieldType"
    [model]="fieldValue" [maxLength]="field.field.maxLength" (modelChange)="changeValue($event)"
    [minValue]="field.minValue" [maxValue]="field.maxValue" [error]="error"></bdr-input>
  <textarea *ngIf="field.field.fieldType === 'textarea'" [(ngModel)]="fieldValue" (ngModelChange)="changeValue($event)">
  </textarea>
  <bdr-select [size]="field.field.size || 'slimmest'" [options]="optionValues || field.field.optionValues"
    [model]="fieldValue" [initialized]="field.initialized"
    *ngIf="field.field.fieldType === 'select' && optionValues && optionValues.length > 0" [initOpen]="true"
    (modelChange)="changeValue($event)" (onInitialized)="initialized()"></bdr-select>
  <span *ngIf="field.field.fieldType === 'select' && optionValues && optionValues.length === 0">{{fieldValue}}</span>
  <div *ngIf="field.field.fieldType === 'select-multiple-check'">
    <bdr-select-multiple-check [modelValue]="fieldValue" [separator]="field.field.separator"
      [optionValues]="field.field.optionValues" (onChange)="changeValue($event)"></bdr-select-multiple-check>
  </div>
</div>