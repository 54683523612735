<section class="body">
  <bdr-splash *ngIf="loading" title="Cargando histórico de reclasificaciones" [descriptions]="['Espere, por favor']">
  </bdr-splash>
  <div style="width: 100%; overflow-y: scroll; overflow-x: scroll;">
    <div style="min-height: 250px; max-height: 600px;">
      <div style="padding-top: 1em" *ngFor="let row of paginatedRows()">
        <table>
          <tr>
            <th class="wrapper-th selector"></th>
            <th class="wrapper-th">Fecha</th>
            <th class="wrapper-th">Año retributivo</th>
            <th class="wrapper-th">Preclasificacion Recl</th>
            <th class="wrapper-th">MEI Recl</th>
            <th class="wrapper-th">ELA Recl</th>
            <th class="wrapper-th">Clasificacion Inversion Recl</th>
            <th class="wrapper-th">Clasificacion Recl</th>
            <th class="wrapper-th">Cod Actuacion Recl</th>
            <th class="wrapper-th">CINI</th>
            <th class="wrapper-th">Num Cuenta</th>
          </tr>
          <tr *ngFor="let version of row.versions">
            <td class="selector">
              <bdr-checkbox *ngIf="!version.currentValue" [disabled]="version.disable"
                [title]='version.disableReason || version.fecha || "Versión original"' [(model)]="version.selected"
                caption="" (modelChange)="changeSelectedVersions(row.id, version)">
              </bdr-checkbox>
            </td>
            <ng-container *ngIf="!version.original; else originalAppointment">
              <td>{{version.fecha}}</td>
              <td>{{version.anio_retributivo}}</td>
              <td>{{version.preclasificacion_recl}}</td>
              <td>{{version.mei_recl}}</td>
              <td>{{version.ela_recl}}</td>
              <td>{{version.clasificacion_inversion_recl}}</td>
              <td>{{version.clasificacion_recl}}</td>
              <td>{{version.cod_actuacion_recl}}</td>
              <td>{{version.cini}}</td>
              <td>{{version.num_cuenta}}</td>
            </ng-container>
            <ng-template #originalAppointment>
              <td>Revertir al estado original</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </ng-template>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="pages">
    <span *ngFor="let page of pages" class="bottom-link link page-number" (click)="setPage(page)">
      {{page+1}}
    </span>
  </div>
</section>

<footer class="footer">
  <span *ngIf="!saving" class="bottom-link link" (click)="save()">Aplicar y guardar</span>
  <span *ngIf="saving" class="saving">
    <bdr-icon icon="ico-save" size="16px"></bdr-icon> Guardando...
  </span>
</footer>