<section *ngIf="retributiveYear" class="body">
  <div class="body-wrapper">
    <ul class="action-list">

      <div *ngIf="joinedRegisteredRows.length > 0">
        <p>Se van a insertar las siguientes instalaciones  en el maestro Adelanto de inversiones del año anterior ({{retributiveYear-1}})</p>
        {{ joinedRegisteredRows }}
      </div>

      <div *ngIf="joinedRegisteredRows.length > 0" style="margin-top: 1em;">
        <p>Esto provocará que su comparativa cambie a Sin Cambios/Modificación para este año ({{retributiveYear}}) y aparezcan registros de Alta en el año anterior ({{retributiveYear-1}}) </p>
      </div>
      
      <div *ngIf="joinedNonRegisteredRows.length > 0">
        <p>No se pueden adelantar los siguientes componentes (no son Altas):</p>
        {{ joinedNonRegisteredRows }}
      </div>
      
    </ul>
  </div>
</section>
<footer *ngIf="retributiveYear" class="footer-wrapper">
  <div class="footer">
    <div class="footer-actions">
      <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()"><span class="link">Cancelar</span></bdr-button>
      <bdr-button class="btn-save" primary size="small" (click)="advanceInstallations()">Aplicar</bdr-button>
    </div>
  </div>
</footer>