import { throwError as observableThrowError, Observable, Subject, forkJoin, of } from 'rxjs';
import { startWith, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { sortBy } from 'lodash';

import { mergeFilters } from '../../util';
import { environment } from '../../../environments/environment';
import { FilterDB, FilterService } from '../filter/filter.service';
import { BdrRequestService } from '../bdr-request/bdr-request.service';
import { MastersService } from '../masters/masters.service';

export const GDO_INVESTMENTS_VALIDATIONS = {
  1: {
    name: 'Instalación sin inversión',
    description: '',
  },
  2: {
    name: 'La instalación puede tener asociada una obra abierta',
    description: '',
  },
  3: {
    name: 'Alta sin expediente asociado',
    description: '',
  },
};

export const PANIC_BUTTON_VALIDATIONS_NICENAME = {
  1: {
    name: 'Obra con validación 2 (tipologías sin informar)',
    description: '',
  },
  2: {
    name: 'Obra sin UUCC',
    description: '',
  },
  3: {
    name: 'Obra sin instalaciones a las que repartir UUCC',
    description: '',
  },
};

export const PANIC_BUTTON_INFORMATION_NICENAME = {
  A: {
    name: 'Obra con importe cesiones',
    description: '',
  },
  B: {
    name: 'Obra con fallo MEI',
    description: '',
  },
  C: {
    name: 'Obra con fallo MEI Cesiones',
    description: '',
  },
  D: {
    name: 'Todas las instalaciones de la obra tienen Importe UFD',
    description: '',
  },
};

export const GDO_SECCI_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia entre la Propiedad asignada en BDI con la de los elementos conectados al EMF',
    description: '',
  },
  2: {
    name: 'PERTENECE_CIA del EMF es distinto del PERTENECE_CIA correspondiente al tramo con el que se modela el EMF en el Formulario 10 MT',
    description: '',
  },
};

export const GDO_INTERR_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia entre la Propiedad asignada en BDI con la de los elementos conectados al EMF',
    description: '',
  },
  2: {
    name: 'PERTENECE_CIA del EMF es distinto del PERTENECE_CIA correspondiente al tramo con el que se modela el EMF en el Formulario 10 MT',
    description: '',
  },
};

export const GDO_TRAMO_INNER_VALIDATIONS = {
  1: {
    name: 'El Tramo MT ha cambiado de propiedad respecto al año pasado;',
    description: '',
  },
  2: {
    name: 'Se aplica el criterio declaración del activo con TENSIÓN CONSTRUCCIÓN;',
    description: '',
  },
  4: {
    name: 'Denominación no existente en el Maestro Relación de Parámetros Normalizados AT/MT',
    description: '',
  },
  5: {
    name: 'Edición recurrente en el campo Denominación conductor',
    description: '',
  },
};

export const GDO_TRAMO_T_INNER_VALIDATIONS = {
  1: {
    name: 'El Tramo AT ha cambiado de propiedad respecto al año pasado;',
    description: '',
  },
  2: {
    name: 'Se aplica el criterio declaración del activo con TENSIÓN CONSTRUCCIÓN;',
    description: '',
  },
  4: {
    name: 'Denominación no existente en el Maestro Relación de Parámetros Normalizados AT/MT',
    description: '',
  },
};

export const GDO_TRAMO_BAJA_INNER_VALIDATIONS = {
  1: {
    name: 'Cuarta posición del CINI no coherente',
    description: '',
  },
  2: {
    name: 'Nudo inicial es igual al Nudo Final',
    description: '',
  },
  3: {
    name: 'Longitud informada a 0',
    description: '',
  },
  4: {
    name: 'Denominación no existente en el Maestro Relación de Parámetros Normalizados BT',
    description: '',
  },
  5: {
    name: 'Línea Asociada sin informar',
    description: '',
  },
  6: {
    name: 'Código de Tramo Único sin informar',
    description: '',
  },
};

export const GDO_TRAMOSS_INNER_VALIDATIONS = {
  4: {
    name: 'Denominación no existente en el Maestro Relación de Parámetros Normalizados AT/MT',
    description: '',
  },
};

export const AT_FORMS_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia en el tramo declarado entre los Formularios 9AT y 10AT',
    description: '',
  },
};

export const MT_FORMS_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia en el tramo declarado entre los Formularios 9MT y 10MT',
    description: '',
  },
};

export const BT_FORMS_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia en el tramo declarado entre los Formularios 9BT y 10BT',
    description: '',
  },
};

export const F3_INVENTARIO_INNER_VALIDATIONS = {
  1: {
    name: 'El número de posiciones declaradas en el Formulario 4 es distinto a las que tiene esta Subestación.',
    description: '',
  },
};

export const F7_INVENTARIO_INNER_VALIDATIONS = {
  1: {
    name: 'El EMF no se recoge en el Formulario 1',
    description: '',
  },
};

export const F11_INNER_VALIDATIONS = {
  1: {
    name: 'Incoherencia entre el numero de celdas del CT declarado en el Formulario 11 el número de celdas asociadas al CT y declaradas en el Formulario 12Bis',
    description: '',
  },
  2: {
    name: 'Incoherencia entre el numero de máquinas del CT declarado en el Formulario 11 y el número de máquinas asociadas al CT y declaradas en el Formulario 12',
    description: '',
  },
  3: {
    name: 'Incoherencia entre la potencia del CT declarado en el Formulario 11 y la potencia de las máquinas asociadas al CT y declaradas en el Formulario 12',
    description: '',
  },
};

export const F13_INNER_VALIDATIONS = {
  1: {
    name: 'Subestación móvil con posiciones duplicadas en subestación operativa',
    description: '',
  },
};

export const F13BIS_INNER_VALIDATIONS = {
  1: {
    name: 'Código de nudo no existente en formularios 10AT ni 10MT',
    description: '',
  },
};

export const COHERENCE_PROCESSES_INSTALLATIONS = {
  1: {
    name: '>85%',
    description: '',
  },
  2: {
    name: 'Comparativa VUR',
    description: '',
  },
  '3': {
    name: 'Motivación-estado incoherente',
    description: '',
  },
  '4': {
    name: 'Posible SRE',
    description: '',
  },
  '5': {
    name: 'Registro a añadir en el Maestro Identificadores SRE',
    description: '',
  },
  '6': {
    name: 'Registro a borrar del Maestro Identificadores SRE',
    description: '',
  },
};

export const COHERENCE_PROCESSES_MTBT = {
  1: {
    name: 'Obras sin instalación informada',
    description: '',
  },
  2: {
    name: 'Obras sin tipología informada',
    description: '',
  },
  3: {
    name: 'Tipología sin instalación coherente',
    description: '',
  },
  4: {
    name: 'UUCC de tramos incoherentes con instalaciones reales',
    description: '',
  },
  5: {
    name: 'UUCC de CTs/EMF incoherentes con instalaciones reales',
    description: '',
  },
  6: {
    name: 'Obra abierta en SGM de forma incorrecta',
    description: '',
  },
  7: {
    name: 'UUCC sin repartir',
    description: '',
  },
  8: {
    name: 'Instalaciones sin UUCC asignadas',
    description: '',
  },
  9: {
    name: 'Instalaciones con inversión > % VUR',
    description: '',
  },
  10: {
    name: 'MEI incorrectamente asignado a UUCC',
    description: '',
  },
  11: {
    name: 'Expediente clase coste ingeniería y/o tramitación',
    description: '',
  },
  12: {
    name: 'Instalaciones informadas por inventario',
    description: '',
  },
  13: {
    name: 'Instalación con inversión en otro expediente',
    description: '',
  },
  14: {
    name: 'Actuación con imputación en años diferentes',
    description: '',
  },
  15: {
    name: 'Reclasificación superior UUCC MTBT-Despachos',
    description: '',
  },
  17: {
    name: 'Instalaciones Despacho-Digitalización sin tipología informada',
    description: '',
  },
  18: {
    name: 'Instalaciones sin inversión eléctrica',
    description: '',
  },
  19: {
    name: 'Obra no cargada en el transaccional del año retributivo',
    description: '',
  },
  20: {
    name: 'Obra no cargada en el transaccional de ningún año retributivo',
    description: '',
  },
  21: {
    name: 'Instalación marcada a diferir en el inventario',
    description: '',
  },
  22: {
    name: 'UUCC mal imputadas corregidas',
    description: '',
  },
  23: {
    name: 'No se puede aplicar el reparto de GDA por falta de información',
    description: '',
  },
  24: {
    name: 'No se puede aplicar el reparto de GDA porque la obra tiene tipologías que no están en el maestro',
    description: '',
  },
  25: {
    name: 'Los importes de UC procedentes de GDA no coinciden con los importes de incurridos de SAP',
    description: '',
  },
  26: {
    name: 'No coinciden los materiales de las UC que generan reclasificación con los del incurrido',
    description: '',
  },
  27: {
    name: 'No coincide ningún material de las UC que generan reclasificación y los incurridos',
    description: '',
  },
};

export const COHERENCE_PROCESSES_DISPATCHERS = {
  16: {
    name: 'Reclasificación superior UUCC Despachos-MTBT',
    description: '',
  },
  26: {
    name: 'No coinciden los materiales de las UC que generan reclasificación con los del incurrido',
    description: '',
  },
  27: {
    name: 'No coincide ningún material de las UC que generan reclasificación y los incurridos',
    description: '',
  },
};

export const COHERENCES_PROCESSES_FORM_A1 = {
  1: {
    name: 'Cups repetido',
    descripcion: '',
  },
  2: {
    name: 'Formato PROVINCIA incorrecto',
    descripcion: '',
  },
  3: {
    name: 'Formato MUNICIPIO incorrecto',
    descripcion: '',
  },
  4: {
    name: 'Incoherencia entre MUNICIPIO y PROVINCIA',
    descripcion: '',
  },
  5: {
    name: 'Cambio a null del CT obtenido en origen',
    descripcion: '',
  },
  6: {
    name: 'CT fuera de zona',
    descripcion: '',
  },
  7: {
    name: 'Cambio a null de la SUBESTACIÓN obtenida en origen',
    descripcion: '',
  },
  8: {
    name: 'Campos incorrectos',
    descripcion: '',
  },
  9: {
    name: 'POTENCIA CONTRATADA incorrecta',
    descripcion: '',
  },
  10: {
    name: 'POTENCIA ADSCRITA negativa o nula',
    descripcion: '',
  },
  11: {
    name: 'POT ADSCRITA < POT CONTRATADA',
    descripcion: '',
  },
  12: {
    name: 'Formato CNAE incorrecto',
    descripcion: '',
  },
  13: {
    name: 'Formato F_inst incorrecto',
    descripcion: '',
  },
  14: {
    name: 'CAU no contiene al CUPS',
    descripcion: '',
  },
  15: {
    name: 'Exceso energía activa',
    descripcion: '',
  },
  16: {
    name: 'CAU no informado',
    descripcion: '',
  },
  17: {
    name: 'LECTURAS < 0',
    descripcion: '',
  },
  18: {
    name: 'Incoherencia entre CAMBIO TITULARIDAD y LECTURAS',
    descripcion: '',
  },
  19: {
    name: 'Incoherencia entre ESTADO CONTRATO y LECTURAS',
    descripcion: '',
  },
  20: {
    name: 'TENSIÓN sin informar',
    descripcion: '',
  },
  21: {
    name: 'CÓDIGO_TFA y TIPO sin informar',
    descripcion: '',
  },
  22: {
    name: 'Código TFA y TIPO no válidos',
    descripcion: '',
  },
  23: {
    name: 'Incoherencia entre COD_TFA declarado y la TENSIÓN declarada',
    descripcion: '',
  },
  24: {
    name: '',
    descripcion: '',
  },
  25: {
    name: 'NUDO NULL',
    descripcion: '',
  },
  26: {
    name: 'NUDO no aparece en los formularios B',
    descripcion: '',
  },
  27: {
    name: 'Código NUDO de transporte erróneo',
    descripcion: '',
  },
  28: {
    name: 'ZONA DE CALIDAD no informada',
    descripcion: '',
  },
  29: {
    name: 'Energía Activa Consumida nula',
    descripcion: '',
  },
  30: {
    name: '',
    descripcion: '',
  },
  31: {
    name: 'Incoherencia entre las coordenadas y el campo provincia',
    descripcion: '',
  },
  32: {
    name: 'Incoherencia entre las coordenadas y el campo provincia',
    descripcion: '',
  },
  33: {
    name: '',
    descripcion: '',
  },
  34: {
    name: 'CUPS no aparece en el Formulario 4',
    descripcion: '',
  },
  35: {
    name: 'Campo IDENTIFICADOR no aparece en la columna IDENTIFICADOR_CT del B2 ??',
    descripcion: '',
  },
  36: {
    name: 'Distancia entre el cliente y el CT > 5000',
    descripcion: '',
  },
  37: {
    name: '',
    descripcion: '',
  },
};

export const COHERENCES_PROCESSES_FORM_A4 = {
  1: {
    name: 'Cups repetido',
    descripcion: '',
  },
  2: {
    name: 'CUPS no está en FA1',
    descripcion: '',
  },
  3: {
    name: 'CUPS de transporte',
    descripcion: '',
  },
  4: {
    name: 'IDENTIFICADOR no aparece en los formularios B',
    descripcion: '',
  },
  5: {
    name: 'CUPS de BT no conectado a un CT',
    descripcion: '',
  },
};

export const COHERENCES_PROCESSES_FORM_A3 = {
  1: {
    name: 'Cod_Crecimiento repetido',
    descripcion: '',
  },
  2: {
    name: 'Incoherencia entre TIPO_CRECIMIENTO y el resto de campos',
    descripcion: '',
  },
  3: {
    name: 'Incoherencia entre TIPO_CRECIMIENTO y el resto de campos',
    descripcion: '',
  },
  4: {
    name: 'Incoherencia entre MUNICIPIO y PROVINCIA',
    descripcion: '',
  },
};

export const A2_F3_INFORMATION_VALIDATIONS = {
  1: {
    name: 'COD_NUDO no existe',
    descripcion: '',
  },
  2: {
    name: 'La georeferenciación ha variado para este nudo',
    descripcion: '',
  }
};


export const COHERENCES_PROCESSES_FORM_7 = {
  1: {
    name: 'Campos coordenadas vacías',
    descripcion: '',
  },
};

export const COHERENCES_PROCESSES_F1 = {
  1: {
    name: 'Contrato Inactivo',
    descripcion: '',
  },
  2: {
    name: 'Tipo suministro excluido',
    descripcion: '',
  },
};

export const COHERENCE_PROCESSES_AT = {
  '1': {
    name: 'Agregado sin Tipología informada',
    description: '',
  },
  '2': {
    name: 'Agregado sin Instalación informada',
    description: '',
  },
  '3': {
    name: 'Validación entre tipología de actuación y Clasificación',
    description: '',
  },
  '4': {
    name: 'Validación entre el MEI y la Tipología-Clasificación del agregado',
    description: '',
  },
  '5': {
    name: 'Coherencia entre tipología de actuación final y el valor del campo comparativa de inversiones',
    description: '',
  },
  '6': {
    name: 'Instalaciones con inversión por encima del VTR ',
    description: '',
  },
  '7': {
    name: 'Cola de inversión',
    description: '',
  },
  '8': {
    name: 'Instalaciones con varios ELAs',
    description: '',
  },
  '9': {
    name: 'Instalaciones sin Tipología de Actuación Final',
    description: '',
  },
  '10': {
    name: 'Instalación con imputación en años diferentes',
    description: '',
  },
};

export const GDO_REGULATORIA_VALIDATIONS = {
  '1': {
    name: 'La instalación se encuentra simultáneamente en un apunte diferido y en uno no diferido',
    description: '',
  },
  '2': {
    name: 'La instalación se encuentra simultáneamente en una obra diferida y en una no diferida',
    description: '',
  },
  '3': {
    name: 'Posible obra abierta',
    description: '',
  },
  '5': {
    name: 'Financiado no correspondiente a fecha base de inventario',
    description: '',
  },
  '6': {
    name: 'Instalación diferida automáticamente por Maestro de Obras Abiertas',
    description: '',
  },
};

export const GDOSECCI_REGULATORIA_VALIDATIONS = {
  ...GDO_REGULATORIA_VALIDATIONS,
  ...{
    '4': {
      name: 'Seccionador de línea o asociado a un CT intemperie u otro tipo de CT con seccionador en apoyo',
      description: '',
    },
  },
};

export const GDOINTERR_REGULATORIA_VALIDATIONS = {
  ...GDO_REGULATORIA_VALIDATIONS,
  ...{
    '4': {
      name: 'Interruptor de línea áerea',
      description: '',
    },
  },
};

export const CUADRE_INVERSIONES_VALIDATIONS = {
  1: {
    name: 'Incurridos de BDR no existen en el fichero SAP original',
    description: '',
  },
  2: {
    name: 'Incurridos originales del SAP no están en BDR',
    description: '',
  },
  3: {
    name: '[MT/BT] Hay expedientes en el Detalle de Traspaso de Incurridos que no llegan al Agregado por Obra',
    description: '',
  },
  4: {
    name: '[MT/BT] Hay expedientes el Agregado por Obra que no tienen incurridos',
    description: '',
  },
  5: {
    name: '[MT/BT] Descuadre de importe entre el Detalle de Traspaso Incurridos y el Agregado por Obra',
    description: '',
  },
  6: {
    name: '[Despachos] Hay expedientes en el Detalle de Traspaso de Incurridos que no llegan al Agregado por Obra”',
    description: '',
  },
  7: {
    name: '[Despachos] Hay expedientes el Agregado por Obra que no tienen incurridos',
    description: '',
  },
  8: {
    name: '[Despachos] Descuadre de importe entre el Detalle de Traspaso Incurridos y el Agregado por Obra',
    description: '',
  },
  9: {
    name: '[MT/BT] Descuadre de importe entre el Agregado por Obra y la Pantalla de Cierre de Obra',
    description: '',
  },
  10: {
    name: '[Despachos] Descuadre de importe entre el Agregado por Obra y la Pantalla de Cierre de Obra',
    description: '',
  },
  11: {
    name: 'Descuadre de importe entre la Pantalla de Cierre de Obra y el Informe de Instalaciones Declaradas',
    description: '',
  },
};

@Injectable()
export class BdrValidationsService {
  host: string;
  mergeFilters: any;
  manualValidationsSubject: Subject<any> = new Subject();
  manualValidations: any[] = [];
  scope: string;
  validationsProcesses = {};
  validationsOrigin: string;

  constructor(
    private request: BdrRequestService,
    private filterService: FilterService,
    private mastersService: MastersService,
  ) {
    this.host = environment.host;
    this.mergeFilters = mergeFilters;
  }

  getSummary(): Observable<any> {
    return forkJoin(
      this.request.get(`${this.host}/table/data/${environment.origins.validationsSummaryMtbt}`),
      this.request.get(`${this.host}/table/data/${environment.origins.validationsSummaryAt}`),
    );
  }

  getManualValidationsObservable() {
    return this.manualValidationsSubject.asObservable().pipe(startWith(this.manualValidations));
  }

  getFilteredSummary(params = null): Observable<any> {
    return this.request.get(
      `${this.host}/${environment.origins.filteredValidationsSummary}`,
      params,
    );
  }

  getValidationsName(idValidations): string {
    return this.validationsProcesses[idValidations].name;
  }

  getPanicButtonValidationsNicename(idValidations): string {
    return PANIC_BUTTON_VALIDATIONS_NICENAME[idValidations].name;
  }

  getPanicButtonInformationNicename(idValidations): string {
    return PANIC_BUTTON_INFORMATION_NICENAME[idValidations].name;
  }

  getInvestmentsValidationName(origin, idValidations): string {
    return GDO_INVESTMENTS_VALIDATIONS[idValidations].name;
  }

  getCnmcValidationsDescription(validationCode) {
    return this.mastersService.getCnmcValidationsDescription(validationCode);
  }

  getAdjusmentInvestmentDescription(validationId) {
    return CUADRE_INVERSIONES_VALIDATIONS[validationId].name;
  }

  getFormF1Description(validationId) {
    return COHERENCES_PROCESSES_F1[validationId].name;
  }

  getFormA1Description(validationId) {
    return COHERENCES_PROCESSES_FORM_A1[validationId].name;
  }

  getFormA4Description(validationId) {
    return COHERENCES_PROCESSES_FORM_A4[validationId].name;
  }

  getFormA1Validations() {
    var validations = [];
    for (const validationId in COHERENCES_PROCESSES_FORM_A1) {
      validations.push({
        id: validationId,
        description: COHERENCES_PROCESSES_FORM_A1[validationId].name,
      });
    }
    return validations;
  }

  getA2F3InformationValidationsDescription(validationId) {
    return A2_F3_INFORMATION_VALIDATIONS[validationId].name;
  }

  getFormA3Description(validationId) {
    return COHERENCES_PROCESSES_FORM_A3[validationId].name;
  }

  getFormA3Validations() {
    var validations = [];
    for (const validationId in COHERENCES_PROCESSES_FORM_A3) {
      validations.push({
        id: validationId,
        description: COHERENCES_PROCESSES_FORM_A3[validationId].name,
      });
    }
    return validations;
  }

  getForm7Description(validationId) {
    return COHERENCES_PROCESSES_FORM_7[validationId].name;
  }

  getForm7Validations() {
    var validations = [];
    for (const validationId in COHERENCES_PROCESSES_FORM_7) {
      validations.push({
        id: validationId,
        description: COHERENCES_PROCESSES_FORM_7[validationId].name,
      });
    }
    return validations;
  }

  getInnerValidationNameParametros(origin, idValidations, parametroValidaciones): string {
    let validacion = '';
    switch (origin) {
      case 'gdoct_circular_vista': {
        switch (idValidations) {
          case '1': {
            //Validaciones variables para la tabla de CT (Envolventes)
            if (parametroValidaciones === null || parametroValidaciones.length == 0) break;

            for (let i of parametroValidaciones.split('|')) {
              switch (i) {
                case 'GDOTRAFO_MB':
                  validacion +=
                    'Existe una Máquina en CT (Trafo de MT) con un estado de declaracion distinto;';
                  break;
                case 'GDOCELDA':
                  validacion +=
                    'Existe una Posición en CT (Celda) con un estado de declaracion distinto;';
                  break;
              }
            }
            break;
          }
          case '2': {
            validacion = 'El CT ha cambiado de propiedad respecto al año pasado;';
            break;
          }
          case '3': {
            validacion = 'Se aplica el criterio declaración del activo con TENSIÓN CONSTRUCCIÓN;';
            break;
          }
          case '4': {
            validacion = 'Este CT está desconectado de Tramos MT';
            break;
          }
          case '5': {
            validacion = 'CT sin celda probable CT Palomar y/o CT ocioso';
            break;
          }
        }
        break;
      }
      case 'gdoctss_circular_vista': {
        switch (idValidations) {
          case '1': {
            //Validaciones variables para la tabla de CT (Envolventes)
            if (parametroValidaciones === null || parametroValidaciones.length == 0) break;

            for (let i of parametroValidaciones.split('|')) {
              switch (i) {
                case 'GDOTRAFO_MB':
                  validacion +=
                    'Existe una Máquina en CT (Trafo de MT) con un estado de declaracion distinto;';
                  break;
                case 'GDOCELDA':
                  validacion +=
                    'Existe una Posición en CT (Celda) con un estado de declaracion distinto;';
                  break;
              }
            }
            break;
          }
          case '2': {
            validacion = 'El CT ha cambiado de propiedad respecto al año pasado;';
            break;
          }
          case '3': {
            validacion = 'La tensión de explotación no coincide con la tensión de Inventario';
            break;
          }
          case '4': {
            validacion = 'Este CT está desconectado de Tramos MT';
            break;
          }
          case '5': {
            validacion = 'CT sin celda probable CT Palomar y/o CT ocioso';
            break;
          }
        }
        break;
      }
      case 'gdoposicion_circular_vista': {
        switch (idValidations) {
          case '1': {
            //Validaciones variables para la tabla de Parques subestacion
            if (parametroValidaciones === null || parametroValidaciones.length == 0) break;

            for (let i of parametroValidaciones.split('|')) {
              switch (i) {
                case 'GDOPARQUE_T':
                  validacion +=
                    'Existe un Parque en Subestación con un estado de declaracion distinto;';
                  break;
                case 'GDOSUBESTAC':
                  validacion +=
                    'Existe un Parque en Subestación con un estado de declaración distinto;';
                  break;
              }
            }
            break;
          }
          case '2': {
            validacion =
              'La Posición en Subestación ha cambiado de propiedad respecto al año pasado;';
            break;
          }
          case '3': {
            validacion = 'Se aplica el criterio declaración del activo con TENSIÓN CONSTRUCCIÓN;';
            break;
          }
        }
        break;
      }

      case 'gdotrafo_pot_circular_vista': {
        switch (idValidations) {
          case '1': {
            //Validaciones variables para la tabla de Subestaciones
            if (parametroValidaciones === null || parametroValidaciones.length == 0) break;

            for (let i of parametroValidaciones.split('|')) {
              switch (i) {
                case 'GDOSUBESTAC':
                  validacion +=
                    'Existe un Parque en Subestación con un estado de declaración distinto;';
                  break;
                case 'GDOPARQUE_T':
                  validacion +=
                    'Existe un Parque en Subestación con un estado de declaracion distinto;';
                  break;
              }
            }
            break;
          }
        }
        break;
      }
    }
    return validacion;
  }

  getInnerValidationName(origin, idValidations): string {
    let validations;

    switch (origin) {
      case 'gdosecci_circular_vista':
        validations = GDO_SECCI_INNER_VALIDATIONS;
        break;
      case 'gdointerr_circular_vista':
        validations = GDO_INTERR_INNER_VALIDATIONS;
        break;

      case 'gdotramo_circular_vista':
        validations = GDO_TRAMO_INNER_VALIDATIONS;
        break;

      case 'gdotramoss_circular_vista':
        validations = GDO_TRAMO_INNER_VALIDATIONS;
        break;

      case 'gdotramo_baja_circular_vista':
        validations = GDO_TRAMO_BAJA_INNER_VALIDATIONS;
        break;

      case 'gdotramo_t_circular_vista':
        validations = GDO_TRAMO_T_INNER_VALIDATIONS;
        break;

      case 'gdotrafo_pot_regulatoria_circular_vista':
      case 'gdobiacondens_regulatoria_circular_vista':
      case 'gdotramo_t_regulatoria_circular_vista':
      case 'gdoct_regulatoria_circular_vista':
      case 'gdotramo_regulatoria_circular_vista':
      case 'gdotramo_baja_regulatoria_circular_vista':
      case 'gdotraforeg_regulatoria_circular_vista':
      case 'gdosecci_t_regulatoria_circular_vista':
        validations = GDO_REGULATORIA_VALIDATIONS;
        break;
      case 'gdoposicion_regulatoria_circular_vista':
      case 'gdointerr_regulatoria_circular_vista':
        validations = GDOINTERR_REGULATORIA_VALIDATIONS;
        break;
      case 'gdosecci_regulatoria_circular_vista':
        validations = GDOSECCI_REGULATORIA_VALIDATIONS;
        break;

      case 'f3_inventario_vista':
        validations = F3_INVENTARIO_INNER_VALIDATIONS;
        break;

      case 'f7_inventario_vista':
        validations = F7_INVENTARIO_INNER_VALIDATIONS;
        break;

      case 'f9at_vista':
      case 'f10at_vista':
        validations = AT_FORMS_INNER_VALIDATIONS;
        break;

      case 'f9bt_vista':
      case 'f10bt_vista':
        validations = BT_FORMS_INNER_VALIDATIONS;
        break;

      case 'f9mt_vista':
      case 'f10mt_vista':
        validations = MT_FORMS_INNER_VALIDATIONS;
        break;

      case 'f11_vista':
        validations = F11_INNER_VALIDATIONS;
        break;

      case 'f13_vista':
        validations = F13_INNER_VALIDATIONS;
        break;

      case 'f13bis_vista':
        validations = F13BIS_INNER_VALIDATIONS;
        break;
    }

    return validations[idValidations].name;
  }

  formatValidations(item): any {
    return {
      name: this.validationsProcesses[item.coherencia].name,
      description: this.validationsProcesses[item.coherencia].description,
      ...item,
    };
  }

  formatSummary(result = [], scope?): ValidationsSummary[] {
    let validationsProcesses = scope === 'mtbt' ? COHERENCE_PROCESSES_MTBT : COHERENCE_PROCESSES_AT;
    return sortBy(
      result.map((item) => {
        return {
          // idValidations: manual ? parseInt(item.coherencia) : item.coherencia,
          idValidations: item.coherencia,
          name: validationsProcesses[item.coherencia].name,
          description: validationsProcesses[item.coherencia].description,
          jobs: item.num_obras || 0,
          installations: item.num_instalaciones || 0,
          incurreds: item.num_incurridos || 0,
        };
      }),
      'idValidations',
    );
  }

  createFilterDB(idValidations): FilterDB {
    let filter = new FilterDB();
    filter.meta = { type: 'filter' };
    filter.table = this.validationsOrigin;
    filter.filter =
      this.scope === 'mtbt'
        ? {
            condition: 'AND',
            rules: [
              {
                operator: 'equal',
                id: 'coherencia',
                field: 'coherencia',
                type: 'string',
                value: idValidations,
              },
            ],
          }
        : {
            condition: 'AND',
            rules: [
              {
                operator: 'contains',
                id: 'coherencias',
                field: 'coherencias',
                type: 'string',
                value: idValidations,
                origin: this.validationsOrigin,
              },
            ],
          };
    return filter;
  }

  getValidationsDetail(idValidations: number, filters: Object = null): Observable<any> {
    let sortOptions = '[{"field": "importe", "dir": "desc"}]';
    if (filters === null || Object.keys(filters).length === 0) {
      const filterValidations = this.mergeFilters(
        this.filterService.formatFilter([this.createFilterDB(idValidations)]).filter,
      );
      return this.request.get(`${this.host}/table/data/${this.validationsOrigin}`, {
        query: filterValidations,
        limit: this.scope === 'at' ? 50 : 0,
        sort: sortOptions,
      });
    }
    const filterValidations = this.mergeFilters(filters);
    return this.request.get(`${this.host}/validations/${idValidations}`, {
      query: filterValidations,
      limit: 0,
      sort: sortOptions,
    });
  }

  stripManualValidations(validations, update?: boolean) {
    if (update) {
      return {
        name: validations.name,
        description: validations.description,
      };
    }
    return {
      id: validations.id,
      name: validations.name,
      description: validations.description,
    };
  }

  saveError(validations, err) {
    validations.error = true;
    validations.errorMsg = 'Error actualizando la coherencia manual';
    return observableThrowError(err);
  }

  setScope(scope) {
    if (scope === 'mtbt') {
      this.validationsProcesses = COHERENCE_PROCESSES_MTBT;
      this.validationsOrigin = environment.origins.validationsFilterMtbt;
    } else if (scope === 'at') {
      this.validationsProcesses = COHERENCE_PROCESSES_AT;
      this.validationsOrigin = environment.origins.validationsFilterAt;
    } else if (scope === 'installations' || scope === 'forms.inventory') {
      this.validationsProcesses = COHERENCE_PROCESSES_INSTALLATIONS;
    } else if (scope === 'dispatchers') {
      this.validationsProcesses = COHERENCE_PROCESSES_DISPATCHERS;
    } else if (scope === 'a1') {
      this.validationsProcesses = COHERENCES_PROCESSES_FORM_A1;
    } else if (scope === 'a4') {
      this.validationsProcesses = COHERENCES_PROCESSES_FORM_A4;
    } else if (scope === 'fa1') {
      this.validationsProcesses = COHERENCES_PROCESSES_F1;
    } else if (scope === 'f7') {
      this.validationsProcesses = COHERENCES_PROCESSES_FORM_7;
    } else if (scope === 'fa3') {
      this.validationsProcesses = COHERENCES_PROCESSES_FORM_A3;
    }

    this.scope = scope;
  }

  getValidationsFilters() {
    return this.request.get(`${this.host}/validations/filters`);
  }
}

export interface ValidationsSummary {
  idValidations: number | string;
  name: string;
  description: string;
  jobs: number;
  installations: number;
  incurreds: number;
}
