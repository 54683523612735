<div class="items-list-container">
  <div class="items-list-header">
    <span class="items-list-title" *ngIf="!inputSearch">
      <ng-content></ng-content>
    </span>
    <input class="items-list-input-search" type="text" *ngIf="inputSearch" [focusDirective]="setFocus"
      [ngModel]="search" (ngModelChange)="filterOptions($event)" />
    <div class="items-list-icon-search" *ngIf="items[origin] && items[origin].length > 0">
      <bdr-icon *ngIf="iconSearch === 'search' " icon="ico-search" (click)="openInputSearch()"></bdr-icon>
      <bdr-icon *ngIf="iconSearch === 'cancel-search' " icon="ico-ko" (click)="closeInputSearch()"></bdr-icon>
      <bdr-icon *ngIf="iconSearch === 'keyboard-search' " icon="ico-ko" (click)="clear()"></bdr-icon>
    </div>
    <div class="items-list-cancel">
      <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
    </div>
  </div>
  <div class="action-line" *ngIf="canEditFilters && items[origin] && items[origin].length !== 0">
    <a class="link text-header-link link-icon" (click)="createFolder()">
      <bdr-icon icon="ico-masterdata" class="icon-header"></bdr-icon>
      <span class="item-list-span-create">Crear carpeta</span>
    </a>
    <a class="link text-header-link link-icon" (click)="createItem()">
      <bdr-icon icon="ico-plus" class="icon-header"></bdr-icon>
      <span class="item-list-span-create">Crear filtro</span>
    </a>
  </div>

  <div class="items-list-body">
    <div class="items-list-empty-search" *ngIf="textEmptySearch">
      No existe ninguna coincidencia
    </div>
    <div class="items-list-item" *ngIf="displayFolders.length > 0">
      <bdr-row-drag *ngFor="let item of displayFolders" title="{{ item.folder_name }}" [editable]="true"
        [type]="'manualValidations'" [iconText]="item.folder_id" [iconClass]="manual - validations"
        [menu]="getFolderMenu(item)" class="manual-validations folder">
        <span class="folder-title" *ngIf="!item.editMode">
          {{item.folder_name}}
          <bdr-icon class="icon" (click)="item.expanded = !item.expanded" icon="caret-down" [esir]="true"
            *ngIf="item.children.length>0 && !item.expanded"></bdr-icon>
          <bdr-icon class="icon" (click)="item.expanded = !item.expanded" icon="caret-up" [esir]="true"
            *ngIf="item.children.length>0 && item.expanded"></bdr-icon>
        </span>
        <div class="permutable-input" *ngIf="item.editMode">
          <input [(ngModel)]="item.folder_name" placeholder="Nombre de carpeta" />
        </div>
        <span class="folder-description" *ngIf="!item.editMode">{{ item.folder_desc }}</span>
        <div class="permutable-input extend">
          <textarea *ngIf="item.editMode" [(ngModel)]="item.folder_desc" placeholder="Descripción"></textarea>

          <div class="button-container" *ngIf="item.editMode">
            <bdr-icon class="icon-button" (click)="cancelFolder(item)" [esir]="true" icon="cancel" size="18px">
            </bdr-icon>
            <bdr-icon class="icon-button save" (click)="saveFolder(item)" [esir]="true" icon="check" size="18px">
            </bdr-icon>
          </div>
        </div>

        <div class="error-messages" *ngIf="item.error">
          {{ item.errorMsg }}
        </div>
        <div class="child-items-list" *ngIf="item.expanded">
          <bdr-row-drag *ngFor="let filterItem of item.children" title="{{ filterItem.name }}" [itemId]="filterItem.id"
            [origin]="origin" [type]="type" [menu]="getMenu(filterItem)" [isSelected]="filterItem.applied"
            (applyItemRequest)="applyItem(filterItem)">
            {{ filterItem.name }}
          </bdr-row-drag>
        </div>
      </bdr-row-drag>


    </div>
    <div class="items-list-item" *ngIf="displayItems.length > 0">
      <bdr-row-drag *ngFor="let item of displayItems" title="{{ item.name }}" [itemId]="item.id" [origin]="origin"
        [type]="type" [menu]="getMenu(item)" [isSelected]="item.applied" (applyItemRequest)="applyItem(item)">
        {{ item.name }}
      </bdr-row-drag>
    </div>

    <div class="items-list-noitem" *ngIf="!items[origin] || (items[origin] && items[origin].length === 0)">
      <div class="items-list-circle">
        <img class="item-list-circle-img" src="../../assets/images/punta_emptySpace.png" alt="" />
        <div class="items-list-circle-in">
          <bdr-icon class="item-list-circle-icon" icon="ico-eyenot" size="48"></bdr-icon>
        </div>
      </div>
      <div class="items-list-noitem-text" *ngIf="type === 'filter' || type === 'issueFilter'">
        <span class="items-list-noitem-title">Aún no se ha creado ningún filtro</span>
        <span class="items-list-noitem-description">Puedes crearte tantos filtros como precises, ejecutarlos,
          modificarlos y compartirlos de una manera simple.</span>
        <span class="items-list-noitem-link" *ngIf="canEditFilters" (click)="createItem()">Crear filtro</span>
        <span class="items-list-noitem-link" *ngIf="canEditFilters" (click)="createFolder()">Crear Carpeta</span>
      </div>
    </div>
  </div>
</div>