<div class="download-options">
    <div class="panel-header">
        <span class="title">
            <ng-content></ng-content>
        </span>
        <div class="panel-header-cancel">
            <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
        </div>
    </div>
</div>

<div class="panel-body">
    <div *ngFor="let exportItem of exportData">
        <div *ngIf="exportItem.origin !== 'landing'">
            <span class="link" (click)="export(exportItem)">{{exportItem.title}}</span>
            <span
                class="link"
                [ngClass]="{'disabled': !isFilter}"
                *ngIf="exportItem.filterable"
                (click)="(isFilter)? export(exportItem, true):return"
                >Descarga selección actual
            </span>

            <span
                *ngIf="hasRegulatoryId(exportItem.origin)"
                class="link"
                (click)="export(exportItem, false, getRegulatoryParameter(exportItem.origin))"
            >
                {{exportItem.title}} con IDENTIFICADOR_REGULATORIO
            </span>
            <span
                class="link"
                [ngClass]="{'disabled': !isFilter}"
                *ngIf="hasRegulatoryId(exportItem.origin) && exportItem.filterable"
                (click)="(isFilter)? export(exportItem, true, getRegulatoryParameter(exportItem.origin)):return"
                >Descarga selección actual con IDENTIFICADOR_REGULATORIO
            </span>
        </div>
        <span
            class="link"
            *ngIf="exportItem.origin === 'landing'"
            (click)="exportLanding(exportItem.filterOrigin)"
        >
            {{exportItem.title}}
        </span>
        <span
            class="link"
            *ngIf="hasSres(exportItem.origin)"
            (click)="downloadSREForms(exportItem.title)"
        >
            {{exportItem.title}} con SREs
        </span>
    </div>
</div>
