import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MastersService } from "../services/masters/masters.service";
import { environment } from './../../environments/environment';


@Injectable()
export class YearParameterInterceptor implements HttpInterceptor {
  currentYear: string = "";
  constructor(private mastersService: MastersService) {
    this.mastersService.getRetributiveYear().subscribe(year => {
      this.currentYear = year;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newRequest;
    if (req.url.match(environment.apiDomain)) {
      if (req.method === "POST" || req.method === "PUT") {
        req.body.year = this.currentYear;
        newRequest = req.clone();
        return next.handle(newRequest);
      }

      newRequest = req.url.match(/system\//gi)
        ? req.clone()
        : req.clone({
          setParams: {
            year: this.currentYear.toString()
          }
        });
      return next.handle(newRequest);
    }
    return next.handle(req);
  }
}
