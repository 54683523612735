<div [ngClass]="{'overlay-wrapper': showOverlay}">
  <div [ngClass]="{'overlay': showOverlay}">
    <bdr-dialog-wrapper *ngFor="let dialog of dialogs"
      [ngClass]="{ 'dialog': true, 'focused': dialog.id === focusedDialog, 'minimized': dialog.minimized, 'fixed': dialog.fixed }"
      [id]="dialog.bindings.inputs.id" [dialog]="dialog" [rulerCtx]="rulerCtx"
      (onDialogTitleChange)="dialog.bindings.inputs.item.name = $event; dialog.title = $event"
      (minimizeDialogRequest)="handleMinimizeDialog($event)" (restoreDialogRequest)="handleRestoreDialog($event)"
      (focusDialogRequest)="handleFocusDialog($event)">
      <bdr-reflect-component class="dialog-content" [ngClass]="{'headerComponent': dialog.headerComponent}"
        [component]="dialog.component" [bindings]="dialog.bindings">
      </bdr-reflect-component>
    </bdr-dialog-wrapper>
    <canvas #rulerCanvas class="ruler-canvas" [width]="window.outerWidth" [height]="window.outerHeight">
    </canvas>
  </div>
</div>