<table mat-table [dataSource]="startupActs">
  <ng-container *ngFor="let column of headerColumns; let i = index" [matColumnDef]="column.name">
    <th mat-header-cell *matHeaderCellDef>
      {{ column.label }}
    </th>
    <td mat-cell *matCellDef="let row" nowrap>
      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="'input'">
          <div class="form-input" [ngClass]="{ editing: editableField.focused }">
            <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
              (ngModelChange)="onChangeEditable($event, column.name)" [value]="row[column.name]" />
            <bdr-icon icon="ico-edit" size="10"></bdr-icon>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>{{ row[column.name] }}</ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>