<div class="download-options">
  <div class="panel-header">
    <span class="title">
      <ng-content></ng-content>
    </span>
    <div class="panel-header-cancel">
      <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
    </div>
  </div>
</div>

<div class="panel-body">
  <div *ngFor="let exportItem of exportData">
    <span class="link" (click)="exportLogsCNMC()">Descargar todos los logs</span>
    <span class="link" (click)="exportCNMCFormLogs()">Descargar logs de este formulario</span>
    <span *ngIf="groupedForms()" class="link" (click)="exportCNMCFormGroupedLogs()">Descargar logs de este formulario completo AT/MT/BT</span>
  </div>
</div>