<div class="bdr-table">
  <table mat-table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" *ngIf="hasSelect">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkbox(row)" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Dynamic Columns -->
    <ng-container *ngFor="let column of columns; let i = index; trackBy: trackByFn" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>
        {{ column.label }}
      </th>
      <td mat-cell style="
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;" *matCellDef="let row" nowrap>

        <ng-container *ngIf="isEditable; else viewMode">
          <ng-container [ngSwitch]="column.type">
            <ng-container *ngSwitchCase="'input'">
              <div class="form-input" [ngClass]="{ editing: editableField?.focused }">
                <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
                  (input)="onChangeInput($event, row)" [value]="row[column.name]" />
                <bdr-icon icon="ico-edit" size="10"></bdr-icon>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <mat-select [(ngModel)]="row[column.name]" (ngModelChange)="onChangeSelect($event, row)"
                [compareWith]="compareFn">
                <ng-container
                  *ngIf="row.specialSelectOptions && row.specialSelectOptions[column.name]; else standardSelect">
                  <mat-option *ngFor="let option of row.specialSelectOptions[column.name]" [value]="option">
                    {{ option }}
                  </mat-option>
                </ng-container>
                <ng-template #standardSelect>
                  <mat-option *ngFor="let option of column.options" [value]="option">
                    {{ option }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </ng-container>
            <ng-container *ngSwitchCase="'checkbox'">
              <mat-checkbox (click)="$event.stopPropagation()" (ngModelChange)="checkbox(row)"></mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container [ngSwitch]="column.name">
                <ng-container *ngSwitchCase="'validaciones_ufd'">
                  <ng-container *ngFor="let validation of (row[column.name] || '').split('|')">
                    <span *ngIf="validation" class="validations validations--ela-detail fake-icon"
                      [title]="getUFDValidationNicename(validation)">
                      {{ validation }}
                    </span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tipo_reclasificacion'">
                  <ng-container *ngFor="let reclassification of (row[column.name] || '').split('|')">
                    <span class="validations fake-icon" *ngIf="reclassification"
                      [title]="getReclassificationTypeNicename(reclassification)">
                      {{ reclassification }}
                    </span>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row[column.name] }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #viewMode>
          <ng-container [ngSwitch]="column.name">
            <ng-container *ngSwitchCase="'validaciones_ufd'">
              <ng-container *ngFor="let validation of (row[column.name] || '').split('|')">
                <span *ngIf="validation" class="validations validations--ela-detail fake-icon"
                  [title]="getUFDValidationNicename(validation)">
                  {{ validation }}
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'tipo_reclasificacion'">
              <ng-container *ngFor="let reclassification of (row[column.name] || '').split('|')">
                <span class="validations fake-icon" *ngIf="reclassification"
                  [title]="getReclassificationTypeNicename(reclassification)">
                  {{ reclassification }}
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ row[column.name] }}
            </ng-container>
          </ng-container>
        </ng-template>

      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>