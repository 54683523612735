<nav class="menu">
  <a class="menu-item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
    <span class="row-container">
      <bdr-icon icon="ico-home"></bdr-icon>
    </span>
  </a>

  <a class="menu-item" routerLink="/loads" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-menu1"></bdr-icon>
      <span>Cargas</span>
    </span>
  </a>

  <a class="menu-item" routerLink="/investments-adjustment-sheet" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-menu5"></bdr-icon>
      <span>Cuadre de Inversiones</span>
    </span>
  </a>

  <a class="menu-item" routerLink="/investments-sheet" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-menu2"></bdr-icon>
      <span>Inversiones</span>
    </span>
  </a>

  <a class="menu-item" routerLink="/inventory-sheet" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-menu3"></bdr-icon>
      <span>Inventario</span>
    </span>
  </a>

  <a class="menu-item" routerLink="/installations-sheet" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-menu4"></bdr-icon>
      <span>Informe instalaciones declaradas</span>
    </span>
  </a>

  <a class="menu-item disabled">
    <span class="row-container">
      <bdr-icon icon="ico-legalizacion"></bdr-icon>
      <span>Legalizacion</span>
    </span>
  </a>

  <a class="menu-item" routerLink="/forms" routerLinkActive="active">
    <span class="row-container">
      <bdr-icon icon="ico-formularios"></bdr-icon>
      <span>Formularios</span>
    </span>
  </a>
</nav>


