<div class="download-options">
  <div class="panel-header">
    <span class="title">
      <ng-content></ng-content>
    </span>
    <div class="panel-header-cancel">
      <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
    </div>
  </div>
</div>

<div class="panel-body">
  <div *ngFor="let exportItem of exportData">
    <span class="link" (click)="exportCNMC()">Exportación Formularios B</span>
   <span
      *ngIf="hasRegulatoryId(exportItem.origin)"
      class="link"
      (click)="exportForm(getRegulatoryParameter(exportItem.origin))"
    >
      Exportar este formulario
    </span>

    <span
      *ngIf="!hasRegulatoryId(exportItem.origin)"
      class="link"
      (click)="exportForm(getRegulatoryParameter(exportItem.origin))"
    >
      Exportar este formulario
    </span>


    <span class="link" [ngClass]="{'disabled': !isFilter && !isSelectedFields}" (click)="(isFilter || isSelectedFields)? exportFilteredForm(getRegulatoryParameter(exportItem.origin)) : return">
      Exportar este formulario con filtros aplicados
    </span>
    <span *ngIf="groupedForms()" class="link" (click)="exportGroupedForms()"
    >
      Exportar formularios completos AT/MT/BT
    </span>
  </div>
</div>
