<div class="applied-filters" *ngIf="appliedFilters && appliedFilters.length > 0">
  <span class="toolbar-actions-separator invisible"></span>
  <span class="applied-filters-title">Tienes aplicado:</span>
  <span class="filter" *ngFor="let filter of (appliedFilters | slice: 0:2)">
    <bdr-icon *ngIf="filter.meta && filter.meta.type === 'filter'" icon="ico-filter" size="16px"
      (click)="resetFilter(filter)"></bdr-icon>
    <span *ngIf="filter.meta && filter.meta.type === 'manual-validations-filter'"
      class="manual-validations fake-icon ">{{ filter.filter.rules[0].value }}</span>
    <span *ngIf="filter.meta && filter.meta.type === 'validations-filter'" class="fake-icon">{{
      filter.filter.rules[0].value }}</span>
    <span class="filter-name" title="{{ getItemName(filter) }}">{{
      getItemName(filter)
      }}</span>
    <bdr-icon class="filter-icon-close" icon="ico-close" size="16px" (click)="resetFilter(filter)"></bdr-icon>
  </span>
  <bdr-icon *ngIf="appliedFilters.length > 2" class="icon-more" icon="menu" [esir]="true" size="24px"
    (click)="openAppliedFiltersTooltip($event)">
  </bdr-icon>
</div>