<grid-handler-view scope="inventory" [panels]="panels" [volumetries]="null" [gridProperties]="gridProperties"
  [editMode]="editMode" [editChangedValues]="editData !== undefined && editData.length > 0"
  [canSave]="editData !== undefined && editData.length > 0" [saving]="savingFlag" [showModalSaving]="showModalSaving"
  [subheaderSelector]="subheaderSelector" 
  [recurrentEditionCheckbox]="true"
  (onGenerateAction)="generateSheet($event)"
  (onSaveChanges)="saveChanges($event)" 
  (onGridEditChange)="gridEditChange($event)" 
  (onCancel)="cancelEdit()"
  (onDblClick)="dblClick($event)"
>
</grid-handler-view>