<div class="grid-pagination">
  <div class="pagination-action">
    <span class="paginate" *ngIf="!loadingMore && totalRows > dataLength">
      Ver más {{ subject }}:
      <span class="paginate-number" (click)="triggerPaginate(50)">50</span>
      <span class="paginate-number" (click)="triggerPaginate(200)">, 200</span>
      <span class="paginate-number" (click)="triggerPaginate(500)">, 500</span>
    </span>
    <span class="loading-more" *ngIf="loadingMore">
      Cargando {{ subject }}...
    </span>
    <span class="current-view">
      {{ dataLength }}
      {{
      subject[subject.length - 1] === "s" && dataLength == 1
      ? subject.substr(0, subject.length - 1)
      : subject
      }}
      mostrado{{ dataLength == 1 ? "" : "s" }}
    </span>
  </div>
  <span class="goto-top" (click)="triggerScrollTop()">
    <bdr-icon class="scroll-top-icon" icon="ico-up"></bdr-icon>
  </span>
</div>