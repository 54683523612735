<div class="visible-columns">
  <div class="visible-columns__title" [matMenuTriggerFor]="menu" #clickMenuTrigger="matMenuTrigger">
    <bdr-icon class="main-eye-icon" icon="ico-eye"></bdr-icon>
    <p>
      Visualizar datos
    </p>
  </div>

  <mat-menu class="visible-columns__menu" #menu="matMenu" class="form-field__items" (click)="$event.stopPropagation()">
    <div class="visible-columns__filter" (click)="$event.stopPropagation()">
      <input matInput (input)="handleFilter($event)" />
      <bdr-icon icon="ico-search"></bdr-icon>
    </div>

    <div class="visible-columns__body">
      <mat-checkbox class="visible-columns__body--checkbox" (click)="$event.stopPropagation()"
        (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()">
        Todos
      </mat-checkbox>
      <mat-checkbox class="visible-columns__body--checkbox" *ngFor="let item of filters"
        (click)="$event.stopPropagation()" (change)="$event ? addItem(item) : null"
        [checked]="selection.isSelected(item)">
        {{ item.label }}
      </mat-checkbox>
    </div>

    <div class="visible-columns__footer">
      <button (click)="removeFilters($event)" class="bdr-btn bdr-btn-white">
        Borrar Todos</button><button (click)="applyFilters($event)" class="bdr-btn bdr-btn-blue">
        Aplicar
      </button>
    </div>
  </mat-menu>
</div>