<ul class="tabs" style="height: 53px">
  <li *ngFor="let option of options; let i = index" (click)="clickHandler(option)" [ngClass]="{
    active: model === option.value,
    first: i === 0,
    last: i === options.length - 1,
    disabled: option.disabled
  }">
    <span>{{ option.name }}</span>
  </li>
</ul>


