<section class="body">
  <div>
    <table>
      <tr>
        <td>
          <bdr-checkbox [(model)]="isEditing" caption="Editar">
          </bdr-checkbox>
        </td>

        <td>
          <bdr-checkbox [(model)]="isDiffering" caption="Diferir">
          </bdr-checkbox>
        </td>
        <td>
          <bdr-checkbox [(model)]="isReclassificating" caption="Reclasificar">
          </bdr-checkbox>
        </td>
        <td *ngIf="isDiffering" class="differing-message">{{ differingMessage }}</td>
      </tr>
    </table>
  </div>

  <div style="width: 100%; overflow-y: scroll; overflow-x: scroll;">
    <div style="width: 1600px; min-height: 250px; max-height: 600px;">
      <table>
        <tr style>
          <th class="wrapper-th" style="width:200px">ID</th>
          <ng-container *ngIf="isDiffering">
            <th class="wrapper-th">Año retributivo</th>
          </ng-container>
          <ng-container *ngIf="isReclassificating">
            <th class="wrapper-th">Preclasificacion Recl</th>
            <th class="wrapper-th">MEI Recl</th>
            <th class="wrapper-th">Clasificacion Recl</th>
            <th class="wrapper-th">ELA Recl</th>
            <th class="wrapper-th">Cod Actuacion Recl</th>
            <th class="wrapper-th">Clasificacion Inversion Recl</th>
          </ng-container>
          <ng-container *ngIf="isEditing">
            <th class="wrapper-th">CINI</th>
            <th class="wrapper-th">Num Cuenta</th>
            <th class="wrapper-th">Codigo Obra</th>
            <th class="wrapper-th">Id. plan inversiones</th>
          </ng-container>
          <th class="wrapper-th">Importe</th>
          <th class="wrapper-th">
            <bdr-checkbox [(model)]="massiveEdition" caption="Edición masiva">
            </bdr-checkbox>
          </th>
          <th class="wrapper-th">
            <span></span>
          </th>
        </tr>

        <tr *ngIf="massiveEdition" style="border: thing solid #eee; color: black">
          <td class="wrapper-id"></td>

          <ng-container *ngIf="isDiffering">
            <td>
              <bdr-select 
                [options]="yearOptions" 
                [model]="retributiveYear"
                (modelChange)="setAll('anio_retributivo', $event)">
              </bdr-select>
            </td>
          </ng-container>

          <ng-container *ngIf="isReclassificating">
            <td>
              <bdr-select [options]="preclasificacionReclOptions"
                (modelChange)="setAll('preclasificacion_recl', $event)">
              </bdr-select>
            </td>
            <td>
              <bdr-select [options]="globalMeiOptions" [disabled]="globalMeiOptions.length===0"
                (modelChange)="setAll('mei_recl', $event)">
              </bdr-select>
            </td>

            <td>
              <bdr-select [options]="globalClassificationOptions" [disabled]="globalClassificationOptions.length===0"
                (modelChange)="setAll('clasificacion_recl', $event)">
              </bdr-select>
            </td>

            <td>
              <bdr-input size="wide" [horizontal]="true" (focusout)="setAll('ela_recl', $event.target.value)">
              </bdr-input>
            </td>

            <td>
              <bdr-select [options]="globalActingCodesOptions" [disabled]="globalActingCodesOptions.length===0"
                (modelChange)="setAll('cod_actuacion_recl', $event)">
              </bdr-select>
            </td>
            <td>
              <bdr-select [options]="globalInvestmentsClassificationOptions"
                [disabled]="globalInvestmentsClassificationOptions.length===0"
                (modelChange)="setAll('clasificacion_inversion_recl', $event)">
              </bdr-select>
            </td>
          </ng-container>

          <ng-container *ngIf="isEditing">
            <td>
              <bdr-input size="wide" [horizontal]="true" (focusout)="setAll('cini', $event.target.value)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [horizontal]="true" (focusout)="setAll('num_cuenta', $event.target.value)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [horizontal]="true" (focusout)="setAll('codigo_obra', $event.target.value)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [horizontal]="true" (focusout)="setAll('identificador_plan_inversiones', $event.target.value)">
              </bdr-input>
            </td>
          </ng-container>

          <td></td>
          <td>
            <span *ngIf="canMassiveSplit" style="margin-right:10px" (click)="addMassiveEditionSplittedRow()">
              <bdr-icon icon="ico-plus" size="16px"></bdr-icon>
              <span class="link">Partir</span>
            </span>
          </td>
          <td>
            <span class="observations-button link" title="Observaciones"
              (click)="showObservationsDialog(null,'massiveEdition')">
              <bdr-icon [ngClass]="{'has-data': row?.observaciones }" class="icon icon-header" size="18px"
                icon="ico-actions"></bdr-icon>
            </span>
          </td>
        </tr>

        <tr *ngFor="let row of massiveEditionSplittedRows" style="border: thing solid #eee; color:black">
          <td class="wrapper-id">Partido #{{ row.id }}</td>
          <ng-container *ngIf="isDiffering">
            <td>
              <bdr-select [options]="yearOptions" [model]="row.anio_retributivo"
                (modelChange)="setAllSplitted('anio_retributivo', row, $event, row.id)">
              </bdr-select>
            </td>
          </ng-container>

          <ng-container *ngIf="isReclassificating">
            <td>
              <bdr-select [options]="preclasificacionReclOptions" [model]="row.preclasificacion_recl"
                (modelChange)="setAllSplitted('preclasificacion_recl', row, $event, row.id)">
              </bdr-select>
            </td>

            <td>
              <bdr-select [options]="row.mei_options" [model]="row.mei_recl" [disabled]="row.mei_options.length===0"
                (modelChange)="setAllSplitted('mei_recl', row, $event, row.id)">
              </bdr-select>
            </td>

            <td>
              <bdr-select [options]="row.classification_options" [model]="row.clasificacion_recl"
                [disabled]="row.classification_options.length===0"
                (modelChange)="setAllSplitted('clasificacion_recl', row, $event, row.id)">
              </bdr-select>
            </td>

            <td>
              <bdr-input size="wide" [horizontal]="true" [model]="row.ela_recl"
                (focusout)="setAllSplitted('ela_recl', row, $event.target.value, row.id)">
              </bdr-input>
            </td>
            <td>
              <bdr-select [options]="row.acting_code_options" [model]="row.cod_actuacion_recl"
                [disabled]="row.acting_code_options.length===0"
                (modelChange)="setAllSplitted('cod_actuacion_recl', row, $event, row.id)">
              </bdr-select>
            </td>
            <td>
              <bdr-select [model]="row.clasificacion_inversion_recl" [options]="row.investments_classification_options"
                [disabled]="globalInvestmentsClassificationOptions.length===0"
                (modelChange)="setAllSplitted('clasificacion_inversion_recl', row, $event, row.id)">
              </bdr-select>
            </td>
          </ng-container>

          <ng-container *ngIf="isEditing">
            <td>
              <bdr-input size="wide" [model]="row.cini" [horizontal]="true"
                (focusout)="setAllSplitted('cini', row, $event.target.value, row.id)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [model]="row.num_cuenta" [horizontal]="true"
                (focusout)="setAllSplitted('num_cuenta', row, $event.target.value, idx, row.id)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [model]="row.codigo_obra" [horizontal]="true"
                (focusout)="setAllSplitted('codigo_obra', row, $event.target.value, idx, row.id)">
              </bdr-input>
            </td>
            <td>
              <bdr-input size="wide" [model]="row.identificador_plan_inversiones" [horizontal]="true"
                (focusout)="setAllSplitted('identificador_plan_inversiones', row, $event.target.value, idx, row.id)">
              </bdr-input>
            </td>
          </ng-container>

          <td>
            <bdr-input [horizontal]="true" [placeholder]="row.importe" [size]="small"
              [model]="row.importe_round || row.importe"
              [title]="amountErrors[row.id_padre]? 'Los importes partidos deben sumar el importe original y ser todos positivos' : 'Importe'"
              (focusout)="setAllSplitted('importe', row, $event.target.value, row.id)">
            </bdr-input>
          </td>
          <td>
            <span *ngIf="canMassiveSplit" style="margin-right:10px" (click)="removeMassiveEditionSplittedRow(row.id)">
              <bdr-icon icon="ico-close" size="16px"></bdr-icon>
              <span class="link">Eliminar</span>
            </span>
          </td>
          <td>
            <span class="observations-button link" title="Observaciones"
              (click)="showObservationsDialog(row,'massiveSplittedEdition')">
              <bdr-icon [ngClass]="{'has-data': row?.observaciones }" class="icon icon-header" size="18px"
                icon="ico-actions"></bdr-icon>
            </span>
          </td>
        </tr>

        <tr [ngClass]="{'new-row': row.new}" *ngFor="let row of paginatedRows()"
          style="border-bottom: 1 px solid grey;">
          <ng-container
            *ngIf="!row.splitted && !row.annulation && row.clasificacion_inversion_recl !== 'PARTIDO-EXCLUIR'; else blockedAppointment">
            <ng-container *ngIf="!row.new; else tabulatedId">
              <td class="wrapper-id" [title]="row.id">
                {{row.id? row.id : ""}}
              </td>
            </ng-container>
            <ng-template #tabulatedId>
              <td>
                <table>
                  <td>
                    <bdr-icon icon="ico-plus" size="16px"></bdr-icon>
                  </td>
                  <td class="wrapper-id" [ngClass]="{'tabulated-id': row.new}" [title]="row.id">
                    {{row.id? row.id : ""}}
                  </td>
                </table>
              </td>
            </ng-template>

            <!-- DIFERIR -->
            <ng-container *ngIf="isDiffering">
              <td>
                <bdr-select [options]="yearOptions" [model]="row.anio_retributivo"
                  (modelChange)="set('anio_retributivo', row, $event)">
                </bdr-select>
              </td>
            </ng-container>

            <!-- RECLASIFICAR -->
            <ng-container *ngIf="isReclassificating">
              <td>
                <bdr-select [options]="preclasificacionReclOptions" [placeholder]="getPreclasificacion(row)"
                  [model]="row.preclasificacion_recl" (modelChange)="set('preclasificacion_recl', row, $event)">
                </bdr-select>
              </td>
              <td [ngClass]="{'sum-error': preclassificationErrors[row.id]}">
                <bdr-select [placeholder]="row.mei_recl || row.mei" [options]="row.mei_options"
                  [disabled]="row.mei_options.length===0" [model]="row.mei_recl"
                  [title]="preclassificationErrors[row.id]? 'Ha modificado la preclasificación y debe rellenar estos campos' : ''"
                  (modelChange)="set('mei_recl', row, $event.split('::')[0])">
                </bdr-select>
              </td>
              <td [ngClass]="{'sum-error': preclassificationErrors[row.id]}"
                [title]="preclassificationErrors[row.id]? 'Ha modificado la preclasificación y debe rellenar estos campos' : ''">
                <bdr-select [placeholder]="row.clasificacion_recl || row.clasificacion"
                  [disabled]="row.clasificacion_options.length===0" [options]="row.clasificacion_options"
                  [model]="row.clasificacion_recl" (modelChange)="set('clasificacion_recl', row, $event)">
                </bdr-select>
              </td>
              <ng-container
                *ngIf="isPreclassificationAppointment(row, 'AT') || isPreclassificationAppointment(row, 'DESPACHOS'); else nonAtPreclassification">
                <td>
                  <bdr-input size="wide" [horizontal]="true" [placeholder]="row.ela_recl || row.ela || ''"
                    [model]="row.ela_recl" (focusout)="set('ela_recl', row, $event.target.value)">
                  </bdr-input>
                </td>
                <td>
                  <bdr-select [placeholder]="row.cod_actuacion_recl || row.cod_actuacion"
                    [options]="row.cod_actuacion_recl_options" [disabled]="row.cod_actuacion_recl_options.length===0"
                    [model]="row.cod_actuacion_recl" (modelChange)="set('cod_actuacion_recl', row, $event)">
                  </bdr-select>
                </td>
              </ng-container>
              <ng-template #nonAtPreclassification>
                <td></td>
                <td></td>
              </ng-template>
              <td [ngClass]="{'sum-error': preclassificationErrors[row.id]}">
                <bdr-select [placeholder]="row.clasificacion_inversion_recl || row.clasificacion_inversion"
                  [options]="row.clasificacion_inversion_options"
                  [disabled]="row.clasificacion_inversion_options.length===0" [model]="row.clasificacion_inversion_recl"
                  [title]="preclassificationErrors[row.id]? 'Ha modificado la preclasificación y debe rellenar estos campos' : ''"
                  (modelChange)="set('clasificacion_inversion_recl', row, $event)">
                </bdr-select>
              </td>
            </ng-container>


            <!-- EDITAR -->
            <ng-container *ngIf="isEditing" [@slideUpDownAnimation]="isEditing">
              <ng-container *ngIf="
                  isPreclassificationAppointment(row, 'DESPACHOS') || isPreclassificationAppointment(row, 'EDIFICIOS/VARIOS')
                  else nonCiniNumCuentaPreclassification">
                <td>
                  <bdr-input size="wide" [horizontal]="true" [placeholder]="row.cini || ''" [model]="row.cini"
                    (focusout)="set('cini', row, $event.target.value)">
                  </bdr-input>
                </td>
                <td>
                  <bdr-input size="wide" [horizontal]="true" [placeholder]="row.num_cuenta || ''"
                    [model]="row.num_cuenta" (focusout)="set('num_cuenta', row, $event.target.value)">
                  </bdr-input>
                </td>
                <td>
                  <bdr-input *ngIf="!row.codigo_obra_original" size="wide" [horizontal]="true" [placeholder]=""
                    [model]="row.codigo_obra" (focusout)="set('codigo_obra', row, $event.target.value)">
                  </bdr-input>
                </td>
                <td>
                  <bdr-input *ngIf="true" size="wide" [horizontal]="true" [placeholder]=""
                    [model]="row.identificador_plan_inversiones" (focusout)="set('identificador_plan_inversiones', row, $event.target.value)">
                  </bdr-input>
                </td>

              </ng-container>
              <ng-template #nonCiniNumCuentaPreclassification>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="row.new; else originalAppointment">
              <td [ngClass]="{'sum-error': amountErrors[row.id_padre]}">
                <bdr-input [horizontal]="true" [placeholder]="row.importe" [size]="small"
                  [model]="row.importe_round || row.importe"
                  [title]="amountErrors[row.id_padre]? 'Los importes partidos deben sumar el importe original y ser todos positivos' : 'Importe'"
                  (focusout)="set('importe', row, $event.target.value)">
                </bdr-input>
              </td>
              <td>
                <span *ngIf="canSingleSplit" style="margin-right:10px"
                  (click)="removeVirtualAppointment(row.id_padre, row)">
                  <bdr-icon icon="ico-close" size="16px"></bdr-icon>
                  <span class="link">Eliminar</span>
                </span>
              </td>
              <td>
                <span class="observations-button link" title="Observaciones"
                  (click)="showObservationsDialog(row,'singleEdition')">
                  <bdr-icon [ngClass]="{'has-data': row?.observaciones }" class="icon icon-header" size="18px"
                    icon="ico-actions"></bdr-icon>
                </span>
              </td>
            </ng-container>

            <ng-template #originalAppointment>
              <td style="float: right">{{ row.importe | localeCurrency }}</td>
              <td>
                <span *ngIf="canSingleSplit" style="margin-right:10px" (click)="splitAppointment(row)">
                  <bdr-icon icon="ico-plus" size="16px"></bdr-icon>
                  <span class="link">Partir</span>
                </span>
              </td>
              <td>
                <span class="observations-button link" title="Observaciones"
                  (click)="showObservationsDialog(row,'singleEdition')">
                  <bdr-icon [ngClass]="{'has-data': row?.observaciones }" class="icon icon-header" size="18px"
                    icon="ico-actions"></bdr-icon>
                </span>
              </td>
            </ng-template>
          </ng-container>

          <ng-template #blockedAppointment>
            <ng-container *ngIf="!row.new; else tabulatedId">
              <td class="wrapper-id" [title]="row.id">{{ row.id }}</td>
            </ng-container>
            <ng-template #tabulatedId>
              <td>
                <table>
                  <td>
                    <bdr-icon icon="ico-plus" size="16px"></bdr-icon>
                  </td>
                  <td class="wrapper-id" [title]="row.id">{{ row.id }}</td>
                </table>
              </td>
            </ng-template>

            <ng-container *ngIf="isDiffering">
              <td>{{ row.anio_retributivo }}</td>
            </ng-container>
            <ng-container *ngIf="isReclassificating">
              <td>{{ row.preclasificacion }}</td>
              <td>{{ row.mei }}</td>
              <td>{{ row.clasificacion }}</td>
              <td>{{ row.ela }}</td>
              <td>{{ row.cod_actuacion }}</td>
              <td>{{ row.clasificacion_inversion_recl }}</td>
            </ng-container>
            <ng-container *ngIf="isEditing">
              <td>{{ row.cini }}</td>
              <td>{{ row.num_cuenta }}</td>
              <td>{{ row.codigo_obra }}</td>
              <td>{{ row.identificador_plan_inversiones }}</td>
            </ng-container>
            <td style="float: right">{{ row.importe | localeCurrency }}</td>
            <td>
              <span
                *ngIf="canSingleSplit && (!row.annulation && (row.splitted || row.clasificacion_inversion_recl !== 'PARTIDO-EXCLUIR'))"
                style="margin-right:10px" (click)="splitAppointment(row)">
                <bdr-icon icon="ico-plus" size="16px"></bdr-icon>
                <span class="link">Partir</span>
              </span>
            </td>
            <td>
              <span class="observations-button link" title="Observaciones"
                (click)="showObservationsDialog(row,'singleEdition')">
                <bdr-icon [ngClass]="{'has-data': row?.observaciones }" class="icon icon-header" size="18px"
                  icon="ico-actions"></bdr-icon>
              </span>
            </td>
          </ng-template>
        </tr>

      </table>
    </div>
  </div>
  <div class="pages">
    <span *ngFor="let page of pages" class="bottom-link link page-number" (click)="setPage(page)">
      {{page+1}}
    </span>
  </div>
</section>

<footer class="footer">
  <span *ngIf="!saving" class="bottom-link link" (click)="reload()">Deshacer cambios</span>
  <span *ngIf="!saving" class="bottom-link link" (click)="save()">Aplicar y guardar</span>
  <span *ngIf="saving" class="saving">
    <bdr-icon icon="ico-save" size="16px"></bdr-icon> Guardando...
  </span>
</footer>