<div class="info-master-configuration">
  <div class="title" style="display: flex; align-items: stretch;">

    <ng-container>
      <ng-container>
        Modo:
      </ng-container>
      <br/>
      <ng-container *ngIf="infoMasterConfiguration.comparision">
        Comparativa:
      </ng-container>

    </ng-container>

  </div>

  <div class="value" style="display: flex; align-items: stretch;">
    <ng-container>
      <ng-container>
        {{infoMasterConfiguration.load_mode}}
      </ng-container>
      <br>
      <ng-container *ngIf="infoMasterConfiguration.comparision">
        {{infoMasterConfiguration.comparision}}
      </ng-container>
    </ng-container>
  </div>
</div>