<div class="container-notification {{ notification?.type }} animation-icon-pop">
  <bdr-icon class="notification-icon" icon="alert" *ngIf="notification?.type === 'warning'"></bdr-icon>
  <bdr-icon class="notification-icon" icon="thumbs-down" *ngIf="notification?.type === 'error'"></bdr-icon>
  <bdr-icon class="notification-icon" icon="info" *ngIf="notification?.type === 'info'"></bdr-icon>
  <div class="notification-body">
    <span class="notification-text">{{ notification?.message }}</span>
    <!-- <a
      class="notification-undo-link {{ notification?.type }}"
      *ngIf="notification?.link"
      href="{{ notification?.link }}"
      >{{ notification?.linkText }}</a
    > -->
    <!-- <span
      class="notification-undo-link {{ notification?.type }}"
      *ngIf="notification?.actionFunction"
      (click)="action()"
      >{{ notification?.linkText }}</span
    >
    <span
      *ngIf="notification?.undoFunction"
      (click)="undo()"
      class="notification-undo-link {{ notification?.type }}"
      >Deshacer</span
    > -->
  </div>
  <bdr-icon (click)="close()" class="notification-close-icon {{ notification?.type }}" icon="close-popup" esir="true">
  </bdr-icon>
</div>