<ng-container [ngSwitch]="uploadView">
  <div *ngSwitchCase="'deleteConfirmation'">
    <section class="body">
      <header class="header" (escapeDialog)="handleCloseDialog()">
        <div class="header-title">
          <span class="title-prefix">Borrar fichero</span>
        </div>
      </header>
      <div class="body-wrapper-confirm">
        <p>¿Está seguro de que desea eliminar el fichero?</p>
        <p>Esto <b>no eliminará</b> los datos asociados al maestro (estos sólo cambiarán si sube un nuevo fichero).</p>
      </div>
    </section>
    <footer class="footer-wrapper">
      <div class="footer">
        <div class="footer-actions">
          <bdr-button class="btn-save" size="small" (click)="backToMasterdataFiles()"><span class="link">Cancelar</span></bdr-button>
          <bdr-button class="btn-save" primary size="small" (click)="deleteFile()">Borrar fichero</bdr-button>
        </div>
      </div>
    </footer>
  </div>

  <div *ngSwitchCase="'masterdataFiles'">
    <section class="body">
      <header class="header" (escapeDialog)="handleCloseDialog()">
        <div class="header-title">
          <span class="title-prefix"> Ficheros en S3 de {{ masterToShowFiles }}</span>
        </div>
      </header>
      <div class="body-wrapper">
        <div *ngIf='loading' style="display: flex;justify-content:center" >
          <img src="/assets/images/loading.gif" width="100" height="100" alt="Cargando" />
        </div>
        <div>{{notFoundMsg}}</div>
        <div *ngFor="let s3File of s3Files">
          <p>
            <bdr-icon icon="ico-download link" size="15px" style="margin-right: 10px;" title="Descargar archivo de S3" (click)="downloadFile(s3File)"></bdr-icon>
            <bdr-icon icon="ico-delete link" size="15px" title="Borrar archivo de S3" (click)="showDeleteConfirmation(s3File)"></bdr-icon>
            
            <span style="padding-left: 10px">
              {{s3File.file.replace( "landing/" + s3SelectedMasterdata + "/", "")}} (subido el {{locale(s3File.upload_date)}})
            </span>
          </p>
        </div>
      </div>
    </section>
    <footer class="footer-wrapper">
      <div class="footer">
        <div class="footer-actions">
          <bdr-button class="btn-save" size="small" (click)="backToMasterdata()"><span class="link">Volver</span></bdr-button>
        </div>
      </div>
    </footer>
  </div>

  <div *ngSwitchCase="'masterdata'">
    <section class="body">
      <header class="header" (escapeDialog)="handleCloseDialog()">
        <div class="header-title">
          <span class="title-prefix">Subir archivos maestros</span>
        </div>
      </header>
      <div class="body-wrapper">
        <div class="file-upload-field">
          <span class="error-message" *ngIf="formErrors['fileUpload']">Debe seleccionar un archivo</span>
          <label class="fake-input-file">
            <span class="bdr-button primary">Seleccionar Archivo</span>
            <span class="input-file-label" *ngIf="!fileUpload">Ningún archivo seleccionado</span>
            <span class="input-file-label" *ngIf="fileUpload">{{ fileUpload.name }}<span class="remove-file"
                (click)="resetFileUpload($event)">
                <bdr-icon icon="close" [esir]="true"></bdr-icon>
              </span></span>
            <input #fileinput type="file" class="input-file" name="file-upload" (change)="fileUpdate($event)" />
          </label>
        </div>
        <div class="search-content-header content-header">
          <input class="items-list-input-search" type="text" [ngModel]="queryString" (ngModelChange)="filter($event)" />
          <div class="items-list-icon-search">
            <bdr-icon *ngIf="iconSearch === 'search'" class="highlight-icon" icon="ico-search" (click)="openInputSearch()">
            </bdr-icon>
            <bdr-icon *ngIf="iconSearch === 'cancel-search'" class="highlight-icon" icon="ico-ko"
              (click)="closeInputSearch()"></bdr-icon>
          </div>
        </div>
        <span class="error-message" *ngIf="formErrors['filePath']">Debe seleccionar un maestro que actualizar</span>
        <div class="content-body">
          <div *ngIf='loading' style="display: flex;justify-content:center" >
            <img src="/assets/images/loading.gif" width="100" height="100" alt="Cargando" />
          </div>
          
          <ul class="path-list" *ngIf="displayed">
            <li class="column" *ngFor="let key of objectKeys(displayed)">
              
              <bdr-icon icon="ico-eye link" size="15px" title="Ver archivos de S3" (click)="showMasterdataFiles(key)"></bdr-icon>
              
              <span class="path-item" [ngClass]="{ selected: filePath === key }" (click)="filePath = key">
                {{ nicename(key) }}  
              </span>
              
            </li>
          </ul>
        </div>
      </div>
    </section>
    <footer class="footer-wrapper">
      <div class="footer">
        <div class="footer-actions">
          <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()"><span class="link">Cancelar</span></bdr-button>
          <bdr-button class="btn-save" primary size="small" (click)="handleUpload()">Aplicar</bdr-button>
        </div>
      </div>
    </footer>
  </div>
</ng-container>