import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DownloadsList, Download } from './downloads-list';
import { FilterService } from '../filter/filter.service';
import { NotificationsService, Notification } from '../notifications/notifications.service';
import { DataApiService } from '../data-api/data-api.service';
import { GridExportData } from '../grid/grid.service';
import { mergeFilters } from '../../util';
import { AsyncQueryResponse } from '../../models/query-poller';
import { OriginService } from '../origin/origin.service';

@Injectable({ providedIn: 'root' })
export class DownloadsService {
  private downloads: DownloadsList = new DownloadsList();;
  public downloadNotification: boolean;
  public downloadReady: boolean;
  constructor(
    private notificationsService: NotificationsService,
    private filterService: FilterService,
    private apiService: DataApiService,
    private originService: OriginService
  ) {
    this.downloadNotification = false;
    this.downloadReady = false;
  }

  addDownload(download: Download) {
    this.downloads.addDownload(download);
  }

  getDownloadsObservable(): Observable<any> {
    return this.downloads.getSubject();
  }

  cancelAll() {
    this.downloads.cancelAll();
  }

  cancelOne(index) {
    this.downloads.cancelOne(index);
  }

  assignData(data, downloadId) {
    if (this.downloads.isEmpty) {
      this.downloadReady = false;
    }
    this.downloads.assignData(data, downloadId);
  }

  downloadNotificationTimer() {
    this.downloadNotification = true;
    setTimeout(() => {
      this.downloadNotification = false;
    }, 5000);
  }


  downloadGeneratedForms(downloadTitle, origin) {
    this.addDownload({
      id: origin,
      fileName: downloadTitle,
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getExportGeneratedFormsCircular(origin).subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }
 
  downloadMasterdateCsvGz(downloadTitle, path) {
    this.addDownload({
      id: origin,
      fileName: downloadTitle,
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getMasterdataCsvGz(path).subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }

  downloadCNMCGeneratedForms() {
    this.addDownload({
      id: 'cnmc',
      fileName: 'cnmc',
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getExportCNMCForms().subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, 'cnmc');
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, 'cnmc');
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }


  downloadCNMCGroupedGeneratedForms(origin: string) {
    this.addDownload({
      id: origin,
      fileName: `Agrupado AT/MT/BT`,
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getExportGroupedCNMCForms(origin).subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, origin);
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }


  downloadCNMCLogs() {
    this.addDownload({
      id: 'logs-cnmc',
      fileName: 'Log de errores CNMC',
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getCNMCLogs().subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, 'logs-cnmc');
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, 'logs-cnmc');
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }

  downloadCNMCFormLogs(origin: string) {
    this.addDownload({
      id: 'logs-cnmc-form',
      fileName: 'Log de errores CNMC',
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getCNMCFormLogs(origin).subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, 'logs-cnmc-form');
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, 'logs-cnmc-form');
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }

  downloadCNMCFormGroupedLogs(origin: string) {
    this.addDownload({
      id: 'log-cnmc-grouped-form',
      fileName: 'Log de errores CNMC',
      url: '#',
      status: 'RUNNING'
    });

    let requestSubject = new Subject();
    this.apiService.getCNMCFormGroupedLogs(origin).subscribe(
      response => {
        let updateItem = {
          type: 'SUCCESS',
          data: { result: { url: response.url } }
        };
        this.assignData(updateItem, 'log-cnmc-grouped-form');
        requestSubject.next({ type: 'SUCCESS' });
      },
      error => {
        let updateItem = { data: {}, type: 'ERROR' };
        this.assignData(updateItem, 'log-cnmc-grouped-form');
        requestSubject.next({ type: 'ERROR' });
      }
    );
  }






  processDownloadResponse(response) {
    if (response.type === 'ERROR') {
      const notificationError: Notification = {
        type: 'error',
        message: 'Ha habido un error al generar el archivo.',
        fixed: false,
        popup: true
      };
      this.notificationsService.add(notificationError);
      return;
    }
    this.downloadReady = true;
  }

  asyncDownload(exportItem: GridExportData, filtered, appliedFilters, visibleColumns, allColumns, operation='download') {
    const params = {
      name: exportItem.origin,
      operation: operation,
      query: filtered && appliedFilters.length > 0 ? mergeFilters(this.filterService.formatFilter(appliedFilters).filter)
        : { filters: [] },
      filename: exportItem.filename
    };
    if (!exportItem.bypassFields) {
      params['fields'] = filtered ? visibleColumns : allColumns;
    }

    this.getFilteredFile(params, exportItem.title).subscribe(res => {
      this.processDownloadResponse(res);
    });
  }

  getFilteredFile(params, title) {
    this.downloadNotificationTimer();
    let requestSubject = new Subject();
    this.apiService.getExportAsync(params).subscribe(response => {
      this.addDownload({
        id: response.async_job_id,
        fileName: title,
        url: '#',
        status: 'RUNNING'
      });

      const jobID = response.async_job_id;

      this.pollAsyncJob(jobID).subscribe(response => {
        requestSubject.next(response);
      });
    });
    return requestSubject.asObservable();
  }

  pollAsyncJob(jobId): Observable<AsyncQueryResponse> {
    let jobSubject = new Subject<AsyncQueryResponse>();
    let poller = this.apiService.jobQueryPoller(jobId);
    poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
      poller.complete();
      this.assignData(pollerResponse, jobId);
      jobSubject.next(pollerResponse);
      return;
    });
    return jobSubject.asObservable();
  }

  exportLanding(origin, visibleColumns, allColumns) {
    this.apiService.getLandingZones().subscribe(data => {
      data.zones.forEach((zone: string) => {
        let downloadTitle = `OT_Instalación_AT_${zone}_${this.getTimestamp()}`;
        let landingFilter = [
          {
            id: 'fs_id',
            meta: {
              type: 'filter',
              fastSearch: true
            },
            filter: {
              condition: 'AND',
              rules: [
                {
                  id: 'zona',
                  origin,
                  fieldError: '',
                  operatorError: '',
                  valueError: '',
                  operator: 'equal',
                  field: 'zona',
                  type: 'string',
                  value: zone.toUpperCase()
                }
              ]
            }
          }
        ];
        let exportItem: GridExportData = {
          origin,
          title: downloadTitle,
          filename: downloadTitle,
          bypassFields: true,
          filterOrigin: origin,
          filterable: true
        };
        this.asyncDownload(exportItem, true, landingFilter, visibleColumns, allColumns);
      });
    });
  }

  private getTimestamp() {
    let timestamp = new Date();
    let year = timestamp.getFullYear();
    let month = (timestamp.getMonth() + 1).toString();
    let day = timestamp.getDate().toString();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;
    return year + month + day;
  }
}
