<div class="distributions-header">
  <div class="distributions-header__filter">
  </div>

  <div class="distributions-header__actions">
    <div class="distributions-header__total">
      <span>1</span>
      <p>Total registros</p>
    </div>
  </div>
</div>

<bdr-table #bdrTable [isEditable]="true" [items]="distributions?.rows" [columns]="distributions?.headers"
  [hasSelect]="false" selectableRows="true" (changeSelect)="handleEditDistributions($event)"></bdr-table>

<bdr-splash *ngIf="isLoading" [title]="splashMessage" [descriptions]="['Espere, por favor']"></bdr-splash>