<form (ngSubmit)="submit()">
  <ng-container [ngSwitch]="type">
    <bdr-input *ngSwitchCase="'number'" size="slimmest" [type]="'number'"
      [model]="conditions[field] ? conditions[field].search : null"
      (modelChange)="safeSet(conditions, field + '.search', $event)">
    </bdr-input>
    <bdr-input *ngSwitchCase="'date'" size="slimmest" [type]="'date'"
      [model]="conditions[field] ? conditions[field].search : null"
      (modelChange)="safeSet(conditions, field + '.search', $event); submit()">
    </bdr-input>
    <bdr-select *ngSwitchCase="'boolean'" size="slimmest"
      [options]="[{ name: '-', value: null }, { name: 'True', value: 'true' }, { name: 'False', value: 'false' }]"
      [model]="conditions[field] ? conditions[field].search : null"
      (modelChange)="safeSet(conditions, field + '.search', $event); submit()">
    </bdr-select>
    <bdr-input-predictive *ngSwitchDefault [placeholder]="''"
      [model]="conditions[field] ? conditions[field].search : null"
      (modelChange)="safeSet(conditions, field + '.search', $event)" (modelReset)="submit()" size="slimmest">
    </bdr-input-predictive>
  </ng-container>
</form>