<div class="hero-image" [ngClass]="['bg-' + segmentPath]">
  <div class="hero-image--content">
    <div [ngSwitch]="segmentPath">
      <div *ngSwitchCase="'home'">
        <h1 class="hero-image--content-title">Bienvenido a BDR</h1>
        <div class="hero-image--content-summary">
          <p [routerLink]="['/loads']">Gestione y actualice las <b>cargas</b> de información</p>
          <p>Gestione la <b>Planificación de la inversión</b> anual y plurianual</p>
          <p [routerLink]="['/investments-adjustment-sheet']">
            Consulte, clasifique y haga seguimiento de las <b>inversiones</b> sobre los activos y
            configure las inversiones a declarar
          </p>
          <p [routerLink]="['/investments-sheet']">
            Consulte, clasifique y haga seguimiento de las <b>inversiones</b> sobre los activos y
            configure las inversiones a declarar
          </p>
          <p [routerLink]="['/inventory-sheet']">
            Consulte la información directa y calculada del <b>inventario</b> de instalaciones total
            y configure las instalaciones a declarar
          </p>
          <p>
            Consulte, clasifique y haga seguimiento de las <b>aportaciones</b> sobre los activos
          </p>
          <p>Infórmese sobre el estado de <b>legalización</b> de los activos</p>
          <p [routerLink]="['/forms']">
            Consulta, tratamiento y exportación de los <b>formularios</b>
          </p>
          <p [routerLink]="['/settings/master-data']">Acceda al menú de <b>configuración</b></p>
        </div>
      </div>

      <div *ngSwitchCase="'investments-adjustment-sheet'">
        <h1 class="hero-image--content-title">Cuadre de Inversiones.</h1>
        <h2 class="hero-image--content-subtitle">
          Información económica del movimiento del incurrido original.
        </h2>
        <div class="hero-image--content-summary">
          
          <p>Diferentes cuadres de inversiones que exponen el
          tratamiento que se ha realizado a lo largo del año contable con el
          incurrido original.
          </p>
          <p>
            Conocer si el importe se ha subido correctamente a cada preclasificación y contrastarlo con los datos de la BDR.
          </p>
          <p>
            Localizar y documentar todos los movimientos de apuntes originales que entran y salen hacia el año contable.
          </p>
          <p>
            Cerciorarse de que todo el importe a declarar en el año viaje correctamente a las pantallas de tipificación.
          </p>
          <p>
            Verificar que la tipificación y otros importes indirectos asociados a las obras van de la mano con el incurrido original.
          </p>
          <p>
            Validar que los datos a entregar a la CNMC son acordes a la inversión original esperada.
          </p>
        </div>
      </div>

      <div *ngSwitchCase="'investments-sheet'">
        <h1 class="hero-image--content-title">Inversiones</h1>
        <h2 class="hero-image--content-subtitle">
          Información de la inversión incurrida y traspasada anualmente en SAP sobre los activos de
          UFD
        </h2>
        <div class="hero-image--content-summary">
          <p>Maestro de información económica</p>
          <p>Ordenación y clasificación de las inversiones realizadas</p>
          <p>Revisión y validación de la información mediante avisos de fallos de coherencia</p>
          <p>
            Cualificación de las inversiones y control de calidad de la información para la
            declaración de auditoría
          </p>
          <p>Gestiona los criterios de reparto de las inversiones realizadas</p>
          <p>Control de inversión declarada o diferida</p>
        </div>
      </div>

      <div *ngSwitchCase="'inventory-sheet'">
        <h1 class="hero-image--content-title">Inventario</h1>
        <h2 class="hero-image--content-subtitle">
          Información de las instalaciones y cálculo de la información técnica necesaria para el
          regulador
        </h2>
        <div class="hero-image--content-summary">
          <p>Maestro de instalación técnicas de instalaciones</p>
          <p>
            Cálculo de información técnica necesaria para el control de inventario y las entregas al
            regulador
          </p>
          <p>
            Doble visión, equipo e instalación regulatoria a través de un árbol jerárquico de
            instalaciones
          </p>
          <p>Información base para el reparto de inversiones</p>
        </div>
      </div>

      <div *ngSwitchCase="'installations-sheet'">
        <h1 class="hero-image--content-title">Informe Instalaciones Declaradas</h1>
        <h2 class="hero-image--content-subtitle">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nibh quis vulputate pretium, lacus ex
          varius elit, eu sagittis purus ligula a magna
        </h2>
        <div class="hero-image--content-summary">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nibh quis vulputate pretium, lacus
            ex varius elit, eu sagittis purus ligula a magna</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nibh quis vulputate pretium, lacus
            ex varius elit, eu sagittis purus ligula a magna
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nibh quis vulputate pretium, lacus
            ex varius elit, eu sagittis purus ligula a magna
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquam, nibh quis vulputate pretium, lacus
            ex varius elit, eu sagittis purus ligula a magna</p>
        </div>
      </div>

      <div *ngSwitchCase="'forms'">
        <h1 class="hero-image--content-title">Formularios</h1>
        <h2 class="hero-image--content-subtitle">
          Gestiona y genera los formularios finales entregados al regulador. Repositorio de los
          formularios entregados en ejercicios anteriores
        </h2>
        <div class="hero-image--content-summary">
          <p>
            Repositorio unificado de informes regulatorios en forma de Formularios declarados o en
            elaboración
          </p>
          <p>Validación y edición de la información</p>
          <p>Exportación de la información en formato regulatorio</p>
          <p>Gestión de versiones</p>
        </div>
      </div>
    </div>
  </div>
</div>