<div [ngClass]="{'editable':editable}" class="drag-container" (mouseenter)="hover = true" (mouseleave)="hover = false"
  [ngClass]="{ 'flexible': iconText  && (type === 'validations' || type=== 'manualValidations'), 'selected':isSelected }">
  <div class="drag-icon {{type}} {{origin}}">
    <bdr-icon size="20px" icon="ico-filter" *ngIf="type === 'filter' || type === 'issueFilter'"></bdr-icon>
    <bdr-icon size="20px" icon="rule" *ngIf="type === 'rule'"></bdr-icon>
    <bdr-icon size="20px" icon="rule" *ngIf="type === 'plan'"></bdr-icon>
    <bdr-icon size="20px" icon="flux-rule" *ngIf="type === 'workflowRule'"></bdr-icon>
    <bdr-icon size="20px" icon="assignation-rule" *ngIf="type === 'assignationRule'"></bdr-icon>
    <span class="fake-icon" [ngClass]="{'manual-validations': type==='manualValidations' }"
      *ngIf="iconText  && (type === 'validations' || type==='manualValidations')">{{iconText}}</span>
  </div>
  <div class="drag-text">
    <span (click)="applyItem($event)">
      <ng-content></ng-content>
    </span>
  </div>
  <div class="icon drag-linked-filter" *ngIf="hover && linkedFilter" (click)="linkFilter()">
    <bdr-icon size="20px" icon="joint" *ngIf="linkedFilter" [esir]="true"></bdr-icon>
  </div>
  <div class="icon drag-menu" *ngIf="menu && !newEdit" (click)="openMenu($event)">
    <bdr-icon icon="menu" [esir]="true"></bdr-icon>
  </div>
  <div class="icon drag-menu-new-edit" *ngIf="hover && newEdit" (click)="linkFilter()">
    <bdr-icon size="20px" icon="expand-rule" [esir]="true"></bdr-icon>
  </div>
</div>