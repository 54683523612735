<div class="volumetries-container" *ngIf="resultsCount">

  <div class="title" style="display: flex; align-items: stretch;">
    {{ resultsCount.total.title }}
    <br>
    {{ resultsCount.filtered? resultsCount.filtered.title : '' }}
  </div>

  <div class="value" *ngIf="resultsCount.total.count" style="display: flex; align-items: stretch;">
    {{ resultsCount.total.count | localeNumber }}
    <br>
    <ng-container *ngIf="resultsCount.filtered && resultsCount.filtered.title">
      {{resultsCount.filtered.count | localeNumber}}
    </ng-container>
  </div>

  <span class="toolbar-actions-separator" *ngIf="resultsCount.total.amount || resultsCount.filtered && resultsCount.filtered.auditedAmount"></span>

  <div class="title" *ngIf="resultsCount.total.amount" style="display: flex; align-items: stretch;">
    {{ resultsCount.total.amountTitle}}

    <ng-container *ngIf="resultsCount.filtered">
      <br>
      <ng-container *ngIf="resultsCount.filtered.amountTitle">
        {{ resultsCount.filtered.amountTitle }}
      </ng-container>

      <ng-container *ngIf="resultsCount.filtered.worksTitle">
        {{ resultsCount.filtered.worksTitle}}
        <br>
      </ng-container>
      <ng-container *ngIf="resultsCount.filtered.mtbtTitle">
        {{ resultsCount.filtered.mtbtTitle}}
        <br>
        {{ resultsCount.filtered.dispatchersTitle }}
        <br>
      </ng-container>
    </ng-container>

  </div>
  <div class="value" *ngIf="resultsCount.total.amount" style="display: flex; align-items: stretch;">
    {{ resultsCount.total.amount | localeCurrency:true }}

    <ng-container *ngIf="resultsCount.filtered">
      <br>
      <ng-container *ngIf="resultsCount.filtered.amount">
        {{ resultsCount.filtered.amount | localeCurrency:true}}
      </ng-container>

      <ng-container *ngIf="resultsCount.filtered.worksAmount">
        {{ resultsCount.filtered.worksAmount | localeCurrency:true}}
        <br>
      </ng-container>
      <ng-container *ngIf="resultsCount.filtered.mtbtAmount">
        {{ resultsCount.filtered.mtbtAmount | localeCurrency:true}}
        <br>
        {{ resultsCount.filtered.dispatchersAmount | localeCurrency:true }}
        <br>
      </ng-container>
    </ng-container>


  </div>

  <!-- Report Installations -->

  <div class="title" *ngIf="resultsCount.filtered && resultsCount.filtered.auditedAmount" style="display: flex; align-items: stretch;">
    {{ resultsCount.filtered.auditedTitle }}
  </div>

  <div class="value" *ngIf="resultsCount.filtered && resultsCount.filtered.auditedAmount" style="display: flex; align-items: stretch;">
    {{ resultsCount.filtered.auditedAmount | localeCurrency:true}}
  </div>

  <!--  -->


  <span class="toolbar-actions-separator" *ngIf="resultsCount.filtered && resultsCount.filtered.totalWorks"></span>

  <div class="title" *ngIf="resultsCount.filtered && resultsCount.filtered.totalWorks" style="display: flex; align-items: stretch;">
    Nº Obras
    <br>
    Nº Instalaciones
    <br>
    Importe PCO
    <br>
    Importe IID
    <br>
    Diferencia
  </div>

  <div class="value" *ngIf="resultsCount.filtered && resultsCount.filtered.totalWorks" style="display: flex; align-items: stretch;">
    {{ resultsCount.filtered.totalWorks | localeNumber }}
    <br>
    {{ resultsCount.filtered.totalInstallations | localeNumber }}
    <br>
    {{ resultsCount.filtered.amountPco  | localeCurrency:true }}
    <br>
    {{ resultsCount.filtered.amountIid  | localeCurrency:true }}
    <br>
    {{ resultsCount.filtered.amountDifference  | localeCurrency:true }}
  </div>

</div>

<!-- <div class="volumetries-container" *ngIf="resultsCount.length > 1 && resultsCount[0].total !== resultsCount[1].total">
  <span class="title">{{ resultsCount[0].title }}</span>
  <span class="value">{{ resultsCount[0].total | localeNumber }}</span>
  <span class="toolbar-actions-separator" *ngIf="resultsCount[0].amount"></span>
  
  <div class="title" style="display: flex; align-items: stretch;">
    Expediente original
    <br>
    MTBT+Cesiones
    <br>
    Despachos
    <br>
  </div>
  
  <div class="value" style="display: flex; align-items: stretch;">
    {{ resultsCount[0].amount | localeCurrency:true }}
    <br>
    {{ resultsCount[0].amount | localeCurrency:true }}
    <br>
    {{ resultsCount[0].amount | localeCurrency:true }}
    <br>
  </div>

</div> -->