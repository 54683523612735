<div class="grid-loading" (click)="clickEvt()">
  <div class="loading-wrapper">
    <div class="vignette">
      <bdr-icon *ngIf="icon" class="vignette-icon" [icon]="iconImage" size="48px"></bdr-icon>
      <img *ngIf="!icon" src="/assets/images/loading-grid.gif" width="48" height="44" alt="Cargando" />
    </div>
    <p class="loading-title">{{ title }}</p>
    <p *ngFor="let description of descriptions" class="loading-desc" [ngClass]="{ link: clickable }">
      {{ description }}
    </p>
  </div>
</div>