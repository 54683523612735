<grid-handler-view 
  [panels]="panels" 
  [volumetries]="null" 
  [gridProperties]="gridProperties" 
  [editMode]="editMode"
  [subheaderSelector]="subheaderSelector" 
  (selectedRowsChange)="selectedRowsChange($event)"
  (onGenerateAction)="generateSheet($event)" 
  [editChangedValues]="editData !== undefined && editData.length > 0"
  [canSave]="editData !== undefined && editData.length > 0" 
  [saving]="savingFlag" 
  [savingType]="savingType"
  [showModalSaving]="showModalSaving"
  [recurrentEditionCheckbox]="true"
  (onSaveChanges)="saveChanges($event)" 
  (onCreateRows)="createRows($event)"
  (onDeleteRows)="deleteRows($event)" 
  (onCopyRows)="copyRows($event)" 
  (onGridEditChange)="gridEditChange($event)" 
  (onCancel)="cancelEdit()"
  (onDblClick)="dblClick($event)" 
  scope="forms"
>
</grid-handler-view>