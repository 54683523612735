import { throwError as observableThrowError, Subscription, zip } from 'rxjs';
import {
  ViewChild,
  Input,
  Output,
  OnChanges,
  Injectable,
  EventEmitter,
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularSlickgridComponent,
  AngularGridInstance,
  Column,
  Editors,
  Formatter,
  CurrentFilter,
  BackendServiceOption,
  Pagination,
  FilterChangedArgs,
  SortChangedArgs,
  GridOption,
  FieldType,
  unsubscribeAllObservables,
  Formatters,
  ContextMenu,
  Grouping,
  Aggregators,
  GroupTotalFormatters,
} from 'angular-slickgrid';

import { SchemaService, Schema } from './../../services/schema/schema.service';
import { DataApiService } from './../../services/data-api/data-api.service';
import { OriginService } from '../../services/origin/origin.service';
import { GridService } from '../../services/grid/grid.service';
import { BdrValidationsService } from '../../services/bdr-validations/bdr-validations.service';
import { MastersService } from '../../services/masters/masters.service';
import { WorkClosureService } from '../../containers/work-closure/service/work-closure.service';
import { environment } from '../../../environments/environment';
import { RECLASSIFICATION_TYPES } from '../../../environments/common/default-views';
import { AuthService } from '../../services/auth/auth.service';

import * as deepEqual from 'deep-equal';
import { filter, catchError } from 'rxjs/operators';
import { mergeFilters } from '../../util';
import * as clone from 'clone';
import * as _ from 'lodash';
import * as DOMPurify_ from 'dompurify';

const DOMPurify = DOMPurify_;

declare const Slick: any;
const REQUEST_TIMEOUT = environment.grid.requestTimeout;
const SHEET_INVESTMENT_AGGREGATE_VIEWS = [
  'sabana_inversiones_mtbt_agregada_vista',
  'sabana_inversiones_despachos_agregado_obra_vista',
];

const SHEET_WITH_PREHEADER = [
  'sabana_informe_instalaciones_cuadro_resumen_cuadro_general_vista',
  'sabana_informe_instalaciones_cuadro_resumen_cuadro_cesiones_vista',
  'sabana_cuadre_inversiones_seguimiento_inversion',
];

@Component({
  selector: 'bdr-grid',
  templateUrl: './bdr-grid.component.html',
  styleUrls: ['./bdr-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Injectable()
export class BdrGridComponent implements OnChanges {
  @Input() originTitle: string; // Almacena el valor del selector
  @Input() filterOrigin: string;
  @Input() origin: string;
  @Input() originKey: string; // Almacena la pestaña seleccionada
  @Input() dataset: any[]; //Dataset que se carga en el grid
  @Input() visibleColumns: string[];
  @Input() filters: any[];
  @Input() staticSchema: Schema;
  @Input() editingRows: any[];
  @Input() globalEditMode = false;
  @Input() additionalSchemaData: any;
  @Input() schemaScope: string;
  @Input() externalData: any;
  @Input() columnsSearchVisible: boolean;
  @Input() voidRow: boolean = false;
  @Input() showModalSaving: any;
  @Input() showSaving: any;
  @Input() savingType: string;
  @Input() reload: boolean;
  @Input() subject: string; // {{ data.length }} {{ subject }} mostrados
  @Input() preFiltered: boolean = false; // CHANGES THE TOTAL ROWS COUNT WHEN COMES WITH A FILTER BY DEFAULT
  @Input() isTabSelected: any;
  @Input()
  fastSearchConditions: {
    [field: string]: { type?: string; search: string; colName: string };
  }; //Input encargado de comprobar cuando se modifican los filtros desde fuera del componente
  @Input() createNewRowObject: {};
  @Input() deleteRowsObject: {};
  @Input() copyRowsObject: {};
  @Input() massEditionObject: any;

  @Input() bordered: boolean;

  @ViewChild('angularSlickGrid', { static: false }) angularSlickGrid: AngularSlickgridComponent;

  @Output() onTotalDataChange: EventEmitter<number>;
  @Output() onInfoMasterLoadChange: EventEmitter<any>;
  @Output() onSchemaChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGridEditChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDblClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancelEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRequestParamsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onFastSearchChange: EventEmitter<{
    [field: string]: { type: string; search: string };
  }>; // Evento responsable de indicar cambios en los filtros de columnas
  @Output() sortChange: EventEmitter<any[]>; // Evento que indica cambios en la ordenación de columnas
  @Output() createDelete: EventEmitter<any>; //Evento que determina si el banner de creación y registro se debe mostrar
  @Output() onCreateRows: EventEmitter<any> = new EventEmitter<any>(); //Evento de creación de registros
  @Output() onDeleteRows: EventEmitter<any[]> = new EventEmitter<any[]>(); //Evento de borrado de registros
  @Output() onCopyRows: EventEmitter<any[]> = new EventEmitter<any[]>(); //Evento de copia de registros a año anterior
  @Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>(); //Evento responsable de mostrar el numero de lineas seleccionadas.
  @Output() showModalConfirm: EventEmitter<any> = new EventEmitter(); //Evento responsable de mostrar el modal de confirmación para el borrado de registros

  //private dateCols: string[];
  private dataSubscription: Subscription;
  private limit: number;
  private filterParams: any;
  private sortParams: any;
  private mergeFilters = mergeFilters;
  private safeRequestTimeout: any;
  private distinct: boolean;
  private editDataToSave: any[] = [];

  private loading: boolean;
  private currentRequestParams: any;
  private colaDeComandos: any = [];

  gridObj: any;
  dataViewObj: any;

  // TODO Request timestamp, to debounce request on changes
  requestTS: number;

  angularGrid: AngularGridInstance;
  offset: number; // se usa tambien en la vista para ver el nº de registros cargados
  schemaItems; // Cabeceras de la tabla
  editData: any = { rows: [] };
  columns: any[];
  columnsObj: { [field: string]: any }; // el array de columnas pasado a objeto
  columnsSort: any; // mímico de schemaObj, con el orden de las columnas; me ahorra un bucle
  showError = false;
  innerColumnSearchVisible: boolean;
  schemaSubscription: Subscription;
  subscriptions = [];
  dataRequestFails: number;
  id: any;

  totalRows: number; // Variable para el componente de paginación
  filteredRows: number;
  selectedRows: any[];
  totalAmount;
  filteredAmount;

  defaultRetries = 10;
  firstLoad: boolean; // para controlar como se muestra el loading
  loadingMore: boolean; // para controlar cuando pagina
  showLoading: boolean;
  showSplash: boolean;
  splashTitle: any;
  splashIcon: any;
  splashDescriptions: any;
  existParametrizedValidations: any;

  validationName: string;

  addingNewRow: {
    id: number;
    index: number;
  };
  gridEditMode = false;
  isMassEdition = false;
  fastSearchFiltering = false;

  columnDefinitions: Column[] = [];
  hideColumnDefinitions: any;
  backendServiceOptions: BackendServiceOption = {};

  customContextMenu: ContextMenu = {
    hideMenuOnScroll: true,
    hideCloseButton: true,
    width: 250,
    commandTitle: 'Options',
    commandItems: [
      {
        command: 'command1',
        title: 'Open in a new tab',
        iconCssClass: 'fa fa-external-link',
        positionOrder: 61,
        itemVisibilityOverride: (args) => {
          if (
            this.additionalSchemaData != undefined &&
            this.additionalSchemaData[args.column.id] != undefined &&
            this.additionalSchemaData[args.column.id].link
          ) {
            return true;
          }
        },

        action: (e, args) => {
          const grid = args.grid;

          const columnDef = grid && grid.getColumns()[args.cell];
          const field = (columnDef && columnDef.field) || '';

          const columnParams = this.additionalSchemaData[field] || {};

          let outputLink = DOMPurify.sanitize(columnParams.linkUrlBase || '');

          const finalUrl = outputLink.concat(args.value);

          window.open(finalUrl, '_blank');
        },
      },
    ],
  };

  gridOptionsInitial = {
    enableAutoResize: true,
    autoResize: {
      containerId: 'slickGridWrapper',
      sidePadding: 0,
      bottomPadding: 30,
    },
    enableGridMenu: false,
    enableGrouping: true,
    createPreHeaderPanel: true,
    showPreHeaderPanel: false,
    preHeaderPanelHeight: 25,
    showHeaderRow: true, // hide the filter row (header row)
    enableFiltering: true, // enable the filtering but hide the user filter row since we use our own single filter
    enablePagination: false,
    enableEmptyDataWarningMessage: false,
    enableCellMenu: true,
    enableCellNavigation: true, // Para que funcionen los Editors de las celdas
    editable: true, // Habilitar que las celdas se puedan editar
    enableHeaderButton: true, // Habilitar la muestra del icono EDIT
    enableHeaderMenu: false, // Deshabilitar el menu de los headers
    enableContextMenu: true, // enabled by default
    defaultFilterPlaceholder: '',
    contextMenu: this.customContextMenu,
    editCommandHandler: (item, column, editCommand) => {
      this.colaDeComandos.push(editCommand);
      editCommand.execute();
    },
    backendServiceApi: {
      options: this.backendServiceOptions,
      service: this,
      preProcess: () => {},
      process: () => {},
    } as any,
    enableCheckboxSelector: true,
    enableRowSelection: true,
    rowSelectionOptions: {
      // True (Single Selection), False (Multiple Selections)
      selectActiveRow: false,
    },
    hideExportExcelCommand: true,
    enableExcelExport: false,
  };

  gridOptions: GridOption = this.gridOptionsInitial;

  constructor(
    private authService: AuthService,
    private api: DataApiService,
    private router: Router,
    private schemasService: SchemaService,
    private originService: OriginService,
    private gridService: GridService,
    private validationsService: BdrValidationsService,
    private cdr: ChangeDetectorRef,
    private masterService: MastersService,
    private workClosureService: WorkClosureService,
  ) {
    this.dataset = [];
    //this.dateCols = [];
    this.totalRows = 0;
    this.selectedRows = [];
    this.limit = 50;
    this.offset = 0;
    this.filterParams = null;
    this.dataRequestFails = 0;
    this.columns = [];
    this.columnsObj = {};
    this.columnsSort = {};
    this.sortParams = [];
    this.schemaItems = [];

    this.subject = 'registros';
    this.currentRequestParams = {};

    this.firstLoad = true;
    this.loadingMore = true;
    this.showLoading = false;

    this.onTotalDataChange = new EventEmitter<number>();
    this.onInfoMasterLoadChange = new EventEmitter<any>();
    this.onFastSearchChange = new EventEmitter<{
      [field: string]: { type: string; search: string; colName: string };
    }>();
    this.sortChange = new EventEmitter<any>();
    this.createDelete = new EventEmitter<any>();
  }

  private defineGridHeaders() {
    //Se eliminan todas las columnas anteriores exceptuando el RowSelection
    const currentColumns = this.angularGrid
      ? this.angularGrid.gridService.getAllColumnDefinitions()
      : [];
    var numColumns = currentColumns.length - 1;
    for (let i = 0; i < numColumns; i++) {
      currentColumns.pop();
    }

    this.hideColumnDefinitions = [];

    // Creación de las columnas
    for (let index = 0; index < this.schemaItems.length; index++) {
      const filter = null;
      const outputType = null;
      var params = null;
      var type = FieldType.string;
      var editor = null;
      var formatter = null;
      var header = null;
      var minWidth = this.schemaItems[index].title.length * 14;
      var width = this.schemaItems[index].title.length * 19;

      type = FieldType.text;

      editor = {
        model: Editors.text,
      };

      //Comprobamos las columnas que deben ser editables para incluir el icono.
      if (
        this.schemaItems[index].editable === true &&
        this.schemaItems[index].column != 'ediciones'
      ) {
        header = {
          buttons: [
            {
              cssClass: 'fa fa-pencil highlight-icon',
              tooltip: 'Las filas son editables en esta columna.',
            },
          ],
        };

        if (this.schemaItems[index].fieldType === 'select') {
          (type = FieldType.string),
            (editor = {
              model: Editors.singleSelect,
              collection: this.schemaItems[index].optionValues,
              collectionOptions: {
                // Se le indica que incialmente debe tener un valor a vacío
                addBlankEntry: true,
              },
              customStructure: {
                // Se le indica la estructura que tienen los valores de la lista
                value: 'value',
                label: 'name',
              },
            });
        }
      }

      if (this.schemaItems[index].groupedKey === true) {
        header = {
          buttons: [
            {
              cssClass: 'fa fa-server highlight-icon',
              tooltip: 'Esta columna es clave de agrupación.',
            },
          ],
        };
      }

      //Creamos formatter de celda para las validaciones
      if (this.schemaItems[index].field === 'validaciones_internas') {
        this.existParametrizedValidations = this.columns.findIndex(
          (item) => item.field === 'parametros_validaciones',
        );
        this.existParametrizedValidations != -1
          ? (this.schemaItems[this.existParametrizedValidations].visible = false)
          : '';

        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getInnerValidationName(element, row) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.schemaItems[index].field === 'validaciones_inversiones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getInvestmentsValidationName(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.origin.includes('fa1_vista') && this.schemaItems[index].field === 'validaciones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getFormA1Description(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.origin.includes('fa4_vista') && this.schemaItems[index].field === 'validaciones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getFormA4Description(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.origin.includes('fa3_vista') && this.schemaItems[index].field === 'validaciones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getFormA3Description(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.origin.includes('sabana_inventario_otras_informacion_a2_f3_vista') && this.schemaItems[index].field === 'validaciones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getA2F3InformationValidationsDescription(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.origin.includes('f7_vista') && this.schemaItems[index].field === 'validaciones') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getForm7Description(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (
        this.origin.includes('inventario_otros_f1_') &&
        this.schemaItems[index].field === 'validaciones'
      ) {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getFormF1Description(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (
        this.origin === 'sabana_cuadre_inversiones_validaciones' &&
        this.schemaItems[index].field === 'validaciones'
      ) {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.validationsService.getAdjusmentInvestmentDescription(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.schemaItems[index].field === 'validaciones_ufd') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getValidationsName(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.schemaItems[index].field === 'validaciones_importes_automaticos') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getPanicButtonValidationsNicename(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (
        [
          'validaciones_informativas',
          'validaciones_bloqueantes',
          'validaciones_formato_tabla',
        ].includes(this.schemaItems[index].field)
      ) {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getCnmcValidationsDescription(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.schemaItems[index].field === 'informacion_importes_automaticos') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getPanicButtonInformationNicename(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      if (this.schemaItems[index].field === 'tipo_reclasificacion') {
        var validacionFormatter: Formatter = (row: number, cell: number, value: any): string => {
          var validacion = '';

          if (value != undefined && value != '') {
            var arrayValues = value.split('|').map((element) => {
              return (
                '<span class="validations fake-icon" data-toggle="tooltip" title="' +
                this.getReclassificationTypeNicename(element) +
                '">' +
                element +
                '</span>'
              );
            });
            validacion = arrayValues.join(' ');
          }
          return validacion;
        };
        formatter = validacionFormatter;
      }

      //Creamos formatter de celda para lo hypervinculos
      if (this.additionalSchemaData != undefined) {
        var hyperlinkIndex = Object.keys(this.additionalSchemaData).indexOf(
          this.schemaItems[index].column,
        );
        if (hyperlinkIndex > -1 && this.additionalSchemaData[this.schemaItems[index].column].link) {
          formatter = Formatters.fakeHyperlink; // Se aplica el fake hyperlink pero se gestiona el click desde el evento onCellClicked
        }
      }

      //Creamos la columna con cada una de las caracteristicas.
      const col = {
        id: this.schemaItems[index].column,
        name: this.schemaItems[index].title,
        field: this.schemaItems[index].field,
        columnGroup: this.schemaItems[index].headerGroup,
        sortable: true,
        filterable: true,
        type,
        editor,
        formatter,
        filter,
        outputType,
        params,
        minWidth,
        width,
        header,
        excludeFromColumnPicker: !this.schemaItems[index].visible,
        excludeFromExport: !this.schemaItems[index].visible,
        excludeFromGridMenu: !this.schemaItems[index].visible,
        excludeFromQuery: !this.schemaItems[index].visible,
        excludeFromHeaderMenu: !this.schemaItems[index].visible,
      };

      // Preparar el array de columnas que se harán invisibles debido a su propiedad 'visible'
      if (!this.schemaItems[index].visible) {
        this.hideColumnDefinitions.push(this.schemaItems[index].column);
      }
      // Insertamos en el array las columnas
      currentColumns.push(col);
    }
    this.columnDefinitions = currentColumns;

    this.columns = this.angularGrid.gridService.getAllColumnDefinitions();
  }

  private defineGridOptions() {
    this.gridOptions = this.gridOptionsInitial;
  }

  private getData(reset = false): void {
    if (this.externalData) {
      this.dataset = this.externalData;
      this.resetSplash();
      this.loading = false;
      return;
    }

    if (!!reset) {
      this.offset = 0;
      this.dataset = [];
      this.selectedRows = [];
      this.selectedRowsChange.emit(this.selectedRows);
    }
    const params = this.buildRequestParams();
    this.currentRequestParams = clone(params, false);
    this.onRequestParamsChange.emit(this.currentRequestParams);

    const finalOrigin = params.query ? this.filterOrigin || this.origin : this.origin;

    if (finalOrigin === this.origin) {
      // TODO:  Terminar

      this.authService.getProfile().subscribe((profile) => {
        this.dataSubscription = this.api
          .dataTableTask(finalOrigin, params, profile.email)
          .pipe(catchError(this.processResponseError.bind(this)))
          .subscribe(this.processTableDataTask.bind(this));

        this.subscriptions.push(this.dataSubscription);
      });

      return;
    }

    this.dataSubscription = this.api
      .getApi(finalOrigin, params)
      .pipe(catchError(this.processResponseError.bind(this)))
      .subscribe(this.processResponse.bind(this));
    this.subscriptions.push(this.dataSubscription);
  }

  private safeGetData(reset = false): void {
    if (this.safeRequestTimeout && new Date().valueOf() - this.requestTS < REQUEST_TIMEOUT) {
      clearTimeout(this.safeRequestTimeout);
    }
    this.requestTS = new Date().valueOf();

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (!!this.externalData) {
      this.getData(reset);
      return;
    }
    this.showLoading = true;
    this.setSplash();
    this.safeRequestTimeout = setTimeout(() => {
      this.getData(reset);
    }, REQUEST_TIMEOUT);
  }

  /**
   * Funcion responsable de la creación individual de registros.
   *
   * addNewRow
   */

  private addNewRow() {
    const newItem = {
      id: this.dataset.length + 1,
      highlight: false,
    };

    //Añadimos el registro a la tabla pero aún no se manda a Back
    this.addingNewRow = {
      id: newItem.id,
      index: this.angularGrid.gridService.addItem(newItem, { highlightRow: false }),
    };
  }

  /** END */

  /**
   * Funciones responsables de la preparación de los datos para la edición individual de los campos que son editables.
   *
   * setEditData
   * getEditableFields
   * isCellEditable
   *
   */

  private setEditData() {
    this.editData.rows = [];
    this.editDataToSave = [];
    this.dataset.forEach((item) => {
      const editRow = this.gridService.prepareRowForEdit(clone(item), this.additionalSchemaData);
      this.editData.rows.push(editRow);
      this.editDataToSave.push(this.getEditableFields(item));
    });
  }

  private getEditableFields(row) {
    const returnValue = {};
    for (const key in this.additionalSchemaData) {
      if (row.hasOwnProperty(key)) {
        if (this.additionalSchemaData[key].pk) {
          returnValue[key] = row[key];
        }
        if (this.additionalSchemaData[key].editable) {
          returnValue[key] = row[key];
        }
      }
    }
    return returnValue;
  }

  private isCellEditable(args) {
    //args.column.field
    if (args.column.field === 'ediciones') {
      // Este campo nunca será editable por lo que lo fijamos a false
      return false;
    } else {
      //Comprobamos primero si la celda pertenece al nuevo registro para habilitar la edición
      if (this.addingNewRow && args.item.id === this.addingNewRow.id) {
        return true;
      } else if (this.isMassEdition === true) {
        return false;
      } else {
        //Si no es nueva linea, se trabaja con el valor de editable que obtenemos del schemaItem
        var fieldToSearch = args.column.field;
        return this.schemaItems.some(
          (field) =>
            field.column === fieldToSearch &&
            field.editable != undefined &&
            field.editable === true,
        );
      }
    }
  }

  /** END  */

  // TODO: New process Table Data

  processTableDataTask(response) {
    this.dataSubscription = this.api
      .dataTableState(response)
      .pipe(catchError(this.processResponseError.bind(this)))
      .subscribe(this.processTableDataState.bind(this));

    this.subscriptions.push(this.dataSubscription);
  }

  processTableDataState(response) {
    if (response.data !== null) {
      this.processResponse(response.data);
      return;
    }

    setTimeout(() => {
      this.dataSubscription = this.api
        .dataTableState(response)
        .pipe(catchError(this.processResponseError.bind(this)))
        .subscribe(this.processTableDataState.bind(this));

      this.subscriptions.push(this.dataSubscription);
    }, 10000);
  }

  processResponse(res) {
    this.setWowkClosureAggregatesCodes(res);
    this.resetRequestFails();

    this.onInfoMasterLoadChange.emit(<any>res.info_master_load);

    if (+res.filtered === 0) {
      if (!this.voidRow) {
        this.showSplash = true;
        this.splashIcon = true;
        this.splashTitle = 'No hemos encontrado ningún registro';
        this.splashDescriptions = [
          'Puede deberse a que tu filtro o regla aplicada no tiene ninguna coincidencia.',
          'Borra la aplicación del filtro en la parte superior para continuar',
        ];
        this.loading = false;
        this.loadingMore = false;
        this.firstLoad = false;
        this.showLoading = false;
        this.onTotalDataChange.emit(<any>{
          total: +res.count,
          filtered: 0,
          showing: 0,
          amount: {
            total: +res.amount.total,
            filtered: 0,
            mtbt: +res.amount.mtbt,
            dispatchers: +res.amount.dispatchers,
            audited: +res.amount.audited,
            investment: +res.amount.investment,
            tpi: +res.amount.tpi,
          },
        });
        return;
      }
      this.offset = 1;
      this.showError = false;
      this.loading = false;
      this.loadingMore = false;
      this.firstLoad = false;
      this.showLoading = false;
      this.totalRows = 0;
      this.onTotalDataChange.emit(<any>{
        total: +res.count,
        filtered: 0,
        showing: 0,
        amount: {
          total: +res.amount.total,
          filtered: 0,
          mtbt: +res.amount.mtbt,
          dispatchers: +res.amount.dispatchers,
          audited: +res.amount.audited,
          investment: +res.amount.investment,
          tpi: +res.amount.tpi,
        },
      });
    }

    //Se rellena el Array de valores (dataset)
    if (res != undefined && res.result.length > 0) {
      var count = 0;
      res.result.forEach((row) => {
        row.id = row.id != undefined ? row.id : count;
        this.dataset.push(row);
        count++;
      });
    }
    this.offset = this.dataset.length;

    //Se muestra el banner de eliminar y crear
    this.createDelete.emit({
      showNewRows: true,
      showDeletesOrCopy: false,
    });

    this.angularGrid.dataView.refresh();
    this.angularGrid.gridService.hideColumnByIds(this.hideColumnDefinitions);

    //Gestión del array editData
    this.setEditData();

    //Gestión de las lineas totales para ser mostradas en el componente bdr-volumetries
    this.totalRows = +res.count || 0;
    this.filteredRows = +res.filtered || 0;
    this.totalAmount = !!this.preFiltered ? res.amount.filtered : +res.amount.total || 0;
    this.filteredAmount = +res.amount.filtered || 0;
    this.onTotalDataChange.emit(<any>{
      total: this.totalRows,
      filtered: this.filteredRows,
      showing: this.dataset.length,
      amount: {
        total: this.totalAmount,
        filtered: this.filteredAmount,
        mtbt: +res.amount.mtbt,
        dispatchers: +res.amount.dispatchers,
        audited: +res.amount.audited,
        investment: +res.amount.investment,
        tpi: +res.amount.tpi,
        totalWorks: res.amount.totalWorks,
        totalInstallations: res.amount.totalInstallations,
        amountPco: res.amount.amountPco,
        amountIid: res.amount.amountIid,
        amountDifference: res.amount.amountDifference,
      },
    });

    this.showError = false;
    this.loading = false;
    this.loadingMore = false;
    this.firstLoad = false;
    this.resetSplash();

    if (
      this.origin === 'sabana_informe_instalaciones_cuadro_resumen_cuadro_general_vista' ||
      this.origin === 'sabana_informe_instalaciones_cuadro_resumen_cuadro_cesiones_vista'
    ) {
      let gridStyles = document.querySelector('.slick-viewport').getAttribute('style');
      let originalHeight = gridStyles.split(';').find((style) => style.includes('height'));
      let originalHeightNumber = Number(originalHeight.replace('height: ', '').replace('px', ''));
      let newHeight = `height: ${originalHeightNumber - 25}px`;
      document
        .querySelector('.slick-viewport')
        .setAttribute('style', gridStyles.replace(originalHeight, newHeight));

      this.dataViewObj.setGrouping({
        getter: 'preclasificacion',
        formatter: (g) => `${g.value} <span>(${g.count} combinaciones)</span>`,
        aggregateCollapsed: false,
      } as Grouping);
      this.angularGrid.gridService.renderGrid();
    } else {
      this.dataViewObj.setGrouping([]);
    }

    this.showLoading = false;
  }

  private queryToBackend() {
    var currentFilters = this.angularGrid.filterService.getCurrentLocalFilters();

    var columnFilters = [];

    for (const param of currentFilters) {
      columnFilters.push({
        fieldName: param.columnId,
        term: param.searchTerms[0],
      });
    }

    const send = currentFilters
      .map((filter) => ({
        field: filter.columnId,
        colName: this.columnsObj[filter.columnId].title,
        type: this.columnsObj[filter.columnId].type,
        search: filter.searchTerms[0],
      }))
      .reduce((a, b) => {
        a[b.field] = { type: b.type, search: b.search, colName: b.colName };
        return a;
      }, {});

    this.onFastSearchChange.emit(send);
  }

  resetRequestFails() {
    this.dataRequestFails = 0;
  }

  reset(): void {
    if (this.showError) {
      if (!!this.filterParams) {
        this.sortParams = [];
        this.filterParams = this.mergeFilters(this.filters);
        this.columnsSort = {};
        this.columnsSearchVisible = false;
        this.onFastSearchChange.emit({});
        this.selectedRows = [];
        this.currentRequestParams = {};
      }
      this.safeGetData(true);
    }
  }

  private processChanges(changes): void {
    let reloadData = false;
    if (changes.origin && changes.origin.currentValue) {
      this.limit = environment.grid.defaultLimit;
      reloadData = true;
    }

    if (
      changes.filters &&
      !deepEqual(changes.filters.previousValue, changes.filters.currentValue)
    ) {
      const current = this.mergeFilters(changes.filters.currentValue);
      let previous = changes.filters.previousValue;
      if (
        previous instanceof Object &&
        previous.toString &&
        previous.toString() === 'CD_INIT_VALUE'
      ) {
        previous = null;
      }
      if (current != previous && !!this.origin) {
        this.onCancelEdit.emit();
        this.filterParams = current;
        if (changes.fastSearchChange || changes.filters) {
          this.setCurrentFilters(this.filterParams); // Se actualiza el Filterheader del grid
        }
        reloadData = true;
      }
    }

    if (reloadData) {
      this.resetRequestFails();
      this.safeGetData(true);
    }

    if (SHEET_WITH_PREHEADER.includes(this.origin)) {
      document.querySelector('.slick-preheader-panel').removeAttribute('style');
    } else {
      document.querySelector('.slick-preheader-panel').setAttribute('style', 'display: none');
    }
  }

  processResponseError(error) {
    if (error.type === 'SCHEMA') {
      this.showError = true;
      this.showSplash = true;
      this.splashIcon = true;
      this.splashTitle = 'Ha ocurrido un error obteniendo datos de la aplicación ';
      this.cdr.markForCheck();
      return;
    }
    this.dataRequestFails++;
    if (this.dataRequestFails < this.defaultRetries) {
      setTimeout(this.safeGetData.bind(this), 10000);
      return observableThrowError(error);
    }
    this.showError = true;
    this.showSplash = true;
    this.splashIcon = true;
    this.splashTitle = 'Ha ocurrido un error obteniendo datos de la aplicación ';
    this.splashDescriptions = ['Pulse para recargar'];
    this.loading = false;
    this.loadingMore = false;
    this.firstLoad = false;
    this.showLoading = false;
    this.cdr.markForCheck();

    return observableThrowError(error);
  }

  private processSchemaResponse(schema) {
    if (!schema) {
      this.processResponseError({ type: 'SCHEMA', status: 404 });
      return;
    }
    this.schemaItems = schema;
    this.setColumns(true);
    this.columnsObj = this.columns.reduce((a, b) => {
      a[b.field] = b;
      return a;
    }, {});
    this.onSchemaChange.next(this.columns);
  }

  private processEditingRows() {
    //Responsable de eliminar los cambios que se han realizado en el grid cuando se pulsa el CANCEL del header
    if (!this.globalEditMode && this.editingRows.length === 0) {
      if (this.isMassEdition) {
        this.isMassEdition = false;
        this.editData.rows = [];
        this.editDataToSave = [];

        this.resetRequestFails();
        this.safeGetData(true);
      } else {
        setTimeout(() => {
          this.colaDeComandos.forEach((element) => {
            if (element && Slick.GlobalEditorLock.cancelCurrentEdit()) {
              element.undo();
            }
          });
          this.colaDeComandos = [];
        }, 50);
      }
    }
  }

  private setCurrentFilters(filterParams) {
    let currentFilters: CurrentFilter[] = [];

    if (filterParams && filterParams.filters.length > 0) {
      filterParams.filters[0].rules[0].rules.forEach((element) => {
        if (element.id) {
          currentFilters.push({
            columnId: element.id,
            searchTerms: element.value,
          });
        }
      });
    }

    if (
      !_.isEmpty(currentFilters) &&
      currentFilters[0].columnId != undefined &&
      this.fastSearchFiltering
    ) {
      this.angularGrid.filterService.updateFilters(currentFilters, false, false, false);
      if (currentFilters.length === 0) {
        this.fastSearchFiltering = false;
      }
    } else {
      this.angularGrid.filterService.clearFilters(false);
      this.fastSearchFiltering = false;
    }
  }

  private setCurrentParams(currentParams) {
    const sortColumns = currentParams.map((element) => ({
      columnId: element.field,
      sortAsc: element.dir === 'asc' ? true : false,
    }));
    this.angularGrid.filterService.setSortColumnIcons(sortColumns);
  }

  private buildRequestParams(): any {
    const params: any = {
      limit: this.limit,
      offset: this.offset,
    };

    if (this.originTitle && this.originKey) {
      params.info_master_load = {
        section: this.originKey,
        table: this.originTitle,
      };
    }

    //Gestiona las columnas visibles recibidas desde el banner lateral
    if (
      this.originService.getOriginsFilteredByColumns().includes(this.origin) &&
      !!this.visibleColumns
    ) {
      params.fields = this.visibleColumns;
    }

    if (this.sortParams) {
      params.sort = Array.isArray(this.sortParams) ? this.sortParams : [this.sortParams];
    }

    if (this.filterParams) {
      params.query = this.filterParams;
    }

    if (this.distinct) {
      params.distinct = this.distinct;
    }

    return params;
  }

  private setColumns(resetAdjust = false) {
    this.columns = this.gridService.processSchemaItems(
      this.schemaItems,
      this.columns,
      this.additionalSchemaData,
      this.visibleColumns,
      resetAdjust,
    );
  }

  private obtainSchema(): void {
    this.schemaSubscription = this.schemasService
      .get(this.origin)
      .pipe(catchError(this.processResponseError.bind(this)))
      .pipe(
        filter((schema) => {
          return Array.isArray(schema) && schema.length > 0;
        }),
      )
      .subscribe(
        (schema: any[]) => {
          this.processSchemaResponse(schema);
        },
        (err) => this.processResponseError.bind(this),
      );
    this.subscriptions.push(this.schemaSubscription);
  }

  private unsubscribeItems(): void {
    this.subscriptions.forEach((subs) => {
      if (subs.unsubscribe) {
        subs.unsubscribe();
      }
    });
  }

  /* Funcion responsable de editar automaticamente el campo 'ediciones' en la tabla. */
  private modifyEdicionesField(editedRow) {
    var headers = this.schemaItems;
    var arrayEdicionesTitle = [];
    var currentEditedFields =
      this.editDataToSave[editedRow.row].currentEditedFields != null
        ? this.editDataToSave[editedRow.row].currentEditedFields
        : [];
    if (this.editDataToSave[editedRow.row].ediciones != null) {
      arrayEdicionesTitle = this.editDataToSave[editedRow.row].ediciones.split(', ');
    }

    if (this.gridEditMode) {
      // EDICIÓN

      if (!arrayEdicionesTitle.includes(editedRow.column.name)) {
        // El valor no se encuentra en el campo ediciones

        arrayEdicionesTitle.push(editedRow.column.name);
        currentEditedFields.push(editedRow.column.field);
      }

      // Se indica el valor edited a true para la linea
      this.editDataToSave[editedRow.row].edited = true;
    } else {
      // CANCELACIÓN

      currentEditedFields.forEach((element) => {
        arrayEdicionesTitle.splice(arrayEdicionesTitle.indexOf(element), 1);
      });

      currentEditedFields = [];

      // Se indica el valor edited a true para la linea
      this.editDataToSave[editedRow.row].edited = false;
    }

    this.editDataToSave[editedRow.row].ediciones =
      arrayEdicionesTitle.join(', ') === '' ? null : arrayEdicionesTitle.join(', ');

    // Actualizamos currentEditedFields para conocer los campos que se estan editando
    this.editDataToSave[editedRow.row].currentEditedFields = currentEditedFields;

    // Obtenemos la información de la row actual y la actualizamos.
    const metadata = this.angularGrid.gridService.getColumnFromEventArguments(editedRow);
    var newRow = metadata.dataContext;
    newRow.ediciones = this.editDataToSave[editedRow.row].ediciones;
    this.angularGrid.gridService.updateItemById(editedRow.item.id, newRow, { highlightRow: false });
    this.angularGrid.gridService.renderGrid();
  }

  private changeColumnVisibility() {
    if (this.columns[0].field === 'sel') {
      // Controlamos que siempre que entre este el header de seleccion

      //Actualizamos el array de columnas ocultas con la nueva relación de columnas que entra por visibleColumns
      this.hideColumnDefinitions.forEach((hideColumn, index) => {
        var idx = this.visibleColumns.indexOf(hideColumn);
        if (idx != -1) {
          this.hideColumnDefinitions.splice(index, 1);
        }
      });

      this.angularSlickGrid.updateColumnDefinitionsList(
        this.columns.filter(
          (item) => item.field === 'sel' || this.visibleColumns.includes(item.field),
        ),
      );
      this.angularGrid.gridStateService.resetToOriginalColumns(true);
    }
  }

  private massEdition(massEditionObj) {
    this.isMassEdition = true;
    var headers = this.schemaItems;

    //Se prepara los valores para guardar
    this.editDataToSave = this.editDataToSave || [];
    // Se indica el valor edited a true para la linea
    this.editDataToSave = this.editDataToSave.map((row) => {
      row.edited = true;
      row[massEditionObj.column] = massEditionObj.value;

      if (row.ediciones == null) {
        row.ediciones = massEditionObj.name;
      } else if (!row.ediciones.split(', ').includes(massEditionObj.name)) {
        row.ediciones = row.ediciones.concat(', ', massEditionObj.name);
      }

      row.currentEditedFields = row.ediciones
        .split(', ')
        .map((element) => headers.find((item) => item.title === element).column);

      return row;
    });

    //Se lanza el evento
    if (!this.addingNewRow) {
      this.onGridEditChange.emit({
        type: 'massiveChange',
        object: this.editDataToSave.filter((item) => item.edited === true),
      });
    }

    //Se actualiza el dataset de la tabla
    this.dataset.forEach((element) => {
      element[massEditionObj.column] = massEditionObj.value;

      if (element.ediciones == null) {
        element.ediciones = massEditionObj.name;
      } else if (!element.ediciones.split(', ').includes(massEditionObj.name)) {
        element.ediciones = element.ediciones.concat(', ', massEditionObj.name);
      }
    });

    //Se inserta en el grid las nuevas modificaciones
    this.angularGrid.gridService.updateItems(this.dataset, { highlightRow: false });
    //Se refresca la vista
    this.angularGrid.gridService.renderGrid();
  }

  private getOptionsMei(value) {
    const optionsMei: string[] = this.masterService.getMeisByPreclasification(value);
    const formatMeis = optionsMei.map((option) => ({
      value: option,
      name: option,
    }));
    return formatMeis;
  }

  private setWowkClosureAggregatesCodes(res) {
    if (SHEET_INVESTMENT_AGGREGATE_VIEWS.includes(this.origin)) {
      const codes = res.result.map((item) => item.codigo_obra);
      this.workClosureService.setWorkCodes(codes);
    }
  }

  /**
   * Funciones para obtener descripciones de validaciones
   *
   * getInnerValidationName
   * getValidationsName
   * getPanicButtonValidationsNicename
   * getInvestmentsValidationName
   */

  getInnerValidationName(innerValidationId, row) {
    if (this.dataset[row] === undefined) {
      return '';
    }

    let validationName;
    if (this.existParametrizedValidations != -1) {
      validationName = this.validationsService.getInnerValidationNameParametros(
        this.origin,
        innerValidationId,
        this.dataset[row].parametros_validaciones,
      );
    } else {
      validationName = this.validationsService.getInnerValidationName(
        this.origin,
        innerValidationId,
      );
    }
    return validationName;
  }

  getValidationsName(validations_id) {
    return this.validationsService.getValidationsName(validations_id);
  }

  getPanicButtonValidationsNicename(validation_id) {
    return this.validationsService.getPanicButtonValidationsNicename(validation_id);
  }

  getPanicButtonInformationNicename(validation_id) {
    return this.validationsService.getPanicButtonInformationNicename(validation_id);
  }

  getInvestmentsValidationName(innerValidationId) {
    return this.validationsService.getInvestmentsValidationName(this.origin, innerValidationId);
  }

  getReclassificationTypeNicename(reclassificationTypeId) {
    return RECLASSIFICATION_TYPES[reclassificationTypeId];
  }

  getCnmcValidationsDescription(validationCode) {
    return this.validationsService.getCnmcValidationsDescription(validationCode);
  }

  /**
   * EVENTOS
   * ngOnInit
   * ngOnChanges
   * ngOnDestroy
   * angularGridReady
   * gridReady
   * paginate
   * scrollTop
   * onCellDblClicked
   * onCellClicked
   * onCellChanged
   * onSelectedRowsChanged
   *
   *  */

  ngOnInit() {
    this.obtainSchema();
    this.defineGridHeaders();
    this.defineGridOptions();
    this.resetRequestFails();
    this.safeGetData(true);
  }

  ngOnChanges(changes) {
    // Variable que contiene si el filtrado rapido debe estar activo o no.
    if (this.dataViewObj != undefined && changes.columnsSearchVisible) {
      this.innerColumnSearchVisible = this.columnsSearchVisible;
      this.angularGrid.filterService.toggleFilterFunctionality(true);

      if (changes.columnsSearchVisible.currentValue) {
        var element = document.getElementsByClassName(
          'slick-viewport slick-viewport-top slick-viewport-right',
        )[0];
        element.scroll(element.scrollLeft - 1, 0);
      }
    }

    if (
      !this.staticSchema &&
      changes.origin &&
      !changes.origin.firstChange &&
      this.origin !== null
    ) {
      this.obtainSchema();
      this.defineGridHeaders();
      this.defineGridOptions();
      this.processChanges(changes);
    } else if (
      (changes.fastSearchConditions && !changes.fastSearchConditions.firstChange) ||
      (changes.filters !== undefined && changes.filters !== null)
    ) {
      if (
        changes.fastSearchConditions &&
        !changes.fastSearchConditions.firstChange &&
        changes.filters
      ) {
        this.fastSearchFiltering = true;
      }
      this.processChanges(changes);
      return;
    }

    //Para controlar en el caso de que se este guardando nuevo registro
    if (changes.showModalSaving !== undefined && changes.savingType === undefined) {
      if (changes.showModalSaving.currentValue) {
        this.showSplash = true;
        this.splashIcon = false;
        this.splashTitle = 'Guardando cambios realizados';
        this.splashDescriptions = ['Espere, por favor'];
      } else {
        this.resetSplash();
        this.safeGetData(true);
        this.angularGrid.gridService.setSelectedRows([]);
      }
    }

    if (changes.globalEditMode) {
      this.gridEditMode = this.globalEditMode;
    }
    if (changes.editingRows) {
      this.processEditingRows();
    }

    // Responsable de los cambios cuando se hace Edición masiva
    if (changes.massEditionObject) {
      this.massEdition(changes.massEditionObject.currentValue);
    }

    //CREACIÓN de registros
    if (changes.createNewRowObject && changes.createNewRowObject.currentValue != undefined) {
      // Mostrar el nuevo registro en blanco en el GRID
      if (changes.createNewRowObject.currentValue.showNewRow) {
        this.addNewRow();
      } else {
        if (this.addingNewRow && !changes.createNewRowObject.currentValue.confirmNewRow) {
          //Se cancela la edición del nuevo registro
          this.angularGrid.gridService.deleteItemById(this.addingNewRow.id);
        } else {
          //Se confirma la creación del nuevo registro contra la BBDD
          let newRow: { rowToSend: any; columnsInfo: string[] } = {
            rowToSend: {},
            columnsInfo: [],
          };
          var row = this.angularGrid.gridService.getDataItemByRowIndex(this.addingNewRow.index);
          var columns = this.angularGrid.gridService
            .getAllColumnDefinitions()
            .slice(1)
            .map((row) => row.field);

          newRow.columnsInfo = columns;
          newRow.rowToSend = row;

          if (newRow.columnsInfo.includes('observaciones')) {
            newRow.rowToSend.observaciones = 'Registro añadido por usario';
          }

          if (newRow.columnsInfo.includes('fuente_de_datos')) {
            newRow.rowToSend.fuente_de_datos = 'CALCULAR BDR';
          }

          this.onCreateRows.emit(newRow); //OBJETO CONTENIENTE DEL NUEVO REGISTRO
        }

        this.addingNewRow = null;
      }
    }

    //ELIMINACIÓN de registros
    if (changes.deleteRowsObject && changes.deleteRowsObject.currentValue != undefined) {
      //CANCELADA LA ELIMINACIÓN
      if (!changes.deleteRowsObject.currentValue.confirmDeleteRows) {
        this.angularGrid.gridService.setSelectedRows([]);
      }

      //CONFIRMADA LA ELIMINACIÓN
      if (changes.deleteRowsObject.currentValue.confirmDeleteRows) {
        var rows = this.angularGrid.gridService.getDataItemByRowIndexes(
          this.angularGrid.gridService.getSelectedRows(),
        );

        //Evento de eliminación de los registros en BBDD
        this.onDeleteRows.emit(rows);
        //Evento de Mostrar/Ocultar el banner
        this.createDelete.emit({
          showNewRows: true,
          showDeletesOrCopy: false,
        });
      }
    }

    // COPIADO de registros
    if (changes.copyRowsObject && changes.copyRowsObject.currentValue != undefined) {
      //CANCELAR EL COPIADO
      if (!changes.copyRowsObject.currentValue.confirmCopyRows) {
        this.angularGrid.gridService.setSelectedRows([]);
      }

      //CONFIRMAR LA COPIA
      if (changes.copyRowsObject.currentValue.confirmCopyRows) {
        var rows = this.angularGrid.gridService.getDataItemByRowIndexes(
          this.angularGrid.gridService.getSelectedRows(),
        );

        //Evento de copiado de los registros en BBDD
        this.onCopyRows.emit(rows);
        //Evento de Mostrar/Ocultar el banner
        this.createDelete.emit({
          showNewRows: true,
          showDeletesOrCopy: false,
        });
      }
    }

    if (changes.visibleColumns && changes.visibleColumns.previousValue.length > 0) {
      this.changeColumnVisibility();
    }

    if (
      (changes.reload && !!this.reload) ||
      (changes.visibleColumns && !changes.visibleColumns.firstChange && !!origin)
    ) {
      this.resetRequestFails();
      this.safeGetData(true);
    }

    if (changes.isTabSelected) {
      this.totalRows = 0;
      this.filteredRows = 0;
      this.totalAmount = 0;
      this.filteredAmount = 0;
      this.onTotalDataChange.emit(<any>{
        total: this.totalRows,
        filtered: this.filteredRows,
        showing: 0,
        amount: {
          total: this.totalAmount,
          filtered: this.filteredAmount,
          mtbt: 0,
          dispatchers: 0,
        },
      });

      this.createDelete.emit({
        showNewRows: false,
        showDeletesOrCopy: false,
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeItems();
    this.dataset = null;
    this.subscriptions = unsubscribeAllObservables(this.subscriptions);
  }

  // Después del gridReady
  angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;

    //Se desactiva incialmente el Filter header Row
    this.angularGrid.filterService.toggleFilterFunctionality(true);
    this.gridObj = angularGrid.slickGrid;
    this.dataViewObj = angularGrid.dataView;
  }

  // Antes del angularGridReady
  gridReady(grid) {
    //Evento responsable de permitir la edición de la celda.
    grid.onBeforeEditCell.subscribe((e, args) => {
      return this.isCellEditable(args);
    });
    this.gridObj = grid;
  }

  // Click para paginar
  paginate($event): void {
    this.limit = $event;
    this.loadingMore = true;
    //this.adjustColumns = true;
    //this.adjustedColumns = false;
    this.safeGetData(false);
  }

  // Click para ir al principio de la tabla
  scrollTop(): void {
    this.gridObj.navigateTop();
  }

  onCellDblClicked(e: Event, args: any) {
    const metadata = this.angularGrid.gridService.getColumnFromEventArguments(args);
    if (metadata.columnDef.editor != null) {
      this.onDblClick.emit(args);
    }
  }

  onCellClicked(e: Event, args: any) {
    if (e.target['className'] === 'fake-hyperlink') {
      const grid = args.grid;
      const columnDef = grid && grid.getColumns()[args.cell];
      const field = (columnDef && columnDef.field) || '';

      const columnParams = this.additionalSchemaData[field] || {};

      let outputLink = DOMPurify.sanitize(columnParams.linkUrlBase || '');

      const finalUrl = outputLink.concat(e.target['innerText']);

      this.router.navigate([finalUrl]);
    }
  }

  onCellChanged(e: Event, args: any) {
    this.editDataToSave[args.row] = this.editDataToSave[args.row] || {};
    //Se almacena el nuevo valor.
    this.editData.rows[args.row][args.cell] = args.item[args.column.id];
    //Se llama a la función para comprobar si el campo editado ya esta registrado como editado.
    this.modifyEdicionesField(args);

    this.editDataToSave[args.row][args.column.id] = args.item[args.column.id];

    this.cdr.detectChanges();

    //Se lanza el evento
    if (!this.addingNewRow) {
      this.onGridEditChange.emit({
        type: 'individualChange',
        object: this.editDataToSave.filter((item) => item.edited === true),
      });
    }
  }

  onSelectedRowsChanged(e, args) {
    if (Array.isArray(args.rows)) {
      this.selectedRowsChange.emit(this.angularGrid.gridService.getDataItemByRowIndexes(args.rows));
    }

    // Evento para mostrar u ocultar el banner de los botones de acción
    if (args.rows.length > 0) {
      this.createDelete.emit({
        showNewRows: false,
        showDeletesOrCopy: true,
      });
    } else {
      this.createDelete.emit({
        showNewRows: true,
        showDeletesOrCopy: false,
      });
    }
  }

  /** END EVENTOS */

  /**
   * Gestión del SPLASH en pantalla
   *
   * resetSplash
   * setSplash
   */

  private resetSplash() {
    this.showSplash = false;
    this.splashIcon = false;
    this.splashTitle = '';
    this.splashDescriptions = [];
    this.cdr.detectChanges();
  }

  private setSplash() {
    this.showSplash = true;
    this.splashTitle = 'Estamos cargando la información';
    this.splashIcon = false;
    this.splashDescriptions = ['Espere, por favor'];
    this.cdr.detectChanges();
  }

  /** END SPLASH */

  /**
   * Servicio de Backend
   *
   * init
   * updateOptions
   * buildQuery
   * updateFilters
   * resetPaginationOptions - No se usa debido a que la paginación se gestiona como hasta ahora
   * processOnFilterChanged
   * processOnSortChanged
   *
   */

  /**
   * Init del CustomBackend que se crea para gestionar los filtros y ordenación
   * @param serviceOptions
   * @param pagination
   */
  init(serviceOptions: BackendServiceOption, pagination?: Pagination): void {
    this.backendServiceOptions = serviceOptions;
  }

  /**
   *
   * @param serviceOptions
   */

  updateOptions(serviceOptions?: Partial<BackendServiceOption>) {
    this.backendServiceOptions = { ...this.backendServiceOptions, ...serviceOptions };
  }

  /**
   * Función encargada de preparar la query
   * @returns
   */
  buildQuery(): string {
    return 'buildQuery...';
  }

  /**
   * Función encargada de actualizar los filtros
   * @param filterParams
   * @param isUpdatedByPresetOrDynamically
   */
  updateFilters(filterParams, isUpdatedByPresetOrDynamically) {}

  /**
   * resetPaginationOptions
   */
  resetPaginationOptions() {}

  /**
   * Evento de Filtrado
   * @param event
   * @param args
   */
  processOnFilterChanged(
    event: KeyboardEvent | undefined,
    args: FilterChangedArgs,
  ): Promise<string> | string {
    if (
      event &&
      event.type === 'keyup' &&
      (event.code === 'NumpadEnter' || event.code === 'Enter')
    ) {
      this.queryToBackend();
    }

    return 'onFilterChanged';
  }

  /**
   * Evento de ordenación
   * @param event
   * @param args
   */
  processOnSortChanged(event: Event | undefined, args: SortChangedArgs) {
    if (event && event.type === 'click') {
      if (args.sortCols.length > 0) {
        let dir = args.sortCols[0].sortAsc ? 'desc' : 'asc';
        let field = args.sortCols[0].columnId;

        this.columnsSort[field] = dir;
        const paramIndex = this.sortParams.findIndex((p) => p.field === field);
        if (dir === null) {
          if (paramIndex > -1) {
            this.sortParams.splice(paramIndex, 1);
          }
        } else {
          if (paramIndex > -1) {
            this.sortParams[paramIndex].dir = dir;
          } else {
            this.sortParams.push({
              dir,
              field,
            });
          }
        }
      }

      this.sortParams.forEach((item) => {
        const fields = [];
        const directions = [];

        fields.push(item.field);
        directions.push(item.dir);
      });

      this.safeGetData(true);
      this.sortChange.emit(this.sortParams);
    } else {
      if (this.sortParams) {
        this.setCurrentParams(this.sortParams);
      }
    }

    return 'onSortChanged';
  }
}
