<section class="body">
  <div>
    <bdr-input id="inputInstallations" caption="Asociar instalación"
      placeholder="Introduzca una instalación. Ej.: 309999999" [disabled]="loading" [bottomError]="error"
      (keyup.enter)='validateInstallation($event.target.value)'>
    </bdr-input>
    <div *ngIf="loading" class="vignette text"
      style="font-weight: bold; color:lightgrey;padding: 10px 10px 10px 10px; font-size: 13px;font-family: Arial, sans-serif; ">
      Recuperando información de la instalación...
    </div>
    <div *ngIf="error" class="vignette text"
      style="font-weight: bold; padding: 10px 10px 10px 10px; font-size: 13px;font-family: Arial, sans-serif; color: red;">
      {{errorMsg}}
    </div>
  </div>

  <div class="body-wrapper">
    <p>El incurrido quedará con las instalaciones seleccionadas:</p>
    <div class="installation-list" id="associatedList">
      <li *ngFor="let i of installations">
    <bdr-checkbox
      [disabled]="i.clasificacion !== installationType && incurreds[0].clasificacion_final !== 'DESPACHOS DISTRIBUCIÓN-AT'"
      title='Esta instalacion no se puede asociar a los incurridos seleccionados' [(model)]="i.selected"
      [caption]="i.instalacion + ' (' + i.secuencial + ')'">
    </bdr-checkbox>
      </li>
    </div>
  </div>
</section>
<footer class="footer">
  <ng-container>
    <span class="link" (click)="back()">Volver</span>
    <span class="link" (click)="save()">Guardar</span>
  </ng-container>
</footer>