<mat-tab-group>
  <mat-tab *ngFor="let zone of zones">
    <ng-template mat-tab-label>
      <p>
        {{ zone.name }} <br />
        ({{ zone.count }})
      </p>
    </ng-template>
    <div class="validations__delegations">
      <bdr-collapse-panel *ngFor="let delegation of zone.delegations">
        <div class="collapse-panel__title" title>
          <label [for]="delegation.name">
            <mat-checkbox [id]="delegation.name" [(ngModel)]="delegation.checked" [checked]="delegation.checked"
              (change)="toggle(zone.name, delegation.name)">
              {{ delegation.name }} ({{ delegation.count }})
            </mat-checkbox>
          </label>
        </div>
        <div class="collapse-panel__content" content>
          <div *ngFor="let base of delegation.bases" class="validations__bases">
            <label [for]="base.name">
              <mat-checkbox [id]="base.id" [(ngModel)]="base.checked" [checked]="base.checked"
                (change)="toggle(zone.name, delegation.name, false)">
                {{ base.name }} ({{ base.count }})
              </mat-checkbox>
            </label>
          </div>
        </div>
      </bdr-collapse-panel>
    </div>
  </mat-tab>
</mat-tab-group>