<section [ngClass]="{'wrapper': true, 'minimized': dialog.minimized}">
  <header *ngIf="!dialog.fixed && !dialog.notHeader && !dialog.headerComponent" class="header"
    (escapeDialog)="closeDialog()" [bdrDialogDraggable]="rulerCtx" [dialogSize]="dialog.size">
    <div class="header-title">
      <span class="editable-title" *ngIf="!dialog.editableTitle; else editableTitleTpl">{{ dialog.title }}</span>
      <ng-template #editableTitleTpl>
        <span *ngIf="dialog.titlePrefix" class="title-prefix">{{dialog.titlePrefix}}</span>
        <span *ngIf="!editTitle" (click)="editTitle = true;showEditTitleIcon=false"
          (mouseenter)="showEditTitleIcon=true" (mouseout)="showEditTitleIcon = false">{{ dialog.title }}</span>
        <bdr-icon *ngIf="showEditTitleIcon" class="button" icon="ico-edit" (click)="editTitle = false"></bdr-icon>
        <div *ngIf="editTitle">
          <input type="'text'" class="inline" [ngClass]="{ 'error': titleError }" [value]="dialog.title" required
            (change)="changeTitle($event)" (keyup)="($event.keyCode === 13) ? editTitle=false : editTitle=true" />
          <bdr-icon *ngIf="dialog.title.length > 0" class="button" icon="ico-save" (click)="saveTitle(dialog.title)">
          </bdr-icon>
        </div>
      </ng-template>
    </div>
    <div class="header-buttons">
      <bdr-icon size="18px" class="button-drag" icon="ico-validations"></bdr-icon>
      <bdr-icon class="button button-maximize" icon="ico-max" *ngIf="dialog.minimized" (click)="restoreDialog()">
      </bdr-icon>
      <bdr-icon size="18px" class="button button-minimize" icon="ico-min" *ngIf="!dialog.minimized"
        (click)="minimizeDialog()"></bdr-icon>
      <bdr-icon size="18px" class="button button-close" icon="ico-ko" (click)="closeDialog()"></bdr-icon>
    </div>
  </header>

  <header *ngIf="dialog.fixed && !dialog.notHeader && !dialog.headerComponent" class="header fixed"
    (escapeDialog)="closeDialog()">
    <div class="header-title">{{ dialog.title }}</div>
    <div class="header-buttons">
      <bdr-icon class="button" icon="ico-ko" (click)="closeDialog()"></bdr-icon>
    </div>
  </header>

  <div *ngIf="dialog.notHeader && !dialog.headerComponent" class="not-header">
    <div class="header-buttons">
      <bdr-icon class="button" icon="ico-ko" (click)="closeDialog()"></bdr-icon>
    </div>
  </div>

  <ng-content *ngIf="!dialog.minimized"></ng-content>

  <bdr-icon *ngIf="!dialog.minimized && !dialog.fixed" class="resize-handler" size="20px" icon="resize-textarea"
    esir="true" [bdrDialogResize]="rulerCtx">
  </bdr-icon>

  <div *ngIf="ctxmenuService.visible && ctxmenuService.context === ctxDialogContext" class="contextualmenu-factory"
    (click)="ctxmenuService.close()" [ngClass]="{ 'no-layer': ctxmenuService.options.complex }">
    <bdr-reflect-component [component]="ctxMenuComponent"
      [bindings]="{ inputs: { component: ctxmenuService.component, options: ctxmenuService.options, target: ctxmenuService.target } }">
    </bdr-reflect-component>
  </div>
</section>