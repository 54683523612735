<section class="body">
    <header class="header" (escapeDialog)="handleCloseDialog()">
        <div class="header-title">
            <span class="title-prefix">{{ title }}</span>
        </div>
    </header>
    <div class="body-wrapper">
        <label>Codigos de obra</label>
        <br />
        <textarea
            matInput
            rows="20"
            placeholder="CODIGO1,CODIGO2,CODIGO3,CODIGO4"
            [(ngModel)]="payload.workCodes"
            (input)="changeWorks()"
        ></textarea>
    </div>
</section>

<footer class="footer-wrapper">
    <div class="footer">
        <div class="footer-actions">
            <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()"
                >Cancelar</bdr-button
            >
            <bdr-button
                [ngClass]="{'bdr-btn-disabled': userPermissionsService.canCheckoutAllWorks == false || payload.workCodes.length == 0 || blockSaveButton}"
                class="btn-save"
                primary
                size="small"
                (click)="handleSave()"
                >Guardar</bdr-button
            >
        </div>
    </div>
</footer>
