<section class="body">
  <div class="body-wrapper" [innerHtml]="content"> </div>
</section>
<footer class="footer-wrapper">
  <div class="footer">
    <bdr-button class="btn-save" [ngClass]="{'small': !!alternativeButtonText }" primary
      [size]="!!alternativeButtonText ? 'small' : null" (click)="onConfirm()">{{buttonText}}</bdr-button>
    <bdr-button *ngIf="alternativeButtonText" class="btn-save small" primary size="small"
      (click)="onConfirmAlternative()">{{alternativeButtonText}}</bdr-button>
  </div>
</footer>