<div class="download-box" *ngIf="downloads.length > 0">
  <div class="title" [ngClass]="{'alertTitle': downloadsService.downloadReady}">
    <span>Procesos en ejecución</span>
    <bdr-icon *ngIf="expanded" (click)="expanded = !expanded" class="icon" icon="caret-down"
      [ngClass]="{'alertIcon': downloadsService.downloadReady}" [esir]="true"></bdr-icon>
    <bdr-icon *ngIf="!expanded" (click)="expanded = !expanded" class="icon" icon="caret-up"
      [ngClass]="{'alertIcon': downloadsService.downloadReady}" [esir]="true"></bdr-icon>
  </div>
  <div class="downloads" *ngIf="expanded">
    <span class="download" *ngFor="let download of downloads; let i = index">

      <bdr-icon *ngIf="download.status === 'RUNNING'" class="icon icon-header" size="18px" icon="ico-load">
      </bdr-icon>
      <bdr-icon *ngIf="download.status === 'SUCCESS'" class="icon icon-header" size="18px" icon="ico-ok">
      </bdr-icon>
      <bdr-icon *ngIf="download.status === 'ERROR'" class="icon icon-header" size="18px" icon="ico-ko">
      </bdr-icon>

      <div class="download-name" *ngIf="download.status !== 'SUCCESS'" [title]="download.fileName">
        {{ download.fileName }}
      </div>

      <div class="download-name" *ngIf="download.status === 'SUCCESS'" [title]="download.successMessage">
        {{ download.successMessage }}
      </div>

      <div class="running" *ngIf="download.status === 'RUNNING'">
        <a class="link" (click)="cancelOne(i)">Cancelar</a>
        <div class="loading-wrapper">
          <div class="vignette">
            <img src="/assets/images/downloading.gif" width="24" height="22" ico-downloa alt="Cargando" />
          </div>
        </div>
      </div>
      <a *ngIf="download && download.url && download.url!=='#'" [href]="download.url" (click)="cancelOne(i)"
        class="link">Descargar</a>
    </span>
    <a class="cancel-all link" (click)="cancelAll()">Cerrar procesos en ejecución</a>
  </div>
</div>