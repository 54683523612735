<div class="loads-report" *ngIf="auth.authenticated === true">
  <div class="loads-panel">
    <div class="loads-column">
      <h2 class="title-main" data-cy="loads-title">Carga de archivos</h2>

      <div class="load-date-block">
        <span class="title">Fecha de la última carga manual</span>
        <div class="date-time-block">
          <span class="date">{{ lastLoadDate }}</span>
          <span class="time">{{ lastLoadTime }}</span>
        </div>
      </div>
      <div class="load-date-block">
        <span class="title">Fecha de la última carga automática</span>
        <div class="date-time-block">
          <span class="date">{{ lastAutomaticLoadDate }}</span>
          <span class="time">{{ lastAutomaticLoadTime }}</span>
        </div>
      </div>
      <bdr-button [ngClass]="{ hide: currentTasks.length != 0 }" class="update-loads" (click)="confirmReloadFiles()"
        primary data-cy="update-loads">Actualizar las cargas</bdr-button>

      <div class="load-file">
        <a class="link" [ngClass]="{ disabled: masterDataPaths.length === 0 }" primary (click)="fileUploadDialog()">
          <bdr-icon icon="ico-version2"></bdr-icon>
          Subir archivos
        </a>
      </div>
    </div>
    <div class="log-column bordered-grid">
      <grid-handler-view [title]="loadErrorsTitle" [panels]="panels" [volumetries]="null"
        [gridProperties]="gridProperties" [editMode]="false" [editChangedValues]="false" isTabSelected="true"
        scope="loads">
      </grid-handler-view>
    </div>
  </div>
  <section class="loads-body">
    <aside class="actions"></aside>
  </section>
</div>