<p class="title">Otros filtros y reglas aplicadas</p>

<span class="filter" *ngFor="let filter of filters | slice:2">
  <bdr-icon *ngIf="filter.meta && filter.meta.type === 'filter'" class="filter__type" icon="ico-filter" size="16px">
  </bdr-icon>
  <span *ngIf="filter.meta && filter.meta.type === 'manual-validations-filter'"
    class="manual-validations fake-icon ">{{filter.filter.rules[0].value}}</span>
  <span *ngIf="filter.meta && filter.meta.type === 'validations-filter'"
    class="fake-icon">{{filter.filter.rules[0].value}}</span>
  <span class="filter-name" title="{{ getItemName(filter) }}">{{ getItemName(filter) }}</span>
  <bdr-icon class="filter-icon-close" icon="ico-close" size="16px" (click)="removeAppliedFilter(filter)"></bdr-icon>
</span>