<div class="log-info" *ngIf="!error && !loading && logInfo">
  <header class="log-header">Modificaciones realizadas</header>
  <div class="body">
    <log-item [item]="logInfo"></log-item>
  </div>
  <footer>
    <a (click)="showAll()" class="link">Ver todas las modificaciones</a>
  </footer>
</div>
<div class="loading" *ngIf="!error && loading">
  <div class="loading-wrapper" [ngClass]="{ 'loading-first-load': firstLoad }">
    <div class="vignette">
      <img src="/assets/images/loading-grid.gif" width="18" height="18" alt="Cargando" />
    </div>
    <p class="loading-title">Cargando</p>
  </div>
</div>
<div class="error" *ngIf="error && !loading">
  <p class="loading-title">{{ errorMessage }}</p>
</div>