<section class="body">
  <header class="header" (escapeDialog)="handleCloseDialog()">
    <div class="header-title">
      <span class="title-prefix">{{ title }}</span>
    </div>
  </header>
  <div class="body-wrapper">
    <div class="search-content-header content-header">
      <input class="items-list-input-search" type="text" [(ngModel)]="queryString"
        (ngModelChange)="filterColumns($event)" />
      <div class="items-list-icon-search">
        <bdr-icon *ngIf="iconSearch === 'search'" class="highlight-icon" icon="ico-search" (click)="openInputSearch()">
        </bdr-icon>
        <bdr-icon *ngIf="iconSearch === 'cancel-search'" class="highlight-icon" icon="ico-ko"
          (click)="closeInputSearch()"></bdr-icon>
      </div>
    </div>
    <div class="de-select-all-container">
      <a class="select-all-link" (click)="massiveChange(true)"
        [ngClass]="{ selected: allSelected === true }">Seleccionar todos</a>
      <a class="select-all-link" (click)="massiveChange(false)"
        [ngClass]="{ selected: allDeselected === true }">Deseleccionar todos</a>

      <a class="reset-all-link" (click)="resetToDefault()">Reestablecer</a>
    </div>
    <div class="content-body">
      <ul class="column-list" *ngIf="displayedColumns">
        <li class="column" *ngFor="let key of objectKeys(displayedColumns)">
          <bdr-checkbox [caption]="displayedColumns[key].title | uppercase" [name]="key"
            [(model)]="schemaData[key].visible" (modelChange)="setMassiveSelectors()"></bdr-checkbox>
        </li>
      </ul>
      <!-- {{schemaData | json}} -->
    </div>
  </div>
</section>

<footer class="footer-wrapper">
  <div class="footer">
    <div class="footer-actions">
      <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()">Cancelar</bdr-button>
      <bdr-button class="btn-save" primary size="small" (click)="handleApplyItem()">Aplicar</bdr-button>
    </div>
  </div>
</footer>