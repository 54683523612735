<section class="body">
  <div class="body-wrapper">
    <span class="title">Seleccione el año sobre el que desea trabajar:</span>
    <form class="dialog-form">
      <div class="error-messages" *ngIf="formError">
        <p>{{formErrorMsg}}</p>
      </div>
      <div class="radio" *ngFor="let year of yearList">
        <label class="radio-label">
          <input name="year" type="radio" [value]="year" [(ngModel)]="selectedYear"
            (ngModelChange)="selectedYear = $event" />
          {{year}}
          <span class="current" *ngIf="year === staticCurrentYear">(actual)</span>
        </label>
      </div>
    </form>
  </div>
</section>
<footer class="footer-wrapper">
  <div class="footer">
    <bdr-button class="btn-save" [context]="'dialog'" primary (click)="submit()">Cambiar</bdr-button>
  </div>
</footer>