<mat-form-field class="form-field">
  <div class="form-field__search">
    <input matInput [formControl]="searchInput" [placeholder]="placeholder" />

    <div class="form-field__filter">
      <bdr-icon icon="ico-filter" [matMenuTriggerFor]="menu" #clickMenuTrigger="matMenuTrigger"></bdr-icon>
      <mat-menu #menu="matMenu" class="form-field__items" (click)="$event.stopPropagation()">
        <div class="menu-wrapper">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" (click)="$event.stopPropagation()">
            Todos
          </mat-checkbox>
          <mat-checkbox *ngFor="let item of filters" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(item) : null" [checked]="selection.isSelected(item)">
            {{ item.label }}
          </mat-checkbox>
        </div>
        <div class="form-field__buttons">
          <button (click)="removeFilters($event)" class="bdr-btn bdr-btn-white">
            Borrar Todos</button><button (click)="onClickFilter($event)" class="bdr-btn bdr-btn-blue">
            Aplicar
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</mat-form-field>