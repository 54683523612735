<grid-handler-view [panels]="panels" [volumetries]="null" [gridProperties]="gridProperties" [editMode]="editMode"
  [editChangedValues]="editData !== undefined && editData.length > 0"
  [canSave]="editData !== undefined && editData.length > 0" [saving]="savingFlag" [showModalSaving]="showModalSaving"
  [subheaderSelector]="subheaderSelector" [hasDeferredAppointments]="hasDeferredAppointments"
  [deferredAppointments]="deferredAppointments" [hasDeferredWorks]="hasDeferredWorks" [deferredWorks]="deferredWorks"
  [hasDeferredAppointmentsBuildings]="hasDeferredAppointmentsBuildings"
  [deferredAppointmentsBuildings]="deferredAppointmentsBuildings"
  [hasDeferredAppointmentsOffices]="hasDeferredAppointmentsOffices"
  [deferredAppointmentsOffices]="deferredAppointmentsOffices"
  [hasDeferredAppointmentsMeasuring]="hasDeferredAppointmentsMeasuring"
  [deferredAppointmentsMeasuring]="deferredAppointmentsMeasuring" [saveDisabled]="saveDisabled"
  [saveDisabledReason]="saveDisabledReason" scope="investments" (onSaveChanges)="saveChanges($event)"
  (onGridEditChange)="gridEditChange($event)" (onCancel)="cancelEdit()" (onGenerateAction)="generateSheet($event)"
  (onDblClick)="dblClick($event)" (selectedRowsChange)="selectedRowsChange($event)">
</grid-handler-view>