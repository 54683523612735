<section class="body">


  <div class="body-wrapper">
    <div class="title-row">
      <label>Título</label>
      <input type="'text'" [ngClass]="{ error: titleError }" placeholder="Nombre del filtro" [value]="item.name"
        required (change)="item.name = $event.target.value" />
      <p class="error-caption" *ngIf="titleError">
        Debe introducir un nombre para el filtro
      </p>
    </div>
    <div class="content-body">
      <bdr-query-builder-new [dragInfo]="dragInfo" [query]="item?.filter" [dropZones]="[id]" [type]="type"
        [origin]="origin" [schemaItems]="schemaItems" [itemType]="typeI18n" [admin]="admin"
        (validationError)="handleValidationError($event)" (openMainListRequest)="openMainList($event)"
        (expandItemMainRequest)="expandItem($event)">
      </bdr-query-builder-new>
    </div>
    <div class="body-wrapper-side-list" *ngIf="selectedSideList">
      <span class="item-name-title">{{ sideListTitle }}</span>
      <div *ngFor="let sideItem of sideList" [ngClass]="{
          'side-action-list-item': true,
          active: selectedSideList === sideItem.id
        }">
        <bdr-row-drag title="{{ item.name }}" cdkDrag class="item-row-drag" *ngFor="
            let item of (sideItem.list.get(origin)
              | async
              | filterArray: { key: 'origin', value: origin })
          " [type]="sideItem.type" [origin]="item.origin" (onDragStart)="setDragElementInfo($event)">
          {{ item.name }}
        </bdr-row-drag>
      </div>
    </div>

    <footer class="footer-wrapper">
      <div class="footer">
        <div class="footer-actions">
          <a class="link" (click)="handleSaveItem(true)">Aplicar y guardar</a>
        </div>
      </div>
    </footer>
  </div>
</section>