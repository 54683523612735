<div class="download-options">
  <div class="panel-header">
    <span class="title">
      <ng-content></ng-content>
    </span>
    <div class="panel-header-cancel">
      <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
    </div>
  </div>
</div>

<div class="panel-body">
  <div *ngFor="let exportItem of exportData">
    <span class="link" (click)="reloadCNMCValidations()">Calcular validaciones</span>
    <span class="link" (click)="reloadFormCnmcValidations()">Calcular validaciones de este formulario</span>
    <span class="link" (click)="reloadCNMCErrorLogs()">Calcular log de errores</span>
    <span class="link" (click)="reloadFormCnmcErrorLogs()">Calcular log de errores de este formulario</span>
  </div>
</div>