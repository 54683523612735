<div class="ela-detail">
  <aside class="draggable-dialogs-wrapper ">
    <bdr-dialog-list></bdr-dialog-list>
  </aside>
  <div class="wc-description bdr-card__description">
    <div class="wc-description__header">
      <button class="wc-description__button--back"
        [routerLink]="['/investments-sheet/at/sabana_inversiones_at_traspaso_agregado_ela_vista']">
        <bdr-icon icon="ico-left"></bdr-icon>
      </button>

      <div *ngIf="elaRecord" class="wc-description__info">
        <div class="wc-description__description">
          <p class="wc-description__title">
            <span class="wc-description__ela--number">
              ELA {{ elaRecord?.ela_final }} - {{ elaRecord?.ela_final_desc }} |
              Importe total: <span class="wc-description__total"> {{ atMoney + dispatchersMoney | localeCurrency
                }}</span>
              (AT: <span class="wc-description__total"> {{ atMoney | localeCurrency }}</span>,
              Despachos: <span class="wc-description__total"> {{ dispatchersMoney | localeCurrency }}</span>
              )
            </span>
          </p>
        </div>
      </div>
      <ng-container *ngIf="canSave(); else cantSave">
        <div class="ela-detail-installations__undo">
          <bdr-icon size="35px" icon="ico-undo" (click)="undo()"></bdr-icon>
        </div>
        <div class="ela-detail-installations__save">
          <bdr-icon size="35px" icon="ico-save" (click)="save()"></bdr-icon>
        </div>
      </ng-container>
      <ng-template #cantSave>
        <div class="ela-detail-installations__undo">
          <bdr-icon size="35px" class="disabled" icon="ico-undo"></bdr-icon>
        </div>

        <div class="ela-detail-installations__save">
          <bdr-icon size="35px" class="disabled" icon="ico-save"></bdr-icon>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- 
    AT 
  -->
  <div class="wc-menu bdr-card grid-main">
    <div class="ela-detail-content">
      <div class="ela-detail-incurred__header">
        <div class="ela-detail-incurred__filter">
          <span class="ela-detail-incurred-title">INCURRIDOS AT AGREGADOS</span>
        </div>
        <div class="ela-detail-incurred__actions">
          <ng-container *ngIf="atSelectedRows?.length > 0">
            <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
            <ng-container *ngIf="atSelectedRows?.length < 6; else disabledFilterAtDetail">
              <span class="link text-header-link" style="margin-right:10px" (click)="filterAtDetail()">Detalle
                Incurridos</span>
            </ng-container>
            <ng-template #disabledFilterAtDetail>
              <span class="link disabled text-header-link" style="margin-right:10px"
                title="Ha seleccionado demasiados registros (máximo 5)">
                Detalle Incurridos
              </span>
            </ng-template>

            <ng-container *ngIf="atManualInstallationsEditionEnable.enable">
              <ng-container *ngIf="atSelectedRows.length === 1">
                <bdr-icon class="icon icon-header" size="18px" style="margin-left:10px" icon="ico-actions"></bdr-icon>
                <span [ngClass]="{
                    'link': true,
                    'disabled': !isEditable,
                    'text-header-link': true
                  }" (click)="isEditable? showAtModifyWeights($event) : null" [title]="nonEditableMessage">
                  Modificar reparto
                </span>
              </ng-container>

              <bdr-icon class="icon icon-header" size="18px" style="margin-left:10px" icon="ico-actions"></bdr-icon>
              <span [ngClass]="{
                  'link': true,
                  'disabled': !isEditable,
                  'text-header-link': true
                }" (click)="isEditable? showAssociateAtInstallations($event) : null" [title]="nonEditableMessage">
                Asociar/desasociar instalaciones
              </span>
            </ng-container>
          </ng-container>

          <div class="ela-detail-incurred__visible-columns" (click)='onAtIncurredsFilter($event)'>
            <bdr-icon icon="ico-filter"></bdr-icon>Filtrar
          </div>
          <div class="ela-detail-incurred__download" (click)="onDownloadAtIncurreds($event)">
            <bdr-icon icon="ico-download"></bdr-icon>Descargar
          </div>

          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_at_traspaso_agregado_vista']">
              {{ dataTables['sabana_inversiones_at_traspaso_agregado_vista'].filtered }} registros
            </span>
          </div>
          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_at_traspaso_agregado_vista']">
              {{ filteredAtMoney | localeCurrency }}
            </span>
          </div>
        </div>
      </div>


    </div>
    <div class="grid-main__table">
      <ng-container *ngIf="data$ | async as data">
        <ag-grid-angular contextMenu='at-incurreds-table' id='at-incurreds-table' class="ag-theme-balham"
          rowSelection='multiple' [columnDefs]="data.headers" [rowData]="data.rows" [gridOptions]="atGridOptions"
          [floatingFilter]="isFloatingFilter" [overlayNoRowsTemplate]="noRowsTemplate"
          (gridReady)="onAtIncurredsGridReady($event)" (onRowSelected)="onAtRowSelected($event)">
        </ag-grid-angular>
      </ng-container>
    </div>
  </div>

  <div class="wc-menu bdr-card grid-main">
    <div class="ela-detail-content">
      <div class="ela-detail-incurred__header">
        <div class="ela-detail-incurred__filter">
          <span class="ela-detail-incurred-title">INSTALACIONES AT</span>
        </div>
        <div class="ela-detail-incurred__actions">
          <div class="ela-detail-incurred__visible-columns">
            <div class="ela-detail-incurred__visible-columns" (click)="onAtInstallationsFilter($event)">
              <bdr-icon icon="ico-filter"></bdr-icon>Filtrar
            </div>
          </div>
          <div class="ela-detail-incurred__download" (click)="onDownloadAtInstallations($event)">
            <bdr-icon icon="ico-download"></bdr-icon>Descargar
          </div>

          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_at_detalle_ela_instalaciones_vista']">
              {{ dataTables['sabana_inversiones_at_detalle_ela_instalaciones_vista'].filtered }} registros
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-main__table">
      <ng-container *ngIf="data2$ | async as data">
        <ag-grid-angular contextMenu='at-installations-table' id='at-installations-table' class="ag-theme-balham"
          rowSelection='multiple' [columnDefs]="data.headers" [rowData]="data.rows" [gridOptions]="gridOptions"
          [floatingFilter]="isFloatingFilter" [overlayNoRowsTemplate]="noRowsTemplate"
          (gridReady)="onAtInstallationsGridReady($event)" (cellValueChanged)="onAtCellValueChanged($event)"
          stopEditingWhenCellsLoseFocus>
        </ag-grid-angular>
      </ng-container>
    </div>
  </div>



  <!-- 
    DESPACHOS
  -->
  <div class="wc-menu bdr-card grid-main">
    <div class="ela-detail-content">
      <div class="ela-detail-incurred__header">
        <div class="ela-detail-incurred__filter">
          <span class="ela-detail-incurred-title">INCURRIDOS DESPACHOS AGREGADOS</span>
        </div>
        <div class="ela-detail-incurred__actions">
          <ng-container *ngIf="!!dispatchersElaRecord">
            <ng-container *ngIf="dispatchersManualInstallationsEditionEnable.enable">
              <ng-container *ngIf="dispatchersSelectedRows.length === 1">
                <bdr-icon class="icon icon-header" size="18px" style="margin-left:10px" icon="ico-actions"></bdr-icon>
                <span [ngClass]="{
                    'link': true,
                    'disabled': !isEditable,
                    'text-header-link': true
                  }" [title]="nonEditableMessage" (click)="isEditable? showDispatchersModifyWeights($event) : null">
                  Modificar reparto
                </span>
              </ng-container>
              <bdr-icon class="icon icon-header" size="18px" style="margin-left:10px" icon="ico-actions"></bdr-icon>
              <span [ngClass]="{
                  'link': true,
                  'disabled': !isEditable,
                  'text-header-link': true
                }" [title]="nonEditableMessage"
                (click)="isEditable? showAssociateDispatchersInstallations($event) : null">
                Asociar/desasociar instalaciones
              </span>
            </ng-container>
          </ng-container>

          <div class="ela-detail-incurred__visible-columns" (click)='onDispatchersIncurredsFilter($event)'>
            <bdr-icon icon="ico-filter"></bdr-icon>Filtrar
          </div>
          <div class="ela-detail-incurred__download" (click)="onDownloadDispatchersIncurreds($event)">
            <bdr-icon icon="ico-download"></bdr-icon>Descargar
          </div>

          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_despachos_detalle_ela_vista']">
              {{ dataTables['sabana_inversiones_despachos_detalle_ela_vista'].filtered }} registros
            </span>
          </div>

          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_despachos_detalle_ela_vista']">
              {{ filteredDispatchersMoney | localeCurrency}}
            </span>
          </div>
        </div>
      </div>


    </div>
    <div class="grid-main__table">
      <ng-container *ngIf="data3$ | async as data">
        <ag-grid-angular id='dispatchers-incurreds-table' class="ag-theme-balham" rowSelection='multiple'
          contextMenu='dispatchers-incurreds-table' [columnDefs]="data.headers" [rowData]="data.rows"
          [gridOptions]="dispatchersGridOptions" [floatingFilter]="isFloatingFilter"
          [overlayNoRowsTemplate]="noRowsTemplate" (gridReady)="onDispatchersIncurredsGridReady($event)"
          (onRowSelected)="onDispatchersRowSelected($event)">
        </ag-grid-angular>
      </ng-container>
    </div>
  </div>

  <div class="wc-menu bdr-card grid-main">
    <div class="ela-detail-content">
      <div class="ela-detail-incurred__header">
        <div class="ela-detail-incurred__filter">
          <span class="ela-detail-incurred-title">INSTALACIONES DESPACHOS</span>
        </div>
        <div class="ela-detail-incurred__actions">
          <div class="ela-detail-incurred__visible-columns" (click)="onDispatchersInstallationsFilter($event)">
            <bdr-icon icon="ico-filter"></bdr-icon>Filtrar
          </div>
          <div class="ela-detail-incurred__download" (click)="onDownloadDispatchersInstallations($event)">
            <bdr-icon icon="ico-download"></bdr-icon>Descargar
          </div>

          <div class="ela-detail-incurred__total">
            <span *ngIf="dataTables['sabana_inversiones_despachos_detalle_ela_instalaciones_vista']">
              {{ dataTables['sabana_inversiones_despachos_detalle_ela_instalaciones_vista'].filtered }} registros
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-main__table">
      <ng-container *ngIf="data4$ | async as data">
        <ag-grid-angular id='dispatchers-installations-table' class="ag-theme-balham" rowSelection='multiple'
          contextMenu='dispatchers-installations-table' [columnDefs]="data.headers" [rowData]="data.rows"
          [gridOptions]="gridOptions" [editType]="editType" [floatingFilter]="isFloatingFilter"
          [overlayNoRowsTemplate]="noRowsTemplate" (gridReady)="onDispatchersInstallationsGridReady($event)"
          (cellValueChanged)="onDispatchersCellValueChanged($event)" stopEditingWhenCellsLoseFocus>
        </ag-grid-angular>
      </ng-container>
    </div>
  </div>

  <!-- <div class="ela-error">
    <div class="loading-wrapper" (click)="reset()">
      <div class="vignette">
        <bdr-icon icon="ico-clickable" class="icon icon-header" size="48px"></bdr-icon>
      </div>
      <p class="loading-title">{{ errorDetail.splashTitle }}</p>
      <p class="loading-desc" *ngFor="let desc of errorDetail.splashDescriptions">{{ desc }}</p>
    </div>
  </div> -->

  <bdr-splash
    *ngIf="isLoadingWeights || isLoadingAT || isLoadingATInstallations || isLoadingDispatchers || isLoadingDispatchersInstallations"
    [title]="splashMessage" [descriptions]="['Cargando datos']"></bdr-splash>


  <bdr-splash *ngIf="saving" [title]="['Guardando cambios realizados']" [descriptions]="['Espere, por favor']">
  </bdr-splash>

</div>