<div class="toolbar-actions">
  <span class="toolbar-title" *ngIf="title">
    <span class="fake-icon" *ngIf="fakeIcon">{{ fakeIcon }}</span>
    <span>{{ title }}</span>
  </span>
  <div class="main-action-container" *ngIf="selectedRows" [@slideInOutAnimation]="animationStateRowActions">
    <div *ngFor="let action of headerActions" class="action-container">
      <ng-container *ngIf="action.type === 'actions' && action.requireSelected">
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-actions"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">Acciones</span>
        </span>
      </ng-container>
    </div>
  </div>
  <div class="main-action-container" *ngIf="!selectedRows" [@slideInOutAnimation]="animationStateRowActions">
    <div *ngFor="let action of headerActions" class="action-container">
      <ng-container *ngIf="
          scope === 'master' &&
          action.type === 'columnSearch' &&
          !action.requireSelected && !selectedRows
        ">
        <span class="toolbar-actions-separator"></span>
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-fastfilter"></bdr-icon>
          <span [ngClass]="{ disabled: disabledVisualization }" class="link text-header-link" (click)="
              action.fire && !disabledVisualization ? action.fire($event) : toggleColumnSearch()
            ">Filtrar</span>
        </span>
      </ng-container>


    </div>
  </div>

  <div class="main-action-container" *ngIf="selectedRows" [@slideInOutAnimation]="animationStateRowActions">

    <div *ngFor="let action of headerActions" class="action-container">
      <ng-container *ngIf="
      action.type === 'clearLoads' && 
      action.requireSelected && 
      selectedRows && 
      action.fire
    ">
        <span class="toolbar-actions-separator"></span>
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-delete"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">{{ action.title }}</span>
        </span>
      </ng-container>
    </div>

  </div>

  <div class="main-action-container" *ngIf="!selectedRows && !!classicView"
    [@slideInOutAnimation]="animationStateRowActions">
    <div *ngFor="let action of headerActions" class="action-container">
      <ng-container *ngIf="action.type === 'export' && !action.requireSelected && !selectedRows">
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-download"></bdr-icon>
          <ng-container *ngIf="!action.downloading">
            <span class="link text-header-link" (click)="action.fire($event)">Descargar</span>
          </ng-container>
          <ng-container *ngIf="action.downloading">
            <span class="text-header-link downloading">Descargando...</span>
          </ng-container>
        </span>
      </ng-container>

      <ng-container *ngIf="action.type === 'generateFile' && !action.requireSelected && !selectedRows">
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-download"></bdr-icon>
          <span class="link text-header-link"
            (click)="action.fire ? action.fire($event) : toggleColumnSearch()">Descargar</span>
        </span>
      </ng-container>

      <ng-container *ngIf="action.type === 'columnSearch' && !action.requireSelected && !selectedRows">
        <span class="toolbar-actions-separator"></span>
        <span class="toolbar-actions-button">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <span class="link text-header-link"
            (click)="action.fire ? action.fire($event) : toggleColumnSearch()">Filtrar</span>
        </span>
      </ng-container>
    </div>
  </div>
</div>

<bdr-applied-filters *ngIf="origin === 'sabana_inversiones_mtbt_detalle_vista'" [appliedFilters]="appliedFilters"
  (resetFilterRequest)="resetFilter($event)">
</bdr-applied-filters>

<div class="main-actions">
  <bdr-volumetries *ngIf="!downloadsService.downloadNotification" [resultsCount]="resultsCount">
  </bdr-volumetries>

  <bdr-header-notifications 
    [text]="notificationTextByOrigin()" 
    [saving]="saving" 
    [editMode]="editMode"
    (onSaveChanges)="saveChanges()" 
    (onCancelSave)="cancelActions()"
  >
  </bdr-header-notifications>
</div>

