<div class="reload-options">
  <div class="panel-header">
    <span class="title">Actualizar datos</span>
    <div class="panel-header-cancel">
      <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
    </div>
  </div>
</div>
<div class="panel-body">
  <span class="link" *ngIf="origin?.reload" (click)="reload(origin.reload)">
    Actualizar {{origin.reloadNicename}}
  </span>

  <span class="link" *ngIf="origin?.specificReload" (click)="reload(origin.specificReload)">
    Actualizar {{ origin.specificReloadNicename }}
  </span>

  <span class="link" *ngIf="origin?.specificReload2" (click)="reload(origin.specificReload2)">
    Actualizar {{ origin.specificReloadNicename2 }}
  </span>

  <span class="link" *ngIf="origin?.specificReload3 && origin?.specificReload3 == 'undo_diferred'" (click)="reload(origin.specificReload3)">
    Anular {{ origin.specificReloadNicename3 }} automáticas
  </span>

  <span class="link" *ngIf="origin?.specificReload3 && origin?.specificReload3 != 'undo_diferred'" (click)="reload(origin.specificReload3)">
    Actualizar {{ origin.specificReloadNicename3 }}
  </span>
</div>