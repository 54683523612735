<div class="save notif-style" *ngIf="editMode && !saving">
  <div class="icon-container">
    <bdr-icon icon="ico-menu2"></bdr-icon>
  </div>
  <div class="save-content">
    <span>{{ text }}</span>
    <a class="secondary-link" (click)="cancelActions()">CANCELAR</a>
    <a class="link btn-save " [ngClass]="{ disabled: isDisabled }" 
      (click)="isDisabled? null : saveChanges(saveChanges)"
      [title]="isDisabledReason">
      GUARDAR CAMBIOS
    </a>
    <a *ngIf="recurrentEditionCheckbox" class="secondary-link">
      <bdr-checkbox 
        title="Edición recurrente"
        caption="EDICIÓN RECURRENTE"
        [(model)]="recurrentEdition"
      >
      </bdr-checkbox>
    </a>
  </div>
</div>
<div class="save notif-style" *ngIf="downloadsService.downloadNotification">
  <div class="icon-container">
    <bdr-icon icon="ico-alert"></bdr-icon>
  </div>
  <div class="save-content">
    <span>Los archivos y el enlace para la descarga se están generando. Esta operación puede tardar
      unos minutos</span>
    <bdr-icon class="cancel" icon="ico-close" size="16px" (click)="downloadsService.downloadNotification = false">
    </bdr-icon>
  </div>
</div>