<div class="container-notification {{notification.type}} animation-container">
  <div class="container-notification-icon {{notification.type}} animation-container-icon">
    <bdr-icon class="notification-icon animation-icon-pop" icon="ico-ok" *ngIf="notification.type === 'confirmation'">
    </bdr-icon>
    <bdr-icon class="notification-icon animation-icon-pop" icon="ico-alert" *ngIf="notification.type === 'warning'">
    </bdr-icon>
    <bdr-icon class="notification-icon animation-icon-pop" icon="ico-ko" *ngIf="notification.type === 'error'">
    </bdr-icon>
    <bdr-icon class="notification-icon animation-icon-pop" icon="info" *ngIf="notification.type === 'info'"></bdr-icon>
  </div>
  <div class="container-notification-body {{notification.type}} slide-right">
    <div class="notification-text">
      <span class="notification-text__title">{{notification.message}}</span>
      <a class="notification-undo-link {{notification.type}}" *ngIf="notification.action?.enable" (click)="action()"
        style="padding-left: 5px;">Actualizar</a>
      <a class="notification-undo-link {{notification.type}}" *ngIf="notification.detail" (click)="showDetail()">Ver
        detalle</a>
    </div>
    <div class="actions">
      <div class="notification-close">
        <bdr-icon (click)="close()" class="notification-close-icon {{notification.type}}" icon="ico-close">
        </bdr-icon>
      </div>
    </div>
  </div>
</div>