export const RECLASSIFICATION_TYPES = {
  '1': 'Año retributivo modificado',
  '2': 'Preclasificación RECL modificada',
  '3': 'MEI RECL modificado',
  '4': 'Clasificación RECL modificada',
  '5': 'ELA RECL modificado',
  '6': 'COD Actuacion RECL modificado',
  '7': 'Despachos-digitalización',
  '8': 'Clasificación Inversión RECL modificado',
  A: 'Reclasificación manual',
  B: 'Reclasificación automática',
};

export const DIFFER_TYPES = {
  I: 'Año origen',
  F: 'Año destino',
};

export const DEFAULT_VIEWS = {
  //Inversiones
  sap_incurridos_manual_registro_vista: {
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/reclassification/work-closure/',
      linkParamField: 'id',
    },
  },

  sap_incurridos_manual_registro_agrupado_vista: {
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/reclassification/work-closure/',
      linkParamField: 'id',
    },
  },

  sabana_inversiones_despachos_detalle_vista: {
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/dispatchers/work-closure/',
      linkParamField: 'id',
    },
    ela_final: {
      editable: false,
      link: true,
      pk: false,
      linkUrlBase: '/investments-sheet/at/ela-detail/',
      linkParamField: 'id',
    },
  },

  sabana_inversiones_despachos_agregado_obra_vista: {
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/dispatchers/work-closure/',
      linkParamField: 'id',
      order: 3,
    },
  },

  sabana_inversiones_mtbt_agregada_vista: {
    anio_contable: { pk: true },
    anio_retributivo: { pk: true },
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/mtbt/work-closure/',
      linkParamField: 'preclasificacion_final',
      order: 3,
    },
    obra_revisada: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'SI', name: 'SÍ' },
        { value: 'NO', name: 'NO' },
      ],
    },

    moi: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_ingenieria: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_tramitaciones: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_supervision: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_seguridad: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_galicia: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_centro: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    bolsa_general: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },
  sabana_inversiones_mtbt_detalle_vista: {
    codigo_obra: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/mtbt/work-closure/',
      linkParamField: 'id',
      order: 2,
    },
  },

  sabana_inversiones_at_traspaso_agregado_ela_vista: {
    inventario: {
      editable: false,
      delimiter: ';',
    },
    ela_final: {
      editable: false,
      link: true,
      pk: true,
      linkUrlBase: '/investments-sheet/at/ela-detail/',
      linkParamField: 'id',
    },
  },

  // Instalaciones
  sabana_informe_instalaciones_tramos_mtbt_vista: {
    codigo_agrupador: {
      editable: true,
    },
  },

  sabana_informe_comunicaciones_expedientes_mtbt_vista: {
    anio_contable: { visible: true },
    codigo_obra: { visible: true },
    tipologia_actuacion_final: { visible: true },
    identificador: { visible: true },
    tipo_inversion_final: { visible: false },
    financiado: { visible: false },
    planificacion: { visible: false },
    estado_instalacion: { visible: false },
    motivacion: { visible: false },
    motivacion_final: { visible: false },
    clasificacion: { visible: true },
    cini: { visible: true },
    nivel_tension: { visible: true },
    cuenta_contable: { visible: false },
    porcentaje_modificacion: { visible: false },
    codigo_ccuu: { visible: true },
    importe_instalacion_ufd: { visible: false },
    importe_ingenieria: { visible: false },
    importe_materiales: { visible: false },
    importe_obra_civil: { visible: false },
    importe_trabajos: { visible: false },
    importe_asociado_instalacion: { visible: false },
    importe_bolsa_general: { visible: false },
    importe_bolsa_centro: { visible: false },
    importe_bolsa_galicia: { visible: false },
    valor_auditado: { visible: false },
    valor_contable: { visible: false },
    codigo_tramo_unico: { visible: true },
    conductor: { visible: true },
    intensidad_maxima: { visible: true },
    linea_asociada: { visible: true },
    longitud: { visible: true },
    numero_circuitos: { visible: true },
    numero_conductores: { visible: true },
    seccion: { visible: true },
    tipo: { visible: false },
    numero_celdas: { visible: true },
    numero_maximo_maquinas: { visible: true },
    potencia: { visible: true },
    tipo_elemento: { visible: true },
    capacidad: { visible: true },
    codigo_zona: { visible: true },
    coordenada_x_ini: { visible: true },
    coordenada_y_ini: { visible: true },
    coordenada_x_fin: { visible: true },
    coordenada_y_fin: { visible: true },
    apm: { visible: true },
    tipo_ct: { visible: true },
    tipo_subestacion: { visible: true },
    matricula: { visible: true },
    municipio: { visible: true },
    provincia: { visible: true },
    des_municipio: { visible: true },
    des_provincia: { visible: true },
    fecha_pta_servicio: { visible: true },
    centro: { visible: true },
    codigo_instalacion: { visible: true },
    nuevo_codigo_igea: { visible: true },
    tipo_instalacion: { visible: false },
    importe_total: { visible: true },
    cod_empresa: { visible: true },
    criterio_reparto: { visible: false },
  },

  //INVENTARIO - COMPONENTE
  gdoapoyo_circular_vista: {
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    tipo_a: { visible: true },
    tip_aislante: { visible: true },
    long_vano: { visible: false },
    modcons: { visible: false },
    modconsai: { visible: false },
    situa: { visible: false },
    matricula: { visible: true },
    mat_pu_m: { visible: false },
    modconscu1: { visible: false },
    modconscu2: { visible: false },
    modconscu3: { visible: false },
    con_etiq: { visible: false },
    aislamiento: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    modconsai_v10: { visible: false },
    modcons_v10: { visible: false },
    modconscu2_v10: { visible: false },
    modconscu1_v10: { visible: false },
    n_aislantes: { visible: true },
    modconscu3_v10: { visible: false },
    pate_fijo: { visible: false },
    cod_linze: { visible: false },
    observaciones: { visible: true },
    apoyo_ant: { visible: false },
    aislamiento2: { visible: true },
    aislamiento3: { visible: true },
    pto_singular: { visible: false },
    fecha_alta: { visible: false },
    fecha_ult_act: { visible: false },
    grarep: { visible: false },
    attr_posn: { visible: false },
    antiescala: { visible: false },
    fabricante: { visible: false },
    tip_aislante2: { visible: true },
    tip_aislante3: { visible: true },
    modconsai2_v10: { visible: false },
    modconsai3_v10: { visible: false },
    pto_singular2: { visible: false },
    pto_singular3: { visible: false },
    avi_fauna: { visible: false },
    enerbob: { visible: false },
    f_ult_rev: { visible: false },
    provincia: { visible: true },
    municipio: { visible: true },
    zona_frec: { visible: false },
    coord_x: { visible: true },
    coord_y: { visible: true },
    coord_z: { visible: false },
    antinidos: { visible: false },
    punto_apoyo: { visible: false },
    apoyo_sig_1: { visible: false },
    tipo_cond_1: { visible: true },
    n_cond_fase_1: { visible: true },
    anticolision_1: { visible: false },
    tipo_arbolado_1: { visible: false },
    ancho_calle_1: { visible: false },
    per_arbolado_1: { visible: false },
    tipo_cruzamiento_1_blq_1: { visible: false },
    tipo_cruzamiento_2_blq_1: { visible: false },
    tipo_cruzamiento_3_blq_1: { visible: false },
    tipo_paralelismo_1_blq_1: { visible: false },
    tipo_paralelismo_2_blq_1: { visible: false },
    tipo_paralelismo_3_blq_1: { visible: false },
    long_vano_1: { visible: false },
    fecha_ult_rev_1: { visible: false },
    fecha_puesta_serv_1: { visible: true },
    codinst1_v10: { visible: true },
    situa1_v10: { visible: true },
    apoyo_sig_2: { visible: false },
    tipo_cond_2: { visible: true },
    n_cond_fase_2: { visible: true },
    anticolision_2: { visible: false },
    tipo_arbolado_2: { visible: false },
    ancho_calle_2: { visible: false },
    per_arbolado_2: { visible: false },
    tipo_cruzamiento_1_blq_2: { visible: false },
    tipo_cruzamiento_2_blq_2: { visible: false },
    tipo_cruzamiento_3_blq_2: { visible: false },
    tipo_paralelismo_1_blq_2: { visible: false },
    tipo_paralelismo_2_blq_2: { visible: false },
    tipo_paralelismo_3_blq_2: { visible: false },
    long_vano_2: { visible: false },
    fecha_ult_rev_2: { visible: false },
    fecha_puesta_serv_2: { visible: true },
    codinst2_v10: { visible: true },
    situa2_v10: { visible: true },
    apoyo_sig_3: { visible: false },
    tipo_cond_3: { visible: true },
    n_cond_fase_3: { visible: true },
    anticolision_3: { visible: false },
    tipo_arbolado_3: { visible: false },
    ancho_calle_3: { visible: false },
    per_arbolado_3: { visible: false },
    tipo_cruzamiento_1_blq_3: { visible: false },
    tipo_cruzamiento_2_blq_3: { visible: false },
    tipo_cruzamiento_3_blq_3: { visible: false },
    tipo_paralelismo_1_blq_3: { visible: false },
    tipo_paralelismo_2_blq_3: { visible: false },
    tipo_paralelismo_3_blq_3: { visible: false },
    long_vano_3: { visible: false },
    fecha_ult_rev_3: { visible: false },
    fecha_puesta_serv_3: { visible: true },
    codinst3_v10: { visible: true },
    situa3_v10: { visible: true },
    expediente: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    fecha_pta_ser_1: { visible: true },
    fecha_pta_ser_2: { visible: true },
    fecha_pta_ser_3: { visible: true },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    tipomantenimiento: { visible: false },
    codigo_etiquetacion: { visible: false },
    fecha_etiq: { visible: false },
    mfe_def: { visible: false },
    mfe_nomformarb: { visible: false },
    mfe_esp1: { visible: false },
    mfe_porcocupesp1: { visible: false },
    mfe_esp2: { visible: false },
    mfe_porcocupesp2: { visible: false },
    mfe_esp3: { visible: false },
    mfe_porcocupesp3: { visible: false },
    zepas: { visible: false },
  },

  gdoapoyo_baja_circular_vista: {
    anio_contable: { pk: true },
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    class_id: { maxFractionDigits: 0, groupDecimals: false },
  },
  gdoapoyo_t_circular_vista: {
    anio_contable: { pk: true },
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    codigo: { visible: true },
    codinst: { visible: false },
    codinst2: { visible: false },
    codinst3: { visible: false },
    codinst4: { visible: false },
    situa: { visible: false },
    situa2: { visible: false },
    situa3: { visible: false },
    situa4: { visible: false },
    modcons_apoyo_tipo: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: true },
    matricula2: { visible: true },
    matricula3: { visible: false },
    matricula4: { visible: false },
    nro_cod_cir: { visible: false },
    nro_ord_cir_1: { visible: false },
    nro_cod_cir_2: { visible: false },
    nro_ord_cir_2: { visible: false },
    nro_cod_cir_3: { visible: false },
    nro_ord_cir_3: { visible: false },
    nro_cod_cir_4: { visible: false },
    nro_ord_cir_4: { visible: false },
    nro_cod_proy: { visible: true },
    cod_proy: { visible: false },
    nro_apo_proy: { visible: true },
    provincia: { visible: true },
    municipio: { visible: true },
    ubicacion: { visible: false },
    accesib_apo: { visible: false },
    recubri: { visible: false },
    nro_anagrama: { visible: false },
    prot_anti_esca: { visible: false },
    tipo_tierra: { visible: false },
    cod_tip_amb: { visible: false },
    cod_tip_zona: { visible: false },
    supl1_apoy: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_puesta_serv: { visible: true },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    desc_sgmt: { visible: false },
    bdi_fecha: { visible: false },
    xmin_: { visible: false },
    ymin: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    xmax_: { visible: false },
    ymax: { visible: false },
    angulo: { visible: false },
    modcons_cruceta_tipo: { visible: false },
    long_utms: { visible: false },
    long_utms2: { visible: false },
    long_utms3: { visible: false },
    long_utms4: { visible: false },
    armor_rod: { visible: false },
    fibra: { visible: false },
    disp_cond: { visible: false },
    antivibradores: { visible: false },
    baliza: { visible: false },
    ancho_calle: { visible: false },
    nro_tot_empalme: { visible: false },
    autoval: { visible: false },
    nro_aisladores: { visible: true },
    contrapesos: { visible: false },
    descargadores: { visible: false },
    modcons_aislamiento_tipo: { visible: false },
    mat_apoy: { visible: true },
    fun_apoy: { visible: true },
    seccionadores: { visible: true },
    antivibradores2: { visible: false },
    antivibradores3: { visible: false },
    antivibradores4: { visible: false },
    baliza2: { visible: false },
    baliza3: { visible: false },
    baliza4: { visible: false },
    nro_tot_empalme2: { visible: false },
    nro_tot_empalme3: { visible: false },
    nro_tot_empalme4: { visible: false },
    nro_emp_grapa: { visible: false },
    nro_emp_grapa2: { visible: false },
    nro_emp_grapa3: { visible: false },
    nro_emp_grapa4: { visible: false },
    tip_mont: { visible: false },
    tip_mont2: { visible: false },
    tip_mont3: { visible: false },
    tip_mont4: { visible: false },
    nro_aisladores2: { visible: true },
    nro_aisladores3: { visible: false },
    nro_aisladores4: { visible: false },
    contrapesos2: { visible: false },
    contrapesos3: { visible: false },
    contrapesos4: { visible: false },
    descargadores2: { visible: false },
    descargadores3: { visible: false },
    descargadores4: { visible: false },
    grapa_con: { visible: false },
    grapa_con2: { visible: false },
    grapa_con3: { visible: false },
    grapa_con4: { visible: false },
    grapa_cab: { visible: false },
    grapa_cab2: { visible: false },
    grapa_cab3: { visible: false },
    grapa_cab4: { visible: false },
    modcons_aislamiento_tipo2: { visible: false },
    modcons_aislamiento_tipo3: { visible: false },
    modcons_aislamiento_tipo4: { visible: false },
    cod_fab: { visible: false },
    des_mod: { visible: false },
    cod_fab_cru: { visible: false },
    des_mod_cru: { visible: false },
    attr_posn: { visible: false },
    cod_proy_v10: { visible: true },
    codinst_v10: { visible: true },
    codinst2_v10: { visible: true },
    codinst3_v10: { visible: false },
    codinst4_v10: { visible: false },
    modcons_aislamiento_tipo_v10: { visible: false },
    modcons_aislamiento_tipo2_v10: { visible: false },
    modcons_aislamiento_tipo3_v10: { visible: false },
    modcons_aislamiento_tipo4_v10: { visible: false },
    modcons_apoyo_tipo_v10: { visible: false },
    modcons_cruceta_tipo_v10: { visible: false },
    situa_v10: { visible: true },
    situa2_v10: { visible: true },
    situa3_v10: { visible: false },
    situa4_v10: { visible: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    mat_nodo: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    con_etiq: { visible: false },
    codigo_etiquetacion: { visible: false },
    fecha_etiq: { visible: false },
    mfe_def: { visible: false },
    mfe_nomformarb: { visible: false },
    mfe_esp1: { visible: false },
    mfe_porcocupesp1: { visible: false },
    mfe_esp2: { visible: false },
    mfe_porcocupesp2: { visible: false },
    mfe_esp3: { visible: false },
    mfe_porcocupesp3: { visible: false },
    zepas: { visible: false },
    tipocadena_c1: { visible: false },
    tipocadena_c2: { visible: false },
    tipocadena_c3: { visible: false },
    tipocadena_c4: { visible: false },
    matasilador_c1: { visible: false },
    matasilador_c2: { visible: false },
    matasilador_c3: { visible: false },
    matasilador_c4: { visible: false },
    modasilador_c1: { visible: false },
    modasilador_c2: { visible: false },
    modasilador_c3: { visible: false },
    modasilador_c4: { visible: false },
    frecuentado: { visible: false },
    cimentaciones: { visible: false },
    tipo_antiguo: { visible: false },
    tipo_ciment: { visible: false },
    empalmes_c1: { visible: false },
    empalmes_c2: { visible: false },
    empalmes_c3: { visible: false },
    empalmes_c4: { visible: false },
    botellatermmodelo: { visible: false },
    botellatermfabricante: { visible: false },
    tensorescorredera: { visible: false },
    cajacrossbonding: { visible: false },
    cajasdescargadores: { visible: false },
    codigo_proyecto: { visible: true },
    inversion: { visible: true },
  },
  gdotramo_circular_vista: {
    cod_tramo: { pk: true },
    longitud_planta: {
      visible: true,
      editable: true,
    },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    propiedad: { visible: true },
    tipo_t: { visible: true },
    longi: { visible: true },
    tip_cond_1: { visible: true },
    tip_cond_2: { visible: true },
    no_cond_fase: { visible: true, editable: true },
    tip_canal: { visible: true },
    r: { visible: true, minFractionDigits: 6, maxFractionDigits: 6, groupDecimals: false },
    x: { visible: true, maxFractionDigits: 6, minFractionDigits: 6, groupDecimals: false },
    impendancia: { visible: true },
    codinst: { visible: false },
    modcons1: { visible: false },
    modcons2: { visible: false },
    matricula: { visible: true },
    fabricante: { visible: false },
    est_nor: { visible: true },
    fecha_pta_ser: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    desarrollo: { visible: false },
    estructura: { visible: false },
    centro_res: { visible: false },
    fecha_ces: { visible: true },
    expediente_ind: { visible: true },
    con_etiq: { visible: false },
    ten_nom: { visible: true },
    etiqueta: { visible: true },
    nombre: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    attr_posn: { visible: false },
    estado_cesion: { visible: true },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    modcons1_v10: { visible: true },
    modcons2_v10: { visible: true },
    ten_construccion: { visible: true },
    expediente: { visible: true },
    f_conv_resar: { visible: false },
    f_exp_proy: { visible: true },
    f_ult_rev: { visible: false },
    reg_aplic: { visible: false },
    tip_zona: { visible: false },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    punta: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    tipo_conductor: { visible: true },
    tip_edicion: { visible: true },
    longitud_real: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    expediente_o: { visible: true },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    codigo_tramo_unico: { visible: true, editable: true },
    longi_sgt: { visible: true },
    resistencia_estimada: { maxFractionDigits: 6 },
    reactancia_estimada: { maxFractionDigits: 6 },
    longitud_real_actualizada: { visible: true, editable: true },
    tipo: { visible: true, editable: true },
    denominacion_conductor: { visible: true, editable: true },
    tension_circular: { visible: true, editable: true },
    tension_inventario: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    codigo_tramo_historico: { visible: true, editable: true },
    observaciones: { visible: true, editable: true },
  },
  gdotramo_baja_circular_vista: {
    anio_contable: { visible: true, pk: true },
    longitud_planta: {
      visible: true,
      editable: true,
    },
    cod_tramo: { pk: true },
    codigo: { visible: true },
    tension: { visible: true },
    longitud: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    tipo: { visible: true, editable: true },
    t_soporte_anclaje: { visible: true, editable: true },
    seccion: { visible: true },
    expediente: { visible: true },
    longitud_real: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    n_fases: { visible: true },
    fechaser: { visible: true },
    codigo_tramo_unico: { visible: true },
    fecha_aps: { visible: true },
    seccion_declarada: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    resistencia: { maxFractionDigits: 6 },
    reactancia: { maxFractionDigits: 6 },
    longitud_real_actualizada: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    matricula: { visible: false },
    codinst: { visible: false },
    situa1: { visible: false },
    situa2: { visible: false },
    conductor: { visible: false },
    tipo_bdi: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: false },
    situa1_v10: { visible: false },
    situa2_v10: { visible: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    fecha_convenio: { visible: false },
    dur_convenio: { visible: false },
    nat_conductor: { visible: false },
    abierto_puntas_v10: { visible: false },
    modcons_v10: { visible: false },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    punta: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    propiedad: { visible: false },
    tip_edicion: { visible: false },
    expediente_o: { visible: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    longi_sgt: { visible: false },
    codigo_aps: { visible: false },
    comentarios: { visible: false },
    expediente_2: { visible: false },
    cod_prov_mun_ine_inicio: { visible: false },
    cod_prov_mun_ine_fin: { visible: false },
    prov_ine_ini: { visible: false },
    prov_ine_fin: { visible: false },
    municipio_ine_ini: { visible: false },
    municipio_ine_fin: { visible: false },
    grarep_30: { visible: false },
    numero_conductores: { visible: false },
    punto_frontera: { visible: false },
    class_id_bdr: { visible: false },
    declarar_circular: {
      visible: false,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    validaciones_internas: { visible: false },
    ediciones: { visible: false, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    codigo_tramo_historico: { visible: true, editable: true },
    observaciones: { visible: true, editable: true },
  },
  gdotramo_t_circular_vista: {
    cod_tramo: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    observaciones: {
      editable: true,
      fieldType: 'text',
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    origen: { visible: false },
    destino: { visible: false },
    modcons_cab_ais: { visible: false },
    modcons_cab_des: { visible: false },
    modcons_cab_tie: { visible: false },
    modcons_terminal_ori: { visible: false },
    modcons_terminal_des: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: true },
    denom: { visible: false },
    nro_cod_cir: { visible: false },
    nro_cod_proy: { visible: true },
    clase_tramo: { visible: true },
    estado: { visible: true },
    tipo_fun: { visible: true },
    tipo: { visible: true, editable: true },
    long_aprox: { visible: true },
    long_utms: { visible: true },
    cod_niv_tens: { visible: true },
    niv_tens_serv: { visible: true, editable: true },
    capacidad: { visible: false },
    autoinduc: { visible: false },
    int_max_tr: { visible: true },
    pot_max_tr: { visible: true },
    reactan: { visible: true, maxFractionDigits: 6 },
    resis: { visible: true, maxFractionDigits: 6 },
    suscep: { visible: true },
    tens_dis: { visible: false },
    tens_ais: { visible: true },
    nro_cable_tierra: { visible: false },
    nro_cable_fib_opt: { visible: false },
    nro_conduc_fase: { visible: true, editable: true },
    cod_fib_opt: { visible: false },
    cod_fab_fib_opt: { visible: false },
    nro_empalmes: { visible: false },
    nro_terminales_ori: { visible: false },
    nro_terminales_des: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    desc_sgmt: { visible: true },
    bdi_fecha: { visible: true },
    simbolo: { visible: false },
    grarep: { visible: false },
    longitud: {
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: true,
    },
    longitud_planta: {
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: true,
    },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    cod_exp_sgd: { visible: true },
    tipo_canal: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    destino_v10: { visible: true },
    modcons_arqueta_v10: { visible: false },
    modcons_cab_ais_v10: { visible: true },
    modcons_cab_des_v10: { visible: true },
    modcons_cab_tie_v10: { visible: false },
    modcons_terminal_des_v10: { visible: false },
    modcons_terminal_ori_v10: { visible: false },
    origen_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    reactan_ho: { visible: true },
    resis_ho: { visible: true },
    fecha_convenio: { visible: false },
    longitud_acta: { visible: true },
    cal_resist: { visible: false },
    segmento_pss: { visible: false },
    disp_cond: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    apm_tipo: { visible: true },
    n_exp_industria: { visible: true },
    apm_fecha: { visible: true },
    apm_titular: { visible: true },
    tipo_conductor: { visible: true },
    tension_explotacion: { visible: true },
    tip_edicion: { visible: true },
    cod_fab_fib_opt2: { visible: false },
    cod_fab_fib_opt3: { visible: false },
    cod_fib_opt2: { visible: false },
    cod_fib_opt3: { visible: false },
    nro_cable_fib_opt2: { visible: false },
    nro_cable_fib_opt3: { visible: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    codigo_tramo_unico: { visible: true, editable: true },
    codigo_proyecto: { visible: true },
    inversion: { visible: true },
    reactancia_estimada: { maxFractionDigits: 6 },
    resistencia_estimada: { maxFractionDigits: 6 },
    reactancia_real: { maxFractionDigits: 6 },
    resistencia_real: { maxFractionDigits: 6 },
    denominacion_conductor: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    codigo_tramo_historico: { visible: true, editable: true },
  },
  gdoempalme_circular_vista: {
    cod_empalme: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0 },
    tramo: { visible: false },
    canalizacion: { visible: false },
    texto: { visible: true },
    class_memb: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    xmin: { visible: false },
    ymin: { visible: false },
    xmax: { visible: false },
    ymax: { visible: false },
    angulo: { visible: false },
    attr_posn: { visible: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    canalizacion_v10: { visible: true },
    tramo_v10: { visible: true },
    expediente: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    fechaser: { visible: true },
    tip_edicion: { visible: true },
    fabricante: { visible: false },
    modelo: { visible: false },
    ano_fab: { visible: false },
    geohash: { visible: false },
  },
  gdointerr_circular_vista: {
    cod_elem_proteccion: { pk: true },
    emf_modelado_tramo: { visible: true, editable: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    est_nor: { visible: true },
    teleman: { visible: true },
    sectierra: { visible: false },
    ccfusible: { visible: false },
    releter: { visible: false },
    bobina: { visible: false },
    modcons: { visible: false },
    fabricante: { visible: false },
    modelo: { visible: false },
    anofab: { visible: true },
    fechaser: { visible: true },
    codinst: { visible: false },
    descripcion: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    posicion: { visible: false },
    principal: { visible: false },
    desarrollo: { visible: false },
    niv_tension: { visible: false },
    matricula: { visible: false },
    esquema: { visible: false },
    pasofalta: { visible: false },
    int_fus: { visible: false },
    con_etiq: { visible: false },
    mat_pu_m: { visible: false },
    tip_sec: { visible: true },
    alimentador: { visible: false },
    codigo_etiquetacio: { visible: false },
    telemando_instalado: { visible: true, editable: true },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    mat_apoyo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    posicion_v10: { visible: true, editable: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    desc_sgmt: { visible: false },
    modcons_v10: { visible: false },
    via: { visible: false },
    comentarios: { visible: false },
    num_serie: { visible: true },
    expediente: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    logica: { visible: true },
    propiedad: { visible: true },
    fecha_etiq: { visible: false },
    fase_tierra: { visible: false },
    fase_fase: { visible: false },
    longi: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    tipo_elemento: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdointerr_t_circular_vista: {
    cod_elem_proteccion: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: false },
    modcons: { visible: false },
    cod_fase: { visible: true },
    nro_fab: { visible: true },
    estado: { visible: true },
    niv_tens_serv: { visible: true },
    cod_niv_tens: { visible: true },
    int_nom_serv: { visible: true },
    int_nom_sim: { visible: true },
    pot_cor_nom: { visible: false },
    tipo_encap: { visible: false },
    cant_accion: { visible: false },
    ciclo_oper: { visible: false },
    cod_tip_ace: { visible: false },
    trasductor: { visible: false },
    tipo_tras: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    instalacion: { visible: true },
    cod_agente_ext: { visible: false },
    tipo_mando: { visible: false },
    cod_fab_mando: { visible: false },
    cod_mod_mando: { visible: false },
    nro_fab_mando: { visible: false },
    ten_man_mando: { visible: false },
    ten_mot_mando: { visible: false },
    desc_sgmt: { visible: true },
    bdi_fecha: { visible: true },
    posicion: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    modcons_v10: { visible: true },
    posicion_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    peso_ext: { visible: false },
    ubicacion: { visible: false },
    propiedad: { visible: true },
    nr_cam_fase: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_nor: { visible: false },
    element_scada: { visible: false },
    codigo_ansi: { visible: false },
    carreton_extr: { visible: false },
  },
  gdosecci_circular_vista: {
    cod_elem_proteccion: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    est_nor: { visible: true },
    tip_sec: { visible: true },
    modcons: { visible: false },
    fabricante: { visible: false },
    modelo: { visible: false },
    anofab: { visible: true },
    fechaser: { visible: true },
    codinst: { visible: false },
    descripcion: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    posicion: { visible: false },
    principal: { visible: false },
    desarrollo: { visible: false },
    niv_tension: { visible: false },
    matricula: { visible: false },
    esquema: { visible: false },
    con_etiq: { visible: false },
    mat_pu_m: { visible: true },
    alimentador: { visible: false },
    codigo_etiquetacion: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    mat_apoyo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    posicion_v10: { visible: true, editable: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    desc_sgmt: { visible: false },
    modcons_v10: { visible: false },
    num_serie: { visible: true },
    comentarios: { visible: false },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    propiedad: { visible: true },
    longi: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    fecha_etiq: { visible: false },
    logica_autosecci: { visible: true },
    tipo_elemento: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    emf_modelado_tramo: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdosecci_t_circular_vista: {
    cod_elem_proteccion: { pk: true },
    emf_modelado_tramo: { visible: true, editable: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: false },
    modcons: { visible: false },
    cod_fase: { visible: true },
    nro_fab: { visible: true },
    tipo_fun: { visible: true },
    estado: { visible: true },
    cod_niv_tens: { visible: true },
    int_nom_serv: { visible: true },
    linea_fuga: { visible: false },
    nro_polos: { visible: false },
    equipo_pat: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    instalacion: { visible: true },
    lin_tipo_mando: { visible: false },
    lin_cod_fab_mando: { visible: false },
    lin_cod_mod_mando: { visible: false },
    pat_tipo_mando: { visible: false },
    pat_cod_fab_mando: { visible: false },
    pat_cod_mod_mando: { visible: false },
    ten_man_mando: { visible: false },
    ten_mot_mando: { visible: false },
    desc_sgmt: { visible: false },
    bdi_fecha: { visible: false },
    posicion: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    tipo_cons: { visible: true },
    aislamiento: { visible: true },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    modcons_v10: { visible: true },
    posicion_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    int_nom_fus: { visible: false },
    mec_man: { visible: false },
    cod_sector: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_nor: { visible: true },
    element_scada: { visible: false },
    codigo_ansi: { visible: false },
    tipo_elemento: { visible: true, editable: true },
    niv_tens_serv: { visible: true, editable: true, maxFractionDigits: 0, groupDecimals: false },
    telemando: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdodisyun_circular_vista: {
    cod_elem_proteccion: { pk: true },
    emf_modelado_tramo: { visible: true, editable: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    est_nor: { visible: true },
    modcons: { visible: false },
    anofab: { visible: true },
    fechaser: { visible: true },
    codinst: { visible: false },
    descripcion: { visible: false },
    modelo: { visible: false },
    teleman: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    posicion: { visible: false },
    principal: { visible: false },
    desarrollo: { visible: false },
    matricula: { visible: false },
    esquema: { visible: false },
    con_etiq: { visible: false },
    mat_pu_m: { visible: false },
    tip_sec: { visible: false },
    alimentador: { visible: false },
    codigo_etiquetacion: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    mat_apoyo: { visible: false },
    cod_fase: { visible: true },
    nro_fab: { visible: true, editable: true },
    estado: { visible: true },
    niv_tens_serv: { visible: true, editable: true },
    int_nom_serv: { visible: true },
    int_nom_sim: { visible: false },
    pot_cor_nom: { visible: false },
    tipo_encap: { visible: false },
    ciclo_oper: { visible: false },
    cod_tip_ace: { visible: false },
    trasductor: { visible: false },
    tipo_tras: { visible: false },
    instalacion: { visible: true },
    cod_agente_ext: { visible: false },
    tipo_mando: { visible: false },
    cod_fab_mando: { visible: false },
    cod_mod_mando: { visible: false },
    nro_fab_mando: { visible: false },
    ten_man_mando: { visible: false },
    ten_mot_mando: { visible: false },
    cant_accion: { visible: false },
    antiguedad: { visible: true },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    posicion_v10: { visible: true, editable: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    cod_niv_tens: { visible: true },
    desc_sgmt: { visible: true },
    peso_ext: { visible: false },
    modcons_v10: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    propiedad: { visible: true },
    longi: { visible: true, editable: true },
    element_scada: { visible: false },
    codigo_ansi: { visible: false },
    carreton_extr: { visible: false },
    cod_nudo_ini: { visible: true, editable: true },
    cod_nudo_fin: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdopasofalta_circular_vista: {
    cod_elem_proteccion: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    fabricante: { visible: false },
    codinst: { visible: false },
    desarrollo: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    modelo: { visible: false },
    con_etiq: { visible: false },
    matricula: { visible: false },
    prefijo: { visible: false },
    flota: { visible: false },
    equipo: { visible: false },
    trunking: { visible: true },
    simbolo: { visible: false },
    grarep: { visible: false },
    xmin_: { visible: false },
    ymin: { visible: false },
    xmax_: { visible: false },
    ymax: { visible: false },
    angulo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    ano_pta_serv: { visible: true },
    revision: { visible: false },
    comunicado: { visible: false },
    rango_ajus_ff: { visible: false },
    rango_ajus_ft: { visible: false },
    valor_ajus_ft: { visible: false },
    valor_ajus_ff: { visible: false },
    f_ult_rev: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
  },
  gdotraforeg_circular_vista: {
    cod_tramo: { pk: true },
    cod_tramo_mt_asociado: { visible: true, editable: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    fabricante: { visible: false },
    modelo: { visible: false },
    funcionamiento: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    posicion: { visible: false },
    matricula: { visible: false },
    nombre: { visible: true },
    tipo_conex: { visible: false },
    tens_sec: { visible: true },
    pot_nom: { visible: true },
    tip_control: { visible: false },
    modcons: { visible: false },
    mat_pu_m: { visible: true },
    codigo_etiquetacion: { visible: false },
    con_etiq: { visible: false },
    descripcion: { visible: false },
    principal: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false, groupDecimals: false },
    y_min: { visible: false, groupDecimals: false },
    x_max: { visible: false, groupDecimals: false },
    y_max: { visible: false, groupDecimals: false },
    angulo: { visible: false },
    mat_apoyo: { visible: false },
    attr_posn: { visible: false },
    codinst_v10: { visible: true },
    posicion_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    modcons_v10: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    fecha_etiq: { visible: false },
    tens_pri: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    intensidad: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdoct_circular_vista: {
    cod_ct: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    matricula: { visible: true },
    cgh: { visible: true },
    numero: { visible: true },
    nombre: { visible: true },
    propiedad: { visible: true },
    centro: { visible: false },
    fechaser: { visible: true },
    pot_pot: { visible: true },
    ten_ser_m: { visible: true, editable: true },
    tip_edif: { visible: true },
    acceso: { visible: false },
    modcons: { visible: false },
    tip_const: { visible: false },
    maquina: { visible: false },
    superficie: { visible: false },
    alto: { visible: false },
    dim_maq_largo: { visible: false },
    dim_maq_ancho: { visible: false },
    no_trafo: { visible: true },
    tipo_centro: { visible: true, editable: true },
    ins_origen: { visible: false },
    no_clien: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    tip_mun: { visible: false },
    desarrollo: { visible: false },
    principal: { visible: false },
    teleman: { visible: true },
    nis: { visible: false },
    fabricante: { visible: false },
    pot_cont: { visible: true },
    esquema: { visible: false },
    pot_1: { visible: false },
    pot_2: { visible: false },
    pot_12: { visible: false },
    pot_cont1: { visible: false },
    pot_cont2: { visible: false },
    fecha_ces: { visible: true },
    expediente: { visible: true },
    con_etiq: { visible: false },
    matricula_ant: { visible: false },
    matricula_nueva: { visible: false },
    alimentacion: { visible: true },
    cgh_seg: { visible: false },
    tipo_seg: { visible: false },
    barrio: { visible: false },
    codigo_etiquetacion: { visible: false },
    telemando_activado: { visible: true },
    en_esq_dxf: { visible: false },
    no_vip: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false, numericFormat: false },
    y_min: { visible: false, numericFormat: false },
    x_max: { visible: false, numericFormat: false },
    y_max: { visible: false, numericFormat: false },
    angulo: { visible: false },
    comentarios: { visible: true },
    attr_posn: { visible: false },
    estado_cesion: { visible: true },
    referencia: { visible: false },
    seg_boe: { visible: false },
    nro_exp_proy: { visible: false },
    nro_tar_nocturnas: { visible: false },
    ins_origen_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    ct_cd: { visible: true },
    fecha_convenio: { visible: false },
    autotrans: { visible: true },
    distrito: { visible: false },
    modcons_v10: { visible: false },
    seg_cal_ind: { visible: false },
    gen_fotov: { visible: true },
    pot_nom_fotov: { visible: true },
    cgh_2: { visible: false },
    f_exp_proy: { visible: false },
    telegestion: { visible: true },
    bloq_rev: { visible: false },
    f_ult_rev: { visible: false },
    reg_aplic: { visible: false },
    tip_zona: { visible: false },
    reventa: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    comunic_fecha: { visible: false },
    comunic_fabri: { visible: false },
    comunic_modelo: { visible: false },
    comunic_tipo: { visible: false },
    obs_sme: { visible: false },
    cod_postal_car: { visible: false },
    telegestion_cont: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    ins_origen_codigo: { visible: false },
    confinados: { visible: false },
    fecha_cargarbt: { visible: false },
    num_sum_gen_bt: { visible: false },
    num_sum_gen_mt: { visible: false },
    pot_gen_conc_bt: { visible: false },
    pot_gen_conc_mt: { visible: false },
    tip_edicion: { visible: true },
    fecha_etiq: { visible: false },
    dir_refcat: { visible: false },
    cod_norma_pob: { visible: false },
    cod_norma_via: { visible: false },
    numero_via: { visible: false },
    anexo_via: { visible: false },
    direccion_norma: { visible: false },
    cups: { visible: false },
    user_mod_unif: { visible: false },
    fecha_mod_unif: { visible: false },
    alim_seg: { visible: false },
    sensor_inund: { visible: false },
    sensor_temp: { visible: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    re_geom: { visible: false },
    re_clima: { visible: false },
    re_atmo: { visible: false },
    re_rec: { visible: false },
    equiufinet: { visible: false },
    pot_tot: { visible: true, editable: true },
    parametros_validaciones: { visible: true },
    tipo: { visible: true, editable: true },
    tension_inventario: { visible: true, editable: true },
    num_max_maquinas: { visible: true, editable: true },
    provincia: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    cod_nudo: { visible: true, editable: true },
    coordx: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    num_celdas: { visible: false, editable: true, maxFractionDigits: 0, groupDecimals: false },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdotrafo_mb_circular_vista: {
    cod_maquina: { pk: true },
    identificador_regulatorio: {
      visible: true,
      editable: true,
    },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    modcons: { visible: false },
    fabricante: { visible: false },
    modelo: { visible: false },
    noserie: { visible: true },
    anofab: { visible: true },
    fechaser: { visible: true },
    codinst: { visible: false },
    descripcion: { visible: false },
    tip_pasat: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    matricula: { visible: true },
    desarrollo: { visible: false },
    termometro: { visible: false },
    sonda_termica: { visible: false },
    esquema: { visible: false },
    rel_con_1: { visible: false },
    rel_con_21: { visible: false },
    rel_con_22: { visible: false },
    nat_puen: { visible: false },
    tip_con_ten: { visible: false },
    dep_exp: { visible: false },
    con_etiq: { visible: false },
    mat_celda: { visible: false },
    propiedad: { visible: true },
    comentarios: { visible: true },
    rel_con_21_new: { visible: false },
    rel_con_22_new: { visible: false },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    modcons_v10: { visible: false },
    clase: { visible: true },
    num_cond_p: { visible: false },
    tens_corto: { visible: false },
    modcons_material_v10: { visible: false },
    dielectrico: { visible: false },
    per_vacio: { visible: true },
    per_carga: { visible: true },
    gru_conexion: { visible: false },
    mat_devanado: { visible: false },
    f_ult_rev: { visible: false },
    expediente: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    gct_fabric: { visible: false },
    gct_modelo: { visible: false },
    alar_disfa: { visible: false },
    alar_dismo: { visible: false },
    alar_tempfa: { visible: false },
    alar_tempmo: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    revisar_bdi: { visible: false },
    tip_edicion: { visible: true },
    bdi_descripcion: { visible: true },
    pot_nom: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },
  gdocelda_circular_vista: {
    cod_posicion: { pk: true },
    identificador_regulatorio: {
      visible: true,
      editable: true,
    },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    situa: { visible: false },
    tipfun: { visible: true, editable: true },
    tip_puente: { visible: false },
    mat_puente: { visible: false },
    seccion: { visible: false },
    modcons: { visible: false },
    fabricante: { visible: false },
    modelo: { visible: false },
    anofab: { visible: true },
    fechaser: { visible: true },
    codinst: { visible: false },
    descripcion: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    matricula: { visible: false },
    con_etiq: { visible: false },
    tipo_celda: { visible: true },
    posicion: { visible: false },
    codigo_etiquetacion: { visible: false },
    codinst_v10: { visible: true },
    posicion_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    modcons_v10: { visible: false },
    en_servicio: { visible: true },
    num_cond_p: { visible: false },
    comentarios: { visible: false },
    num_serie: { visible: true },
    modcons_material_v10: { visible: false },
    enerbob: { visible: false },
    f_ult_rev: { visible: false },
    expediente: { visible: true },
    fecha_dis: { visible: false },
    resp_encargo: { visible: false },
    obs_sme: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    tip_edicion: { visible: true },
    bdi_descripcion: { visible: true },
    fecha_etiq: { visible: false },
    peso: { visible: false },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },
  gdoposicion_circular_vista: {
    cod_posicion: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: true },
    modcons: { visible: false },
    modcons_aco_capa: { visible: false },
    denom_pos: { visible: true },
    nro_pos: { visible: false },
    tipo_fun: { visible: true },
    construccion: { visible: true },
    estado: { visible: true },
    instalacion: { visible: true },
    tipo_cons_pos: { visible: true },
    ano_pta_serv: { visible: true },
    fecha_acta: { visible: true },
    fecha_proy: { visible: true },
    nom_proy: { visible: false },
    nro_exp_proy: { visible: false },
    tipo_acta: { visible: true },
    ano_acr_mle: { visible: false },
    ano_rec_mle: { visible: false },
    cod_tipo_mle: { visible: false },
    cod_esq_mle: { visible: false },
    aco_cap_lin: { visible: false },
    cod_niv_tens: { visible: true },
    niv_tens_serv: { visible: true, editable: true },
    int_nom_serv: { visible: true },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    nro_fab: { visible: true, editable: true },
    tipo_encap: { visible: false },
    mat_emb: { visible: false },
    sec_emb: { visible: false },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    cod_doc_conv: { visible: false },
    empresa_conv: { visible: false },
    fecha_conv: { visible: false },
    desc_sgmt: { visible: true },
    bdi_fecha: { visible: false },
    cod_ree_1: { visible: false },
    cod_ree_2: { visible: false },
    codigo_etiquetacion: { visible: false },
    con_etiq: { visible: false },
    fondo_i: { visible: false },
    fondo_t: { visible: false },
    fondo_q: { visible: false },
    fondo_p: { visible: false },
    tipo_ais: { visible: false },
    peso_ais: { visible: false },
    punto_frontera: { visible: true },
    codinst_v10: { visible: true },
    modcons_v10: { visible: false },
    modcons_aco_capa_v10: { visible: false },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    tip_edicion: { visible: true },
    fecha_etiq: { visible: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    codigo_proyecto: { visible: true },
    inversion: { visible: true },
    parametros_validaciones: { visible: true },
    tipo_const_pos_asim: { visible: true, editable: true },
    tipo_func_pos: { visible: true, editable: true },
    tension_inventario: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    tipfun: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdoparque_t_circular_vista: {
    cod_parque: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: true },
    nro_parque: { visible: false },
    tipo_cons_tip: { visible: true },
    configuracion: { visible: true },
    esq_con_mle: { visible: false },
    instalacion: { visible: true },
    tipo_teleman: { visible: false },
    cod_niv_tens: { visible: true },
    int_cc_emb: { visible: false },
    int_nom_emb: { visible: false },
    cod_neutro: { visible: false },
    cod_mat_aer: { visible: false },
    cod_mat_sub: { visible: false },
    cod_mat_sup: { visible: false },
    seccion_aer: { visible: false },
    seccion_sub: { visible: false },
    seccion_sup: { visible: false },
    cant_parr: { visible: false },
    mod_parr: { visible: false },
    cod_fab_parr: { visible: false },
    tipo_cons_pa: { visible: false },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    ano_pta_serv: { visible: true },
    fecha_acta: { visible: true },
    fecha_proy: { visible: false },
    nombre_proy: { visible: false },
    nro_exp_proy: { visible: false },
    tipo_acta: { visible: false },
    cod_doc_conv: { visible: false },
    empresa_conv: { visible: false },
    fecha_conv: { visible: false },
    desc_sgmt: { visible: true },
    bdi_fecha: { visible: false },
    codinst_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    equip_mov_desc: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    n_exp_industria: { visible: false },
    user_mod_unif: { visible: false },
    fecha_mod_unif: { visible: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    codigo_proyecto: { visible: true },
    inversion: { visible: true },
    tipo_constructivo: { visible: true, editable: true },
    configuracion_traducido: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    niv_tens_serv: { visible: true, editable: true, maxFractionDigits: 0, groupDecimals: false },
    ediciones: { visible: true, editable: true },
  },
  gdoautotrafo_m_circular_vista: {
    anio_contable: { pk: true },
    cod_maquina: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    potencia_nominal: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
  },
  gdotrafo_pot_circular_vista: {
    cod_maquina: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    situa: { visible: false },
    situa1: { visible: false },
    situa2: { visible: false },
    situa2_2: { visible: false },
    situa3: { visible: false },
    situa4: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: false },
    nro_orden: { visible: false },
    nro_fab: { visible: true },
    cod_fase: { visible: true },
    estado: { visible: true },
    ano_acr_mle: { visible: false },
    ano_rec_mle: { visible: false },
    neutro_tie_at: { visible: false },
    neutro_tie_bt: { visible: false },
    neutro_tie_bt2: { visible: false },
    tipo_refrig: { visible: false },
    onan_onaf_rad: { visible: false },
    onan_onaf_fab_rad: { visible: false },
    onan_onaf_mod_rad: { visible: false },
    onan_onaf_peso_rad: { visible: false },
    onan_onaf_sup_rad: { visible: false },
    onan_onaf_tipo_val: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    cod_fab: { visible: false },
    cod_mod: { visible: false },
    autotrans: { visible: true },
    instalacion: { visible: true },
    tipo_cons_tip: { visible: false },
    tip_cuba: { visible: false },
    relac_pot: { visible: true },
    pot_nm_bt1: { visible: true },
    pot_nm_bt2: { visible: true },
    pot_nm_ter: { visible: true },
    pot_nsr_at: { visible: false },
    pot_nsr_bt1: { visible: false },
    pot_nsr_bt2: { visible: false },
    pot_nsr_ter: { visible: false },
    relac_transform: { visible: true },
    gc_at_bt1: { visible: false },
    gc_at_bt2: { visible: false },
    gc_at_ter: { visible: false },
    gc_bt1_ter: { visible: false },
    gc_bt2_ter: { visible: false },
    marg_reg_at: { visible: false },
    marg_reg_bt1: { visible: false },
    marg_reg_bt2: { visible: false },
    reg_car: { visible: false },
    reg_vac_at: { visible: false },
    reg_vac_bt1: { visible: false },
    reg_vac_bt2: { visible: false },
    reg_vac_ter: { visible: false },
    cod_tip_die: { visible: false },
    ia_reg_cub: { visible: false },
    uso_ter: { visible: false },
    niv_ais_une: { visible: false },
    fun_alt_tot: { visible: false },
    fun_anc_tot: { visible: false },
    fun_lar_tot: { visible: false },
    tra_alt: { visible: false },
    tra_anc: { visible: false },
    tra_lar: { visible: false },
    anc_via_lon: { visible: false },
    anc_via_tra: { visible: false },
    peso_ins: { visible: false },
    peso_caj_car: { visible: false },
    peso_par_act: { visible: false },
    peso_bar_acc: { visible: false },
    peso_rad: { visible: false },
    peso_nyd: { visible: false },
    peso_des: { visible: false },
    peso_trans: { visible: false },
    peso_tot: { visible: false },
    aceite_peso: { visible: false },
    desc_sgmt: { visible: false },
    bdi_fecha: { visible: false },
    cod_niv_tens_esq: { visible: false },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    situa1_v10: { visible: true },
    situa2_v10: { visible: true },
    situa2_2_v10: { visible: false },
    situa3_v10: { visible: true },
    situa4_v10: { visible: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    marg_reg_at_b: { visible: false },
    marg_reg_bt1_b: { visible: true },
    marg_reg_bt2_b: { visible: true },
    ten_at_bt1: { visible: false },
    ten_at_bt2: { visible: false },
    ten_at_ter: { visible: false },
    ten_bt1_ter: { visible: false },
    ten_bt2_ter: { visible: false },
    ten_at_bt1_bt2: { visible: false },
    perd_cobre: { visible: true },
    perd_hierro: { visible: true },
    perd_cobre_bt2: { visible: true },
    perd_cobre_ter: { visible: true },
    perd_cobre_bt1_bt2: { visible: true },
    perd_cobre_bt2_ter: { visible: true },
    perd_cobre_at_bt1_bt2: { visible: true },
    perd_cobre_bt1_ter: { visible: true },
    ten_bt1_bt2: { visible: false },
    int_vacio: { visible: false },
    ten_esc_alt_at: { visible: false },
    ten_esc_alt_bt1: { visible: false },
    ten_esc_alt_bt2: { visible: false },
    ten_esc_baj_at: { visible: false },
    ten_esc_baj_bt1: { visible: false },
    ten_esc_baj_bt2: { visible: false },
    ten_esc_cen_at: { visible: false },
    ten_esc_cen_bt1: { visible: false },
    ten_esc_cen_bt2: { visible: false },
    num_esc_at: { visible: false },
    num_esc_bt1: { visible: false },
    num_esc_bt2: { visible: false },
    ten_nom_at: { visible: true },
    ten_nom_bt1: { visible: true },
    ten_nom_bt2: { visible: true },
    ten_nom_ter: { visible: true },
    ten_esc_cen_ter: { visible: false },
    rel_transform_norm: { visible: true },
    equip_mov_desc: { visible: false },
    sum_aceite: { visible: false },
    adit_aceite: { visible: false },
    nomb_aceite: { visible: false },
    reg_fabric: { visible: false },
    reg_ttipo: { visible: false },
    cod_zona: { visible: false },
    nivel_op: { visible: false },
    nivel_mag: { visible: false },
    hydran: { visible: false },
    hydran_nro_serie: { visible: false },
    hydran_rst: { visible: false },
    hydran_rst_ns: { visible: false },
    eq_refr_tipo: { visible: false },
    eq_refr_nro: { visible: false },
    eq_refr_pot_eq: { visible: false },
    eq_refr_bom: { visible: false },
    eq_refr_pot_bom: { visible: false },
    temp_arranq1: { visible: false },
    temp_arranq2: { visible: false },
    rele_cuba_fab: { visible: false },
    rele_cuba_mod: { visible: false },
    rele_bornas_fab: { visible: false },
    rele_bornas_cant: { visible: false },
    rele_bornas_mod: { visible: false },
    rele_gases_fab: { visible: false },
    rele_gases_cant: { visible: false },
    rele_gases_mod: { visible: false },
    imag_term_fab: { visible: false },
    imag_term_cant: { visible: false },
    imag_term_mod: { visible: false },
    term_aceite_fab: { visible: false },
    term_aceite_cant: { visible: false },
    term_aceite_mod: { visible: false },
    imag_term_n1: { visible: false },
    imag_term_n2: { visible: false },
    term_aceite_n1: { visible: false },
    term_aceite_n2: { visible: false },
    sobrepresion_cuba: { visible: false },
    sobrepresion_bornas: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    apm_tipo: { visible: false },
    n_exp_industria: { visible: true },
    apm_fecha: { visible: true, groupDecimals: false },
    apm_titular: { visible: true },
    apm_p: { visible: false },
    codigo_proyecto: { visible: true },
    inversion: { visible: true },
    ten_at_max_bt1: { visible: false },
    ten_at_min_bt1: { visible: false },
    ten_at_max_bt2: { visible: false },
    ten_at_min_bt2: { visible: false },
    parametros_validaciones: { visible: true },
    pertenece_cia: { visible: true, editable: true },
    tension_parque_alta: {
      visible: true,
      maxFractionDigits: 0,
      groupDecimals: false,
      editable: true,
    },
    tension_parque_baja_secundario1: {
      visible: true,
      maxFractionDigits: 0,
      groupDecimals: false,
      editable: true,
    },
    pot_nm_at: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    potencia: { visible: true, maxFractionDigits: 0, groupDecimals: false, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdobia_condens_circular_vista: {
    cod_condensador: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    codinst: { visible: false },
    coordx: { groupDecimals: false },
    coordy: { groupDecimals: false },
    coordz: { groupDecimals: false },
    situa: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: false },
    nro_orden: { visible: false },
    cod_fab: { visible: true },
    cod_mod: { visible: false },
    cod_fab_fusib: { visible: false },
    cod_mod_fusib: { visible: false },
    estado: { visible: true },
    instalacion: { visible: true },
    niv_tens_serv: { visible: true, editable: true },
    cod_niv_tens: { visible: true },
    tip_conex: { visible: false },
    pot_tot: { visible: true, editable: true },
    ten_nom_ais: { visible: true },
    nro_elems: { visible: true },
    prot_dif: { visible: false },
    cod_tip_die: { visible: false },
    cap_nom: { visible: false },
    pot_condens: { visible: true },
    calibre_fusib: { visible: false },
    cod_situa: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    desc_sgmt: { visible: true },
    bdi_fecha: { visible: false },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    reg_neutro: { visible: false },
    ind_amor: { visible: false },
    int_nom: { visible: false },
    cond_fab: { visible: false },
    cond_mod: { visible: false },
    cond_tens: { visible: false },
    cod_sector: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    pertenece_cia: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdosubestac_circular_vista: {
    cod_se: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    matricula: { visible: true },
    nombre: { visible: true },
    prop_1: { visible: true },
    prop_1_part: { visible: true },
    prop_2: { visible: true },
    prop_2_part: { visible: true },
    sup_almacen: { visible: false },
    sup_planta_1: { visible: false },
    sup_planta_2: { visible: false },
    sup_planta_3: { visible: false },
    sup_planta_4: { visible: false },
    sup_sot_1: { visible: false },
    sup_sot_2: { visible: false },
    sup_sot_3: { visible: false },
    sup_sot_4: { visible: false },
    sup_tot_cas: { visible: false },
    sup_tot_aja: { visible: false },
    sup_tot_par: { visible: false },
    sup_tot_prop: { visible: false },
    sup_tot_sup: { visible: false },
    sup_tot_viv: { visible: false },
    sup_trat_herb: { visible: false },
    fax: { visible: false },
    nro_emi_rad: { visible: false },
    tel_1_ctne: { visible: false },
    tel_2_ctne: { visible: false },
    tel_1_uf: { visible: false },
    tel_2_uf: { visible: false },
    ano_pta_serv: { visible: true },
    fecha_proy: { visible: true },
    nombre_proy: { visible: true },
    nro_exp_proy: { visible: true },
    cgh: { visible: false },
    cgh1: { visible: false },
    cgh2: { visible: false },
    cgh3: { visible: false },
    cgh4: { visible: false },
    cgh5: { visible: false },
    cgh6: { visible: false },
    cgh7: { visible: false },
    cod_postal: { visible: false },
    cod_autonom: { visible: false },
    centro: { visible: false },
    cod_zona: { visible: false },
    cod_sector: { visible: false },
    cedida: { visible: false },
    cedida_a: { visible: false },
    fecha_ces: { visible: false },
    acceso: { visible: false },
    pot_cont: { visible: false },
    pot_tot: { visible: true },
    parque_ten: { visible: false },
    tension1: { visible: true },
    tension2: { visible: true },
    num_sal: { visible: true },
    num_trf: { visible: false },
    no_clien: { visible: false },
    esquema: { visible: false },
    cuadromando: { visible: false },
    con_etiq: { visible: false },
    teq_incen: { visible: false },
    no_eq_incen: { visible: false },
    eq_intrus: { visible: false },
    ups: { visible: false },
    bdi_fecha: { visible: false },
    est_reg: { visible: false },
    desc_sgmt: { visible: true },
    en_modulo3d: { visible: false },
    cod_tipo: { visible: true },
    estado: { visible: true },
    instalacion: { visible: true },
    cuadro_control: { visible: false },
    num_arm_control: { visible: false },
    num_cuad_control: { visible: false },
    telectrl_at: { visible: false },
    telectrl_mt: { visible: false },
    cod_scada: { visible: false },
    cod_sie: { visible: false },
    tipo_acta: { visible: true },
    fecha_acta: { visible: true, numericFormat: false },
    cant_casetas: { visible: false },
    cant_viv: { visible: false },
    pte_grua_torr: { visible: false },
    torre_trafo: { visible: false },
    cie_alt_min: { visible: false },
    perimetro: { visible: false },
    ubic_parr: { visible: false },
    tipo_const: { visible: true },
    cant_parr: { visible: false },
    cod_doc_conv: { visible: false },
    empresa_conv: { visible: false },
    fecha_conv: { visible: false },
    en_esq_int: { visible: false },
    direccion: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min: { visible: false },
    y_min: { visible: false },
    x_max: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    tipo_regulacion: { visible: false },
    attr_posn: { visible: false },
    estado_cesion: { visible: false },
    referencia: { visible: true },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    desc_ela: { visible: false },
    cgh_2: { visible: false },
    tipo_propietario: { visible: true },
    reg_especial: { visible: true },
    mercado: { visible: false },
    submercado: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    num_sum_gen_bt: { visible: false },
    num_sum_gen_mt: { visible: false },
    pot_gen_conc_bt: { visible: false },
    pot_gen_conc_mt: { visible: false },
    apm_p: { visible: false },
    ref_cat: { visible: false },
    dir_refcat: { visible: false },
    cod_norma_pob: { visible: false },
    cod_norma_via: { visible: false },
    numero_via: { visible: false },
    anexo_via: { visible: false },
    direccion_norma: { visible: false },
    user_mod_unif: { visible: false },
    fecha_mod_unif: { visible: false, numericFormat: false },
    feciniresarcimiento: { visible: false },
    fecfinresarcimiento: { visible: false },
    recinto_esp: { visible: false },
    tipo_subestacion: { visible: true, editable: true },
    tension_primaria_ufd: {
      visible: true,
      editable: true,
      maxFractionDigits: 0,
      groupDecimals: false,
    },
    tension_secundaria_ufd: {
      visible: true,
      editable: true,
      maxFractionDigits: 0,
      groupDecimals: false,
    },
    potencia_trafos_asociados_ufd: {
      visible: true,
      editable: true,
      maxFractionDigits: 0,
      groupDecimals: false,
    },
    provincia: { visible: true, editable: true },
    municipio: { visible: true, editable: true },
    pertenece_cia: { visible: true, editable: true },
    coordx: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      editable: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    ediciones: { visible: true, editable: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
  },
  gdogenerador_circular_vista: {
    cod_se: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: true },
    cgh: { visible: false },
    matricula: { visible: true },
    nombre: { visible: true },
    fechaser: { visible: true },
    propiedad: { visible: true },
    cuadromando: { visible: false },
    desarrollo: { visible: false },
    esquema: { visible: false },
    centro: { visible: false },
    tipgen: { visible: false },
    telefono: { visible: false },
    ten_ser: { visible: true },
    tipo: { visible: true },
    pot_cont: { visible: false },
    pot_dem: { visible: false },
    pot_gener: { visible: false },
    con_etiq: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    acceso: { visible: false },
    user_cont: { visible: false },
    tel_cont: { visible: false },
    simbolo: { visible: false },
    grarep: { visible: false },
    x_min_: { visible: false },
    y_min: { visible: false },
    x_max_: { visible: false },
    y_max: { visible: false },
    angulo: { visible: false },
    cod_zona: { visible: false },
    cod_sector: { visible: false },
    no_clien: { visible: false },
    estado: { visible: true },
    rel_corto: { visible: false },
    react_sincrona: { visible: false },
    react_sinc_cua: { visible: false },
    react_transitoria: { visible: false },
    react_tran_cua: { visible: false },
    react_subt: { visible: false },
    react_subt_cua: { visible: false },
    react_inversa: { visible: false },
    react_homopolar: { visible: false },
    react_poitier: { visible: false },
    cte_vacio: { visible: false },
    cte_transitoria: { visible: false },
    cte_subtransitoria: { visible: false },
    tension: { visible: true },
    ten_gen: { visible: true },
    ten_con: { visible: true },
    nis: { visible: true },
    attr_posn: { visible: false },
    cod_doc_conv: { visible: false },
    empresa_conv: { visible: false },
    fecha_conv: { visible: false },
    referencia: { visible: false },
    onis_stat: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    onis_next: { visible: false },
    lin_ent: { visible: false },
    lin_ent2_v10: { visible: false },
    area_v10: { visible: false },
    cgh_2: { visible: false },
    onis_ver: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    ref_cat: { visible: true },
    dir_refcat: { visible: false },
    cod_norma_pob: { visible: false },
    cod_norma_via: { visible: false },
    numero_via: { visible: false },
    anexo_via: { visible: false },
    direccion_norma: { visible: false },
  },
  gdoacom_circular_vista: {
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
  },
  gdopuentam_circular_vista: {
    cod_elem_proteccion: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    fechaser: { numericFormat: false },
    fecha_etiq: { numericFormat: false },
    fecha_dis: { numericFormat: false },
    anio_contable: { visible: false, pk: true },
    class_id: { maxFractionDigits: 0, groupDecimals: false },
    cod_nudo_ini: { editable: true },
    cod_nudo_fin: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { editable: true },
  },
  gdotrafo_ssaa_circular_vista: {
    cod_maquina: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    anio_contable: { visible: false, pk: true },
    codigo: { visible: false },
    codinst: { visible: false },
    situa: { visible: false },
    class_id: { visible: false, maxFractionDigits: 0, groupDecimals: false },
    est_reg: { visible: false },
    matricula: { visible: false },
    cod_fab: { visible: false },
    nro_fab: { visible: true },
    tipo_cons_tsa: { visible: false },
    estado: { visible: true },
    instalacion: { visible: true },
    grupo_conex: { visible: false },
    perd_cobre: { visible: true },
    perd_hierro: { visible: true },
    pot_nom_at: { visible: true, editable: true },
    ten_cc_atbt: { visible: false },
    ten_nom_at: { visible: true },
    ten_nom_bt: { visible: true },
    tipo_refrig: { visible: false },
    margen_at: { visible: false },
    cod_tip_die: { visible: false },
    termometro: { visible: false },
    termostato: { visible: false },
    alto_tot: { visible: false },
    ancho_tot: { visible: false },
    largo_tot: { visible: false },
    peso_aceite: { visible: false },
    peso_tot: { visible: false },
    antiguedad: { visible: true },
    ano_fab: { visible: true },
    ano_pta_serv: { visible: true },
    nro_orden: { visible: false },
    ten_nom_bt1: { visible: false },
    ten_nom_bt2: { visible: false },
    int_nom_at: { visible: false },
    int_nom_bt1: { visible: false },
    int_nom_bt2: { visible: false },
    desc_sgmt: { visible: true },
    int_nom_bt: { visible: false },
    bdi_fecha: { visible: false },
    codinst_v10: { visible: true },
    situa_v10: { visible: true },
    onis_stat: { visible: false },
    onis_next: { visible: false },
    cod_sector: { visible: false },
    transf_ta: { visible: false },
    transf_tb: { visible: false },
    onis_ver: { visible: false },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },

  //INVENTARIO - OTROS
  conex_linea_circular_vista: {
    anio_contable: { order: 1 },
    nivel_tension: { order: 2 },
    cod_tramo: { order: 3 },
    id_entidad: { order: 4 },
    cod_nudo_ini: { order: 5 },
    cod_nudo_fin: { order: 6 },
    coordenada_origen: { order: 7 },
    coordenada_destino: { order: 8 },
    coordenadas_intermedias: { order: 9, delimiter: ';' },
  },

  //INVENTARIO - REGULATORIA
  gdotramo_t_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    cod_tramos_historicos_asociados: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    longitud: { minFractionDigits: 3, maxFractionDigits: 3 },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    apm: { editable: true, fieldType: 'text', maxLength: 250 },
    comentarios_apm: { editable: true, fieldType: 'text', maxLength: 250 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_baja: { visible: true, editable: true },
  },
  gdotramo_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    cod_tramos_historicos_asociados: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    longitud: { minFractionDigits: 3, maxFractionDigits: 3 },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    apm: { editable: true, fieldType: 'text', maxLength: 250 },
    comentarios_apm: { editable: true, fieldType: 'text', maxLength: 250 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
    identificador_baja: { visible: true, editable: true },
  },
  gdotramo_baja_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    longitud: { minFractionDigits: 3, maxFractionDigits: 3 },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    ediciones: { visible: true, editable: true },
    identificador_baja: { visible: true, editable: true },
  },

  gdotramoss: {
    r: { maxFractionDigits: 6 },
    x: { maxFractionDigits: 6 },
    impendancia: { maxFractionDigits: 6 },
  },

  //MAESTROS

  maestros_asociacion_nudos_provincias_mt_at: {
    anio_informacion: {
      pk: true,
    },
    cod_nudo: {
      pk: true,
    },
    coordx: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordz: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    tension: {
      pk: true,
      visible: true,
      minFractionDigits: 1,
      maxFractionDigits: 1,
      groupDecimals: false,
    },
  },

  maestros_asociacion_nudos_provincias_bt: {
    anio_informacion: {
      pk: true,
    },
    cod_nudo: {
      pk: true,
    },
    coordx: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordz: {
      pk: true,
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    tension: {
      pk: true,
      visible: true,
      minFractionDigits: 1,
      maxFractionDigits: 1,
      groupDecimals: false,
    },
  },

  maestros_arbol_jerarquico_componente_regulatoria_vista: {
    activo: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Si', name: 'Si' },
        { value: 'No', name: 'No' },
      ],
    },
    alias_rama: { pk: true },
    anio_contable: { pk: true },
    campo_componente: { pk: true },
    campo_entidad_regulatoria: { pk: true },
    class_id_entidad_regulatoria: { pk: true },
    comentario: { pk: true },
    componente: { pk: true },
    condicion_sobre_componente: { pk: true },
    entidad_regulatoria: { pk: true },
    nombre_tabla_componente: { pk: true },
    nombre_tabla_regulatoria: { pk: true },
  },

  maestros_circular_configuracion_vista: {
    anio_contable: {
      pk: true,
    },
    formulario: {
      pk: true,
    },
    fuente_de_datos: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'CARGAR ARCHIVO EXTERNO', name: 'CARGAR ARCHIVO EXTERNO' },
        { value: 'CALCULAR EN BDR', name: 'CALCULAR EN BDR' },
      ],
    },
  },

  maestros_sre_identificadores_modo_ingesta: {
    anio_contable: {
      pk: true,
    },
    fuente_de_datos: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'CARGAR ARCHIVO EXTERNO', name: 'CARGAR ARCHIVO EXTERNO' },
        { value: 'CALCULAR IGEA', name: 'CALCULAR IGEA' },
      ],
    },
  },

  maestros_formularios_inventario_configuracion_vista: {
    anio_informacion: {
      pk: true,
    },
    formulario: {
      pk: true,
    },
    fuente_de_datos: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'CARGAR ARCHIVO EXTERNO', name: 'CARGAR ARCHIVO EXTERNO' },
        { value: 'CALCULAR EN BDR', name: 'CALCULAR EN BDR' },
      ],
    },
    contraste_de_datos: {
      pk: true,
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'CARGAR ARCHIVO EXTERNO', name: 'CARGAR ARCHIVO EXTERNO' },
        { value: 'CALCULAR EN BDR', name: 'CALCULAR EN BDR' },
      ],
    },
  },

  maestros_parques_asociados_maquinas_f14: {
    anio_informacion: {
      pk: true,
    },
    COD_MAQUINA: {
      pk: true,
    },
  },

  maestros_subestaciones_repetidas_circular_vista: {
    anio_informacion: { pk: true },
    cod_se: { pk: true },
    se_repetida: { pk: true },
  },

  // FORMULARIOS

  f9at_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
  },
  f9bt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
  },
  f9mt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
  },

  f10at_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
    longitud: { editable: true, maxFractionDigits: 3 },
    longitud_planta: { maxFractionDigits: 3 },
    longitud_acta: { maxFractionDigits: 3 },
    resistencia: { maxFractionDigits: 6 },
    reactancia: { maxFractionDigits: 6 },
    tipo: { editable: true },
    conductor: { editable: true },
    tension: { editable: true },
    tension_construccion: { editable: true },
    codigo_tramo_unico: { editable: true },
    num_cond_fase: { editable: true },
    provincia: { editable: true },
    cod_nudo_ini: { editable: true },
    cod_nudo_fin: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },

  f10mt_vista: {
    anio_contable: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
    longitud_planta: { maxFractionDigits: 3 },
    longitud: { editable: true, maxFractionDigits: 3 },
    resistencia: { maxFractionDigits: 6 },
    reactancia: { maxFractionDigits: 6 },
    tipo: { editable: true },
    conductor: { editable: true },
    tension: { editable: true },
    tension_construccion: { editable: true },
    codigo_tramo_unico: { editable: true },
    num_cond_fase: { editable: true },
    provincia: { editable: true },
    cod_nudo_ini: { editable: true },
    cod_nudo_fin: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },

  f10bt_vista: {
    anio_contable: { visible: true, pk: true },
    cod_tramo: { visible: true, pk: true },
    longitud: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    longitud_planta: { maxFractionDigits: 3 },
    longitud_real: { maxFractionDigits: 3 },
    resistencia: { maxFractionDigits: 6 },
    reactancia: { maxFractionDigits: 6 },
  },

  f17: {
    resistencia: { maxFractionDigits: 6 },
    reactancia: { maxFractionDigits: 6 },
    potencia: { maxFractionDigits: 6 },
    step: { maxFractionDigits: 6 },
  },
  f1_vista: {
    anio_informacion: { visible: true, pk: true },
    nudo: { visible: true, pk: true },
    coordenada_x: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordenada_y: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f2a_vista: {
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f3_vista: {
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordz: {
      visible: true,
      groupDecimals: false,
    },
  },
  f4_vista: {
    coordx: {
      visible: true,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      groupDecimals: false,
    },
    coordz: {
      visible: true,
      groupDecimals: false,
    },
    pot_tot_solicitada: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f5_vista: {
    coordx: {
      visible: true,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      groupDecimals: false,
    },
    coordz: {
      visible: true,
      groupDecimals: false,
    },
    pot_solicitada: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f6_vista: {
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordz: {
      visible: true,
      groupDecimals: false,
    },
    pot_solicitada: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f7_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_gd: { visible: true, pk: true },
    coordx: {
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordz: {
      editable: true,
      groupDecimals: false,
    },
    cini: { visible: true },
    cod_dis: { visible: true },
    municipio_ine: { editable: true },
    provincia: { editable: true },
    conexion: { visible: true },
    tension: { editable: true },
    pot_solicitada: {
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    energia_act_anual: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    energia_react_anual: {
      visible: true,
      groupDecimals: false,
    },
    anio_previsto: { visible: true },
    tipo_provision: { editable: true },
    cod_estado: { visible: true },
    estado_prevision: { visible: true },
    observaciones: { editable: true },
    ref_catastral: { editable: true },
    cups: { visible: true },
    validaciones: { visible: true },
  },
  f11_vista: {
    anio_contable: { visible: true, pk: true },
    cod_ct: { visible: true, pk: true },
    cod_nudo: { editable: true, visible: true },
    tipo_centro: { editable: true },
    tipo: { editable: true },
    potencia: { editable: true },
    tension_alta: { editable: true },
    num_max_maquinas: { editable: true },
    num_celdas: { editable: true },
    provincia: { editable: true },
    municipio_ine: { editable: true },
    pertenece_cia: { editable: true },
    coordx: {
      visible: true,
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      editable: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    observaciones: {
      editable: true,
      fieldType: 'text',
    },
    ediciones: { visible: true, editable: true },
  },
  f12_vista: {
    observaciones: {
      editable: true,
      fieldType: 'text',
    },
    anio_contable: { visible: true, pk: true },
    cod_ct: { visible: true, pk: true },
    cod_maquina: { visible: true, pk: true },
    potencia: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
    anio_ps: { visible: true, editable: true },
  },
  f12bis_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_posicion: { visible: true, pk: true },
    tipfun: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
    anio_ps: { editable: true },
  },
  f13_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_se: { visible: true, pk: true },
    tipo: { editable: true },
    potencia_total_servicio: { editable: true },
    pertenece_cia: { editable: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
      editable: true,
      fieldType: 'text',
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
      editable: true,
      fieldType: 'text',
    },
    coordz: {
      visible: true,
      groupDecimals: false,
    },
    municipio_ine: {
      visible: true,
      editable: true,
      fieldType: 'text',
    },
    provincia: {
      visible: true,
      editable: true,
      fieldType: 'text',
    },
    ediciones: { visible: true, editable: true },
  },
  f13bis_vista: {
    anio_contable: { visible: true, pk: true },
    cod_parque: { visible: true, pk: true },
    tipo_constructivo: { editable: true },
    configuracion: { editable: true },
    tension: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },
  f13c_vista: {
    anio_contable: { visible: true, pk: true },
    cod_posicion: { visible: true, pk: true },
    tipo_const_pos_asim: { editable: true },
    tipo_func_pos: { editable: true },
    tension: { editable: true },
    pertenece_cia: { editable: true },
    nro_fab: { editable: true },
    anio_ps: { editable: true },
    ediciones: { visible: true, editable: true },
  },
  f14_vista: {
    anio_contable: { visible: true, pk: true },
    cod_maquina: { visible: true, pk: true },
    tension_parque_alta: { editable: true },
    tension_parque_baja: { editable: true },
    potencia: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },
  f15_vista: {
    anio_contable: { visible: true, pk: true },
    cod_elem_proteccion: { visible: true, pk: true },
    tipo_elemento: { editable: true },
    telemando_instalado: { editable: true },
    pertenece_cia: { editable: true },
    provincia: { editable: true },
    cod_nudo: { editable: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    ediciones: { visible: true, editable: true },
  },
  f16_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_condensador: { visible: true, pk: true },
    tension: { editable: true },
    potencia: { editable: true },
    pertenece_cia: { editable: true },
    ediciones: { visible: true, editable: true },
  },
  f17_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_regulador: { visible: true, pk: true },
    tension: { editable: true },
    intensidad: { editable: true },
    pertenece_cia: { editable: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    ediciones: { visible: true, editable: true },
  },
  f18_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_nudo: { visible: true, pk: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
  f18atmt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_nudo: { visible: true, pk: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
  },
  f18bt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_nudo: { visible: true, pk: true },
    coordx: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordy: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
  },
  f1_inventario_vista: {
    anio_informacion: {
      visible: true,
      pk: true,
    },
    identificador: {
      visible: true,
      pk: true,
    },
    financiado: {
      visible: true,
      editable: true,
      fieldType: 'text',
    },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    apm: { editable: true, fieldType: 'text', maxLength: 250 },
    comentarios_apm: { editable: true, fieldType: 'text', maxLength: 250 },
    ediciones: { visible: true, editable: true },
  },
  f2_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    ediciones: {
      visible: true,
      editable: true,
    },
    codigo_agrupador: {
      editable: true,
    },
  },
  f3_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    ediciones: { visible: true, editable: true },
  },
  f4_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    ediciones: { visible: true, editable: true },
  },
  f5_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    estado: { editable: true },
    denominacion: { editable: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    ediciones: { visible: true, editable: true },
  },
  f6_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    ediciones: { visible: true, editable: true },
  },
  f7_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    ediciones: { visible: true, editable: true },
  },
  f8_inventario_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    denominacion: { editable: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
      maxLength: 10,
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    clasif_mgi: { editable: true, fieldType: 'text', maxLength: 50 },
    ediciones: { visible: true, editable: true },
  },
  fa1_vista: {
    anio_informacion: { visible: true, pk: true },
    cups: { visible: true, pk: true },
    ct: { editable: true },
    subestacion: { editable: true },
    clave_acometida: { editable: true },
    nudo: { editable: true },
    coordenada_x: { editable: true },
    coordenada_y: { editable: true },
    coordenada_z: { editable: true },
    cnae: { editable: true },
    cod_tfa: { editable: true },
    municipio: { editable: true },
    provincia: { editable: true },
    zona: { editable: true },
    conexion: { editable: true },
    tension: { editable: true },
    estado_contrato: { editable: true },
    potencia_contratada: { editable: true },
    potencia_adscrita: { editable: true },
    energia_activa_consumida: { editable: true },
    energia_reactiva_consumida: { editable: true },
    autoconsumo: { editable: true },
    cini_equipo_medida: { editable: true },
    fecha_instalacion: { editable: true },
    lecturas: { editable: true },
    baja_suministro: { editable: true },
    cambio_titularidad: { editable: true },
    facturas_estimadas: { editable: true },
    facturas_total: { editable: true },
    cau: { editable: true },
    cod_auto: { editable: true },
    cod_generacion_auto: { editable: true },
    conexion_autoconsumo: { editable: true },
    energia_autoconsumida: { editable: true },
    energia_excedentaria: { editable: true },
    declarar: { editable: true },
    observaciones: { editable: true },
    distancia_ct: { editable: true },
    validaciones: { visible: true },
    ediciones: { visible: true },
  },

  fa2_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_gd: { visible: true, pk: true },
  },

  fa3_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_crecimiento: { visible: true, pk: true },
    observaciones: { editable: true },
  },

  fa4_vista: {
    anio_informacion: { visible: true, pk: true },
    cups: { visible: true, pk: true, editable: true },
    cini: { editable: true },
    identificador: { editable: true },
    validaciones: { visible: true },
  },

  fa5_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
    zona: { editable: true },
    tipo_frontera: { editable: true },
    tension: { editable: true },
    energia_activa_entrante: { editable: true },
    energia_activa_saliente: { editable: true },
    energia_reactiva_entrante: { editable: true },
    energia_reactiva_saliente: { editable: true },
    codigo_empresa: { editable: true },
    codigo_frontera_dt: { editable: true },
  },

  fb1_1at_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_segmento: { visible: true, pk: true },
  },
  fb1_1mt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_segmento: { visible: true, pk: true },
  },
  fb1_1bt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_segmento: { visible: true, pk: true },
  },
  fb1at_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_tramo: { visible: true, pk: true },
    operacion: { visible: true, editable: true },
  },
  fb1mt_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_tramo: { visible: true, pk: true },
    operacion: { visible: true, editable: true },
  },
  fb1bt_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_tramo: { visible: true, pk: true },
    operacion: { visible: true, editable: true },
  },
  fb2_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_ct: { visible: true, pk: true },
  },
  fb2_1_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_ct: { visible: true, pk: true },
  },
  fb2_2_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_ct: { visible: true, pk: true },
  },
  fb3_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_subestacion: { visible: true, pk: true },
  },
  fb3_1_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_subestacion: { visible: true, pk: true },
  },
  fb3_2_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_subestacion: { visible: true, pk: true },
  },
  fb3_3_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_subestacion: { visible: true, pk: true },
  },
  fb4_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_posicion: { visible: true, pk: true },
  },
  fb5_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_maquina: { visible: true, pk: true },
  },
  fb6_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador_em: { visible: true, pk: true },
  },
  fb7atmt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_nudo: { visible: true, pk: true },
  },
  fb7bt_vista: {
    anio_informacion: { visible: true, pk: true },
    cod_nudo: { visible: true, pk: true },
  },
  fb8_vista: {
    anio_informacion: { visible: true, pk: true },
    identificador: { visible: true, pk: true },
  },
  fb9_vista: {
    anio_informacion: { visible: true, pk: true },
    celda: { visible: true, pk: true },
  },
  /*
   * DECLARAR CIRCULAR
   */
  parques_depositos_estrategicos_ssaa_circular_vista: {
    anio_informacion: { pk: true },
    cod_se: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  subestaciones_deposito_repuestos_estrategicos_circular_vista: {
    anio_informacion: { pk: true },
    cod_se: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  tramos_at_doble_circuito_vista: {},

  gdointerr_baja_circular_vista: {
    anio_contable: { pk: true },
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  gdocuadro_baja_circular_vista: {
    anio_contable: { pk: true },
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  gdoautoval_circular_vista: {
    anio_contable: { pk: true },
    codigo_coordenadas: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  gdoctss_circular_vista: {
    anio_contable: { pk: true },
    cod_ct: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    parametros_validaciones: { visible: true },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    cod_nudo: { editable: true },
  },

  gdosecciss_circular_vista: {
    anio_contable: { pk: true },
    cod_elem_proteccion: { pk: true },
    emf_modelado_tramo: { visible: true, editable: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    identificador_regulatorio: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    cod_nudo_ini: { editable: true },
    cod_nudo_fin: { editable: true },
    posicion_v10: { visible: true, editable: true },
  },

  gdotramoss_circular_vista: {
    anio_contable: { pk: true },
    cod_tramo: { pk: true },
    declarar_circular: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    identificador_regulatorio: { visible: true, editable: true },
    longitud_planta: { visible: true, editable: true },
    anio_sre: { visible: true, editable: true },
    codigo_tramo_historico: { visible: true, editable: true },
    cod_nudo_ini: { editable: true },
    cod_nudo_fin: { editable: true },
  },

  gdoct_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    cod_historicos_asociados: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    apm: { editable: true, fieldType: 'text', maxLength: 250 },
    comentarios_apm: { editable: true, fieldType: 'text', maxLength: 250 },
    financiado: { editable: true },
    denominacion: { editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdobia_condens_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    financiado: { editable: true },
    denominacion: { editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdointerr_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    cod_historicos_asociados: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdotrafo_pot_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    apm_fecha: { editable: true, fieldType: 'text', maxLength: 10 },
    inversion_pi: { visible: true, editable: true, fieldType: 'text', maxLength: 220 },
    financiado: { visible: true, editable: true, fieldType: 'text' },
    denominacion: { visible: true, editable: true, fieldType: 'text' },
    ediciones: { visible: true, editable: true, fieldType: 'text' },
  },

  gdoposicion_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    fecha_puesta_servicio: { editable: true, fieldType: 'text', maxLength: 10 },
    apm_fecha: { editable: true, fieldType: 'text', maxLength: 10 },
  },

  gdotraforeg_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_maquinas: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    financiado: { visible: true, editable: true },
    denominacion: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdosecci_t_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdosecci_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    cod_historicos_asociados: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    modelo: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'I', name: 'I' },
        { value: 'M', name: 'M' },
        { value: 'D', name: 'D' },
        { value: 'E', name: 'E' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },

  gdosubestac_regulatoria_circular_vista: {
    anio_contable: { pk: true },
    identificador: { pk: true },
    fecha_aps: {
      editable: true,
      fieldType: 'text',
    },
    fecha_baja: {
      editable: true,
      fieldType: 'text',
    },
    tipo_alta: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'Real', name: 'Real' },
        { value: 'Depuración', name: 'Depuración' },
        { value: 'Cambio de propiedad', name: 'Cambio de propiedad' },
        { value: 'Sustitución de Conductor', name: 'Sustitución de Conductor' },
      ],
    },
    declarar_inventario: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
        { value: 'DIFERIDA', name: 'DIFERIDA' },
      ],
    },
    estado: {
      editable: true,
      fieldType: 'text',
      maxLength: 1,
    },
    observaciones: { editable: true, fieldType: 'text', maxLength: 4000 },
    financiado: { visible: true, editable: true },
    ediciones: { visible: true, editable: true },
  },

  inventario_otros_f1_zeus_vista: {
    anio_contable: { pk: true },
    cups: { pk: true },
    declarar: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  inventario_otros_f1_sgc_vista: {
    anio_informacion: { pk: true },
    cups: { pk: true },
    declarar: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  puntos_frontera_vista: {
    anio_informacion: { pk: true },
    cod_frontera: { pk: true },
    declarar: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },
  },

  sabana_inventario_otras_informacion_a2_f3_vista: {
    anio_contable: { pk: true },
    cod_gd: { pk: true },

    cod_nudo: {
      editable: true,
      fieldType: 'text',
    },
    coordx: {
      editable: true,
      fieldType: 'text',
    },
    coordy: {
      editable: true,
      fieldType: 'text',
    },
    cil: {
      editable: true,
      fieldType: 'text',
    },
    municipio_ine: {
      editable: true,
      fieldType: 'text',
    },
    provincia: {
      editable: true,
      fieldType: 'text',
    },
    tension: {
      editable: true,
      fieldType: 'text',
    },
    pot_isnta: {
      editable: true,
      fieldType: 'text',
    },
    observaciones: {
      editable: true,
      fieldType: 'text',
    },
    cups_contrato: {
      editable: true,
      fieldType: 'text',
    },
    cups_referencia: {
      editable: true,
      fieldType: 'text',
    },

    declarar: {
      editable: true,
      fieldType: 'select',
      optionValues: [
        { value: 'S', name: 'S' },
        { value: 'N', name: 'N' },
      ],
    },

    cod_nudo_insta: {
      editable: true,
      fieldType: 'text',
    },
    tipo_central: {
      editable: true,
      fieldType: 'text',
    },
  },

  inventario_otros_informe_prevision_demanda_suministro: {
    coordenada_x: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    coordenada_y: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
    potencia_solicitada: {
      visible: true,
      minFractionDigits: 3,
      maxFractionDigits: 3,
      groupDecimals: false,
    },
  },
};
