<div class="notifications-wrapper" (click)="toggleShowNotifications()">
  <bdr-icon class="notify-icon" icon="ico-notify"></bdr-icon>
  <span class="notify-badge" *ngIf="unreadNotifications.length > 0">{{
    unreadNotifications.length
    }}</span>
</div>

<div *ngIf="showNotifications" class="fixed-notifications-wrapper">
  <header class="header">
    <bdr-icon class="header__icon" icon="ico-notify"></bdr-icon>
    <span class="header__title">Notificaciones</span>
  </header>
  <div class="toolbar">
    <span class="toolbar__message">{{ unreadNotifications.length }} notificaci{{
      unreadNotifications.length === 1 ? 'ón nueva' : 'ones nuevas'
      }}</span>
    <span class="toolbar__action" (click)="deleteAll()">Borrar todas</span>
    <span class="toolbar__action" (click)="markAllAsRead()">Marcar como leídas</span>
  </div>

  <div class="notification-list">
    <div class="notification" [ngClass]="{ unread: !notification.read }" *ngFor="let notification of notifications"
      (click)="markAsRead(notification)">
      <div class="notification__type {{ notification.type }} animation-container-icon">
        <bdr-icon class="notification-icon animation-icon-pop" icon="ico-ok"
          *ngIf="notification.type === 'confirmation'"></bdr-icon>
        <bdr-icon class="notification-icon animation-icon-pop" icon="ico-alert" *ngIf="notification.type === 'warning'">
        </bdr-icon>
        <bdr-icon class="notification-icon animation-icon-pop" icon="ico-ko" *ngIf="notification.type === 'error'">
        </bdr-icon>
        <bdr-icon class="notification-icon animation-icon-pop" icon="info" *ngIf="notification.type === 'info'">
        </bdr-icon>
      </div>
      <span class="notification__message">{{ notification.message }}</span>
      <span *ngIf="notification.detail" class="notification__action" (click)="showDetail(notification)">Ver
        detalle</span>
      <span *ngIf="notification.action?.enable" class="notification__action"
        (click)="action(notification)">Actualizar</span>
    </div>
  </div>

  <footer class="footer">
    <span class="footer__message">{{ notifications.length }} notificaci{{ notifications.length === 1 ? 'ón' : 'ones' }}
      en la
      última sesión</span>
  </footer>
</div>

<div *ngIf="showNotifications" (click)="toggleShowNotifications()" class="fixed-notifications__overlay"></div>