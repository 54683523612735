<section class="body" style="width: 100%;">
  <div style="width: 100%; overflow-y: scroll; overflow-x: scroll;">
    <div style="max-height: 600px;">
      <ng-template #recursiveList let-splittedAppointments>
        <div *ngFor="let row of splittedAppointments">
          <div *ngIf="row.splitted_appointments.length > 0">
            <div>
              <table>
                <tr>
                  <td class="empty-operational"></td>
                  <td class="empty-operational"></td>
                  <td class='horizontal-th'>ID</td>
                  <td class='horizontal-th'>IMPORTE</td>
                  <td class='horizontal-th'>TIPO APUNTE</td>
                  <td class='horizontal-th'>CLASIFICACION INVERSION</td>


                  <td class='horizontal-th'>ID PADRE</td>
                  <td class='horizontal-th'>ID ORIGINAL</td>
                  <td class='horizontal-th'>FECHA</td>
                </tr>
                <tr>
                  <td class="operational">
                    <a class='link' (click)="switchVisible(row.id)">
                      <bdr-icon *ngIf='!visible(row.id)' class="icon" icon="ico-plus"></bdr-icon>
                      <bdr-icon *ngIf='visible(row.id)' class="icon" icon="ico-less"></bdr-icon>
                    </a>
                  </td>
                  <td class="operational">
                    <bdr-checkbox [(model)]="appointmentsFlatMap[row.id].selected"
                      (modelChange)="changeSelectedPartitions(row)">
                    </bdr-checkbox>
                  </td>
                  <td class='horizontal-td' [title]="row.id">{{row.id}}</td>
                  <td class='horizontal-td'>{{row.importe}}</td>
                  <td class='horizontal-td'>{{row.tipo_apunte}}</td>
                  <td class='horizontal-td'>{{row.clasificacion_inversion}}</td>
                  <td class='horizontal-td' [title]="row.id_padre">{{row.id_padre}}</td>
                  <td class='horizontal-td' [title]="row.id_original || row.id">{{row.id_original || row.id}}</td>
                  <td class='horizontal-td'>{{row.fecha}}</td>
                </tr>
              </table>
            </div>
            <span (click)="switchVisible(row.id)"></span>
          </div>

          <div *ngIf="visible(row.id)">
            <div class="inner-left-border" *ngFor="let appointment of row.splitted_appointments">
              <div *ngIf="appointment.splitted_appointments.length > 0">
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: row.splitted_appointments }">
                </ng-container>
              </div>
              <div>
                <table *ngIf="appointment.splitted_appointments.length === 0">
                  <tr>
                    <td class="empty-operational"></td>
                    <td class="empty-operational"></td>
                    <td class='horizontal-th'>ID</td>
                    <td class='horizontal-th'>IMPORTE</td>
                    <td class='horizontal-th'>TIPO APUNTE</td>
                    <td class='horizontal-th'>CLASIFICACION INVERSION</td>
                    <td class='horizontal-th'>ID PADRE</td>
                    <td class='horizontal-th'>ID ORIGINAL</td>
                    <td class='horizontal-th'>FECHA</td>
                  </tr>
                  <tr>
                    <td class="empty-operational"></td>
                    <td class="empty-operational"></td>
                    <td class='horizontal-td' [title]="appointment.id">{{appointment.id}}</td>
                    <td class='horizontal-td'>{{appointment.importe}}</td>
                    <td class='horizontal-td'>{{appointment.tipo_apunte}}</td>
                    <td class='horizontal-td'>{{appointment.clasificacion_inversion}}</td>
                    <td class='horizontal-td' [title]="appointment.id_padre">{{appointment.id_padre}}</td>
                    <td class='horizontal-td' [title]="appointment.id_original">{{appointment.id_original}}</td>
                    <td class='horizontal-td'>{{appointment.fecha}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: splittedAppointments}">
      </ng-container>
    </div>
  </div>
  <div class="pages">
    <span *ngFor="let page of pages" class="bottom-link link page-number" (click)="setPage(page)">
      {{page+1}}
    </span>
  </div>
</section>

<footer class="footer">
  <span *ngIf="!saving && hasValues" class="bottom-link link" (click)="save()">Revertir particiones seleccionadas</span>
  <span *ngIf="saving" class="saving">
    <bdr-icon icon="ico-save" size="16px"></bdr-icon> Guardando...
  </span>
</footer>

<bdr-splash *ngIf="loading" title="Cargando particiones" [descriptions]="['Espere, por favor']">
</bdr-splash>

<bdr-splash *ngIf="!loading && !hasValues" title="No se han encontrado datos" [icon]="true"
  [descriptions]="['Los incurridos seleccionados no pertenecen a ninguna partición o ésta no se puede revertir.']"
  (onClick)="close()">
</bdr-splash>