<div id=slickGridWrapper class="grid-wrapper main-grid" [ngClass]="{
    'not-main-grid-paginated': !externalData,
    'search-active': columnsSearchVisible,
    'bordered-grid': bordered
}">
  <angular-slickgrid class="angular-slickgrid" gridId="bdrGrid" #angularSlickGrid
    [columnDefinitions]="columnDefinitions" [gridOptions]="gridOptions" [dataset]="dataset"
    (onAngularGridCreated)="angularGridReady($event)" (onGridCreated)="gridReady($event)"
    (sgOnCellChange)="onCellChanged($event.detail.eventData, $event.detail.args)"
    (sgOnDblClick)="onCellDblClicked($event.detail.eventData, $event.detail.args)"
    (sgOnClick)="onCellClicked($event.detail.eventData, $event.detail.args)"
    (sgOnSelectedRowsChanged)="onSelectedRowsChanged($event.detail.eventData, $event.detail.args)">
  </angular-slickgrid>
  <bdr-splash *ngIf="showSplash" [icon]="splashIcon" [title]="splashTitle" [clickable]="showError"
    [descriptions]="splashDescriptions" (onClick)="reset()"></bdr-splash>
</div>


<bdr-pagination *ngIf="!externalData"
  [ngClass]="{ 'bordered-pagination': bordered, 'not-main-grid': bordered && notMainGrid  }" [subject]="subject"
  [totalRows]="totalRows" [dataLength]="dataset.length" [loadingMore]="loadingMore" (paginate)="paginate($event)"
  (scrollTop)="scrollTop()">
</bdr-pagination>