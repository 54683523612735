<section class="body">
  <h5> ELA: {{ elaDesc }} Importe: {{ elaAmount | localeCurrency }} | Importe a repartir: {{ incurred.importe |
    localeCurrency}} </h5>
  <table class="weigths-table">
    <tr class="grid-title">
      <th>Instalación</th>
      <th>Secuencial</th>
      <th>Clasificación final</th>
      <th>Comparativa</th>
      <th>VUR</th>
      <th>Unidades</th>
      <th>VTR</th>
      <th>Reparto original</th>
      <th>Porcentaje</th>
      <th></th>
    </tr>
    <tr *ngFor="let installation of installations">
      <td> {{ installation.instalacion }} </td>
      <td> {{ installation.secuencial }} </td>
      <td> {{ installation.clasificacion }} </td>
      <td> {{ installation.comparativa_inversiones }} </td>
      <td> {{ installation.vur || 0 | localeCurrency }} </td>
      <td> {{ installation.unidades }} </td>
      <td> {{ installation.vtr || 0 | localeCurrency }} </td>
      <td> {{ installation.reparto_original || 0 | localeNumber }} % </td>
      <td class="installations-input-wrapper">
        <bdr-input [horizontal]="true" type="number" [model]="installation.reparto" [minValue]="0" [maxValue]="100"
          (focusout)="registerWeight(installation, $event.target.value)"></bdr-input>
      </td>
      <td>%</td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td><span *ngIf="hasWeights()" class="link" (click)="deleteManualWeights()">Borrar todo</span></td>
      <td></td>
    </tr>

  </table>
  <p *ngIf="!!error" class="onehundred-percentage-error">Los pesos deben ser positivos y sumar 100</p>
</section>
<footer class="footer">
  <ng-container>
    <span class="link" (click)="back()">Volver</span>
    <span class="link" (click)="save()">Guardar</span>
  </ng-container>
</footer>