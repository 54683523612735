<bdr-icon class="qb-drag-handle drag-handler" icon="bullet" esir="true"></bdr-icon>
<ng-container *ngIf="!queryRule.reference">
  <div class="qb-grid-selector-container qb-control-wrapper" *ngIf="gridSelector">
    <bdr-input-search *ngIf="originItems.length > 1" [selectMode]="true" placeholder="Elegir tabla"
      [model]="queryRule.origin" [options]="originItems" (modelChange)="originChange($event)"
      [errorMsg]="originErrorMessage" [bottomError]="true">
    </bdr-input-search>
    <span *ngIf="originItems.length === 1" class="origin-name">{{schemaItems[queryRule.origin].caption}}</span>
  </div>

  <div class="qb-rule-filter-container qb-control-wrapper">
    <bdr-input-search [selectMode]="true" placeholder="Elegir campo" [model]="queryRule.field"
      [options]="currentSchemaItems" [disabled]="!fieldSelectorReady" (modelChange)="filterChange($event)"
      [selectMode]="true" [errorMsg]="queryRule.fieldError" [bottomError]="true">
    </bdr-input-search>
  </div>

  <div class="qb-operator-container qb-control-wrapper">
    <bdr-select bold="true" [model]="queryRule.operator" [options]="operatorsShown"
      (modelChange)="operatorChange($event)" [errorMsg]="queryRule.operatorError" [error]="!!queryRule.operatorError"
      [bottomError]="true"></bdr-select>
  </div>

  <div class="qb-value-container qb-control-wrapper" *ngIf="queryRule.field && queryRule.value !== null">
    <bdr-input *ngIf="!(queryRule.operator === 'in' || queryRule.operator === 'not_in')" [type]="inputType"
      [size]="'regular'" [model]="inputValueFormatted" (change)="valueChange($event)" [errorMsg]="queryRule.valueError"
      [error]="!!queryRule.valueError" [bottomError]="true">
    </bdr-input>
    <bdr-input-multiple class="input-multiple" *ngIf="queryRule.operator === 'in' || queryRule.operator === 'not_in'"
      [separator]="' '" [numeric]="inputType === 'number'" [grow]="false" [model]="multipleInputValue"
      (validationError)="handleInputMultipleValidationError($event)" (modelChange)="valueMultipleChange($event)"
      [errorMsg]="queryRule.valueError" [error]="!!queryRule.valueError" [bottomError]="true">
    </bdr-input-multiple>
    <div class="validation-error-message">
      <span *ngIf="valueError">{{valueErrorMessage}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="queryRule.reference">
  <bdr-row-drag title="{{ queryRule.value }}" [type]="type" [newEdit]=true (expandItemRequest)="expandItem()">
    {{ queryRule.value }}
  </bdr-row-drag>
</ng-container>

<bdr-icon class="qb-remove-condition" icon="ico-delete" (click)="removeCondition($event)"></bdr-icon>