<table mat-table [dataSource]="filteredInstallations" sticky>
  <!-- Dynamic Columns -->
  <ng-container *ngFor="let column of headerColumns; let i = index" [matColumnDef]="column.name" [sticky]="isSticky(column.name)">
    <th mat-header-cell *matHeaderCellDef  (click)="toggleFilters()" style="cursor: pointer">
      {{ column.label }}
    </th>
  
    <td mat-cell *matCellDef="let row" nowrap [ngClass]="{ 'mat-row-warning': installationWarning(row), 'mat-row-error': installationError(row)}">
      <ng-container *ngIf="row.filter">
        <mat-select
            [(ngModel)]="column.selected_value"
            (ngModelChange)="filterData($event, column.name)"
            [compareWith]="compareFn">
            <mat-option
              *ngFor="let option of filterFieldOptions(column.name)"
              [value]="option">
              {{ option }}
            </mat-option>
        </mat-select>
      </ng-container>

      <ng-container *ngIf="!row.filter">
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'input'">
            <div class="form-input" [ngClass]="{ editing: editableField.focused }">
              <input matInput #editableField="matInput" [(ngModel)]="row[column.name]"
                (ngModelChange)="handleEditInstallation($event, row.codigo_bdr_instalaciones, row.secuencial, column.name, row.codigo_obra)"
                [value]="row[column.name]" />
              <bdr-icon icon="ico-edit" size="10"></bdr-icon>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'select'">
            <mat-select
              *ngIf="getInstallationOptions(column.name, row.tipo_instalacion, row.comparativa_inversiones).length > 0"
              [(ngModel)]="row[column.name]"
              (ngModelChange)="handleEditInstallation($event, row.codigo_bdr_instalaciones, row.secuencial, column.name, row.codigo_obra, row.comparativa_inversiones)"
              [compareWith]="compareFn">
              <mat-option
                *ngFor="let option of getInstallationOptions(column.name, row.tipo_instalacion, row.comparativa_inversiones)"
                [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="column.name.includes('validaciones') && row[column.name]; else standard">
              <span *ngFor="let validations of row[column.name].split('|')" [title]="getValidationsName(validations)"
                style="margin-left:1px" class="validations fake-icon">
                {{ validations }}
              </span>
            </ng-container>
            <ng-template #standard>
              {{ row[column.name] }}
            </ng-template>
          </ng-container>

        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>