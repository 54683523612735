<div class="validations-summary">
    <div class="panel-header">
        <span class="title">
            <ng-content></ng-content>
        </span>
        <div class="panel-header-cancel">
            <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
        </div>
    </div>
    <div class="panel-body">
        <div class="tab-headers">
            <a
                class="tab-header"
                [ngClass]="{ selected: selectedTab === 'automatic' }"
                (click)="selectedTab = 'automatic'"
                >Automáticas</a
            >
            <!-- <a
          *ngIf="validationsScope === 'mtbt'"
          class="tab-header"
          [ngClass]="{
            selected: selectedTab === 'manual',
            disabled: !showManualData
          }"
          (click)="!!showManualData && (selectedTab = 'manual')"
          >Manuales</a
        > -->
        </div>
        <div class="tab-body automatic-validations" *ngIf="selectedTab === 'automatic'">
            <bdr-validations [origin]="origin"> </bdr-validations>
        </div>
        <!-- <div class="tab-body" *ngIf="showManualData && selectedTab === 'manual'">
        <div class="action-line">
          <a class="link text-header-link link-icon" (click)="createValidations()">
            <bdr-icon icon="ico-plus" class="icon-header"></bdr-icon>
            <span>Crear coherencia</span>
          </a>
        </div>
        <div class="validations-container">
          <bdr-row-drag
            *ngFor="let item of manualItems"
            [type]="'manualValidations'"
            [iconText]="item.id"
            [editable]="true"
            [iconClass]="manual - validations"
            [menu]="getMenu(item)"
            [ngClass]="{
              unclickable: item.editables.name || item.editables.description
            }"
            (click)="manualValidationsClickAction(item)"
            class="manual-validations"
          >
            <span class="validations-title" *ngIf="!item.editables.name">{{
              item.name
            }}</span>
            <div class="permutable-input" *ngIf="item.editables.name">
              <input [(ngModel)]="item.name" placeholder="Nombre de coherencia" />
            </div>
            <span
              class="validations-description"
              *ngIf="!item.editables.description"
              >{{ item.description }}</span
            >
            <div class="permutable-input extend">
              <textarea
                *ngIf="item.editables.description"
                [(ngModel)]="item.description"
                placeholder="Descripción"
              ></textarea>

              <div class="button-container" *ngIf="item.editables.name">
                <bdr-icon
                  class="icon-button"
                  (click)="removeManualItem(item)"
                  [esir]="true"
                  icon="cancel"
                  size="18px"
                ></bdr-icon>
                <bdr-icon
                  class="icon-button save"
                  (click)="saveValidations(item)"
                  [esir]="true"
                  icon="check"
                  size="18px"
                ></bdr-icon>
              </div>
            </div>

            <div class="error-messages" *ngIf="item.error">
              {{ item.errorMsg }}
            </div>
          </bdr-row-drag>
          <div class="no-data-panel" *ngIf="manualItems.length === 0">
            No se han encontrado coherencias manuales
          </div>
        </div>
      </div> -->
    </div>
</div>
