import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { DataApiService } from '../../../services/data-api/data-api.service';
import { MASTER_AMENDMENT_TYPES } from '../../../shared/interfaces/master-data.interface';
import { MastersService } from '../../../services/masters/masters.service';
import { DownloadsService } from '../../../services/downloads/downloads.service';
import { AsyncQueryResponse } from '../../../models/query-poller';
import { Subject } from 'rxjs';
import { MasterDataService } from '../../../master-data/services/master-data.service';


const COMPONENT_ID_FIELDS = {
  "gdoapoyo_t": "codigo_coordenadas",
  "gdoapoyo_baja": "codigo_coordenadas",
  "gdoapoyo": "codigo_coordenadas",
  "gdoautotrafo_m": "cod_maquina",
  "gdoautoval": "codigo_coordenadas",
  "gdoct": "cod_ct",
  "gdoctss": "cod_ct",
  "gdobia_condens": "cod_condensador",
  "gdocuadro_baja": "codigo_coordenadas",
  "gdodisyun": "cod_elem_proteccion",
  "gdoempalme": "cod_empalme",
  "gdogenerador": "cod_se",
  "gdointerr_t": "cod_elem_proteccion",
  "gdointerr_baja": "codigo_coordenadas",
  "gdointerr": "cod_elem_proteccion",
  "gdotrafo_mb": "cod_maquina",
  "gdotrafo_pot": "cod_maquina",
  "gdoparque_t": "cod_parque",
  "gdopasofalta": "cod_elem_proteccion",
  "gdocelda": "cod_posicion",
  "gdoposicion": "cod_posicion",
  "gdopuentam": "cod_elem_proteccion",
  "gdotraforeg": "cod_tramo",
  "gdosalidasbt": "codigo_coordenadas",
  "gdosecci_t": "cod_elem_proteccion",
  "gdosecci": "cod_elem_proteccion",
  "gdosecciss": "cod_elem_proteccion",
  "gdosubestac": "cod_se",
  "gdotrafo_ssaa": "cod_maquina",
  "gdotramo_t": "cod_tramo",
  "gdotramo_baja": "cod_tramo",
  "gdotramo": "cod_tramo",
  "gdotramoss": "cod_tramo"
}

@Component({
  selector: 'dialog-acinvestments-advancetions',
  templateUrl: './dialog-investments-advance.component.html',
  styleUrls: ['./dialog-investments-advance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogInvestmentsAdvance implements OnInit {
  @Input() id;
  @Input() selectedRows: any[];
  @Input() origin: string;

  @Input() registeredRows: any[];
  @Input() nonRegisteredRows: any[];

  @Input() joinedRegisteredRows: string;
  @Input() joinedNonRegisteredRows: string;

  componentIdField: string;
  retributiveYear: number;

  @Output() closeDialog: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCloseAction: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    private dataApiService: DataApiService,
    private mastersService: MastersService,
    private downloadsService: DownloadsService,
    private masterDataService: MasterDataService
  ) { }


  ngOnInit(): void {
    this.mastersService.getRetributiveYear().subscribe((year) => {
      this.retributiveYear = year;
    });

    this.componentIdField = COMPONENT_ID_FIELDS[this.origin];

    this.registeredRows = this.selectedRows.filter(c=>c.comparativa === 'Alta');
    this.joinedRegisteredRows = this.registeredRows.map(c=>c[this.componentIdField]).join(", ");

    this.nonRegisteredRows = this.selectedRows.filter(c=>c.comparativa !== 'Alta');
    this.joinedNonRegisteredRows = this.nonRegisteredRows.map(c=>c[this.componentIdField]).join(", ");

  }

  handleCloseDialog(): void {
    this.closeDialog.emit(this.id);
    this.onCloseAction.emit(this.id);
  }


  advanceInstallations() {
    let amendments = this.registeredRows.map(sr => {
      let componentId = '' + sr[this.componentIdField];

      return {
        table: 'maestros_adelanto_inversiones',
        amendment_type: MASTER_AMENDMENT_TYPES.UPSERT,
        primary_keys: {
          anio_informacion: this.retributiveYear - 1,
          anio_origen: this.retributiveYear,
          componente: this.origin,
          codigo_coordenadas: componentId,
          zona: componentId.substring(0, 2),
          codigo: componentId.substring(2, componentId.length)
        },

        new_values: {
          expediente_asociado_anio_retributivo: ''
        },
      };
    })

    this.dataApiService.putAsyncAmendments({amendments}).subscribe((response) => {
      let asyncId = response.async_job_id;

      this.downloadsService.addDownload({
        id: response.async_job_id,
        fileName: 'Adelantando instalaciones',
        url: '#',
        status: 'RUNNING',
      });

      let poller = this.dataApiService.jobQueryPoller(asyncId);
      poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
        poller.complete();
        this.downloadsService.assignData(pollerResponse, asyncId);
        new Subject<AsyncQueryResponse>().next(pollerResponse);
        this.downloadsService.downloadReady = true;
        this.masterDataService.unloadAmendment(this.retributiveYear - 1, 'maestros_adelanto_inversiones').subscribe();
        return;
      });
    });

  }

  
}
