<div class="data-panel" *ngIf="!loading && !error && keys(logs).length > 0">
  <table class="log-table" cellpadding="0">
    <thead>
      <tr>
        <th></th>
        <th>Fecha</th>
        <th>Usuario</th>
        <th>Columna</th>
        <th colspan="3">Modificación</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-year [ngForOf]="logs" let-logIndex="index">
        <ng-template ngFor let-month [ngForOf]="year.months" let-monthIndex="index">
          <ng-template ngFor let-item [ngForOf]="month.items" let-itemIndex="index">
            <ng-template ngFor let-field [ngForOf]="item.changes" let-changeIndex="index">
              <tr [ngClass]="{
                  'button-display': itemIndex === 0 && changeIndex === 0,
                  last: changeIndex === item.changes.length - 1
                }">
                <td [attr.rowspan]="month.rows" class="circle-button-cell"
                  [ngClass]="{ 'first-month': monthIndex === 0 }" *ngIf="itemIndex === 0 && changeIndex === 0">
                  <div class="month">
                    <span class="month-circle">
                      <bdr-button-circle active="true">{{
                        month.month
                        }}</bdr-button-circle>
                    </span>
                  </div>
                </td>
                <td>
                  <span *ngIf="!timeline">{{ item.displayDate }}</span><span *ngIf="timeline">{{ item.timelineDate
                    }}</span>
                </td>
                <td>
                  <span>{{ item.user }}</span>
                </td>
                <td>
                  <span>{{ field.title }}</span>
                </td>
                <td class="field-values">
                  <span>
                    <span class="field-old-value">{{ field.oldValue }} </span>
                  </span>
                </td>
                <td>
                  <span>
                    <span class="field-arrow">
                      <bdr-icon class="arrow-icon" icon="ico-next" size="13px"></bdr-icon>
                    </span>
                  </span>
                </td>
                <td>
                  <span><span class="field-new-value">{{ field.newValue }}</span>
                  </span>
                </td>
              </tr>
              <tr class="button-display year" [ngClass]="{ last: changeIndex === item.changes.length - 1 }" *ngIf="
                  monthIndex === year.months.length - 1 &&
                  itemIndex === month.items.length - 1 &&
                  changeIndex === item.changes.length - 1
                ">
                <td class="circle-button-cell">
                  <div class="month">
                    <span class="month-circle">
                      <bdr-button-circle class="year-button" [ngClass]="{ last: logIndex === logs.length - 1 }"
                        active="true">{{ year.year }}</bdr-button-circle>
                    </span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </tbody>
  </table>
</div>

<div class="no-data" *ngIf="logs && logs.length === 0">
  <p class="loading-title">No se han encontrado datos</p>
</div>
<div class="error" *ngIf="!loading && error">
  <p class="loading-title">{{ errorMessage }}</p>
</div>
<div class="loading" *ngIf="loading && !error">
  <div class="loading-wrapper" [ngClass]="{ 'loading-first-load': firstLoad }">
    <div class="vignette">
      <img src="/assets/images/loading-grid.gif" width="18" height="18" alt="Cargando" />
    </div>
    <p class="loading-title">Cargando</p>
  </div>
</div>