<bdr-select-pills [options]="pillsOptions" [model]="selectedGroup?.key" (modelChange)="changeSelectedGroup($event)"
  [tabs]="true">
</bdr-select-pills>
<div class="table-column-selector" *ngIf="selectedGroup?.key && selectedKeyObj">

  <bdr-select *ngIf="selectedGroup?.hideSelector !== true && scope !== 'investments' && selectedGroup?.key" [options]="selectedGroup.selectData"
    [model]="selectedKeyObj[selectedGroup.key]" (modelChange)="changeOption($event)" size="big"></bdr-select>

  <div class="option-list" *ngIf="scope === 'investments' && selectedGroup.selectData.length > 1">
    <div *ngFor="let option of selectedGroup.selectData; let i = index">
      <div class="option" (click)="changeOption(option.value)" [ngClass]="{ active: selectedKey === option.value }">
        {{ option.name }}
      </div>
      <span class="toolbar-actions-separator" [ngClass]="{ invisible: i == 0 }"></span>

    </div>
  </div>
  
  <div class="main-action-container" style="display: flex!important" [@slideInOutAnimation]="animationStateRowActions">
    <div *ngFor="let action of headerActions" class="action-container">

      <span class="toolbar-actions-button" *ngIf="action.type === 'investments_advance' && selectedRowsNumber > 0 && selectedGroup?.key === 'componente'" style="margin-left: 10px">
        <bdr-icon class="icon" size="18px" icon="ico-forward"></bdr-icon>
        <span class="link text-header-link" (click)="action.fire(selectedRows)">Adelanto de inversiones</span>
      </span>

      <span class="toolbar-actions-button" *ngIf="action.type === 'at_download_installations'" style="margin-left: 10px">
        <bdr-icon class="icon" size="18px" icon="ico-formularios"></bdr-icon>
        <span class="link text-header-link" (click)="action.fire($event)">Instalaciones</span>
      </span>
      <span class="toolbar-actions-button" *ngIf="action.type === 'investments_adjustment.validations'" style="margin-left: 10px">
        <bdr-icon class="icon" size="18px" icon="ico-formularios"></bdr-icon>
        <span class="link text-header-link" (click)="action.fire($event)">Validaciones 1 y 2</span>
      </span>
      <ng-container *ngIf="action.requireSelected && hasSelectedRows">
        <span class="toolbar-actions-button" *ngIf="action.type === 'actions'">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-actions"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">Acciones</span>
        </span>
        <span class="toolbar-actions-button" *ngIf="action.type === 'at_detail_filter'" style="margin-left: 10px">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <ng-container *ngIf="selectedRowsNumber <= action.maxNumber; else disabledAtDetailFilter">
            <span class="link text-header-link" (click)="action.fire($event)">Detalle Incurridos</span>
          </ng-container>
          <ng-template #disabledAtDetailFilter>
            <span class="link disabled text-header-link" [title]="action.title">Detalle Incurridos</span>
          </ng-template>
        </span>
        <span class="toolbar-actions-button" *ngIf="action.type === 'at_aggregate_transfer_filter'" style="margin-left: 10px">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <ng-container *ngIf="selectedRowsNumber <= action.maxNumber; else disabledAtAggregateTransferFilter">
            <span class="link text-header-link" (click)="action.fire($event)">Traspaso Agregado</span>
          </ng-container>
          <ng-template #disabledAtAggregateTransferFilter>
            <span class="link disabled text-header-link" [title]="action.title">Traspaso Agregado</span>
          </ng-template>
        </span>
      </ng-container>
      <ng-container *ngIf="action.requireFilter && appliedFilters.length > 0">
        <span class="toolbar-actions-button" *ngIf="action.type === 'mtbt_detail_filter'" style="margin-left: 10px">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">Mostrar Incurridos Filtro</span>
        </span>
        <span class="toolbar-actions-button" *ngIf="action.type === 'dispatchers_detail_filter'"
          style="margin-left: 10px">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">Mostrar Incurridos Filtro</span>
        </span>
        <span class="toolbar-actions-button" *ngIf="action.type === 'at_detail_filter'" style="margin-left: 10px">
          <bdr-icon class="icon icon-header" size="18px" icon="ico-filter"></bdr-icon>
          <span class="link text-header-link" (click)="action.fire($event)">Mostrar Incurridos Filtro</span>
        </span>
      </ng-container>
      <span class="toolbar-actions-button" *ngIf="action.type === 'auditory_alerts'" style="margin-left: 10px">
        <bdr-icon class="icon icon-header" size="18px" icon="ico-alert"></bdr-icon>
        <span class="link text-header-link" (click)="action.fire($event)">Mostrar alertas</span>
      </span>
      <span class="toolbar-actions-button" *ngIf="action.type === 'auditory_back'" style="margin-left: 10px">
        <bdr-icon class="icon icon-header" size="18px" icon="ico-left"></bdr-icon>
        <span class="link text-header-link" (click)="action.fire($event)">Volver al formulario</span>
      </span>
    </div>
  </div>

  <span *ngIf="hasSelectedRows" class="selected-number-info">{{ selectedRowsNumber}}
    seleccionado{{selectedRowsNumber>1?'s':''}}
  </span>
  
  <bdr-info-master-configuration *ngIf="infoMasterConfiguration" [infoMasterConfiguration]="infoMasterConfiguration">
  </bdr-info-master-configuration>

  <bdr-applied-filters *ngIf="!downloadsService.downloadNotification" [appliedFilters]="appliedFilters"
    (resetFilterRequest)="resetFilter($event)">
  </bdr-applied-filters>

  <bdr-create-delete-banner *ngIf="(showCreateDeleteBanner.showNewRows || showCreateDeleteBanner.showDeletesOrCopy)
      && scope === 'forms'
      && originKey !== 'auditoria' 
      && originKey !== 'cnmc' " [showCreateDeleteBanner]="showCreateDeleteBanner" (onCreateRow)="createRow($event)"
    (onDeleteRows)="deleteRows($event)" (onCopyRows)="copyRows($event)">
  </bdr-create-delete-banner>

  <div class="main-actions">
    <bdr-volumetries *ngIf="!downloadsService.downloadNotification" [resultsCount]="resultsCount">
    </bdr-volumetries>
    <bdr-header-notifications [text]="notificationTextByOrigin()" [saving]="saving" [editMode]="editMode"
      [isDisabled]="saveDisabled" [isDisabledReason]="saveDisabledReason" (onSaveChanges)="saveChanges($event)"
      (onCancelSave)="cancelActions()"
      [recurrentEditionCheckbox]="recurrentEditionCheckbox"
    >
    </bdr-header-notifications>
  </div>




</div>