<div class="sap-header">
  <div class="sap-header__filter">
    <bdr-input-filter [filters]="sap?.headers" (onSearch)="search = $event"></bdr-input-filter>

    <div class="sap-header__options">
      <div *ngIf="selectedRows.length > 0 && isEditable" class="sap-header__action-dialog"
        (click)="showSelectedActions()">
        <bdr-icon class="icon icon-header" icon="ico-actions" size="18px"> </bdr-icon>
        <span class="link text-header-link">
          Acciones
        </span>
      </div>
    </div>
  </div>

  <div class="sap-header__actions">
    <div class="sap-header__download" (click)="download()">
      <bdr-icon icon="ico-download"></bdr-icon> Descargar
    </div>

    <div class="sap-header__total">
      <span>{{ sap?.rows?.length }}</span>
      <p>Total registros</p>
    </div>
  </div>
</div>

<bdr-table #bdrTable [isEditable]="isEditable" [items]="sap?.rows | filter: search" [columns]="sap?.headers"
  selectableRows="true" (onSelected)="handleSelectedRows($event)"></bdr-table>

<bdr-splash *ngIf="isLoading" [title]="splashMessage" [descriptions]="['Espere, por favor']"></bdr-splash>