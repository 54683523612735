<bdr-side-panel *ngIf="scope !== 'validations' && isTabSelected"
  [ngClass]="{ borderWhite: !['inventory', 'forms', 'investments','installations'].includes(scope) }" [scope]="scope"
  [panels]="panels" [appliedFilters]="appliedFilters" [filters]="currentFilter ? [currentFilter.filter] : null"
  [headerActions]="gridProperties.headerActions" [exportData]="gridProperties.exportData"
  [visibleColumns]="visibleColumns" [visualizationPanelVisible]="visualizationDialogVisible"
  [massEditPanelVisible]="massEditPanelVisible" [columnsSearchVisible]="columnsSearchVisible" [allColumns]="allColumns"
  [rowsToEdit]="rows" [selectedRows]="selectedRows" [originTableName]="gridProperties.origin"
  [hasDeferredAppointments]="hasDeferredAppointments" [deferredAppointments]="deferredAppointments"
  [hasDeferredWorks]="hasDeferredWorks" [deferredWorks]="deferredWorks"
  [hasDeferredAppointmentsBuildings]="hasDeferredAppointmentsBuildings"
  [deferredAppointmentsBuildings]="deferredAppointmentsBuildings"
  [hasDeferredAppointmentsOffices]="hasDeferredAppointmentsOffices"
  [deferredAppointmentsOffices]="deferredAppointmentsOffices"
  [hasDeferredAppointmentsMeasuring]="hasDeferredAppointmentsMeasuring"
  [deferredAppointmentsMeasuring]="deferredAppointmentsMeasuring"
  (addFilterToFolderRequest)="addFilterToFolderDialog($event)" (showConfirm)="showConfirmAction($event)"
  (newItemRequest)="createFilterDialog($event)" (manualValidationsClick)="manualValidationsClick($event)"
  (onApplyFilter)="applyFilter($event)" (outputColumnSearch)="setColumnSearch($event)"
  (generateAction)="generateAction($event)"
  [disabledVisualization]="objectKeys(gridProperties.additionalSchemaData).length === 0">
</bdr-side-panel>
<aside class="draggable-dialogs-wrapper ">
  <bdr-dialog-list></bdr-dialog-list>
</aside>
<div class="grid-main" *ngIf="gridProperties">
  <bdr-grid-header 
    *ngIf="!['inventory', 'forms', 'investments', 'validations','installations'].includes(scope)"
    class="grid-header" [scope]="scope" [canColumnSearch]="gridProperties.columnSearch"
    (outputColumnSearch)="toggleColumnSearch()" [title]="title" [origin]="gridProperties.origin"
    [currentDataQuery]="currentDataQuery" [resultsCount]="resultsCount" [currentFilter]="currentFilter"
    [canEdit]="gridProperties.edit" [canGenerate]="gridProperties.generate"
    [canExport]="gridProperties.export && !grouping" [actionsMenuOptions]="actionsMenuOptions"
    [canManageFastFilters]="panels.filters || panels.issueFilters" [appliedFilters]="appliedFilters"
    [currentRequestParams]="gridProperties.currentRequestParams"
    [currentShowingRows]="gridProperties.resultsShowingCount" [headerActions]="gridProperties.headerActions"
    [selectedRows]="selectedRows" [changedColumns]="changedColumns" [editMode]="editChangedValues"
    [disabledVisualization]="objectKeys(gridProperties.additionalSchemaData).length === 0" [saving]="saving"
    (resetFilterRequest)="resetFilter($event)" (showExecutionPlanRequest)="showPlan($event)"
    (changeFastFiltersVisibility)="handleChangeFastFiltersVisibility($event)" (generateAction)="generateAction($event)"
    (onSaveChanges)="saveChanges($event)" (onCancelSave)="cancelActions()"
  >
  </bdr-grid-header>

  <div class="grid-subheader" *ngIf="subheaderSelector">
    <bdr-button-selectbox-selector [config]="subheaderSelector" [origin]="gridProperties.origin"
      [originKey]="gridProperties.key" [changedColumns]="changedColumns" [appliedFilters]="appliedFilters"
      [saving]="saving" [editMode]="editChangedValues" [resultsCount]="resultsCount" [infoMasterConfiguration]="infoMasterConfiguration"
      [headerActions]="gridProperties.headerActions" [selectedRows]="rows" [hasSelectedRows]="selectedRows" [selectedRowsNumber]="rows.length"
      [scope]="scope" [saveDisabled]='saveDisabled' [saveDisabledReason]='saveDisabledReason'
      [recurrentEditionCheckbox]="recurrentEditionCheckbox"
      [showCreateDeleteBanner]="showCreateDeleteBanner" (onSaveChanges)="saveChanges($event)" (onCancelSave)="cancelActions()"
      (onOpenColumnSelector)="openColumnSelector()" (onResetColumns)="resetSchemaColumns()"
      (resetFilterRequest)="resetFilter($event)" (onChangeTab)="handleChangedTabs($event)"
      (onCreateRow)="newRow($event)" (onDeleteRows)="delRows($event)" (onCopyRows)="cpRows($event)"
    ></bdr-button-selectbox-selector>
  </div>

  <bdr-hero-image *ngIf="!isTabSelected"></bdr-hero-image>

  <bdr-grid *ngIf="
        isTabSelected &&
        !grouping &&
        !isValidationsDetail &&
        (!!gridProperties.origin ||
          (!!gridProperties.externalData && gridProperties.externalData.length))
      " [voidRow]="gridProperties.voidRow" [showSaving]="saving" [showModalSaving]="showModalSaving"
    [savingType]="savingType" [reload]="gridProperties.reload" [bordered]="gridProperties.bordered"
    [origin]="gridProperties.origin" [originKey]="gridProperties.key" [originTitle]="gridProperties.title" [filterOrigin]="gridProperties.filterOrigin"
    [editingRows]="gridProperties.editingRows" [globalEditMode]="gridProperties.globalEditMode"
    [filters]="currentFilter ? [currentFilter.filter] : null" [filterType]="currentFilterType"
    [visibleColumns]="visibleColumns" [additionalSchemaData]="gridProperties.additionalSchemaData"
    [schemaScope]="gridProperties.schemaScope" [isTabSelected]="isTabSelected"
    [columnsSearchVisible]="columnsSearchVisible" [fastSearchConditions]="fastSearchConditions"
    [createNewRowObject]="createNewRowObject" [deleteRowsObject]="deleteRowsObject"
    [copyRowsObject]="copyRowsObject"
    [massEditionObject]="massEditionObject" (onDblClick)="dblClick($event)"
    (selectedRowsChange)="changeSelectedRows($event)" (onSchemaChange)="schemaChange($event)"
    (onGridEditChange)="gridEditChange($event)" (onCancelEdit)="cancelActions()"
    (onRequestParamsChange)="gridProperties.currentRequestParams = $event" (onTotalDataChange)="setResultsCount($event)"
    (onFastSearchChange)="fastSearch($event)" (sortChange)="sortChange($event)"
    (createDelete)="toggleCreateDeleteBanner($event)" (onCreateRows)="createRows($event)"
    (onDeleteRows)="deleteRows($event)" (onCopyRows)="copyRows($event)"
    (showModalConfirm)="showConfirmAction($event)" (onInfoMasterLoadChange)="setInfoMasterLoad($event)"
    >
  </bdr-grid>
</div>