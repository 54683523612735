<div *ngIf="auth.authenticated" class="main-header">
    <div class="toolbar">
        <div class="logo"><img class="logo-img" alt="UFD" src="/assets/images/ufd-logo.svg" /></div>
        <div class="toolbar-right">
            <bdr-retributive-year-selector></bdr-retributive-year-selector>

            <a
                *ngIf="!isMasterSelected"
                routerLink="settings/master-data"
                title="Configuración"
                class="spaced-icon-wrapper"
            >
                <bdr-icon icon="ico-settings"></bdr-icon>
            </a>

            <a
                (click)="showUnlockWorksDialog()"
                title="Desbloquear obras"
                class="spaced-icon-wrapper"
            >
                <bdr-icon icon="ico-unlock"></bdr-icon>
            </a>

            <a
                title="Recarga de sábanas"
                class="spaced-icon-wrapper"
                (click)="showSheetReloadDialog()"
            >
                <bdr-icon icon="ico-update"></bdr-icon>
            </a>

            <a routerLink="/" class="spaced-icon-wrapper">
                <bdr-icon icon="ico-home"></bdr-icon>
            </a>

            <div class="spaced-icon-wrapper">
                <bdr-notifications-wrapper></bdr-notifications-wrapper>
            </div>

            <bdr-user-box
                [version]="version.version"
                [profile]="userProfile"
                (logoutAction)="auth.logout()"
            ></bdr-user-box>
        </div>
    </div>
    <div class="header-menu">
        <bdr-menu *ngIf="!isMasterSelected"></bdr-menu>
    </div>
</div>
<div class="app-wrapper" [ngClass]="{ 'without-navbar': !auth.authenticated }">
    <router-outlet></router-outlet>
</div>

<div class="app-loading-overlay" *ngIf="currentTasks.length > 0">
    <div class="loading-wrapper" [ngClass]="{ 'loading-first-load': firstLoad }">
        <bdr-icon
            class="closeLoadingOverlay"
            icon="ico-ko"
            (click)="closeLoadingOverlay()"
        ></bdr-icon>
        <div class="vignette">
            <img src="/assets/images/loading-grid.gif" width="48" height="44" alt="Error" />
        </div>
        <p class="loading-title">Se están realizando tareas globales</p>
        <p class="loading-desc">Esta operación podría durar algunos minutos</p>
        <bdr-task-load-list [tasks]="currentTasks"></bdr-task-load-list>
    </div>
</div>

<div *ngIf="auth.authenticated" class="notification-wrapper">
    <bdr-popup-notifications *ngFor="let not of popupNotifications" [notification]="not">
    </bdr-popup-notifications>
</div>

<bdr-contextual-menu
    *ngIf="contextualMenuService.visible"
    class="contextualmenu-factory"
    [ngClass]="{ 'no-layer': contextualMenuService.options.complex }"
    [target]="contextualMenuService.target"
    [component]="contextualMenuService.component"
    [options]="contextualMenuService.options"
    (onClose)="contextualMenuService.close()"
>
</bdr-contextual-menu>

<bdr-ctx-download-list *ngIf="auth.authenticated"></bdr-ctx-download-list>
