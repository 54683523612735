<header class="qb-group-header" *ngIf="depth !== 0">
  <bdr-icon class="qb-group-folder drag-handler" icon="{{ !collapsed ? 'folder' : 'folder-remove' }}" [esir]="true"
    title="{{ !collapsed ? 'Minimizar grupo' :  'Maximizar grupo' }}" (click)="toggleCollapsed()"></bdr-icon>
  <span class="group-title">Grupo</span>
  <bdr-icon class="qb-remove-group" icon="ico-delete" (click)="removeGroup(index)"></bdr-icon>
</header>
<div class="group-container" [ngClass]="{'bordered':depth !== 0}">
  <aside *ngIf="!collapsed">
    <bdr-select-pills *ngIf="query.rules.length > 1" class="qb-operators" [options]="logicOperators"
      [model]="query.condition" (modelChange)="query.condition = $event" vertical="true">
    </bdr-select-pills>
  </aside>
  <div class="qb-wrapper">
    <ul #groupItem>
      <ng-container *ngIf="!collapsed">
        <div class="qb-drop qb-drop-slot qb-drop-slot-group" [query-builder-drop]
          (dropRequest)="drop($event, query, true)">
        </div>
        <li #rowItem class="qb-item-row" *ngFor="let queryRule of query.rules; let queryRuleIndex = index"
          [attr.data-id]="queryRule['id']">
          <ng-container *ngIf="!queryRule.condition">
            <bdr-query-builder-condition class="qb-item" [queryBuilderDrag]="{ 'parent': query, 'element': queryRule }"
              [queryRule]="queryRule" [schemaItems]="schemaItems[origin]" [operators]="operators"
              [gridSelector]="gridSelector" [isVisibleButtonTrash]="isVisibleButtonTrash"
              (validationError)="handleValidationError($event)" (dragStartRequest)="dragStart($event)"
              (expandItemRequest)="expandItem($event, queryRuleIndex)"
              (removeConditionRequest)="removeCondition(queryRuleIndex)">
            </bdr-query-builder-condition>
            <div class="condition-drop"></div>
          </ng-container>

          <ng-container *ngIf="queryRule['condition']">
            <bdr-query-builder-group class="qb-item" [queryBuilderDrag]="{ 'parent': query, 'element': queryRule }"
              [query]="queryRule" [origin]="origin" [schemaItems]="schemaItems" [operators]="operators"
              [depth]="depth + 1" [index]="queryRuleIndex" [isVisibleButtonTrash]="isVisibleButtonTrash"
              [gridSelector]="gridSelector" (validationError)="handleValidationError($event)"
              (dragStartRequest)="dragStart($event)" (dragStartMainRequest)="dragStartMain($event)"
              (dropMainRequest)="dropMain($event)" (expandItemMainRequest)="expandItemMain($event)"
              (removeGroupRequest)="removeCondition($event)">
            </bdr-query-builder-group>
          </ng-container>
          <div class="qb-drop qb-drop-slot" [query-builder-drop]="queryRule" (dropRequest)="drop($event, query)">
          </div>
        </li>
      </ng-container>
    </ul>

    <div class="qb-add-items" *ngIf="!collapsed">
      <span class="qb-link" (click)="addCondition(query)">
        <bdr-icon class="icon" icon="ico-plus"></bdr-icon>
        <span>Agregar condición</span>
      </span>
      <span class="qb-link" (click)="addGroup(query)">
        <bdr-icon class="icon" icon="ico-plus"></bdr-icon>
        <span>Agregar grupo</span>
      </span>
    </div>
  </div>
</div>