<div class="create-delete-banner">
  <div *ngIf="showCreateDeleteBanner.showNewRows && !newRowObject.showNewRow && !showCreateDeleteBanner.showDeletesOrCopy">
    <i class="fa fa-plus-square-o" aria-hidden="true"></i>
    <span class="" (click)="toggleNewRow()">&nbsp; Añadir registro
    </span>
  </div>


  <div *ngIf="newRowObject.showNewRow">

    <i class="fa fa-check" aria-hidden="true"></i>
    <span class="" (click)="confirmNewRow()">&nbsp; Confirmar nuevo registro
    </span>

    <i class="fa fa-ban" aria-hidden="true"></i>
    <span class="" (click)="toggleNewRow()">&nbsp; Cancelar nuevo registro
    </span>
  </div>

  <div *ngIf="showCreateDeleteBanner.showDeletesOrCopy">

    <i class="fa fa-trash-o" aria-hidden="true"></i>
    <span class="" (click)="confirmDeleteRows()">&nbsp; Eliminar
    </span>

    <i class="fa fa-clone" aria-hidden="true"></i>
    <span class="" (click)="confirmCopyRows()">&nbsp; Copiar al año anterior
    </span>

    <i class="fa fa-ban" aria-hidden="true"></i>
    <span class="" (click)="toggleDeleteOrCopyRows()">&nbsp; Cancelar
    </span>
  </div>
</div>