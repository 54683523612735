<section *ngIf="(selectedColumn | json) === '{}'" class="body">
  <header class="header" (escapeDialog)="handleCloseDialog()">
    <div class="header-title">
      <span class="title-prefix">{{ title }}</span>
    </div>
  </header>
  <div class="body-wrapper">
    <div class="search-content-header content-header">
      <input class="items-list-input-search" type="text" [ngModel]="queryString"
        (ngModelChange)="filterColumns($event)" />
      <div class="items-list-icon-search">
        <bdr-icon *ngIf="iconSearch === 'search'" class="highlight-icon" icon="ico-search" (click)="openInputSearch()">
        </bdr-icon>
        <bdr-icon *ngIf="iconSearch === 'cancel-search'" class="highlight-icon" icon="ico-ko"
          (click)="closeInputSearch()"></bdr-icon>
      </div>
    </div>
    <div class="content-body">
      <ul class="column-list" *ngIf="displayedColumns.length > 0">
        <li class="column" *ngFor="let column of displayedColumns"><a (click)='handleSelectedColumn(column)'
            class="validations fake-icon">{{column.title | uppercase}}</a>
        </li>
      </ul>
    </div>
  </div>
</section>

<footer *ngIf="(selectedColumn | json) === '{}'" class="footer-wrapper">
  <div class="footer">
    <div class="footer-actions">
      <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()">Cancelar</bdr-button>
    </div>
  </div>
</footer>



<section class="body" *ngIf=" (selectedColumn | json) != '{}'">
  <header class="header" (escapeDialog)="handleCloseDialog()">
    <div class="header-title">
      <span class="title-prefix">Columna seleccionada</span>
    </div>
  </header>
  <br>
  <div class="body-wrapper">
    <div class="content-body">
      <div class="form-group">
        <label for="textAreaColumn">{{selectedColumn.title | titlecase}}</label>
        <textarea class="form-control" id="textAreaColumn" [(ngModel)]="stringToModify"></textarea>
      </div>
    </div>
  </div>
</section>

<footer *ngIf="(selectedColumn | json) != '{}'" class="footer-wrapper">
  <div class="footer">
    <div class="footer-actions">
      <bdr-button class="btn-save" size="small" (click)="handleSelectedColumn({})">Atrás</bdr-button>
      <bdr-button [disabled]="stringToModify === ''" class="btn-save" primary size="small"
        (click)="handleSaveModification()">Modificar</bdr-button>
    </div>
  </div>
</footer>