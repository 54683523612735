import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {
  NotificationsService,
  Notification,
} from '../../../../services/notifications/notifications.service';
import { cloneDeep } from 'lodash';

interface Column {
  name: string;
  label: string;
  type?: 'checkbox' | 'input' | 'select';
  options?: string[];
  colspan?: number;
  format?: RegExp;
}

const OFFICE_DIGITIZATION = 'Despacho-Digitalización';
const TOTAL_OFFICE_DIGITIZATION = 'Total_Despacho-Digitalización';

const LONG_UUCC_HEADERS = [
  { name: 'descripcion', label: 'Descripción' },
  { name: 'uucc_id', label: 'UUCC' },
  { name: 'origen_uucc', label: 'Origen UUCCC' },
  { name: 'mei_desc', label: 'MEI desc' },
  { name: 'mei', label: 'MEI' },
  { name: 'trabajo', label: 'Trabajo' },
  { name: 'importe_mano_obra', label: 'Importe mano de obra' },
  { name: 'importe_material_contrata', label: 'Importe material contrata' },
  { name: 'importe_material_empresa', label: 'Importe material empresa' },
  { name: 'importe_total', label: 'Importe total' },
  { name: 'importe_mtbt', label: 'Importe MT/BT' },
  { name: 'importe_despachos', label: 'Importe Despachos' },
  { name: 'cantidad_instalada', label: 'Cantidad instalada' },
  {
    name: 'tipo_reparto',
    label: 'Tipo de reparto',
    type: 'select',
    options: ['General', 'Cesión', 'UFD'],
  },
  { name: 'peso', label: 'Peso', type: 'input' },
  {
    name: 'tipo_importe',
    label: 'Tipo Importe',
    type: 'select',
    options: ['MT/BT', 'Despachos'],
  }
];

const SHORT_UUCC_HEADERS = [
  { name: 'descripcion', label: 'Descripción' },
  { name: 'origen_uucc', label: 'Origen UUCCC' },
  { name: 'cantidad_instalada', label: 'Cantidad instalada' },
  { name: 'importe_mtbt', label: 'Importe MT/BT' },
  { name: 'importe_despachos', label: 'Importe Despachos' },
  {
    name: 'tipo_reparto',
    label: 'Tipo de reparto',
    type: 'select',
    options: ['General', 'Cesión', 'UFD'],
  },
  { name: 'peso', label: 'Peso', type: 'input' },
  {
    name: 'tipo_importe',
    label: 'Tipo Importe',
    type: 'select',
    options: ['MT/BT', 'Despachos'],
  }
];

const HEADER_FORMAT_VALIDATION = {
  peso: new RegExp('^(0(\\.\\d{1,2})?|1(\\.0{1,2})?)$', '')
}

const VIRTUAL_TIPO_REPARTOS = ['UFD', 'Cesión', 'Despachos'];

const VIRTUAL_PREFIX = '@VIRTUAL_';

const VIRTUAL_ID_TIPO_REPARTO = {
  'UFD': '@VIRTUAL_UFD',
  'Cesión': '@VIRTUAL_CESION',
  'Despachos': '@VIRTUAL_DESPACHOS'
}


@Component({
  selector: 'closure-uucc',
  templateUrl: './closure-uucc.component.html',
  styleUrls: ['./closure-uucc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosureUuccComponent implements OnChanges {
  @ViewChild(MatTable, { static: true }) private matTable: MatTable<any>;

  @Input() items;
  @Input() canEdit: boolean;
  @Input() nonEditableMessage: string;
  @Input() workcode: string;
  @Input() retributiveYear: string;
  @Input() automaticAmounts: string = 'NO';

  @Output() onEditUUCC: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditUUCCDistribution: EventEmitter<any> = new EventEmitter<any>();

  dataSource: MatTableDataSource<any>;
  copyDataSource: MatTableDataSource<any>;
  search: { search: string; filters: string[] }; // Binding en vista del componente bdr-input-filter

  // First headers
  tipologyHeader: Column[];
  displayedTipologyHeader: string[];

  // Second headers
  installationsHeader: Column[] = [];
  displayedInstallationsHeader: string[];

  tipologiesHeader;
  uuccError = new Set();
  isLoading: boolean = false;

  
  ucsTotal: number = 0;
  uuccMtbtAmount: number = 0;
  uuccDispatchersAmount: number = 0;

  constructor(
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.reset();
      this.initTypologyHeader(this.items.installations_header);
      this.initInstallationsHeader(SHORT_UUCC_HEADERS);
      this.setDataSource(this.items.uucc_matrix);
      this.setCalculationTotal();
      this.tipologiesHeader = this.items.installations_header;
    }
   }

  reset() {
    this.installationsHeader = [];
    this.uuccError = new Set();
    this.tipologyHeader = [{ name: 'none', label: '', colspan: SHORT_UUCC_HEADERS.length }];

  }

  setCalculationTotal() {
    this.items.installations_header.forEach((header) => {
      const tipologies = header.installations
        .filter((header) => !header.installation_id.includes('Total'))
        .map((header) => header.installation_id);
      const typologyTotalName = header.installations
        .filter((header) => header.installation_id.includes('Total'))
        .map((header) => header.installation_id.trim())
        .join('');

      this.dataSource.data.map((row) => {
        let total = 0;
        for (let tipology of tipologies) {
          if (row[tipology]) {
            total += row[tipology];
          }
        }

        row[typologyTotalName] = this.totalRoundFixed(total);
      });
    }, this);
  }

  setDataSource(source) {
    this.dataSource = new MatTableDataSource<any>(source);
    
    let mtbtAmount = source.filter(uc=> !uc.uucc_id.includes(VIRTUAL_PREFIX)).map(uc=>uc.importe_mtbt)
    if (mtbtAmount.length == 0) {
      this.uuccMtbtAmount = 0
    } else {
      this.uuccMtbtAmount = mtbtAmount.reduce((a, b)=> a+b).toFixed(2);
    }

    let dispatchersAmount = source.filter(uc=> !uc.uucc_id.includes(VIRTUAL_PREFIX)).map(uc=>uc.importe_despachos)
    if (dispatchersAmount.length == 0) {
      this.uuccDispatchersAmount = 0
    } else {
      this.uuccDispatchersAmount = dispatchersAmount.reduce((a, b)=> a+b).toFixed(2);
    }

    this.ucsTotal = source.length - VIRTUAL_TIPO_REPARTOS.length
  }

  // First headers
  initTypologyHeader(headers) {
    let officesDigitizations = [];
    const createColumn = (header, index, info?: string) => {
      const column: Column = {
        name: header.tipology_description
          ? header.tipology_description
          : `null_description_${index}`,
        label: info ? `${header.tipology_description} ${info}` : header.tipology_description,
        colspan: header.installations.length,
      };

      return column;
    };

    headers.forEach((header, index) => {
      const isDepartments = header.tipology_description === OFFICE_DIGITIZATION;
      if (isDepartments) {
        const column = createColumn(header, index, '€');
        if (!this.tipologyHeader.find(col => col.name == column.name)) {
          officesDigitizations.push(column);
        }
      } else {
        const column = createColumn(header, index);
        if (!this.tipologyHeader.find(col => col.name == column.name)) {
          this.tipologyHeader.push(column);
        }
      }

    });
    this.tipologyHeader.push(...officesDigitizations);

    this.displayedTipologyHeader = this.tipologyHeader.map((header) => header.name);
  }

  initInstallationsHeader(uuccHeaders) {
    this.installationsHeader = cloneDeep(uuccHeaders);

    let officesDigitizations = [];

    this.items.installations_header.forEach((header) => {
      const isDispatchers = header.tipology_description === OFFICE_DIGITIZATION;
      const createColumn = (ins) => {
        const column: Column = {
          name: ins.installation_id,
          label: ins.alias,
          ...(!isDispatchers && { type: 'input' }),
        };
        return column;
      };

      if (isDispatchers) {
        header.installations.forEach((ins) => {
          const column = createColumn(ins);
          officesDigitizations.push(column);
        });
      } else {
        header.installations.forEach((ins) => {
          const column = createColumn(ins);
          this.installationsHeader.push(column);
        });
      }
    });

    this.installationsHeader.push(...officesDigitizations);
    this.displayedInstallationsHeader = this.installationsHeader.map((header) => header.name);
  }

  compareFn(c1, c2): boolean {
    return c1.toLowerCase() === c2.toLowerCase();
  }

  private round(x: number, precision: number) {
    let base = Math.pow(10, precision);
    return Math.round(x * base) / base;
  }

  isTotal(columnName: string) {
    return columnName.includes('Total_');
  }

  getTotalManualInstallationsByTypology(uucc, installation) {
    let installationsGroup = this.tipologiesHeader
      .filter((typo) => typo.installations.find((ins) => ins.installation_id === installation))[0]
      .installations.filter((ins) => !ins.installation_id.includes('Total_'))
      .map((ins) => ins.installation_id);
    let row = this.getRow(uucc);
    return installationsGroup.reduce((acc, val) => acc + (+row[val]), 0);
  }

  getRow(uucc: any) {
    return this.dataSource.data.find((row) =>
      row.uucc_id === uucc.uucc_id
      && row.mei === uucc.mei
      && row.trabajo === uucc.trabajo
      && row.cod_imputacion_uucc === uucc.cod_imputacion_uucc
      && row.tipo_reparto === uucc.tipo_reparto
    );
  }

  getRowErrors(uuccID) {
    return this.uuccError.has(uuccID);
  }

  getRowDistribution(uuccID) {
    return this.items.uucc_reparto.filter(uucc => uucc.uucc_id === uuccID).map(uucc => uucc.reparto).reduce((a, b) => a + b, 0) === 0;
  }

  getRowTitle(uuccID) {
    if (this.getRowErrors(uuccID)) {
      return "Las cantidades repartidas entre las instalaciones no suman la cantidad instalada total";
    }

    if (this.getRowDistribution(uuccID)) {
      return "Esta fila no tiene cantidades repartidas"
    }

    return "";
  }

  getTotalNameFromInstallation(installationID) {
    return this.tipologiesHeader
      .filter((typo) =>
        typo.installations.find(
          (ins) => ins.installation_id === installationID
        )
      )[0].installations.find(
        (ins) => ins.installation_id.includes('Total_')
      ).installation_id;
  }


  addNotification(type: 'confirmation' | 'error', message: string) {
    const saveNotification: Notification = {
      fixed: false,
      type,
      message,
      popup: true,
    };
    setTimeout(() => {
      this.notificationsService.add(saveNotification);
    }, 0);
  }

  getRowEditable(row) {
    let uuccId = row.uucc_id;
    let distributionType = row.tipo_reparto;
    if (uuccId.includes(VIRTUAL_PREFIX)) {
      return this.uuccVirtualCanEditCheck(distributionType)
    }
    return this.uuccRepartoCanEditCheck(distributionType);
  }

  canEditCell(distributionType, uuccId: String, columnName, row) {
    if (this.automaticAmounts === 'SI') {
      return false
    }

    if (uuccId.includes(VIRTUAL_PREFIX)) {
      if (['tipo_reparto', 'tipo_importe'].includes(columnName)) {
        return false
      }
      return this.uuccVirtualCanEditCheck(distributionType)
    }

    if (columnName === 'tipo_reparto') {
      return true
    }

    if (columnName === 'tipo_importe' && ( row.tipo_reparto !== 'UFD' )) {
      this.dataSource.data.find(elem => elem.uucc_id === uuccId).tipo_importe = 'MT/BT'
      return false
    }

    return this.uuccRepartoCanEditCheck(distributionType);
  }

  uuccRepartoCanEditCheck(distributionType) {
    let virtualId = VIRTUAL_ID_TIPO_REPARTO[distributionType]
    const virtualUuccAmount = this.items.uucc_reparto.some(uucc => uucc.uucc_id === virtualId && uucc.reparto != 0);
    return VIRTUAL_TIPO_REPARTOS.includes(distributionType) && !virtualUuccAmount;
  }

  uuccVirtualCanEditCheck(distributionType) {
    return !this.items.uucc_reparto
      .filter(uucc => {
        return !uucc.instalacion.includes("Despacho-Digitalización")
          && !uucc.uucc_id.includes(VIRTUAL_PREFIX)
          && this.items.uucc_matrix.find(u => u.uucc_id === uucc.uucc_id).tipo_reparto === distributionType;
      })
      .some(uucc => uucc.reparto != 0);
  }

  isString(value: string): boolean {
    return !Number(value);
  }

  handleShowedColumns(showAll) {
    if (showAll) {
      this.tipologyHeader[0] = { name: 'none', label: '', colspan: LONG_UUCC_HEADERS.length };
      this.initInstallationsHeader(LONG_UUCC_HEADERS)
    } else {
      this.tipologyHeader[0] = { name: 'none', label: '', colspan: SHORT_UUCC_HEADERS.length };
      this.initInstallationsHeader(SHORT_UUCC_HEADERS)
    }
  }

  handleQuantityAmount(isDistributionAmount) {
    this.setEditableFields(isDistributionAmount);

    if (isDistributionAmount) {
      this.copyDataSource = cloneDeep(this.dataSource);

      const installationsHeader = this.items.installations_header.filter(
        (header) => header.tipology_description !== OFFICE_DIGITIZATION
      );

      installationsHeader.forEach((header) => {
        const tipologies = header.installations
          .filter((ins) => !ins.installation_id.includes('Total'))
          .map((ins) => ins.installation_id);

        const typologyTotalName = header.installations
          .filter((ins) => ins.installation_id.includes('Total'))
          .map((ins) => ins.installation_id);

        this.dataSource.data.forEach((row) => {
          let total = 0;
          tipologies.forEach((t) => {
            const amount = (row[t] * row.importe_mtbt) / row.cantidad_instalada || 0;
            row[t] = this.totalRoundFixed(amount);
            total += row[t];
          });
          row[typologyTotalName] = this.totalRoundFixed(total);
        });
      }, this);
    } else {
      this.dataSource = this.copyDataSource;
    }
  }

  private setEditableFields(isDistributionAmount) {
    const tipologies = this.items.installations_header
      .filter((t) => t.tipology_description !== OFFICE_DIGITIZATION)
      .map((t) => t.installations.map((uucc) => uucc.installation_id))
      .flat();

    tipologies.forEach((tipology) => {
      const installation = this.installationsHeader.find(
        (installation) => installation.name === tipology
      );

      if (installation) {
        installation.type = isDistributionAmount ? null : 'input';
      }
    });
  }

  trackByFn(index) {
    return index;
  }

  private totalRoundFixed(total) {
    return Math.round(total * 100) / 100;
  }

  /*
   *
   * MANUAL
   *
   */

  editUUCC(event, fieldName, row) {
    if (fieldName === 'peso') {
      let peso = row.peso === '' ? 0 : row.peso;
      let regex = HEADER_FORMAT_VALIDATION[fieldName]
      if (!regex.test(peso.toString())) {
        this.uuccError.add(row.uucc_id);
        return;
      } else if (this.uuccError.has(row.uucc_id)) {
        this.uuccError.delete(row.uucc_id);
      }
    }
    this.onEditUUCC.emit({
      editedUUCC: {
        anio_informacion: this.retributiveYear,
        codigo_obra: this.workcode,
        uucc: row.uucc_id,
        cod_imputacion_uucc: row.cod_imputacion_uucc,
        trabajo: row.trabajo,
        mei: row.mei,
        [fieldName]: event
      },
      field: fieldName,
      uucc: this.dataSource.data
    });
  }

  fullAutomaticDistribution(columName) {
    let filteredDatasource = this.filteredUUCC();
    this.items.uucc_matrix.filter(uucc => {
      return !uucc.uucc_id.includes(VIRTUAL_PREFIX) &&
        this.canEditCell(uucc.tipo_reparto, uucc.uucc_id, columName, uucc)
        && filteredDatasource.some(row =>
          row.cod_imputacion_uucc === uucc.cod_imputacion_uucc &&
          row.uucc_id === uucc.uucc_id &&
          row.mei === uucc.mei &&
          row.trabajo === uucc.trabajo
        )
    }).forEach(uucc => {
      this.automaticDistribution(uucc.cantidad_instalada, columName, uucc)
    });
  }

  fullRemoveAutomaticDistribution(columName) {
    let filteredDatasource = this.filteredUUCC();
    this.items.uucc_matrix.filter(uucc => uucc.descripcion !== ''
      && filteredDatasource.some(row =>
        row.cod_imputacion_uucc === uucc.cod_imputacion_uucc &&
        row.uucc_id === uucc.uucc_id &&
        row.mei === uucc.mei &&
        row.trabajo === uucc.trabajo
      )
    ).forEach(uucc => {
      this.automaticDistribution(0, columName, uucc)
    });
  }

  private filteredUUCC() {
    let f = this.matTable.dataSource;
    return f instanceof Array ? f : this.dataSource.data;
  }

  automaticDistribution(installationValue, installationID, row) {
    let actuationTipology = installationID.replace('Total_', '');
    let uuccRow = this.getRow(row);
    this.tipologiesHeader
      .find((t) => actuationTipology === t.tipology_description)
      .installations.filter((installation) => installation.installation_id != installationID)
      .forEach((installation) => {
        let automaticValue = this.round(installation.coefficient * installationValue, 3);
        let automaticInstallationId = installation.installation_id;
        uuccRow[automaticInstallationId] = automaticValue;
        this.editUUCCDistributions(automaticValue, automaticInstallationId, row);
      });
  }

  editUUCCDistributions(installationValue, installationID, row, isManual = false) {
    installationValue = installationValue === '' ? 0 : installationValue;

    let uuccDistributions = this.items.uucc_reparto;

    let uucc = uuccDistributions.find(uucc => uucc.uucc_id === row.uucc_id && uucc.instalacion === installationID);
    if (!uucc) {
      uucc = {
        uucc_id: row.uucc_id,
        instalacion: installationID
      };
      uuccDistributions.push(uucc);
    }

    uucc.reparto = Number(installationValue);
    const hasDecimals = installationValue % 1 !== 0;
    if (isManual && hasDecimals) {
      this.uuccError.add(row.uucc_id);
      return;
    } else if (this.uuccError.has(row.uucc_id)) {
      this.uuccError.delete(row.uucc_id);
    }
    const [tipology, id] = installationID.split('||');

    let totalNameInstallation = this.getTotalNameFromInstallation(installationID);
    let totalGroupInstallations = this.getTotalManualInstallationsByTypology(row, installationID);
    let uuccRow = this.getRow(row);
    uuccRow[totalNameInstallation] = Math.round(totalGroupInstallations);

    this.checkUuccDistributionsError(row);

    this.onEditUUCCDistribution.emit({
      editedUUCCDistribution: {
        anio_informacion: this.retributiveYear,
        codigo_obra: this.workcode,
        tipologias_actuacion: tipology,
        uucc: row.uucc_id,
        cod_imputacion_uucc: row.cod_imputacion_uucc,
        trabajo: row.trabajo,
        instalacion: id,
        mei: row.mei,
        cantidad: installationValue,
      },
      uucc: this.dataSource.data
    });
  }

  private checkUuccDistributionsError(uucc: any) {
    let currentTipologies = this.tipologiesHeader.map(t => t.tipology_description);
    let totalTipologies = Object.keys(uucc)
      .filter((key) =>
        key.includes('Total_')
        && key !== TOTAL_OFFICE_DIGITIZATION
        && currentTipologies.includes(key.replace('Total_', ''))
      )
      .map((key) => uucc[key])
      .reduce((acc, val) => acc + val, 0);

    const totalInstalledAmount = uucc.cantidad_instalada;

    const error = (this.round(totalTipologies, 3) == totalInstalledAmount) ? false
      : (this.round(totalTipologies, 3) == 0 ? false : true);

    if (error) {
      this.uuccError.add(uucc.uucc_id);
    } else {
      this.uuccError.delete(uucc.uucc_id);
    }
  }

  isSticky(column: string): boolean {
    return ["descripcion", "cantidad_instalada"].includes(column);
  }

  rowColor(row) { 
    if (!this.getRowEditable(row)) {
      return 'no-editable'
    }

    if (this.getRowErrors(row.uucc_id)){
      return 'invalid-row'
    }

    if (this.getRowDistribution(row.uucc_id)) { 
      return 'incomplete-row';
    }

    return  'white-background';

  }

  fullSetToZeroAllAutomaticDistributions() {
    let totals: string[] = this.uniqByObject(
      this.dataSource.data
        .map(d=>
          Object.keys(d)
            .filter(k=>k.includes('Total_'))) 
            .reduce((accumulator, value) => accumulator.concat(value), [])  
        );

    totals.forEach(columnName=>{ 
      let filteredDatasource = this.filteredUUCC();
      this.items.uucc_matrix.filter(uucc => {
        return !uucc.uucc_id.includes(VIRTUAL_PREFIX) &&
          this.canEditCell(uucc.tipo_reparto, uucc.uucc_id, columnName, uucc)
          && filteredDatasource.some(row =>
            row.cod_imputacion_uucc === uucc.cod_imputacion_uucc &&
            row.uucc_id === uucc.uucc_id &&
            row.mei === uucc.mei &&
            row.trabajo === uucc.trabajo
          )
      }).forEach(uucc => {
        let installationID = columnName;
        let row = uucc;
        let actuationTipology = installationID.replace('Total_', '');
        let uuccRow = this.getRow(row);

        this.tipologiesHeader
          .find((t) => actuationTipology === t.tipology_description)
          .installations.filter((installation) => installation.installation_id != installationID)
          .forEach((installation) => {
            let automaticInstallationId = installation.installation_id;
            uuccRow[automaticInstallationId] = 0;
            this.editUUCCDistributions(0, automaticInstallationId, row);
          });
      
      });
    });

  }

  uniqByObject(array) {
    const result = [];
    for (const item of array) {
        if (!result.includes(item)) {
            result.push(item);
        }
    }
    return result;
}
}
