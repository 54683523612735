<section class="body">
  <div class="body-wrapper">
    <ul class="action-list">
      <li *ngFor="let action of actions">
        <a class="link" (click)="action.action(id)">
          <bdr-icon [icon]="action.icon" size="18px"></bdr-icon>
          <span class="caption">{{action.title}}</span>
        </a>
      </li>
    </ul>
  </div>
</section>
