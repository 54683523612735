<div [ngClass]="{ inline: horizontal, disabled: disabled }">
  <div class="caption" *ngIf="caption.length > 0 && (!error || errorMsg.length === 0)"
    [ngClass]="{ 'error-caption': error }">
    {{ caption }}
    <div class="error-caption" *ngIf="!bottomError && error && errorMsg.length > 0">
      {{ errorMsg }}
    </div>
  </div>
  <div class="input-wrapper">
    <div class="options-wrapper-top" *ngIf="showOptions && direction === 'top'">
      <div class="options">
        <div class="option" *ngFor="let option of options" (mousedown)="selectOption(option)">
          <span class="option-value" [ngClass]="{ bold: bold }">{{
            option.name
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrapper {{ size }}" [ngClass]="{
        'options-visible': showOptions,
        error: error && errorMsg.length > 0
      }">
      <div class="select" (click)="toggleOptions()" [ngClass]="{
          top: direction === 'top' && showOptions,
          bottom: direction === 'bottom' && showOptions,
          active: showOptions,
          inactive: !showOptions
        }">
        <span class="select-value" [ngClass]="{ bold: bold }" *ngIf="selectedOption && selectedOption.name">{{
          selectedOption.name }}</span>
        <span class="placeholder" *ngIf="!selectedOption || !selectedOption.name">{{ placeholder }}</span>
      </div>
    </div>
    <div class="options-wrapper" *ngIf="showOptions && direction === 'bottom'">
      <div class="options">
        <div class="option" *ngFor="let option of options" (mousedown)="selectOption(option)">
          <span class="option-value">{{ option.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="error-caption" *ngIf="bottomError && error && errorMsg.length > 0">
    {{ errorMsg }}
  </div>
</div>