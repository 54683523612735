<p class="caption" *ngIf="caption.length > 0 && (!error || errorMsg.length === 0)"
  [ngClass]="{ 'error-caption': error }">
  {{ caption }}
</p>

<p class="error-caption" *ngIf="grow && error && errorMsg.length > 0">{{ errorMsg }}</p>
<div class="outer-wrapper" [ngClass]="{ focus: focus, error: error}">
  <div class="wrapper" (click)="focusOnInput()"
    [ngClass]="{ focus: focus, disabled: disabled, error: error, static: !grow }">
    <div class="word" *ngFor="let word of model; let i = index" [ngClass]="{ error: validateWord(word) }">
      <span class="word-text" title="{{ word }}">{{ word }}</span>
      <bdr-icon class="remove-word-icon" [esir]="true" icon="cancel" (click)="removeWord(i)"></bdr-icon>
    </div>
    <input class="input" #input [(ngModel)]="value" [disabled]="disabled" (focus)="focus = true"
      (keydown)="keydown($event)" (paste)="processWords()" (blur)="onBlur()">
  </div>

  <div class="more" *ngIf="!grow && model.length > 0">
    <bdr-icon class="icon-more-info" icon="menu" size="24px" (click)="openMenu($event)">
    </bdr-icon>
  </div>
</div>