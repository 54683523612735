<div class="ci-header-installations">
  <div class="ci-header-installations__filter ci-title">
    Resumen instalaciones
  </div>
  <div class="ci-header-installations__download" (click)="downloadSummary()" title="Descargar el resumen de instalaciones de este año">
    <bdr-icon icon="ico-formularios"></bdr-icon> Resumen
  </div>
</div>

<div class="ci-summary">
  <bdr-table [items]="summary?.rows | filter: searchSummary" [columns]="summary?.headers"></bdr-table>
</div>

<h2 class="ci-title">Acta Puesta en Marcha</h2>
<div class="bdr-table">
  <bdr-table-installations-startup-act [workCode]="workCode" [columns]="startupAct.headers" [canEdit]="canEdit"
    [startupActs]="startupAct.rows" (onChangeStartupAct)="handleStartupActEdition($event)">
  </bdr-table-installations-startup-act>
</div>


<h2 class="ci-title">Detalles de las Instalaciones IGEA</h2>
<div class="ci-header-installations">
  <div class="ci-header-installations__filter">
    <bdr-input-filter [filters]="installations?.headers" (onSearch)="searchInstallations = $event"></bdr-input-filter>
  </div>
  <div class="ci-header-installations__actions">
    <div class="ci-header-installations__associates">
      <button class="bdr-btn bdr-btn-blue" (click)="openManualInstallationsDialog()">
        <bdr-icon icon="ico-plus"></bdr-icon>Asociar/desasociar
      </button>
    </div>

    <div class="ci-header-installations__display-headers">
      <bdr-visible-columns [filters]="installations.headers" (onColumnsVisible)="handleColumnVisible($event)">
      </bdr-visible-columns>
    </div>

    <div class="ci-header-installations__download" (click)="download()" title="Descargar las instalaciones de esta obra">
      <bdr-icon icon="ico-download"></bdr-icon> Descargar 
    </div>



    <div class="ci-header-installations__download" (click)="downloadAll()" title="Descargar todas las instalaciones de este año">
      <bdr-icon icon="ico-formularios"></bdr-icon> Instalaciones
    </div>

    <div class="ci-header-installations__download" (click)="openInstallationEditionsDialog()" title="Descargar ediciones">
      <bdr-icon icon="ico-edit"></bdr-icon>Edición masiva
    </div>

    <div class="ci-header-installations__total">
      <span>{{ items?.installations_detail?.data.length }}</span>
      <p>registro/s</p>
    </div>
  </div>
</div>

<div class="ci-installations">
  <div class="bdr-table">
    <bdr-table-installations 
      [workCode]="workCode" 
      [columns]="installations.headers"
      [automaticAmounts]="automaticAmounts"
      [filteredColumns]="installations.filteredColumns"
      [installations]="installations.rows | filter: searchInstallations" 
      [options]="installations.options"
      [canEdit]="canEdit" (onEditInstallation)="handleInstallationEdition($event)"></bdr-table-installations>
  </div>
</div>