import { Injectable } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { DataApiService } from '../data-api/data-api.service';
import { catchError } from 'rxjs/operators';
import { DialogService } from '../../containers/dialog/dialog.service';
import { DialogNotificationDetail } from '../../containers/dialog/dialog-notifications-detail/dialog-notification-detail.component';

export interface Notification {
  type: string;
  message: any;
  fixed: boolean;
  popup: boolean;
  id?: string;
  class?: string;
  read?: boolean;
  action?: {
    name: string,
    enable: boolean
  };
  detail?: any;
  additionalInfo?: any;
}

@Injectable()
export class NotificationsService {
  private notifications: Notification[] = [];
  private notificationSubject: Subject<Notification[]> = new Subject<Notification[]>();

  constructor(private dataApiService: DataApiService, private dialogService: DialogService) { }

  public getNotifications() {
    return this.notifications;
  }


  public getNotificationObservable(): Observable<Notification[]> {
    return this.notificationSubject.asObservable();
  }

  public refresh(): void {
    this.notificationSubject.next(this.notifications);
  }

  public addUnique(notification: Notification): void {
    let exists = this.notifications.find((item) => item.message === notification.message);
    if (!exists) {
      this.add(notification);
    }
  }

  public addAll(notifications: Notification[]) {
    this.notifications = notifications;
    this.refresh();
  }

  public add(notification: Notification): void {
    this.notifications.push(notification);
    this.refresh();
  }

  public removeById(id: string): void {
    let exists = this.notifications.find((item) => item.id === id);
    if (exists) {
      this.remove(exists[0]);
      this.refresh();
      this.dataApiService.deleteNotification(id).subscribe(res => {});
    }
  }

  public remove(notification: Notification): void {
    this.notifications.splice(this.notifications.indexOf(notification), 1)
    
  }

  public close(notification: Notification): void {
    const index = this.notifications.findIndex(
      (not) => JSON.stringify(not) === JSON.stringify(notification)
    );
    this.notifications.splice(index, 1);
    this.refresh();
  }

  public reset() {
    this.notifications = [];
    this.refresh();
  }

  public markAsRead(notification: Notification): void {
    notification.read = true;
    this.refresh();
  }

  public deleteAllNotifications(): void {
    this.dataApiService.deleteAllNotifications().subscribe(res => {
      this.add({
        type: "confirmation",
        message: "Alertas borradas correctamente",
        fixed: false,
        popup: true
      });
    }
    );;
  }

  public markAllAsRead(): void {
    this.notifications.forEach((item: Notification) => {
      item.read = true;
    });
    this.refresh();
  }

  public action(notification) {
    this.markAsRead(notification);
    let actionName = notification.action.name;
    if (notification.class == 'sheet_reload') {
      this.dataApiService.reloadSheet(actionName)
        .pipe(
          catchError(err => {
            this.add({
              type: "error",
              message: "Ha ocurrido un error al lanzar la tarea",
              fixed: false,
              popup: true
            });
            return throwError(err);
          }))
        .subscribe(res => {
          this.add({
            type: "info",
            message: "La tarea se ha planificado correctamente y se ejecutará en breve",
            fixed: false,
            popup: true
          });
        }
        );
    }
  }

  public showDetail(notification, id?: any) {
    this.markAsRead(notification);
    const dialogOptions: any = {
      component: DialogNotificationDetail,
      title: 'Detalle de notificaciones',
      fixed: true,
      size: {
        width: 40,
        height: 40,
      },
      bindings: {
        inputs: {
          notification: notification,
        },
        outputs: {
          closeDialog: this.dialogService.closeDialog.bind(this.dialogService),
        },
      },
    };
    if (!id || typeof id === 'object') {
      return this.dialogService.createDialog(dialogOptions);
    }
    this.dialogService.updateDialog(id, dialogOptions);
  }

  mergeNotifications(newNotifications) {
    let currentNotifications = this.getNotifications();

    // add new notifications
    newNotifications.forEach((newNotification) => {
      let existingNotification = currentNotifications.find(
        (currentNotification) =>
          currentNotification.message === newNotification.notification_message
      );

      if (!existingNotification) {
        this.add(this.toNotification(newNotification));
      }
    });

    // remove disappeared or finished notifications
    currentNotifications.forEach((currentNotification) => {
      if (newNotifications.every((newNotification) => newNotification.notification_message !== currentNotification.message)
        || (currentNotification.class === 'executing_process' && currentNotification.detail.detail_table.data[0][2] === 100)) {
        this.close(currentNotification);
      }
    });

    // disable sheet reload if executing 
    let executing_process_reasons = this.notifications
      .filter(notification => notification.class === 'executing_process')
      .map(notification => notification.detail.caused_by);

    this.notifications
      .filter(notifications => notifications.class === 'sheet_reload')
      .forEach(notification =>
        notification.action.enable = !executing_process_reasons.includes(notification.action.name))

    this.refresh();
  }

  setNotification(backendNotification) {
    let notification = this.toNotification(backendNotification);
    this.addUnique(notification);
  }

  private toNotification(backendNotification): Notification {
    let notification: Notification = {
      id: backendNotification.id,
      type: backendNotification.notification_type,
      class: backendNotification.notification_class,
      message: backendNotification.notification_message,
      fixed: true,
      popup: this.isPopupNotification(backendNotification.notification_class),
      action: backendNotification.notification_action ? {
        name: backendNotification.notification_action,
        enable: true
      } : null,
      detail: backendNotification.notification_detail,
      additionalInfo: backendNotification.notification_additional_info,
    };
    return notification;
  }

  private isPopupNotification(notificationClass) {
    return notificationClass !== 'executing_process';
  }

  clearErrors() {
    this.notifications = this.notifications.filter((not) => not.type !== 'error');
    this.refresh();
  }
}

export class NotificationText {
  static readonly ICON = {
    NONE: 'icon-none',
    REGULAR: 'icon-regular',
    INVERSE: 'icon-inverse',
  };

  static readonly SIZE = {
    REGULAR: 'size-regular',
    BIG: 'size-big',
  };

  static readonly TYPE = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
  };

  static readonly ICON_NAME = {
    ERROR: 'thumbs-down',
    INFO: 'info',
    SUCCESS: 'thumbs-up',
    WARNING: 'alert',
  };

  static readonly TIMER = {
    DEFAULT: 6,
  };
}
