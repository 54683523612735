<label [ngClass]="{'inline': horizontal === true}">
  <div class="input-caption" *ngIf="caption.length > 0"
    [ngClass]="{ disabled: disabled, 'input-error-caption': error }">
    {{ caption }}
    <span *ngIf="validationInfo" class="validation-info">({{validationInfo}})</span>
  </div>
  <p class="input-error-caption" *ngIf="!bottomError && error && errorMsg.length > 0">{{ errorMsg }}</p>
  <div class="input-wrapper {{ size }}" [ngClass]="{ disabled: disabled, error: error }">
    <input class="input" [maxlength]="maxLength" [ngClass]="{ 'error': error }" [type]="type" [disabled]="disabled"
      [placeholder]="placeholder" [ngModel]="model" (ngModelChange)="emitChange($event)"
      (change)="emitChangeValidate($event)" (focus)="focus()" (blur)="blur()" [min]="minValue" [max]="maxValue">
    <bdr-button *ngIf="button && !loading" id="buttonSave" size="small">
      <bdr-icon icon="ico-save" size="16px"></bdr-icon>
    </bdr-button>
    <bdr-icon *ngIf="loading" icon="ico-load" id="loadingIco" size="16px"></bdr-icon>
  </div>
  <p class="input-error-caption" *ngIf="bottomError && error && errorMsg.length > 0">{{ errorMsg }}</p>
</label>