<div draggable="{{ options.draggable }}" isContextualMenu="true" #menu class="menu" [ngClass]="{
    'menu-complex': options.complex,
    naked: options.naked,
    'arrow-bottom': positioning === 'bottom',
    draggable: options.draggable,
    branded: options.branded,
    hidden: cssHidden
}" [ngStyle]="{
    left: position.x + 'px',
    top: positioning === 'top' ? position.y + 'px' : null,
    bottom: positioning === 'bottom' ? position.y + 'px' : null
  }" (click)="$event.stopPropagation()" (contextmenu)="$event.stopPropagation()">
  <bdr-icon class="menu-close-button" *ngIf="options.complex" icon="ico-ko" (click)="close()"></bdr-icon>
  <bdr-reflect-component [component]="component" [bindings]="bindings"></bdr-reflect-component>
</div>

<canvas #rulerCanvas class="ruler-canvas" [width]="window.outerWidth" [height]="window.outerHeight">
</canvas>