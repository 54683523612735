<section class="body">
  <div class="body-wrapper">
    <p>
      El filtro se incluirá en la carpeta que seleccione a continuación:
    </p>
    <div class="form-row">
      <label>
        <span>{{filter.name}}</span>
        <div class="field-container">
          <bdr-select (modelChange)="folderId = $event" [options]="folders"></bdr-select>
        </div>
      </label>
    </div>
  </div>
</section>
<footer class="footer">
  <bdr-button [disabled]="!folderId" size="small" class="btn-save" primary (click)="saveChanges()">Guardar</bdr-button>
</footer>