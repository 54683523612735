<table>
  <thead *ngIf="headers">
    <tr>
      <th>Fecha</th>
      <th>Usuario</th>
      <th>Columna</th>
      <th>Modificación</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let field of item.changes">
      <td>
        <span *ngIf="!timeline">{{ item.displayDate }}</span><span *ngIf="timeline">{{ item.timelineDate }}</span>
      </td>
      <td>{{ item.user }}</td>
      <td>{{ field.title }}</td>
      <td class="field-values">
        <span class="field-old-value">{{ field.oldValue }} </span>
      </td>
      <td class="field-values">
        <bdr-icon class="arrow-icon" icon="ico-next" size="16px"></bdr-icon>
      </td>
      <td class="field-values">
        <span class="field-new-value">{{ field.newValue }}</span>
      </td>
    </tr>
  </tbody>
</table>