<div class="container-dialog" style="font-family:mULL Arial, Helvetica, sans-serif">

  <div class="container-dialog__header">
    <h1>Asociar / Desasociar Instalaciones</h1>
  </div>

  <div class="container-dialog__body">

    <div class="form-group">
      <mat-form-field>
        <mat-select placeholder="Tipo de instalación" [formControl]="installationType" [disabled]="validating">
          <mat-option value=""></mat-option>
          <mat-option value="LMTA">LMTA</mat-option>
          <mat-option value="LMTS">LMTS</mat-option>
          <mat-option value="RBTAt">RBTAt</mat-option>
          <mat-option value="RBTAp">RBTAp</mat-option>
          <mat-option value="RBTs">RBTs</mat-option>
          <mat-option value="CTs">CTs</mat-option>
          <mat-option value="EMF">EMF</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="hasError">No se ha encontrado la instalación.</mat-error>
      <mat-error *ngIf="validating">Recuperando la información de la instalación...</mat-error>
    </div>

    <div class="form-group">
      <mat-form-field>
        <input class="width-limitator" matInput placeholder="Asociar instalación" [formControl]="installationId"
          [disabled]="validating" (keydown.enter)="add($event)" />
        <bdr-icon class="right-align" (click)="add($event)" icon="ico-plus"></bdr-icon>
      </mat-form-field>
    </div>

    <div class="form-group installations-associates">
      <h2>La obra quedará con las siguientes instalaciones:</h2>
      <ng-container *ngFor="let installation of installations; let i = index">
        <ng-container *ngIf="installation.action != 0">
          <mat-form-field class="example-full-width">
            <input class="width-limitator" matInput
              [placeholder]="installation.codigo_bdr_instalaciones + ' (' + installation.secuencial + ')'"
              disabled='true' />
            <bdr-icon class="right-align" icon="ico-delete" (click)="remove(i)"></bdr-icon>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="container-dialog__footer">
    <button class="container-dialog-bdr-btn bdr-btn-white" (click)="close()">Volver</button>
    <button class="container-dialog-bdr-btn bdr-btn-blue" (click)="save()">Aplicar cambios</button>
  </div>

</div>